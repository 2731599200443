import React, { useEffect, useState } from 'react';

import Tabs from 'react-responsive-tabs';
import Details from './Details';
import Attachment from './Attachment';
import Evaluation from './Evaluation/Evaluation';

import PageHeader from '../../../assets/components/pageheader/PageHeader';

import Introduction from './Introduction/Introduction';
import { useNavigate, useParams, useLocation } from 'react-router';
import InpersonIcon from '../../../assets/images/inperson_interview_icon.png'
import OnlineInterviewIcon from '../../../assets/images/online_interview_icon.png'
import JournalIcon from '../../../assets/images/journal_icon.png'
import ForumIcon from '../../../assets/images/forum_icon.png'
import SurveyIcon from '../../../assets/images/survey_icon.png'
import Activity from './Activity/Activity';
import ArrowLeft from '../../../assets/images/arrow_left.png';
import IntroIcon from '../../../assets/images/intro_header_icon.png';
import DetailIcon from '../../../assets/images/detail_header_icon.png';
import AttachementIcon from '../../../assets/images/attachement_header_icon.png';
import EvaluationIcon from '../../../assets/images/evaluation_header_icon.png';
import ActivityIcon from '../../../assets/images/activity_header_icon.png';
import GroupingIcon from '../../../assets/images/grouping_header_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../../actions/project_actions';


// *********** This file which is called on click of view icon from project listing **********

const Project = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const projectState = useSelector(state => state.Project)
    const params = useParams()
    
    //const query = new URLSearchParams(search);
    const dispatch = useDispatch()
    const [projectTitle, setProjectTitle] = useState('')

    const tabsContent = [
        {
            title: 'Introduction',
            content: <Introduction />
        },
        {
            title: 'Details',
            content: <Details />
        },
        {
            title: 'Attachments',
            content: <Attachment />
        },
        {
            title: 'Screenings',
            content: <Evaluation />
        },
        {
            title: 'Activities',
            content: <Activity />
        },
        // {
        //     title: 'Groupings',
        //     content: <Grouping />
        // }
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }
    const getProjectDetails = () => {
        dispatch(getProject(params.projectid))
            .then(response => {
                setProjectTitle(response.payload.content.title)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getProjectDetails()
    }, [])

    useEffect(() => {
        projectState.projectDetails && setProjectTitle(projectState.projectDetails.title)
    }, [projectState])

    // For changing page header in different tab
    const header = () => {
        switch (getActiveTab()) {
            case 0:
                return <div className="admin_header"><PageHeader
                    heading={projectTitle} img={IntroIcon}
                    btnText="Edit"
                    btnClick={() => history("/researcher/project/edit-intro/" + params.projectid)}
                />
                </div>
            case 1:
                return <div className="project_header"><PageHeader
                    heading={projectTitle} img={DetailIcon}
                /></div>

            case 2:
                return <div className="admin_header"><PageHeader
                    heading={projectTitle} img={AttachementIcon}
                /></div>
            case 3:
                return <div className="project_header"><PageHeader
                    heading={projectTitle} img={EvaluationIcon}
                    btnText="Create Screener"
                    btnClick={() => history("/researcher/project/create-evaluation/" + params.projectid)}
                /></div>

            case 4:
                return <div className="project_header"><PageHeader
                    heading={projectTitle} img={ActivityIcon}
                    btnText="Create Activity"
                    btnClick={() => console.log("I'm clicked")}
                    isDrop={true}
                    droplist={[
                        { text: 'In-depth Interview', icon: InpersonIcon, onClick: () => history("/researcher/project/create-interview/" + params.projectid) },
                        { text: 'Focus Group Discusion', icon: InpersonIcon, onClick: () => history("/researcher/project/create-inpersonforum/" + params.projectid) },
                        { text: 'Online In-depth Interview', icon: OnlineInterviewIcon, onClick: () => history("/researcher/project/create-onlineinterview/" + params.projectid) },
                        { text: 'Survey Form', icon: SurveyIcon, onClick: () => history("/researcher/project/create-survey/" + params.projectid) },
                        { text: 'Diary', icon: JournalIcon, onClick: () => history("/researcher/project/create-journal/" + params.projectid) },
                        { text: 'Forum', icon: ForumIcon, onClick: () => history("/researcher/project/create-forum/" + params.projectid) },
                    ]}
                /></div>

            case 5:
                return <div className="project_header"><PageHeader
                    heading={projectTitle} img={GroupingIcon}
                /></div>

        }
    }

    const getActiveTab = () => {
        switch (path) {
            case '/researcher/project/intro':
                return 0
            case '/researcher/project/detail':
                return 1
            case '/researcher/project/attachement':
                return 2
            case '/researcher/project/evaluation':
                return 3
            case '/researcher/project/activity':
                return 4
            case '/researcher/project/grouping':
                return 5


            default:
                return false;
        }
    }

    // For changing the tab
    const setActiveTab = (key) => {
        switch (key) {
            case 0:
                history('/researcher/project/intro/' + params.projectid)
                break;
            case 1:
                history('/researcher/project/detail/' + params.projectid)
                break;
            case 2:
                history('/researcher/project/attachement/' + params.projectid)
                break;
            case 3:
                history('/researcher/project/evaluation/' + params.projectid)
                break;
            case 4:
                history('/researcher/project/activity/' + params.projectid)
                break;
            case 5:
                history('/researcher/project/grouping/' + params.projectid)
                break;


            default:
                return '/researcher/project/intro';
        }
    }

    return (
        <>

            {header()}

            {/* Back Icon */}

            <span onClick={() => getActiveTab() ? history("/researcher/project") : history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label style={{ cursor: 'pointer' }} className="ml-2">Back</label>
            </span>



            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false}
                    selectedTabKey={getActiveTab() !== false ? getActiveTab() : 4} items={getTabs()} onChange={(item) => { setActiveTab(item) }}
                />
            </div>
        </>
    )

}

export default Project;