import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';

import {
    CardHeader,
    Card,
    CardBody
} from "reactstrap";
import avatar from '../../../../../../assets/images/profile_placeholder.png'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { getAllParticipantReplyStreams, getAllPostReply, getAllReplyComments } from '../../../../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import CreateExcerpt from '../../view/Excerpts/CreateExcerpt';
// import CreateExcerpt from '../../view/Excerpts/CreateExcerpt';
import styles from '../../../../../../assets/css/rstyling.module.css'
import CommentIcon from '../../../../../../assets/images/comment_ico.png'

const Stream = () => {

    const [isModalOpen, setModal] = useState(false)

    const [excerptData, setExcerptData] = useState({
        selectedString: '',
        note: '',
        activity: '',
        responseAuthor: ''
    })

    const resetData = () => {
        setExcerptData({
            selectedString: '',
            note: '',
            activity: '',
            responseAuthor: ''
        })
    }

    // Show Excerpt in Modal
    const showModal = (author, details) => {
        console.log("INN", window.getSelection(), details, author)
        resetData()
        setModal(!isModalOpen)

        setExcerptData(prevState => ({
            ...prevState,
            selectedString: window.getSelection().toString(),
            activityType: details.title,
            activityTitle: details.title,
            activity: params.activityid,
            responseAuthor: author
        }))

    }

    const activityParticipantsState = useSelector(state => state.ActivityParticipant)
    const commentState = useSelector(state => state.Comments)

    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    const [isLoading, setLoading] = useState(true)
    const [commentsPostId, setCommentsPostId] = useState('')
    const [postComments, setPostComments] = useState([])
    const [userStreams, setUserStreams] = useState([])

    const dispatch = useDispatch()
    const params = useParams()

    const getForumReply = (isNew, page = 1, limit = 5) => {

        dispatch(getAllParticipantReplyStreams({ activityId: params.activityid, page: page, limit: limit }))
            .then((response) => {
                setLoading(false)
                isNew ? setUserStreams(response.payload.content.userStreams) : setUserStreams([...userStreams, response.payload.content.userStreams])
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    const getComments = (id, isNew, page = 1, limit = 10) => {
        dispatch(getAllReplyComments({ postId: id, page: page, limit: limit }))
            .then((response) => {
                setCommentsPostId(id)
                isNew ? setPostComments(response.payload.content.comments) :
                    setPostComments([...postComments, ...response.payload.content.comments])
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getForumReply(true)
    }, [])

    const next = (e) => {
        e.preventDefault()
        getForumReply(false, activityParticipantsState.currentPage + 1, 4)
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Card */}
                <div>

                    {
                        userStreams.length > 0 ?
                            userStreams.map((stream, index) => {
                                return <Card className="main-card mb-3" >
                                    <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                                        <img src={stream.author.profileImage ? stream.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                        {stream.author.userName}
                                    </CardHeader>

                                    <CreateExcerpt
                                        data={excerptData} setData={setExcerptData}
                                        isModalOpen={isModalOpen} showModal={() => setModal(false)}
                                     />

                                    <CardBody>

                                        {
                                            stream.posts.length > 0 &&
                                            stream.posts.map((reply, index) => {
                                                return <VerticalTimeline key={index} layout="1-column"
                                                    className="vertical-time-simple vertical-without-time">

                                                    <VerticalTimelineElement className="vertical-timeline-item" >

                                                        <h4 className="timeline-title" style={{ fontWeight: '500' }}>{new Date(reply.createdAt).toLocaleDateString('en-US', options)}</h4>

                                                        {/* Description */}
                                                        <div  onClick={() => showModal(stream.author, reply)}>
                                                            <p id="response" dangerouslySetInnerHTML={{ __html: reply.description }}></p>
                                                        </div>


                                                        {/* Images */}
                                                        {
                                                            reply.images && reply.images.length > 0 &&
                                                            reply.images.map(image => {
                                                                return <div className="px-4">
                                                                    <img src={image} style={{ borderRadius: '10px' }} width="250px" height="250px" alt="reply-img" />
                                                                </div>
                                                            })
                                                        }
                                                        {/* comment Count */}
                                                        {/* {
                                                        reply.comments > 0 &&
                                                        <div className={`${styles.text_blue} mt-3`} onClick={() => getComments(reply._id, true)}>
                                                            <span style={{ fontSize: '0.85rem', fontWeight: '600', cursor: 'pointer' }}><img src={CommentIcon} alt="" className="mr-1" width="20" />
                                                                View {reply.comments} Comments
                                                            </span>
                                                        </div>
                                                    } */}


                                                        {/* Comments */}

                                                        {/* {
                                                        commentsPostId === reply._id &&
                                                        <div className="my-3 ml-4" >
                                                            {
                                                                postComments.length > 0 &&
                                                                postComments.map((comment, index) => {
                                                                    if (comment.post === reply._id) {
                                                                        return <div key={index} className="my-3" >
                                                                            <div className="d-flex mb-2">

                                                                                <div className="d-flex mr-auto">
                                                                                    <img src={comment.author.profileImage ? comment.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                                                                    <h4 className="timeline-title mb-0 align-self-center" style={{ fontWeight: '500' }}>{comment.author.userName}</h4>
                                                                                </div>

                                                                                <div className="text-right">
                                                                                    <small className="text-muted" style={{ fontWeight: '500' }}>{new Date(comment.created_at).toLocaleDateString('en-US', options)}</small>
                                                                                </div>

                                                                            </div>

                                                                            <p id="comment" style={{ userSelect: 'text' }}>{comment.description}</p>


                                                                        </div>

                                                                    }


                                                                })
                                                            }
                                                        </div>
                                                    } */}

                                                        {/* Load More Data */}
                                                        {/* {
                                                        commentsPostId === reply._id && commentState.currentPage !== commentState.totalPages &&
                                                        <div className={styles.card_border} style={{ borderBottom: 0, borderRadius: 0, borderTop: 0 }}>
                                                            <div className={"pb-2 ml-5"}>
                                                                <div className="row px-3">
                                                                    <div className="mx-2 align-self-center d-inline-flex" onClick={() => getComments(reply._id, false, commentState.currentPage + 1, 10)}>

                                                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>View More Comments</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    } */}



                                                    </VerticalTimelineElement>



                                                </VerticalTimeline>
                                            })
                                        }

                                    </CardBody>

                                </Card>

                            }) :
                            <div className={"pb-2 ml-5"}>
                                <div className="row px-3">
                                    <div className="col -12 mt-3 align-self-center text-center">
                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Responses Found</span>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

                {/* Load more data */}
                {
                    activityParticipantsState.totalPages > 0 && activityParticipantsState.totalPages !== activityParticipantsState.currentPage &&
                    <div className={"pb-2 ml-5"}>
                        <div className="row px-3">
                            <div className="col -12 mt-3 align-self-center text-center" onClick={(e) => next(e)} >
                                {/* Comment Counts */}
                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Replies</span>
                            </div>
                        </div>
                    </div>
                }


            </LoadingOverlay>

        </TransitionGroup >
    )
}

export default Stream

