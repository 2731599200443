import { USER_RESEARCH_HISTORY } from "../actions/types";

let research_history_state = { researchHistory:[], totalPages:0, currentPage: 1 };
export default function (state = research_history_state, action) {
    switch (action.type) {
        case USER_RESEARCH_HISTORY:
            if (state.researchHistory.length === 0) {
                return {
                    ...state,
                    researchHistory: action.payload.content.projects,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.researchHistory =  state.researchHistory.concat(action.payload.content.projects);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    researchHistory: concatResult.researchHistory,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        default:
            return state;
    }
}
