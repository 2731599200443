import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { useState } from 'react';
import SecureLS from 'secure-ls';
import { Line } from 'rc-progress';
import { participantsProjects, getProjectsCompletionPercentage } from '../../../actions/project_actions';
function ContinueProject() {

    const dispatch = useDispatch()
    const [projectData, setProjectData] = useState([])
    const [projectPercentage, setProjectPercentage] = useState([])
    const [isLoading, setLoading] = useState(false)
    const ls = new SecureLS()
    const navigate = useNavigate()
    const userFirstName = ls.get('accessUser').user.firstName
    const userId = ls.get('accessUser').user._id

    

    useEffect(() => {
        let currentPage = 1;
        let dataLimit = 5
        //setLoading(true)
       let interest={
         interest : '',
        continueProjectBlock : true
       }
        
        dispatch(participantsProjects(currentPage, dataLimit, interest))
            .then(response => {
console.log('continue project length ->',response.payload.content.projects)
                setProjectData(response.payload.content.projects)
                
                let projectIds = response.payload.content.projects

                dispatch(getProjectsCompletionPercentage(projectIds, userId))
                    .then(response => {
                     
                        setProjectPercentage(response.payload.content)
                    }).catch(err=>{
                        console.log(err);
                    })
                //setLoading(false)
            })
            .catch(err => {
                console.log(err);
                //setLoading(false)
            })
    }, [])


    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>
















                        {projectData && projectData.length > 0 ?

                            <div className="row ">

                               
                               
                                <div>
                                    {/* main block */}
                                    <p  style={{ color: '#000', fontWeight: '500', fontStyle: 'normal', fontSize: '22px' }}>Continue Your Project</p>
                                    {/* <div className={`${styles3.card_border_borderless}`} > */}



                                        <div  className='col-12 d-flex ' style={{ height: 'auto',overflow: projectData.length >1 ? 'scroll':'hidden' }}>
                                            {/* <div className="d-flex" style={{ height: '15rem'}}> */}

                                            {projectData && projectData.length > 0 ? projectData.map(x => {
                                                if (x != null) {
                                                    const matchingProject = projectPercentage && projectPercentage.find(project => project.projectId === x._id);
                                                  

                                                    const completionPercentage = matchingProject ? matchingProject.projectCompletionPercentage : '';
                                                  
                                                    
                                                    return (
                                                        <div className={`${styles.card_border} col-12 mx-1 `}  key={x._id} >

                                                            <div className='row mt-2'>
                                                                <div className="col-4">
                                                                    <img src='https://img.freepik.com/free-vector/project-management-design-concept-symbolizing-analysis-solving-problems-isometric-vector-illustration_1284-77122.jpg' className={styles3.project_tag_banner2}></img>
                                                                </div>
                                                                <div className="col-8">
                                                                    
                                                                <Link to={`/respondent/project-detail-view/${x._id}`}>
                                                                    
                                                                <p className={styles3.continue_project_title}>{x.title}</p>
                                                                    
                                                                    </Link>
                                                                    
                                                                  


                                                                    <p style={{ color: '#B8C3C9', fontWeight: '500', fontSize: '15px' }}>{x.company ? x.company:'Consumer Connect'}</p>
                                                                </div>

                                                            </div>
                                                            <div className='row py-3'>
                                                                <div className='col-12'>
                                                                   
                                                                    <Line  percent={completionPercentage} strokeWidth={4} trailWidth={4} trailColor='#F4F6F8' strokeColor="#0069AB" />
                                                                </div>
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='col-6'>
                                                                    <span ><img src='https://www.svgrepo.com/show/382106/male-avatar-boy-face-man-user-9.svg' className={`${styles3.continue_project_admin_avatar} `}></img></span><span className='mx-2' style={{ color: '#000', fontWeight: '500' }}>{x.admin ? x.admin : 'Administrator'}</span>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <p className={styles3.continue_project_percentage}>{completionPercentage && completionPercentage !=null && completionPercentage !='' ? completionPercentage: 0}%</p>
                                                                </div>

                                                            </div>


                                                        </div>
                                                    );
                                                } else {
                                                    return null; // Skip null values
                                                }
                                            }) : ''}

                                            
                                        </div>
                                 {/* //   </div> */}
                                </div> 
                                


                            </div> :''
                }






















                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />
        </TransitionGroup>
    )

}

export default ContinueProject;