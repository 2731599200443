import { SMS_TEMPLATE_AVAILABLE, EMPTY_SMS_TEMPLATE, GET_SMS_TEMPLATE_DETAILS } from '../actions/types';

let smsTemplateDataState = { smsTemplates:[], totalPages:0, currentPage: 1, smsTemplateDetails: {} };
export default function (state = smsTemplateDataState, action) {
    switch (action.type) {
        case SMS_TEMPLATE_AVAILABLE:
            if (state.smsTemplates.length === 0) {
                return {
                    ...state,
                    smsTemplates: action.payload.content.templates,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.smsTemplates =  state.smsTemplates.concat(action.payload.content.templates);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    smsTemplates: concatResult.smsTemplates,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case EMPTY_SMS_TEMPLATE:
            return {...state, smsTemplates: [], totalPages: 0, currentPage: 1 };
        case GET_SMS_TEMPLATE_DETAILS:
            return {...state, smsTemplateDetails: action.payload.content }
        default:
            return state;
    }
}
