import React, { useEffect, useState } from 'react'
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import Listing from '../../../../../assets/components/listing/Listing';
import { Card, CardBody, Label, Button, FormGroup, CardHeader } from "reactstrap";
import { getPostWordCloud, getSurveyWordCloud } from '../../../../../actions/activities_reply_actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from '../../../../../assets/css/rstyling.module.css';
import { getActivity } from '../../../../../actions/activities_actions';

const resizeStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 'auto',
    border: "none",
    background: "#ffffff",

};


const WordCloud = () => {

    // const wordCloudState = useSelector(state => state.WordCloud)
     const [isLoading, setLoading] = useState(true)
 
     const dispatch = useDispatch()
     const location = useLocation();
 const currentPath = location.pathname;
     const history = useNavigate()
     const params = useParams()
 
     const [isAllQuestions, setAllQuestions] = useState(false)
     const [isShowQuestion, showQuestion] = useState(false)
 
     const [selectedQuestion, setSelectedQuestion] = useState([])
     const [isShowQuestions, setShowQuestions] = useState(false)
     const [questionList, setQuestionList] = useState([])
 
     const path = currentPath
 
     const [column] = useState([
         { Name: 'Rank', Type: 'serialNo' },
         { Name: "Word", Type: 'string', key: 'text' },
         { Name: "Frequency", Type: 'number', key: 'value', width: 110 },
     ])
 
     const [wordCloud, setWordCloud] = useState([])
     const [isShowWordCloud, setShowWordCloud] = useState(false)
 
     // word cloud options
     const options = {
         rotations: 2,
         rotationAngles: [-90, 0],
         fontFamily: 'Poppins'
     };
     function removeSpanTags(inputString) {
         
         let questionText= inputString.questionText.replace(/<span>|<\/span>/g, '');
         let questionId = inputString.questionId
         return {
             questionId: questionId,
             questionText: questionText
         }
       }
 
     const getSurveyQuestionWordCloud = () => {
         if (isShowQuestion || isAllQuestions) {
             setLoading(true)
             let questionId = []
             if (isShowQuestion) {
                 questionId = selectedQuestion.map(question => question.questionId)
             }
             dispatch(getSurveyWordCloud({ questionIds: questionId, activityId: params.activityid }))
                 .then((response) => {
                     console.log('response of wordcloud->', response.payload.content)
                     setShowWordCloud(true)
                     setWordCloud([...response.payload.content.wordCloud])
                     setLoading(false)
                 })
                 .catch(err => {
                     setLoading(false)
                 })
         }
 
     }
 
     useEffect(() => {
         if (path === `/admin/project/journal-analytics/${params.projectid}/${params.activityid}` ||
             path === `/admin/generalforum-analytics/${params.activityid}` ||
             path === `/admin/project/forum-analytics/${params.projectid}/${params.activityid}`) {
             dispatch(getPostWordCloud({ activityId: params.activityid }))
                 .then((response) => {
                     setShowWordCloud(true)
                     setWordCloud([...response.payload.content.wordCloud])
                     setLoading(false)
                 })
                 .catch(err => {
                     setLoading(false)
                 })
 
         } else {
             setLoading(false)
             dispatch(getActivity(params.activityid))
                 .then((response) => {
                     const pages = response.payload.content.survey.pages
                     const questions = []
 
                     for (let i = 0; i < pages.length; i++) {
                         for (let j = 0; j < pages[i].questions.length; j++) {
                             if (pages[i].questions[j].questionType === 'long text') {
                                 questions.push({
                                     questionId: pages[i].questions[j]._id,
                                     questionText: pages[i].questions[j].questionText
                                 })
                             }
                         }
                     }
                    
                     const questionsAll = questions.map(removeSpanTags)
                     
                     setQuestionList(questionsAll)
                     setShowQuestions(true)
                 })
                 .catch(err => {
                     console.log(err)
                 })
         }
 
     }, [])
 
     return (
         <div className="container-fluid">
             <h4 className="text-capitalize m-3" style={{ fontWeight: '500', fontSize: '0.9rem' }}>
                 {/* Activity Name */}
             </h4>
 
             <div className="tbl_round mt-3">
 
                 {
                     isShowQuestions &&
                     <>
                         {/* Questions list for surveys */}
                         <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                             <CardHeader className={styles.text_blue}>Questions WordCloud</CardHeader>
                             <CardBody>
                                 <div className="row">
                                     <div className="col-12">
                                         <FormGroup>
                                             <input type="checkbox" id="allQuestions" className="mr-2" checked={isAllQuestions}
                                                 onChange={(e) => setAllQuestions(e.target.checked)}
                                             />
                                             <label for="allQuestions">All Questions</label>
                                         </FormGroup>
                                     </div>
                                     <div className="col-12">
                                         <FormGroup>
                                             <input type="checkbox" id="question" className="mr-2" checked={isShowQuestion}
                                                 onChange={(e) => { showQuestion(e.target.checked) }}
                                             />
                                             <label for="question">Select Question</label>
                                         </FormGroup>
                                     </div>
                                     <div className="col-12">
                                         {
                                             isShowQuestion &&
                                             <FormGroup className="light_typeahead custom_drop">
                                                 <Label for="questionList">Question</Label>
                                                 <Typeahead
                                                     id="questionList"
                                                     multiple
                                                     onChange={setSelectedQuestion}
                                                     selected={selectedQuestion}
                                                     options={questionList}
                                                     labelKey={(option) => `${option.questionText}`}
                                                     placeholder="Select Question"
                                                 />
                                             </FormGroup>
                                         }
                                     </div>
                                 </div>
 
                             </CardBody>
                         </Card>
 
                         <div className="row mb-3">
                             <div className="col-12 align-self-center pt-3 text-right" >
                                 <Button className={`${styles.bg_blue} px-5 w-25`} onClick={() => getSurveyQuestionWordCloud()}>
                                     Generate WordCloud
                                 </Button>
                             </div>
                         </div>
                     </>
                 }
 
                 <LoadingOverlay tag="div" active={isLoading}
                     styles={{
                         overlay: (base) => ({
                             ...base,
                             background: "#fff",
                             opacity: 0.5,
                         }),
                     }}
                     spinner={
                         <Loader color="#6c757d" active type="ball-pulse" />
                     }>
                     {
                         isShowWordCloud &&
                         <>
                             <Resizable
                                 defaultSize={{
                                     width: 'auto',
                                     height: 300
                                 }}
                                 style={resizeStyle}
                             >
                                 <div style={{ width: "100%", height: "100%" }}>
                                     {/* Word cloud */}
                                     <ReactWordcloud words={wordCloud} options={options} />
                                 </div>
                             </Resizable>
 
                             <Card className="main-card mb-3">
 
 
                                 <CardBody className="p-0">
 
                                     {/* Listing */}
                                     <div className="table_border">
                                         <Listing columnName={column}
                                             data={wordCloud}
                                             dataLimit={wordCloud.length}
                                             // currentPage={wordCloudState.currentPage ? wordCloudState.currentPage : 1}
                                             // totalPages={wordCloudState.totalPages ? wordCloudState.totalPages : 1}
                                             // getListingData={(currentPage, dataLimit) => getWordCloud(currentPage, dataLimit)}
                                             // startLoading={() => setLoading(true)}
                                             isStriped={true}
                                         />
                                     </div>
 
                                 </CardBody>
                             </Card>
                         </>
                     }
 
                 </LoadingOverlay>
 
             </div>
         </div>
     )
 }

export default WordCloud
