import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

import AttachementIcon from '../../../../../assets/images/attachement_header_icon.png'
import PageHeader from '../../../../../assets/components/pageheader/PageHeader';
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import ReportTable from '../../../../../assets/components/report-table/ReportTable';

import { getSurveyReports } from '../../../../../actions/activities_reply_actions';

const SurveyReports = () => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [participants, setParticipant] = useState([])
    const [responses, setResponse] = useState([])

    const getReport = () => {
        dispatch(getSurveyReports({ activityId: params.activityid }))
            .then((response) => {
                let participants = []
                let survey = []

                for (let i = 0; i < response.payload.content.reports.length; i++) {

                    participants.push(response.payload.content.reports[i].author.userName)
                    response.payload.content.reports[i].questions.map(question => {
                        if (i === 0) {
                            survey.push({
                                question: question.questionText,
                                responses: [question.isReplied]
                            })
                        } else {
                            for (let j = 0; j < survey.length; j++) {

                                if (survey[j].question === question.questionText) {
                                    survey[j].responses.push(question.isReplied)
                                }

                            }
                        }

                    })
                }
                setParticipant(participants)
                setResponse(survey)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getReport()
    }, [])

    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                    // position: 'fixed',
                    // top: '2%'
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                <div className="project_header"><PageHeader
                    heading="Reports" img={AttachementIcon}
                /></div>

                <div className="container-fluid">

                    {/* Back Icon */}
                    <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <h4 className="text-capitalize m-3" style={{ fontWeight: '600', fontSize: '1rem' }}>
                        Survey Form 
                    </h4>
                    {
                        (participants.length > 0 && responses.length > 0) ?
                            <ReportTable data={responses} firstColName="Questions" columnName={participants} />
                            :
                            <div className={"pb-2 col-12"}>
                                <div className="row px-3">
                                    <div className="col -12 ]mt-3 align-self-center text-center">
                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Report Available</span>
                                    </div>
                                </div>
                            </div>
                    }

                </div>

            </TransitionGroup >
        </LoadingOverlay>


    )
}

export default SurveyReports
