import { AVAILABLE_POST_REPLY, CREATE_POST_REPLY } from "../actions/types";

let activityReplyDataState = { postReplies: [], totalPages: 0, currentPage: 1 };

export default function (state = activityReplyDataState, action) {
    // console.log(state, action)

    switch (action.type) {
        case AVAILABLE_POST_REPLY:
            if (state.postReplies.length === 0) {
                return {
                    ...state,
                    postReplies: action.payload.content.posts,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.postReplies = state.postReplies.concat(action.payload.content.posts);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    postReplies: concatResult.postReplies,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

        case CREATE_POST_REPLY:
            return {
                ...state,
                postReplies: [],
                totalPages: 0,
                currentPage: 1,
            }
        // if (state.postReplies.length === 0) {
        //     return {
        //         ...state,
        //         postReplies: [action.payload.content],
        //         totalPages: action.payload.content.totalPages,
        //         currentPage: parseInt(action.payload.content.currentPage),
        //     }
        // } else {
        //     let concatResult = {};
        //     concatResult.postReplies = state.postReplies.concat(action.payload.content);
        //     return {
        //         ...state,
        //         postReplies: concatResult.postReplies
        //     }
        // }


        default:
            return state;
    }
}

