import React, { useState, useEffect, useContext } from 'react';
import ResizeDetector from "react-resize-detector";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import { signupEmail, signupPhone } from '../../actions/auth_actions';
import Swal from 'sweetalert'
import styles from '../../assets/css/rstyling.module.css'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'reactstrap';
import { userDetailsContext } from '../../UserDetailsProvider';
import SecureLS from 'secure-ls'





const EmailExternalSurveys = () => {

    
    const [email, setEmail] = useState('');
    
    const dispatch = useDispatch()
    let { search } = useLocation();
    const history = useNavigate();
    const{ActivityId, UserId} = useParams()
   // const {userDetails, setUserDetails} = useContext(userDetailsContext);
    const ls = new SecureLS()

    const [userData, setUserData] = useState({
        
        email: '',
    })

 
 
    console.log('ls in email external form ->', ls.get('accessUser').user._id)
    // simple signup
    const onSignUp = (values) => {
       
        //const user = userDetails.userId
       //console.log('are user details coming here for simple signup?->', user)

       const emailData ={
        userId:  ls.get('accessUser').user._id,
        email: values
       }
        dispatch(signupEmail(emailData))
         
            .then(response => {
                if(response.payload.message == 'OTP code has been sent to the google/facebook/old user email'){
                    history(`/otp/${ActivityId}/${UserId}`)
                    
                Swal({
                    title: "Signed Up",
                    text: "Verification OTP code has been sent. Please verify by email.",
                    icon: 'success'
                })}
                if(response.payload.message == 'Email changed'){
                    history(`/otp/${ActivityId}/${UserId}`)
                    Swal({
                        title: "Signed Up",
                        text: "You have changed your email. Please verify OTP by email",
                        icon: 'info'
                    })
                }
            })
            .catch(err => {
                
                
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                })

            
            
            //history('/otp')
        

           
    }

    

   
   

    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (

                <div className="_signup_">
                    
                    <div className="d-flex">
                        <div className="col-lg-8 col-12">

                            {/* Logo */}
                            <div className="py-2">
                                <a href="/">
                                    <img src={require('../../assets/utils/images/logo.png')} alt="logo" width='135' />
                                </a>
                            </div>

                            <div className="container">
                                
                                <div className="row ">
                                    <div className="col-12">

                                        <div className="mb-5 text-center">
                                            <h4 className="text-dark mb-2 mt-2" style={{ fontWeight: '500' }} >Valid Email Required</h4>
                                            <p style={{ fontSize: '1rem' }}>Please enter your valid email below for verification purposes</p>
                                        </div>

                                         <div className="row"> 

                                        <Formik
                                            initialValues={userData}
                                            enableReinitialize={true}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true)
                                                onSignUp(values)
                                               

                                            }}


                                            const validationSchema={Yup.object().shape({
                                               
                                                email: Yup.string().required("Email is required"),
                                                
                                            })}


                                        >
                                            {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue
                                                } = props;



                                                return (
                                                    <>
                                                    <div class="col">&nbsp;</div>
                                                    <div class="col-auto">
                                                    <form autoComplete="off" onSubmit={handleSubmit}>

                                                        {/* Start of form */}
                                                        <div className="justify-content-center align-items-center">
                                                       
                                                            

                                                             <div > 

                                                             <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Email</label>
                                                                    <Input type="text"
                                                                        className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={(e) => setFieldValue('email', e.target.value.trim())}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                    />

                                                                    {errors.email && touched.email && (
                                                                        <small className='text-danger'>
                                                                            {errors.email}
                                                                        </small>
                                                                    )}

                                                                </div>
                                                             </div> 

                                                           
                                                                <div >
                                                                    <button className={`${styles.bg_blue} btn-hover-shine btn text-light btn-block`} style={{ backgroundColor: '#006FA7' }} type="submit"> Submit Email</button>
                                                                    
                                                                </div>
                                                                
                                                                
                                                                
                                                                       
                                                               
                                                        </div>
                                                                
                                                        

                                                    </form>
                                                    </div>
                                                    <div class="col"></div>
                                                    </>
                                                )
                                            }}

                                        </Formik>
                                        

                                         </div>  



                                    </div>
                                    
                                </div>
                                
                            </div>
                           

                        </div>
                        
                        
                            
                            
                        <div className="col-lg-4 d-lg-block d-none side_bg px-0">
                            {/* Gradient Overlay */}
                            <div className="grad_overlay"></div>

                            <div className="text-center px-3">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <div className="col-12">
                                        <h3 className="mb-4" style={{ color: "#eee", fontWeight: '700', lineHeight: '2.65rem', letterSpacing: '2px' }}>Signup now</h3>
                                        <h4 style={{ color: "#eee", fontWeight: '500', lineHeight: '2.65rem', letterSpacing: '2px' }}>
                                            Genuinely Connecting Consumers to Brands.
                                            Influence change in the world with your Voice!
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >

                </div >
            )}
        />

    )

}

export default EmailExternalSurveys;