import React, { useState, useEffect } from 'react'
import Eyeicon from '../../../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../../../assets/images/delete_icon.png'
import Editicon from '../../../../../../assets/images/edit_icon.png'
import Listing from '../../../../../../assets/components/listing/Listing'
import { useNavigate, useParams, useLocation } from 'react-router'
import AlertModal from '../../../../../../assets/components/modal/AlertModal'
import { deleteExcerpt, getActivityExcerpts } from '../../../../../../actions/excerpts_actions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Excerpts = () => {

    const excerptState = useSelector(state => state.Excerpts)

    const params = useParams()
    const location = useLocation();
const currentPath = location.pathname;
    const history = useNavigate()
    const dispatch = useDispatch()

    const pathWithId = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const path = pathWithId.slice(0, pathWithId.lastIndexOf('/'))

    const [delId, setDelID] = useState()
    const [isLoading, setLoading] = useState(true)
    const [isModalOpen, setModal] = useState(false)

    const [excerptsData, setExcerptsData] = useState([])

    const [column] = useState([
        { Name: 'Excerpts', Type: 'string' },
        { Name: "Note", Type: 'string' },
        { Name: "Activity Title", Type: 'object', key: 'activity', value: 'name' },
        { Name: "Activity Type", Type: 'object', key: 'activity', value: 'activityType' },
        { Name: "User", Type: 'image text', key: 'responseAuthor', image: 'profileImage', value: 'userName' },
        { Name: "Created By", Type: 'image text', key: 'author', image: 'profileImage', value: 'userName' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: path + "/excerpt/" + params.projectid,
                    type: 'button'
                },
                {
                    icon: Editicon,
                    url: path + "/edit-excerpt/" + params.projectid,
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },

            ]
        }
    ])

    const getExcerpts = (page = 1, limit = 5) => {
        dispatch(getActivityExcerpts({ page: page, limit: limit, activityId: params.activityid }))
            .then((response) => {
                setExcerptsData(response.payload.content.excerpt)
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
                setLoading(false)
            })
    }

    const delExcerpt = () => {
        dispatch(deleteExcerpt(delId))
            .then((response) => {
                setDelID()
                getExcerpts(excerptState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Excerpt Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                setDelID()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])


    useEffect(() => {
        getExcerpts()
    }, [])

    return (
        <div>
            {/* Delete Modal */}
            <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={delExcerpt} />
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Listing */}
                <div className="table_border">
                    <Listing columnName={column}
                        data={excerptsData}
                        currentPage={excerptState.currentPage ? excerptState.currentPage : 1}
                        totalPages={excerptState.totalPages ? excerptState.totalPages : 1}
                        getListingData={(currentPage, dataLimit) => getExcerpts(currentPage, dataLimit)}
                        startLoading={() => setLoading(true)}
                    />
                </div>

            </LoadingOverlay>
        </div>
    )
}

export default Excerpts
