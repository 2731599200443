import React, { useState } from "react";
import { TransitionGroup } from 'react-transition-group';

import {
    Card,
    Nav,
    NavItem,
    InputGroup,
    
    Input
} from "reactstrap";
import Swal from 'sweetalert'
import cx from "classnames";
import { Elastic } from "react-burgers";
import classNames from "classnames";
import chartICon from '../../../assets/images/circle_chart.png'
import {
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../../../assets/components/pageheader/PageHeader";
import Chatroom from "./Chatroom";
import NewChat from "./create/Chatroom";

import Chatlist from "./Chatlist";
import { useDispatch } from "react-redux";
import SecureLS from "secure-ls";
import { getChatHistory } from "../../../actions/chats_action";
import styles from '../../../assets/css/styling.module.css'
import io from 'socket.io-client'
import avatar from '../../../assets/images/profile_placeholder.png'

// **************** This Chat component is used for all 3 users ADMIN, RESPONDENT & RESEARCHER *********************
const Chat = () => {

    const ls = new SecureLS()
    const dispatch = useDispatch()
    const [isModalOpen, setModal] = useState(false)

    const [message, setMessage] = useState('')
    const [chat, setChat] = useState([])
    const [chatroomId, setRoomId] = useState()
    const [activeUser, setUser] = useState({
        profileImage: '',
        userName: ''
    })

    const [state, setState] = useState({
        active: false,
    });



    // Connecting with socket to send and receive messages
    const socket = io.connect("https://cc.tedamatic.com:3000", {
        query: {
            token: ls.get('accessToken').accessToken,
        }
    })

    // Setting new room id and user on joining of a new room 
    const chatHistory = (newRoomId, activeUser) => {
        if (chatroomId) {
            socket.emit("leaveRoom", {
                chatroomId: chatroomId,
            });
        }
        if (newRoomId) {
            socket.emit("joinRoom", {
                chatroomId: newRoomId,
            });
        }

        setRoomId(newRoomId)
        setUser(activeUser)
        getMessages(true, 1, 10, newRoomId)

    }

    // Getting users old messages 
    const getMessages = (isNew, page = 1, limit = 10, roomId) => {
        dispatch(getChatHistory({ page: page, limit: limit, chatroom: roomId }))
            .then(response => {
                isNew ? setChat(response.payload.content.messages) : setChat([...chat, ...response.payload.content.messages])
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    // send new message
    const sendMessage = () => {
        socket.emit("chatroomMessage", ({ chatroomId: chatroomId, message: message }))
        setMessage('')
    }

    // Receive new messages
    socket.on("newMessage", newMessage => {
        setChat(chat => [newMessage.newMessage, ...chat]);
    })


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            {/* Page Header */}
            <PageHeader heading="Chat"
                img={chartICon}
                btnText="New"
                btnClick={() => setModal(!isModalOpen)} />

            <NewChat isModalOpen={isModalOpen} showModal={() => setModal(false)} />

            <div style={{ margin: '-30px' }} className={cx("app-inner-layout chat-layout", {
                "open-mobile-menu": state.active,
            })}>


                <div className="app-inner-layout__wrapper admin-chat" style={{ minHeight: 'auto' }}>
                    <Card className="app-inner-layout__content">
                        <div className="table-responsive">


                            {
                                activeUser.userName &&
                                <div className="scrollbar">
                                    <div className="app-inner-layout__top-pane">
                                        <div className="pane-left">
                                            <div className="mobile-app-menu-btn">
                                                <Elastic width={26} lineHeight={2} lineSpacing={5} color="#6c757d" active={state.active}
                                                    onClick={() =>
                                                        setState({ active: !state.active })
                                                    } />
                                            </div>

                                            {/* Chat */}
                                            <div className="avatar-icon-wrapper mr-2">
                                                <div className="avatar-icon">
                                                    <img width={82} src={activeUser.profileImage ? activeUser.profileImage : avatar} alt="" />
                                                </div>
                                            </div>
                                            <h4 className="mb-0 text-nowrap">
                                                {activeUser.userName}
                                                <div >
                                                    <div className={classNames({
                                                        'badge-success': activeUser.role === 1,
                                                        'badge-warning': activeUser.role === 2,
                                                        'badge-danger': activeUser.role === 3,
                                                    }, 'mb-2 mr-2 badge badge-pill text-center')}>
                                                        {activeUser.role === 1 ? 'Admin' : activeUser.role === 2 ? 'Researcher' : 'Respondent'}
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>
                                    {/* Chat room */}
                                    <Chatroom chat={chat} getMoreChat={(isNew, page, limit, roomId) => getMessages(isNew, page, limit, roomId)} roomId={chatroomId} activeUser={activeUser} />
                                </div>

                            }
                        </div>

                        <div className="chat-footer mt-2">
                            <div className="app-footer">
                                <div className={'app-footer__inner'} style={{ width: '100%' }}>

                                    <div className="w-50">
                                        <div className="row px-3">

                                            <div className="d-inline-flex align-self-center">
                                                <div className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }}>
                                                </div>
                                            </div>

                                            <div className="col align-self-center">
                                            {/* Message Box */}
                                                <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                                    <input type="text"
                                                        className={"form-control " + styles.reply_comment_textbox}
                                                        value={message}
                                                        style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                                                        placeholder={"Write a message"}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                </div>

                                            </div>

                                            <div className="d-inline-flex align-self-center">
                                                <button className={styles.sent_gradient_btn} onClick={() => sendMessage()}>
                                                    <span className={styles.sent_icon}></span>
                                                </button>
                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>


                    </Card>

                    {/* Search bar */}
                    <Card className="app-inner-layout__sidebar" style={{ overflowY: 'auto', height: "520px" }}>
                        <div className="app-inner-layout__sidebar-header">
                            <Nav vertical>
                                <NavItem className="pt-4 pl-3 pr-3 pb-3">
                                    <InputGroup>
                                        <InputGroup addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                        </InputGroup>
                                        <Input placeholder="Search..." />
                                    </InputGroup>
                                </NavItem>
                            </Nav>
                        </div>

                        {/* Side bar user list */}
                        <Chatlist getMessages={(id, user) => chatHistory(id, user)} activeChatroom={chatroomId} />
                    </Card>
                </div>
            </div>
        </TransitionGroup>
    )
}

export default Chat
