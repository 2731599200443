import axios from 'axios';
import { BACK_SERVER_URL } from '../config/server';
import { USER_RESEARCH_HISTORY } from './types';

// *****************Used in RESPONDENT END in Research History Screen****************


// Returns an array of completed projects in which logged in user participated before.
export const userResearchHistory = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/projects/participant-history/?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: USER_RESEARCH_HISTORY,
        payload: request
    }
}


