import React from 'react';


import Group_Forum_Reply_View from './Group_Forum_Reply_View';

const Group_Forum_Replies = () => {

    return (
        <div className="mx-3">
            <Group_Forum_Reply_View/>
        </div>
    )

}

export default Group_Forum_Replies;