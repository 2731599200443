import React, { useState } from 'react'
import { Button, Modal, ModalBody, Label, FormGroup } from 'reactstrap'
import { createChatroom } from '../../../../actions/chats_action'
import { findUser } from '../../../../actions/user_action'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import styles from '../../../../assets/css/rstyling.module.css'

const Chatroom = (props) => {

    const { isModalOpen, showModal } = props
    const dispatch = useDispatch()

    const [emailList, setEmailList] = useState([])
    const [selectedEmail, setEmail] = useState([])

    const onCreateChatroom = () => {
       const selectedUser = selectedEmail[0]._id
        dispatch(createChatroom({ users: [selectedUser] }))
            .then(response => {
                setEmail([])

                showModal()
                Swal({
                    title: "Created",
                    text: "Chatroom Created",
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    // Search Email for add participant dropdown
    const searchUser = (searchStr) => {
        if (searchStr.length === 3) {
            dispatch(findUser({ searchStr: searchStr }))
                .then(response => {
                    setEmailList(response.payload.content.user)
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <Modal isOpen={isModalOpen} toggle={showModal} id={`ImageModal`} className="modal-dialog-centered shadow-none" size="lg">
            <ModalBody>
                <div className="text-right close" onClick={() => showModal()} style={{ cursor: 'pointer' }}>&times;</div>
                <div className="container-fluid mt-3">

                    <div className="row">

                        <div className="col-12">
                            <FormGroup>
                                <Label for="email">Select User</Label>
                                <AsyncTypeahead className="light_typeahead custom_drop"
                                    id="Username"
                                    labelKey="userName"
                                    multiple={false}
                                    onChange={setEmail}
                                    options={emailList}
                                    placeholder="Email"
                                    selected={selectedEmail}
                                    allowNew
                                    onSearch={(searchStr) => searchUser(searchStr)}
                                    newSelectionPrefix=""
                                />
                            </FormGroup>
                        </div>

                        <div className="col-12 text-right">
                            <Button className={` ${styles.bg_blue} mr-3`} onClick={onCreateChatroom}>
                                Start Chat
                            </Button>
                            <Button className={` bg-light text-muted`}
                                style={{ border: '1px solid #6c757d ' }}
                                onClick={showModal}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </div>
            </ModalBody>
        </Modal>
    )
}

export default Chatroom