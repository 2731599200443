import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import styles from '../../../assets/css/rstyling.module.css';
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png';
import {
    Card, CardBody,
} from "reactstrap";
import Listing from '../../../assets/components/listing/Listing';
import { getUserReferrals } from '../../../actions/referral_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';

const Referral = () => {

    const history = useNavigate();

    const [isLoading, setLoading] = useState(true)
    const referralState = useSelector(state => state.Referral)
    const dispatch = useDispatch()

    const [referralColumn, setReferralColumn] = useState([
        { Name: 'ID No', Type: 'serialNo', key: 'serialNo' },
        // { Name: "Username", Type: 'object', key: '', value: 'userName' },
        { Name: "Email", Type: 'string', key: 'email' },
        { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Date", Type: 'date', key: 'created_at' },
    ])

    const [referralData, setReferralData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedReferralResult, setSearchedReferralResult] = useState([])

    // Global filtering
    useEffect(() => {
        let search = new RegExp(searchString, 'i');
        let filteredReferral = referralData.filter(row => {
            return (
                search.test(row._id) ||
                search.test(row.email) ||
                search.test(row.status) ||
                search.test(row.email) ||
                search.test(row.date)
            );
        });
        setSearchedReferralResult(filteredReferral);
    }, [searchString]);


    const getReferralListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getUserReferrals({ page: currentPage, limit: dataLimit }))
            .then(response => {
                setReferralData(response.payload.content.referrals)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err);
            })
    }

    useEffect(() => {
        getReferralListing()
    }, [])


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="clearfix mb-4">
                <div className="float-right">
                    <button className="btn btn-light px-4 py-2" onClick={() => history("/respondent/add-referral")}>
                        Add Referral
                    </button>
                </div>
            </div>

            <Card className="main-card mb-3">
                <nav class="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                    <div class="text-white menu-header-title text-capitalize">Referral List</div>

                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav mr-auto">
                        </ul>
                        <form class="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                            <div className="input-group input-group-sm w-75">
                                <div className="input-group-prepend border-0">
                                    <span className={`bg-white my-2 my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                </div>
                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} />
                            </div>

                        </form>
                    </div>
                </nav>
                 <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                    <CardBody className="p-0">

                        {/* Listing */}
                          <div className="table_border">
                            <Listing  
                            columnName={referralColumn}
                                data={(searchString.length > 0 || searchedReferralResult.length > 0) ? searchedReferralResult : referralData}
                                currentPage={referralState.currentPage ? referralState.currentPage : 1}
                                totalPages={referralState.totalPages ? referralState.totalPages : 1}
                                getListingData={(currentPage, dataLimit) => getReferralListing(currentPage, dataLimit)}
                                startLoading={() => setLoading(true)}
                            />
                        </div>

                    </CardBody>
                </LoadingOverlay> 
            </Card>



        </TransitionGroup>
       
    )
}

export default Referral
