//  THIS COMPONENT IS CURRENTLY NOT IN UI RENDERING

import React from 'react';
import { useState, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

import styles from '../../../assets/css/styling.module.css';

import CircularSegmented_progress from '../../../assets/components/Progress/CircularSegmented_progress';
import MultipleCircles_progress from '../../../assets/components/Progress/MutipleCircles_progress';
import { useDispatch, useSelector } from 'react-redux';
import { editUserDetails, getUserDetails, profilePicture } from '../../../actions/user_action';
import { useParams, useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert'
import { getQuickSurvey } from '../../../actions/activities_actions';
import { participantsProjects } from '../../../actions/project_actions';
import SecureLS from 'secure-ls';
import { respondentDashboardDetails } from '../../../actions/dashboard_activities';

function Dashboard() {

    const ls = new SecureLS()
    const params = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)

    const [profileImg, setProfileImg] = useState({});
    const [userDetails, setUserDetails] = useState('')

    const [isDisplayEarnPoint, setDisplayEarnPoint] = useState(false);
    const [userPoints, setUserPoints] = useState({})
    const newProfile = new FormData()

    const activityState = useSelector(state => state.Activity)
    const authState = useSelector(state => state.Auth)
    const [surveyData, setSurveyData] = useState([])
    const [projectData, setProjectData] = useState([])

    const onProfileUploadClick = () => {
        document.getElementById("profile_upload_input").click();
    }
    console.log(authState)
    useEffect(() => {

        // dispatch(profilePicture(params.userId))
        //     .then(response => {

        //         if (response.payload.content.fileData) {
        //             console.log('this is pp in frontend=>', response.payload.content.fileData)
        //             setProfileImg(response.payload.content.fileData);
        //         }
        //         else {
        //             console.log('No profile picture available')
        //            // setProfileImg('')
        //         }
        //     }

        //     )
        //     .catch(err =>{
        //         console.log('no profile picture available')
        //     }
        //         )

        // getting user and dashboard details
        // dispatch(getUserDetails(params.userId))
        //     .then(response => {
        //         if (response.payload.content.profileImage) {
                    
        //             setUserDetails(response.payload.content)
                   
        //         }


        //         dispatch(respondentDashboardDetails())
        //             .then(response2 => {
                      
        //                 setUserPoints(response2.payload.content)
                       
        //                 setLoading(false)
        //             })
        //             .catch(err => {
        //                 setLoading(false)
        //                 Swal({
        //                     title: err.response ? err.response.data.name : "Error",
        //                     text: err.response ? err.response.data.content : "Something went wrong",
        //                     icon: 'error'
        //                 })
        //             })

        //         setUserDetails(response.payload.content)
        //         getProjectListing(1, 3)
        //         getSurvey(1, true)
        //         // setLoading(false)
        //     })
        //     .catch(err => {
        //         setLoading(false)
        //         Swal({
        //             title: err.response ? err.response.data.name : "Error",
        //             text: err.response ? err.response.data.content : "Something went wrong",
        //             icon: 'error'
        //         })
        //     })
    }, []);

    const onSelectProfileImg = (input) => {
        newProfile.append('profileImage', input.files[0])

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setProfileImg(e.target.result);
                console.log(e.target.result)
            }

            reader.readAsDataURL(input.files[0]);

            Swal({
                title: "Please wait!",
                text: "Uploading profile...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(editUserDetails(userDetails._id, newProfile))
                .then((response => {
                    Swal.stopLoading();
                    Swal.close();
                })).catch(err => {
                    Swal.stopLoading();
                    Swal.close();
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }

    }

    // Layout controller
    const layoutController = (width) => {

        width = parseInt(width);

        if (width <= 1660 && width >= 1426)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-9 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-3 mb-3", subColClass: "col-12" },
                isShowReferFreind: true
            }

        else if (width <= 1425 && width >= 1271)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col" },
                isShowReferFreind: true
            }

        else if (width <= 1270 && width >= 1011)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col-6" },
                isShowReferFreind: true
            }

        else if (width <= 1010)
            return {
                column_1: { mainColClass: "col-12 mb-5", subColClass: "col-6", isShowReferFreind: false },
                column_2: { mainColClass: "col-12 mb-3", subColClass: "col-12", },
                column_3: { mainColClass: "col-12 mb-3", subColClass: "col-12" },
                isShowReferFreind: true
            }

        else
            return {
                column_1: { mainColClass: "col-xl-3", subColClass: "col-xl-12", isShowReferFreind: true },
                column_2: { mainColClass: "col-xl-6", subColClass: "col-6" },
                column_3: { mainColClass: "col-xl-3", subColClass: "col-12" },
                isShowReferFreind: false
            }

    }

    // const getReferralLink = () => {
    //     if (ls.get('accessUser')) {
    //         return `https://app.consumerconnect.net/signup?code=` + ls.get('accessUser').user.referralCode
    //     } else {
    //         return `https://app.consumerconnect.net/signup`
    //     }

    // }

    // const ReferFriend = () => {
    //     return (
    //         <div className={styles.card_border}>

    //             <div className={styles.card_border_header}>
    //                 Refer a Friend
    //             </div>

    //             <div className={styles.card_border_body + " py-0"}>

    //                 {/* Refer friends win rewatds */}
    //                 <div className="mt-3">
    //                     <span style={{ color: '#bbb', fontSize: '0.93rem', fontWeight: '500' }}>Refer a Friend to Win 10 Points</span>
    //                 </div>

    //                 {/* Refer Link */}
    //                 <div className={styles.refer_link + " input-group my-3"}>

    //                     {/* Refer Link Input */}
    //                     <input id="copy-refer-link" type="text" className="form-control" placeholder="Refer Link" value={getReferralLink()} disabled={true} />

    //                     {/* Link Icon */}
    //                     <div className="input-group-prepend" style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }} onClick={() => { navigator.clipboard.writeText(document.getElementById('copy-refer-link').value) }}>
    //                         <span className={styles.copy_txt_icon + " input-group-text"}></span>
    //                     </div>

    //                 </div>


    //             </div>

    //         </div>
    //     )
    // }

    // const getSurvey = (page = 1, isNew) => {
    //     dispatch(getQuickSurvey({ page: page, limit: 10 }))
    //         .then(response => {
    //             isNew ? setSurveyData(response.payload.content.activities) : setSurveyData([...surveyData, ...response.payload.content.activities])
    //             setLoading(false)
               
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             setLoading(false)
    //         })
    // }

    // const getProjectListing = (currentPage = 1, dataLimit = 3) => {
    //     dispatch(participantsProjects(currentPage, dataLimit))
    //         .then(response => {
    //             setProjectData(response.payload.content.projects)
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             setLoading(false)
    //         })
    // }

    // const next = () => {
    //     getSurvey(activityState.currentPage + 1, false)
    // }
    // const handleLinkClick = () => {
    //     // Add a delay of 1 second (1000 milliseconds) before navigating
    //     setTimeout(() => {
    //       // Navigate to the new route
    //       window.location.href = "/respondent/profile"; // Use window.location.href for delay
    //     }, 1000);
    //   };
    // const todaysDate = new Date().toLocaleDateString() // for quick survey 

    return (
        <></>
        // <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
        //     transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

        //     <ResizeDetector
        //         handleWidth
        //         render={({ width }) => (

        //             <LoadingOverlay tag="div" active={isLoading}
        //                 styles={{
        //                     overlay: (base) => ({
        //                         ...base,
        //                         background: "#fff",
        //                         opacity: 0.5,
        //                         position: 'fixed',
        //                         top: '20%'
        //                     }),
        //                 }}
        //                 spinner={
        //                     <Loader color="#6c757d" active type="ball-pulse" />
        //                 }>

        //                 <React.Fragment>

        //                     <div className="container-fluid pt-3">

        //                         <div className="row">

        //                             {/* Column_1 */}
        //                             <div className={layoutController(width + 370).column_1.mainColClass}>

        //                                 <div className="row">

        //                                     <div className={layoutController(width + 370).column_1.subColClass + " align-self-center"}>

        //                                         {/*  Profile Uploader */}
        //                                         {console.log(isLoading)}
        //                                         {


        //                                             isLoading ?
        //                                                 <div className={styles.profile_uploader + " mt-3 mx-auto"} style={{ backgroundColor: "#fff" }}>

        //                                                     <span className={styles.profile_upload_setting_icon} onClick={onProfileUploadClick}></span>
        //                                                     <input className="d-none" id="profile_upload_input" type="file" accept=".png, .jpg, .jpeg" onChange={e => onSelectProfileImg(e.target)} />
        //                                                 </div>
        //                                                 :
        //                                                 <div className={styles.profile_uploader + " mt-3 mx-auto"} style={{ backgroundImage: profileImg && profileImg.data ? `url(data:${profileImg.type};base64,${profileImg.data})` : (authState.logged_in_user_data && authState.logged_in_user_data.gender === 'Female') ? `url(${require('../../../assets/images/profile_placeholder.png')})` : `url(${require('../../../assets/images/profile_placeholder_men.png')})` }}>
        //                                                     {console.log("lllll", isLoading)}
        //                                                     <span className={styles.profile_upload_setting_icon} onClick={onProfileUploadClick}></span>
        //                                                     <input className="d-none" id="profile_upload_input" type="file" accept=".png, .jpg, .jpeg" onChange={e => onSelectProfileImg(e.target)} />
        //                                                 </div>
        //                                         }


        //                                         {/* Username */}
        //                                         <div className="text-center my-3">
        //                                             <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1.4rem" }} className="mb-0">{userDetails.firstName}</h5>
        //                                             <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1.05rem" }}>{userDetails.lastName}</h6>
        //                                         </div>

        //                                     </div>

        //                                     <div className={layoutController(width + 370).column_1.subColClass + " align-self-center"}>

        //                                         {/* Progress */}
        //                                         <div className="text-center py-4">

        //                                             {/* Circular Progress */}
        //                                             <CircularSegmented_progress
        //                                                 progress={userPoints.profileCompletion ? userPoints.profileCompletion : 0}
        //                                                 width={parseInt(width + 370) <= 1010 ? 120 : 160}
        //                                                 height={parseInt(width + 370) <= 1010 ? 120 : 160}
        //                                             />
        //                                         </div>



        //                                     </div>

        //                                 </div>

        //                                 <div className="row">
        //                                     <div className={(parseInt(width + 370) >= 1660) ? "col-12" : (parseInt(width + 370) >= 1010) ? "col-6" : "col-12 mt-2"}>

        //                                         {/* Profile Completion points */}
        //                                         < div className={`text-center mt-2`}>
        //                                             <span className={styles.point_icon} style={{ width: '25px', height: '25px', verticalAlign: 'bottom' }}></span>
        //                                             <span className="ml-3" style={{ color: '#777', fontSize: '0.98rem', fontWeight: '600' }}>{userPoints.profileCompletion}- Profile Completionn</span>

        //                                         </div>
        //                                     </div>
        //                                 </div>


        //                                 {/* Refer a Friend */}
        //                                 {layoutController(width + 370).column_1.isShowReferFreind && (
        //                                     <div className="mt-5 mb-3">
        //                                         {ReferFriend()}
        //                                     </div>
        //                                 )}

        //                             </div>

        //                             {/* Column_2 */}
        //                             <div className={layoutController(width + 370).column_2.mainColClass}>

        //                                 {/* Point section */}
        //                                 <div className={styles.card_border}>

        //                                     <div className={styles.card_border_header}>
        //                                         Summary
        //                                     </div>

        //                                     <div className={styles.card_border_body}>


                                                
        //                                         {/* Todays Activities list  */}
        //                                         {
        //                                             userPoints.todaysTasks && userPoints.todaysTasks.length > 0 &&
        //                                                 userPoints.todaysTasks.map(task => {
        //                                                     return <div >
        //                                                         {/* Recent Activities */}
                                                
        //                                                         <div className="col-12">

        //                                                             <div className="clearfix">
        //                                                                 <div className="float-left">
        //                                                                 <Link to='#' onClick={handleLinkClick}>
        //                                                                     <h6 style={{ color: "white", fontWeight: 600, fontSize: "1.5rem", cursor: 'pointer' }} className={`d-flex ${styles.sent_gradient_btnn} mb-1 px-5`}>{task.title}</h6>
        //                                                                     </Link>
        //                                                                 </div>
        //                                                                 <div className="float-right">
        //                                                                     <span className={styles.point_icon} style={{ width: '25px', height: '25px', verticalAlign: 'bottom' }}></span>
        //                                                                     <span className="ml-2" style={{ color: '#666', fontSize: '0.95rem', fontWeight: '600' }}>{task.rewardPoints}</span>
        //                                                                 </div>
        //                                                             </div>

        //                                                         </div>
        //                                                     </div>
        //                                                 })

        //                                                 // :
        //                                                 // <div>
        //                                                 //     <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1.2rem" }} className='py-3 pl-3'>No New Tasks</h6>
        //                                                 // </div>
        //                                         }






        //                                         {/* Active Quick Survey */}
        //                                         {
        //                                             surveyData.length > 0 &&
        //                                             surveyData.map((survey, index) => {
        //                                                 if (survey.startDate && new Date(survey.startDate).toLocaleDateString() <= todaysDate && survey.endDate && new Date(survey.endDate).toLocaleDateString() > todaysDate && !survey.replyExists) {
        //                                                     // console.log(survey.startDate && new Date(survey.startDate).toLocaleDateString() <= todaysDate && survey.endDate && new Date(survey.endDate).toLocaleDateString() > todaysDate && !survey.replyExists)

        //                                                     return <div className={`d-flex ${styles.sent_gradient_btn} mb-1`} style={{ cursor: 'pointer' }}>
        //                                                         <div className="col-12" onClick={() => window.location.href = survey.replyExists ? '/respondent/survey-analysis/' + survey._id + "/" + survey.repliesId : '/respondent/quick-survey/' + survey._id}>

        //                                                             <div className="clearfix" >
        //                                                                 <div className="float-left pt-2">
        //                                                                     <h6 style={{ color: "#666", fontWeight: 600, fontSize: "0.95rem" }}>Complete Survey {survey.name}</h6>
        //                                                                 </div>
        //                                                                 <div className={"float-right pt-1"}>
        //                                                                     <span className={styles.point_icon} style={{ width: '25px', height: '25px', verticalAlign: 'bottom' }}></span>
        //                                                                     <span className="ml-2" style={{ color: '#666', fontSize: '0.95rem', fontWeight: '600' }}>New</span>
        //                                                                 </div>
        //                                                             </div>

        //                                                         </div>
        //                                                     </div>
        //                                                 }

        //                                             })
        //                                         }
        //                                         {/* Total Points */}
        //                                         <div className="row py-5">
        //                                             <div className="col-md-6 col-sm-12 order-md-1 order-2 align-self-center">
        //                                             {/* <span style={{ color: '#444', fontSize: '1.3rem', fontWeight: '600' }}>Points:</span> */}
        //                                                 <span className={styles.point_icon} style={{ width: '65px', height: '45px', verticalAlign: 'bottom' }}></span>
        //                                                 <span className="" style={{ color: '#444', fontSize: '1.6rem', fontWeight: '600' }}>{userPoints.totalPointsEarned}</span>
        //                                                 <span className="ml-3" style={{ color: '#999', fontSize: '1.6rem', fontWeight: '400' }}>{userPoints.badge}</span>
        //                                                 <h6 className="ml-3 py-2" style={{ color: '#444', fontSize: '1.2rem', fontWeight: '200' }}>{userPoints.message}</h6>
        //                                                 <h6 className="ml-3 py-2" style={{ color: '#444', fontSize: '1.2rem', fontWeight: '200' }}>{userPoints.message2}</h6>

        //                                             </div>

        //                                             <div className="col-md-6 col-sm-12 order-md-2 order-1 mb-md-0 mb-3 text-right align-self-center">
        //                                                 <button className={styles.btn_light_border} onClick={() => setDisplayEarnPoint(true)}>How to earn more points?</button>
        //                                             </div>
        //                                         </div>


        //                                     </div>

        //                                 </div>

        //                                 <div className="row mt-3">

        //                                     <div className={layoutController(width + 370).column_2.subColClass + " mb-3"}>
        //                                         {/* Projects Section */}
        //                                         <div className={styles.card_border}>

        //                                             <div className={styles.card_border_header}>
        //                                                 Projects
        //                                             </div>

        //                                             <div className={styles.card_border_body + " pt-2 pb-3 px-0"}>
        //                                                 <div className="scroll-area-lg" style={{ height: '18rem' }}>
        //                                                     <PerfectScrollbar>
        //                                                         {
        //                                                             projectData.length > 0 ?
        //                                                                 projectData.map((project, index) => {
        //                                                                     return <div className="d-flex my-2 py-1" key={index}
        //                                                                         onMouseEnter={e => e.currentTarget.style.cssText = "background-color: #f1f1f1; cursor: pointer"}
        //                                                                         onMouseLeave={e => e.currentTarget.style.cssText = "cursor: pointer"}
        //                                                                         onClick={() => history({
        //                                                                             pathname: '/respondent/project-detail-view/' + project._id,
        //                                                                             state: { project: project, isMyProject: true }
        //                                                                         })}
        //                                                                     // onClick={() => window.location.href = '/respondent/project-detail-view/' + project._id}
        //                                                                     >
        //                                                                         <div className="col-8 align-self-center">
        //                                                                             <h6 style={{ color: "#666", fontWeight: 600, fontSize: "0.95rem" }}>{project.title}</h6>
        //                                                                         </div>
        //                                                                         <div className="col-4 text-center align-self-center">

        //                                                                             {/* Progress */}
        //                                                                             <MultipleCircles_progress
        //                                                                                 progress={28}
        //                                                                                 width={68}
        //                                                                                 height={68}
        //                                                                             />

        //                                                                         </div>
        //                                                                     </div>
        //                                                                 }) :
        //                                                                 <div className={"pb-2"}>
        //                                                                     <div className="row px-3">
        //                                                                         <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
        //                                                                             <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}> No Projects Available</span>
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 </div>
        //                                                         }
        //                                                     </PerfectScrollbar>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>

        //                                     <div className={layoutController(width + 370).column_2.subColClass}>
        //                                         {/* Quick Survey Section */}
        //                                         <div className={styles.card_border}>

        //                                             <div className={styles.card_border_header}>
        //                                                 Quick Survey
        //                                             </div>

        //                                             <div className={styles.card_border_body + " mt-1 py-2"}>
        //                                                 <div className="scroll-area-lg" style={{ height: '18rem' }}>
        //                                                     <PerfectScrollbar>
        //                                                         {
        //                                                             surveyData.length > 0 ?
        //                                                                 surveyData.map((survey, index) => {
        //                                                                     return <div className="row my-2 py-1" key={index}
        //                                                                         onMouseEnter={e => e.currentTarget.style.cssText = "background-color: #f1f1f1; cursor: pointer"}
        //                                                                         onMouseLeave={e => e.currentTarget.style.cssText = "cursor: pointer"}
        //                                                                         onClick={() => window.location.href = survey.replyExists ? '/respondent/survey-analysis/' + survey._id + "/" + survey.repliesId : '/respondent/quick-survey/' + survey._id}
        //                                                                     >
        //                                                                         <div className="col-12 align-self-center px-1">
        //                                                                             <div className="clearfix">
        //                                                                                 <div className="float-left">
        //                                                                                     <div className="d-flex">
        //                                                                                         <div className="d-inline mx-3 mt-1">
        //                                                                                             <span className={styles.circle_number_bullet}>{index + 1}</span>
        //                                                                                         </div>
        //                                                                                         <div className="d-inline">
        //                                                                                             <h6 className="mb-0" style={{ color: "#666", fontWeight: 600, fontSize: "0.95rem" }}>{survey.name}</h6>
        //                                                                                             <span style={{ color: '#999', fontSize: '0.83rem' }}>{survey.description && survey.description.substring(0, 30)}...</span>
        //                                                                                         </div>
        //                                                                                     </div>
        //                                                                                 </div>
        //                                                                                 <div className="float-right mx-3 mt-2">
        //                                                                                     <span className={styles.arrow_right_icon}></span>
        //                                                                                 </div>
        //                                                                             </div>
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 }) :
        //                                                                 <div className={"pb-2"}>
        //                                                                     <div className="row px-3">
        //                                                                         <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
        //                                                                             <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}> No Surveys Available</span>
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 </div>
        //                                                         }

        //                                                         {
        //                                                             activityState.totalPages > 0 && activityState.totalPages !== activityState.currentPage &&
        //                                                             <div className={"pb-2"}>
        //                                                                 <div className="row px-3">
        //                                                                     <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
        //                                                                         <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Surveys</span>
        //                                                                     </div>
        //                                                                 </div>
        //                                                             </div>
        //                                                         }
        //                                                     </PerfectScrollbar>
        //                                                 </div>

        //                                             </div>
        //                                         </div>


        //                                     </div>

        //                                 </div>

        //                             </div>

        //                             {/* Column_3 */}
        //                             <div className={layoutController(width + 370).column_3.mainColClass}>

        //                                 {/* Consumer Connect Profile Section */}
        //                                 <div className={styles.card_border}>

        //                                     <div className={styles.card_border_header}>
        //                                         Consumer Connect Profile
        //                                     </div>

        //                                     <div className={styles.card_border_body}>

        //                                         <div className="row text-center mt-2">

        //                                             {/* Apparel */}
        //                                             <div className={layoutController(width + 370).column_3.subColClass + " mb-3"}>
        //                                                 <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>Apparel</h6>
        //                                                 <span className={styles.apparel_icon + " my-2"} style={{ width: '80px', height: '80px' }}></span>
        //                                             </div>

        //                                             {/* FMCG */}
        //                                             <div className={layoutController(width + 370).column_3.subColClass + " mb-3"}>
        //                                                 <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>FMCG</h6>
        //                                                 <span className={styles.fmcg_icon + " my-2"} style={{ width: '80px', height: '80px' }}></span>
        //                                             </div>

        //                                             {/* Food and Beverages */}
        //                                             <div className={layoutController(width + 370).column_3.subColClass + " mb-3"}>
        //                                                 <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>Food and Beverages</h6>
        //                                                 <span className={styles.foodBev_icon + " my-2"} style={{ width: '80px', height: '80px' }}></span>
        //                                             </div>

        //                                             {/* Information Technology */}
        //                                             <div className={layoutController(width + 370).column_3.subColClass + " mb-3"}>
        //                                                 <h6 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>Information Technology</h6>
        //                                                 <span className={styles.it_icon + " my-2"} style={{ width: '80px', height: '80px' }}></span>
        //                                             </div>

        //                                         </div>

        //                                     </div>
        //                                 </div>

        //                             </div>

        //                         </div>

        //                         {/* Refer a Friend */}
        //                         {layoutController(width + 370).isShowReferFreind && (
        //                             <div className="row">
        //                                 <div className="col-12">
        //                                     {ReferFriend()}
        //                                 </div>
        //                             </div>
        //                         )}

        //                     </div>

        //                     <Modal isOpen={isDisplayEarnPoint} toggle={() => setDisplayEarnPoint(!isDisplayEarnPoint)} id={`howToEarnPoint`} className="modal-dialog-centered modal-lg shadow-none" >
        //                         <div className="modal-header bg-white py-1">
        //                             <h6 className="modal-title my-auto" style={{ color: '#999', fontWeight: 500 }}>Join the community</h6>
        //                             <button type="button" className='btn' onClick={() => setDisplayEarnPoint(!isDisplayEarnPoint)}>
        //                                 <span style={{ color: '#999', fontSize: '1.05rem' }}>&#10006;</span>
        //                             </button>
        //                         </div>

        //                         <ModalBody className="text-justify" style={{
        //                             maxHeight: 'calc(100vh - 210px)',
        //                             overflowY: 'auto'
        //                         }}>
        //                             <div className='p-3' style={{ fontSize: '0.95rem' }}>
        //                                 <p>
        //                                     As a member of the Consumer Connect panel you will be part of a community of people who
        //                                     share their opinions because they know that their Voice matters.
        //                                 </p>
        //                                 <p>
        //                                     It couldn't be easier: we contact you on call or through sms and email you a survey link, online
        //                                     meeting link or request to give your feedback in person! you complete your activity at your con-
        //                                     venience and earn points for doing so! You can save points towards earning great deals and
        //                                     rewards.
        //                                 </p>
        //                                 <h5>What is Consumer Connect?</h5>
        //                                 <p>Consumer Connect conducts feedback activities digitally and in person  about products, brands, politics,
        //                                     public affairs  and other topics of general interest.  Activities are done by people, from all across Pakistan
        //                                     and the world  who have agreed to share their opinions on a wide variety of important issues. It is free
        //                                     to join for everyone.</p>
        //                                 <h5>What happens when I register to the Consumer Connect panel?</h5>
        //                                 <p>As soon as you register by verifying through mobile number or email you will be asked to complete your
        //                                     profile and automatically be redirected to various activities/ surveys .We ask you to complete your
        //                                     profile  so that we contact you for relevant surveys in the future. Once you complete your profile  we
        //                                     will credit your account with 100 bonus points just for joining.</p>
        //                                 <h5>Will I receive e-mail messages from anyone other than Consumer Connect?</h5>
        //                                 <p>
        //                                     No. Under no circumstances will Consumer Connect  give, rent, or sell your e-mail address or mobile
        //                                     number  to anyone else or allow messages to be sent to you by third parties.
        //                                 </p>
        //                                 <h5>How am I told about new surveys?</h5>
        //                                 <p>Every time we have a new survey for you to complete you will receive an email and sms telling you, and
        //                                     you will be able to access the survey directly from the email or sms.</p>
        //                                 <h5>Will the survey responses I provide be confidential?</h5>
        //                                 <p>Yes. Your responses will be used only for statistical tabulations, such as "10% more Pakistan residents
        //                                     valued Brand X". Your individual answers will never be revealed to anyone in a manner that reveals your
        //                                     identity. Please see our Privacy Policy for complete details.</p>
        //                                 <h5>For what purposes is the data collected by Consumer Connect?</h5>
        //                                 <p>Data from  surveys are used by brands,  private and public organisations and marketing and
        //                                     communications agencies to reveal and analyse public opinion.</p>
        //                                 <h5>How long is a typical Consumer Connect survey?</h5>
        //                                 <p>Most Consumer Connect surveys are very short and should only take you about fifteen minutes to complete.
        //                                     Occasionally, there will be longer surveys or surveys with more complex questions. The longest surveys
        //                                     should not take more than 25 minutes to complete.​</p>
        //                                 <h5>What kind of rewards are offered for participating in surveys?</h5>
        //                                 <p>When you take surveys, participate in Consumer Connect activities and refer friends and family who
        //                                     have joined the community as well. You are given different incentives in the form of cash, gift vouchers
        //                                     or you  accumulate points which can later be redeemed for a range of rewards upon reaching 5,000
        //                                     points. </p>
        //                                 <h5>How do I redeem my points for cash?</h5>
        //                                 <p>Once you have accumulated 5,000 points simply click on the 'Redeem' button on the 'Rewards' page and
        //                                     fill in the form to redeem your points for cash.</p>
        //                                 <h5>How long does the cash take to reach me after I ask for it?</h5>
        //                                 <p>
        //                                     All requests are processed within one month of the request being received.</p>
        //                                 <h5>Where can I see my points?</h5>
        //                                 <p>You must be logged in to your account to see your points. If you are logged in you will see it on your
        //                                     dashboard.</p>
        //                                 <h5>Get cash for taking surveys</h5>
        //                                 <p>There's no small print, you've earned those points by participating with Consumer Connect.  Register
        //                                     and participate and you will get your reward either in form of cash or gift vouchers. We add new
        //                                     rewards all the time, based on your feedback.</p>


        //                             </div>
        //                         </ModalBody>
        //                     </Modal>

        //                 </React.Fragment>


        //             </LoadingOverlay>

        //         )}

        //     />

        // </TransitionGroup >

    )

}

export default Dashboard;