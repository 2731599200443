import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProfile } from '../../../actions/auth_actions';
import Swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import SecureLS from 'secure-ls';

const Step_5 = (props) => {

    const { profileDetails } = props
    let formData = new FormData();
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const history = useNavigate()
    const ls = new SecureLS()

    useEffect(() => {
        let userAge = 0
        if (profileDetails.birthday !== "") {
            const today = new Date();
            const birthDate = new Date(profileDetails.birthday);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            let day = today.getDate() - birthDate.getDate();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (m < 0) {
                m += 12;
            }
            if (day < 0) {
                day += 30;
            }
            userAge = age
        }

        formData.append("userName", profileDetails.userName)
        formData.append("birthday", profileDetails.birthday)
        formData.append("age", userAge)
        formData.append("city", profileDetails.city)
        formData.append("country", profileDetails.country)
        formData.append("address", profileDetails.area)
        formData.append("gender", profileDetails.gender)
        formData.append("maritalStatus", profileDetails.maritalStatus)
        formData.append("employment", profileDetails.employment)
        formData.append("education", profileDetails.education)
        formData.append("occupation", profileDetails.occupation)
        formData.append("income", profileDetails.income)
        formData.append("cwe", profileDetails.cwe)
        formData.append("company", profileDetails.company)
        formData.append("jobRole", profileDetails.jobRole)
        formData.append("seniority", profileDetails.seniority)
        profileDetails.profileImage && formData.append("profileImage", profileDetails.profileImage[0])

        Swal({
            title: "Setting your profileee",
            text: "Please wait",
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            }
        });

        dispatch(setProfile(formData))
        .then(response => {
            ls.set('userStatus', 'Complete')
            ls.set('userType', 'member')

            setLoading(false)
            Swal.stopLoading();
            Swal.close()
        })
        .catch((err) => {
            Swal({
                title: err.response ? err.response.data.name : "Error",
                text: err.content ? err.response.data.content : "Please try again!",
                icon: 'error'
            }).then(err => {
                history('/login')
            })
        })

    }, [])

    return (

        <React.Fragment>
            {
                !isLoading &&
                <>
                    <h4 className="text-center mt-3 mb-5">
                        <h1 className="text-dark mb-3" style={{ fontWeight: '500' }}>Woohoo</h1>
                        <p style={{ fontSize: '1.35rem' }}>Congratulations - Your profile is complete!</p>
                    </h4>

                    <div className="clearfix">
                        <div className="text-center">
                            <div className="mb-2">
                                <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={() => window.location.href = '/respondent'} disabled={props.isAnimationPlaying}>Done</button>
                            </div>
                        </div>
                    </div>
                </>

            }

        </React.Fragment>

    )

}

export default Step_5