import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../../../assets/css/styling.module.css';

import { TransitionGroup } from 'react-transition-group';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getQuickSurvey, getRespondentActivityDetails } from '../../../actions/activities_actions';
import ResizeDetector from 'react-resize-detector';
import Project_View from '../Projects/Project_View';
import CryptoJS from 'crypto-js';




// ********************* This Component is used when a respondent logged in as Guest User *******************************

const QuickSurveyListing = () => {

    const activityState = useSelector(state => state.Activity)
    const [surveyData, setSurveyData] = useState([])
    const dispatch = useDispatch()
    const history = useNavigate()
    const [isLoading, setLoading] = useState(true)

    const getSurvey = (page = 1, isNew) => {
        dispatch(getQuickSurvey({ page: page, limit: 10 }))
            .then(response => {
                isNew ? setSurveyData(response.payload.content.activities) : setSurveyData([...surveyData, ...response.payload.content.activities])
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        getSurvey(1, true)
    }, [])

    const next = () => {
        getSurvey(activityState.currentPage + 1, false)
    }

    // Layout controller
    const layoutController = (width) => {

        width = parseInt(width);

        if (width <= 1550 && width >= 1201)
            return {
                class: "col-6"
            }

        else if (width <= 1200)
            return {
                class: "col-12"
            }

        else
            return {
                class: "col-4"
            }

    }

    const todaysDate = new Date() // for quick survey 
    let [isActiveSurvey, setActiveSurvey] = useState(false)
    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <>
                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                    // position: 'fixed',
                                    // top: '2%'
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            {
                                (!isLoading && surveyData.length > 0) &&
                                <div className="container-fluid pt-5">

                                    <div className="mb-2">
                                        {/* Active Surveys */}
                                        <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>Quick Surveys</h5>
                                    </div>

                                    <div className="row py-3">

                                        {!isLoading && surveyData.length > 0 &&
                                            surveyData.map(survey => {
                                                if (survey.startDate && new Date(survey.startDate) <= todaysDate && survey.endDate && new Date(survey.endDate) > todaysDate && !survey.replyExists) {
                                                    setActiveSurvey(true)
                                                    return <Project_View
                                                        class={layoutController(width + 370).class}
                                                        title={survey.name}
                                                        details={`${survey.description.substring(0, 180)}`}
                                                        
                                                        deadline={survey.endDate && new Date(survey.endDate).toLocaleDateString()}
                                                        completePercentage={0}
                                                        images={survey.bannerImage && survey.bannerImage}
                                                        link={() => history({
                                                            pathname: '/respondent/quick-survey/' + survey._id
                                                        })}

                                                    />
                                                }
                                            })
                                        }

                                        {
                                            !isActiveSurvey && <div className="col-12 text-center">No more surveys.</div>
                                        }

                                    </div>

                                </div>
                            }

                            {
                                activityState.totalPages > 0 && activityState.totalPages !== activityState.currentPage &&
                                <div className={"pb-2"}>
                                    <div className="row px-3">
                                        <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Surveys</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </LoadingOverlay>
                    </>



                )
                }
            />
        </TransitionGroup >

    )

}

export default QuickSurveyListing;