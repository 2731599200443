import React, { useState, useContext, useEffect } from 'react';
import styles from '../../../assets/css/styling.module.css';
import Swal from 'sweetalert';
import { uploadUserPP } from '../../../actions/user_action';
import { useDispatch } from 'react-redux';
import { userDetailsContext } from '../../../UserDetailsProvider';
const Step_4 = (props) => {

    const dispatch = useDispatch();
    const [profileImg, setProfileImg] = useState('');
    const { profileDetails, setProfileDetails } = props
    const [profilePic, setProfilePic] = useState({})
    const newProfile = new FormData()
    const onProfileUploadClick = () => {
        document.getElementById("profile_upload_input").click();
    }

    const {userDetails, setUserDetails} = useContext(userDetailsContext);

    const onSelectProfileImg = (input) => {
        newProfile.append('profileImage', input.files[0])

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setProfileImg(e.target.result);
                console.log('e.target.result->',e.target.result)
            }

            reader.readAsDataURL(input.files[0]);

            Swal({
                title: "Please wait!",
                text: "Uploading profile...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            const user = userDetails.userId
            // console.log('is user id coming here for pp?->', user)
            dispatch(uploadUserPP(user, newProfile))
                .then((response => {
                    setProfilePic(response.payload.content)
                    props.nextStep()
                })).catch(err => {
                    Swal.stopLoading();
                    Swal.close();
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }

    }
    // useEffect(() => {
	// 	onSelectProfileImg()
	// },)
    return (

        <React.Fragment>
            <h4 className="mt-3 mb-5">
                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Set Profile Image</h3>
                <p style={{ fontSize: '1rem' }}>It takes only seconds to set up your Profile</p>
            </h4>

            <div className="row pt-5 pb-4">

                 <div className="col-12 mb-3">

                   
                    {/* <div
                        className={styles.profile_uploader + " mt-3 mx-auto"}
                        // style={profilePic && profilePic.data   ? { backgroundImage: ``, width: '200px', height: '200px', cursor: 'pointer' } : { backgroundImage: `url(${require('../../../assets/images/profile_placeholder.png')})`, width: '200px', height: '200px', cursor: 'pointer' }}
                        style={{ backgroundImage: profilePic && profilePic.data  ? `url(data:${profilePic.type};base64,${profileImg.data})` :  `url(${require('../../../assets/images/profile_placeholder_men.png')})` }}
                        
                    >
                        <input className="d-none" id="profile_upload_input" type="file" accept=".png, .jpg, .jpeg" onChange={e => onSelectProfileImg(e.target)} />
                    </div> */}

                </div> 

            </div>

            <div className="clearfix">
                <div className="text-center">
                    <div className="mb-2">
                        {/* <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={onSelectProfileImg}>Upload Image</button> */}
                        <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={onProfileUploadClick}>
                        <input className="d-none" id="profile_upload_input"  type="file" accept=".png, .jpg, .jpeg" onChange={e => onSelectProfileImg(e.target)} />
                         Upload Picture </button>
                    </div>
                    <div className="mb-2">
                        <button className="btn btn-link" style={{ color: '#006FA7', fontSize: '0.95rem', fontWeight: '400' }} onClick={() => props.nextStep()} disabled={props.isAnimationPlaying}>
                            Skip
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}

export default Step_4