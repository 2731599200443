import axios from 'axios';
import { AVAILABLE_USER_REFERRALS, LOADING_START, LOADING_STOP, REFERRAL_AVAILABLE, REFER_FRIEND } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

export const stopLoader = () => {
    return {
        type: LOADING_STOP,
    }
}

export const startLoader = () => {
    return {
        type: LOADING_START,
    }
}

// ******* Used in ADMIN END inside REFERRALS SECTION ********

// for getting all user referrals to display in listing
export const getAllReferrals = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/referrals/?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: REFERRAL_AVAILABLE,
        payload: request
    }
}

// ******* Used in RESPONDENT END inside REFERRALS SECTION ********
// for getting logged in respondent referrals list
export const getUserReferrals = (data) => {
    let url = `${BACK_SERVER_URL}api/referrals/user/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_USER_REFERRALS,
        payload: request
    }
}

// Used for referring friends through email in add referrals section of respondent
export const referFreinds = (emailList) => {

    let url = `${BACK_SERVER_URL}api/referrals/addReferral`;

    const request = axios.post(url, emailList)
        .then(response => response.data);

    return {
        type: REFER_FRIEND,
        payload: request
    }
}