import { GET_ALL_OPTIONS, GET_SPECIFIC_OPTION } from '../actions/types';

let settingsDataState = { options:[], specificOptions:[]};
export default function (state = settingsDataState, action) {
    switch (action.type) {
        case GET_ALL_OPTIONS:
                return {
                    ...state,
                    options: action.payload.content,
                }
        case GET_SPECIFIC_OPTION:
            return {
                ...state,
                specificOptions: action.payload.content,
            }
        default:
            return state;
    }
}
