import React, { useState, useEffect } from 'react'
import '../../../assets/components/pageheader/PageHeader'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import Listing from '../../../assets/components/listing/Listing'
import { useNavigate } from 'react-router';
import DownloadIcon from '../../../assets/images/download_icon.png'
import PlayIcon from '../../../assets/images/play_icon.png'
import DeleteIcon from '../../../assets/images/delete_icon.png'
import ResizeDetector from "react-resize-detector";
import SampleAudio from '../../../assets/images/audio_guitar.mp3'
import AlertModal from '../../../assets/components/modal/AlertModal';


// ************************** This screen is removed from the UI ******************************

const Recording = () => {

    const history = useNavigate()
    const [audio, setAudio] = useState()
    const [delId, setDelID] = useState()
    const [column, setColumn] = useState([
        { Name: 'Serial No', Type: 'number' },
        { Name: "Username", Type: 'string' },
        { Name: "Topic", Type: 'string' },
        { Name: "Created At", Type: 'date' },
        { Name: "Mobile No", Type: 'string' },
        { Name: "City", Type: 'string' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: DownloadIcon,
                    onClick: () => console.log("I'm clicked."),
                    type: 'button'
                },
                {
                    icon: PlayIcon,
                    type: 'audio',
                    setAudio: setAudio
                },
                {
                    icon: DeleteIcon,
                    setID: setDelID,
                    type: 'delete'
                },
            ]
        }
    ])

    const [data, setData] = useState([
        { SerialNo: '04', Username: 'Johnclarke', Topic: 'abc@gmail.com', CreatedAt: '12/01/21 04:00 PM', MobileNo: '06677888899', City: 'Karachi', audio: 'http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav' },
        { SerialNo: '04', Username: 'Johnclarke', Topic: 'abc@gmail.com', CreatedAt: '12/01/21 04:00 PM', MobileNo: '06677888899', City: 'Karachi', audio: SampleAudio },
        { SerialNo: '04', Username: 'Johnclarke', Topic: 'abc@gmail.com', CreatedAt: '12/01/21 04:00 PM', MobileNo: '06677888899', City: 'Karachi', audio: SampleAudio },
        { SerialNo: '04', Username: 'Johnclarke', Topic: 'abc@gmail.com', CreatedAt: '12/01/21 04:00 PM', MobileNo: '06677888899', City: 'Karachi', audio: SampleAudio },
    ])

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                    {/* Delete Modal */}
                    <AlertModal isModalOpen={isModalOpen} setModal={showModal} />
                    <Card className="main-card mb-3">

                        <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                            {/* <div style={{ overflowX: width <= 470 && 'auto', display: 'flex', width: '100%' }}> */}

                            <div className="text-white menu-header-title text-capitalize align-self-center mr-3">Recording List</div>

                            <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                <ul className="navbar-nav mr-auto">
                                </ul>
                                <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                    <div className="input-group input-group-sm w-75">
                                        <div className="input-group-prepend border-0">
                                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                        </div>
                                        <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" />
                                    </div>

                                </div>
                            </div>
                            {/* </div> */}
                        </nav>


                        <CardBody className="p-0">
                            <Listing columnName={column} data={data} />
                        </CardBody>
                    </Card>

                    {/* Recording Audio */}
                    <div className={audio ? "d-block app-footer" : "d-none"}>
                        <div className={'app-footer__inner'}>

                            <div className="container-fluid mx-5">
                                <audio controls className="w-100 py-2" src={audio} />
                            </div>

                        </div>
                    </div>

                </TransitionGroup>
            )}
        />
    )
}

export default Recording
