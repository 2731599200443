import { AVAILABLE_ACTIVITY_MEETINGS } from "../actions/types";

let activityMeetingsDataState = { meetings: [], totalPages: 0, currentPage: 1 };

export default function (state = activityMeetingsDataState, action) {

    switch (action.type) {
        case AVAILABLE_ACTIVITY_MEETINGS:
            if (state.meetings.length === 0) {
                return {
                    ...state,
                    meetings: action.payload.content.meetings,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.meetings = state.meetings.concat(action.payload.content.meetings);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    meetings: concatResult.meetings,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

        default:
            return state;
    }
}

