import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, UncontrolledButtonDropdown, DropdownToggle } from 'reactstrap';
import { delComment, editComments } from '../../../actions/activities_reply_actions';

import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import DotsIcon from '../../../assets/images/dots_icon_gray.png'
import Swal from 'sweetalert'
import AlertModal from '../../../assets/components/modal/AlertModal';
import SecureLS from 'secure-ls';
import female from '../../../assets/images/group-forum-female-avatar.png'
import male from '../../../assets/images/group-forum-male-avatar.png'

const Group_Forum_Comment_View = (props) => {

    const ls = new SecureLS()

    const commentState = useSelector(state => state.Comments)

    const dispatch = useDispatch()

    const [editCommentId, setEditComment] = useState('')
    const [editedCommentText, setEditedComment] = useState('')
    const [isModalOpen, setModal] = useState(false)
    const [delId, setDelId] = useState(false)

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const onDelComment = () => {
        setModal(false)
        dispatch(delComment(delId))
            .then(response => {
                setDelId('')
                Swal({
                    title: "Deleted",
                    text: "Comment Deleted",
                    icon: 'info'
                }).then(() => props.getComments(props.postId, true, commentState.currentPage, 10))
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })

    }

    const onEditComment = () => {
        dispatch(editComments(editCommentId, { description: editedCommentText }))
            .then(response => {
                setEditComment('')
                Swal({
                    title: "Edited",
                    text: "Comment Edited",
                    icon: 'info'
                }).then(() => props.getComments(props.postId, true, commentState.currentPage, 10))
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    const formattedDate =(postedTime)=>{
        const originalDate = new Date(postedTime);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(originalDate);
    return formattedDate
    }

    return (
        <>
            <AlertModal isModalOpen={isModalOpen} setModal={() => setModal(!isModalOpen)}
                onClick={onDelComment}
            />

            {
                props.comments && props.comments.length > 0 &&
                props.comments.map((comment, index) => {

                    if (comment.post === props.postId) {
                        return <div style={{ borderBottom: 0, borderRadius: 0 }}>

                            {
                                editCommentId === comment._id ?
                                    <div className="col align-self-center py-3 px-3 d-flex">

                                        <div className="input-group mx-3" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                            <input type="text"
                                                className={"form-control " + styles.reply_comment_textbox}
                                                value={editedCommentText}
                                                style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                                                onChange={(e) => setEditedComment(e.target.value)}
                                            />
                                        </div>

                                        <button className={styles.sent_gradient_btn} onClick={() => onEditComment()}>
                                            <span className={styles.sent_icon}></span>
                                        </button>

                                    </div>

                                    :
                                    <div className={styles.card_border_body + " py-3 ml-5"}>
                                        <div className="row" style={{ paddingLeft: '50px' }}>
                                            <div className="col">
                                                <div className="clearfix">
                                                    <div className="float-left">

                                                        <div className="d-inline-flex align-self-center">
                                                            {/* User Image */}
                                                            {/* <div className="rounded-circle" style={{ backgroundImage: comment.author.profileImage ? `url(${comment.author.profileImage})` : "#e8e6e5", width: '38px', height: '38px' }}>
                                                            </div> */}
                                                            <div><span  >
                                                               
                                                                <img src={comment.author.gender === 'Male' ? male: female} className={`${styles3.group_forum_respondent_avatar}`} alt="Respondent Avatar"></img>
                                                            </span></div>

                                                            <div className="mx-3 align-self-center">
                                                                {/* User Name */}
                                                                <h5 style={{ color: "#AFAFAF", fontWeight: 500, fontSize: "0.95rem" }} className="">
                                                                    {comment.author.userName} - {formattedDate(comment.created_at)}
                                                                </h5>
                                                                <p style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>
                                                                    {comment.description}
                                                                </p>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div className="float-right">
                                                        <div className="d-inline-flex align-self-center custom_drop">
                                                           

                                                            {/* Edit Del dropdown */}
                                                            {
                                                                comment.author._id === ls.get('accessUser').user._id &&
                                                                <UncontrolledButtonDropdown className={'participant_btn '}>
                                                                    <DropdownToggle>
                                                                        <img src={DotsIcon} alt="" width={4} />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ marginLeft: '-12.8rem' }}>
                                                                        <DropdownItem onClick={() => { setEditComment(comment._id); setEditedComment(comment.description) }}>Edit</DropdownItem>

                                                                        <DropdownItem onClick={() => setDelId(comment._id)}>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                            }

                        </div>
                    }


                })
            }

        </>



    )

}

export default Group_Forum_Comment_View