import { AVAILABLE_USER_REFERRALS, EMPTY_REFERRAL, LOADING_START, LOADING_STOP, REFERRAL_AVAILABLE } from '../actions/types';

let referralDataState = { referrals:[], userReferrals:[], totalPages:0, currentPage: 1, loading:false };
export default function (state = referralDataState, action) {
    switch (action.type) {
        case LOADING_START:
            return {...state, loading:true};
        case LOADING_STOP:
            return {...state, loading:false};
        // All users referrals on admin end
        case REFERRAL_AVAILABLE:
            if (state.referrals.length === 0) {
                return {
                    ...state,
                    referrals: action.payload.content.referrals,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.referrals =  state.referrals.concat(action.payload.content.referrals);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    referrals: concatResult.referrals,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        // Logged in user referrals listing on respondent end
        case AVAILABLE_USER_REFERRALS:
            if (state.userReferrals.length === 0) {
                return {
                    ...state,
                    userReferrals: action.payload.content.referrals,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.userReferrals =  state.userReferrals.concat(action.payload.content.referrals);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    userReferrals: concatResult.userReferrals,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case EMPTY_REFERRAL:
            return {...state, referrals: [], totalPages: 0, currentPage: 1 };
        default:
            return state;
    }
}
