import React, { Fragment, useState, useEffect } from "react";

// import Ionicon from 'react-ionicons';

import { IoIosCalendar } from "react-icons/io";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
    DropdownToggle,
    DropdownMenu,
    Nav,
    Col,
    Row,
    Button,
    NavItem,
    NavLink,
    UncontrolledTooltip,
    UncontrolledButtonDropdown,
} from "reactstrap";

import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import city3 from "../../assets/images/city3.jpg";
import styles from '../../assets/css/rstyling.module.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUserDetails, profilePicture } from "../../actions/user_action";
import { useDispatch } from "react-redux";
import { getLoggedInUser } from "../../actions/auth_actions";
import { updateLogs } from "../../actions/logs_action";
import maleUserAvatar from '../../assets/images/male-avatar.svg'
import femaleUserAvatar from '../../assets/images/female-user-avatar.png'


const ProfileCard = () => {

    const history = useNavigate()
    const [profile, setProfile] = useState({})
    const dispatch = useDispatch()
    const params = useParams()
    
const [profileImg, setProfileImg] = useState({})
    const logout = () => {
        dispatch(updateLogs({ isActive: false }))
            .then(response => {
                console.log(response)
                window.localStorage.clear();
                setTimeout(()=>{
                history('/login')
            },1000)
            })
            .catch(err => {
                console.log(err)
            })
      
    }

    useEffect(() => {
        // downloaded  profile picture from backend
        dispatch(profilePicture(params.userId))
        .then(response=>{
         
            if(response.payload.content.fileData){
                // console.log('this is pp in frontend=>', response.payload.content.fileData)
                setProfileImg(response.payload.content.fileData);
            }
           
            else
            setProfileImg('')
           }
            
            ).catch(err=>console.log('')) 

        dispatch(getLoggedInUser())
            .then(response => {
                setProfile(response.payload.content)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    return (
        <>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <img width={42} height={42} className="rounded-circle" src={profileImg.data ? `data: ${profileImg.type}; base64, ${profileImg.data}` : profile.gender === 'Female' ? femaleUserAvatar : maleUserAvatar} alt="" />
                                    <FontAwesomeIcon
                                        className="ml-2 opacity-8"
                                        icon={faAngleDown}
                                    />
                                </DropdownToggle>

                                {/* Header */}
                                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-dark">
                                            <div className="menu-header-image opacity-2"
                                                style={{
                                                    backgroundImage: "url(" + city3 + ")",
                                                }} />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <div className="avatar-icon-wrapper ml-1">
                                                                <div className="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg" />
                                                                <div className="avatar-icon avatar-icon-lg rounded-circle" style={{ border: '3px solid #006FA7' }}>
                                                                    <img src={profileImg.data ? `data:${profileImg.type}; base64, ${profileImg.data}` : profile.gender === 'Female' ? femaleUserAvatar : maleUserAvatar} alt="" width={42} height={42} className="rounded-circle" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading text-white">
                                                                {profile.userName}
                                                                
                                                            </div>
                                                            <div className="widget-subheading mt-1 opacity-10">
                                                                <span className={classNames({
                                                                    'badge-success': profile.role === 1,
                                                                    'badge-warning': profile.role === 3,
                                                                    'badge-danger': profile.role === 2,
                                                                }, 'badge badge-pill text-right px-3')}>
                                                                    {profile.role === 1 ? 'Admin' : profile.role === 2 ? 'Researcher' : 'Respondent'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button className={`btn-pill btn-shadow btn-shine px-3 ${styles.bg_blue}`} onClick={logout}>
                                                                Logout
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Grid */}
                                    <div className="grid-menu">
                                        <div className="no-gutters row">
                                            <div className="col-6 text-center border-bottom py-2">
                                                <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Joined Study</label><br />
                                                <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSlabelace: 'pre-wrap' }} >{profile.logs && profile.logs.currentProjectJoined ? new Date(profile.logs.currentProjectJoined).toLocaleDateString() : "Not joined yet"}</label>
                                            </div>
                                            <div className="col-6 text-center py-2 border-bottom">
                                                <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Study Visits</label><br />
                                                <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{profile.logs ? profile.logs.totalVisits : 0}</label>
                                            </div>
                                            <div className="col-6 text-center py-2 border-bottom">
                                                <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Visit</label><br />
                                                <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{profile.logs && profile.logs.latestVisit && new Date(profile.logs.latestVisit).toLocaleDateString()}</label>
                                            </div>
                                            <div className="col-6 text-center py-2 border-bottom">
                                                <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Messages</label><br />
                                                <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >0</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Links */}
                                    {
                                        profile.role !== 3 &&
                                        <div className="widget-content-left ml-4 mt-3">
                                            <p className="mb-2 mt-4"><Link  to={profile.role === 2 ? '/researcher/project' : '/admin/project'}
                                             >Projects</Link></p>
                                            
                                            <p className="mb-1 mt-2"><Link
                                             to={profile.role === 2 ? '/researcher/profile' : '/admin/profile'}
                                              className={`${styles.text_blue}`}
                                                                                    >
                                                       My Profile
                                                 </Link></p>
                                        </div>
                                    }

                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileCard
