import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ResizeDetector from "react-resize-detector";
import SecureLS from "secure-ls";
import { getSpecificOption } from "../../actions/settings_actions";
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Step_1 from "./Steps/Step_1";
import Step_2 from "./Steps/Step_2";
import Step_3 from "./Steps/Step_3";
import Step_4 from "./Steps/Step_4";
import Step_5 from "./Steps/Step_5";

const Create_Profile = () => {

    const [animation, setAnimation] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [isAnimationPlaying, setAnimationPlaying] = useState(false);
    const dispatch = useDispatch()
    const ls = new SecureLS()
    const [isLoading, setLoading] = useState(true)
    const [profileDetails, setProfileDetails] = useState({
        userName: '',
        birthday: '',
        city: '',
        country: '',
        area: '',
        gender: '',
        maritalStatus: '',
        employment: '',
        education: '',
        occupation: '',
        seniority: '',
        income: '',
        cwe: '',
        company: '',
        jobRole: '',
        profileImage: ''
    })

    useEffect(() => {
        dispatch(getSpecificOption({ options: ["Education", "Marital Status", "Income", "Employment", "Relationship", "Countries", "Cities", "Seniority"] }))
            .then(response => {
            })
            .catch(err => {
                console.log(err)
            })

        const loggedInUser = ls.get('accessUser').user
        if (loggedInUser.userName) {
            const birthDate = new Date(loggedInUser.birthday)

            setProfileDetails({
                ...profileDetails,
                userName: loggedInUser.userName,
                city: loggedInUser.city,
                country: loggedInUser.country,
                gender: loggedInUser.gender,
                education: loggedInUser.education,
                occupation: loggedInUser.occupation,
                income: loggedInUser.income,
                firstName: loggedInUser.firstName,
                lastName: loggedInUser.lastName,
                birthday: birthDate.getFullYear() + "-" + ("0" + (birthDate.getMonth() + 1)) + "-" + ("0" + (birthDate.getDate())).slice(-2)
            })
        }
        setLoading(false)
    }, [])

    const video_steps = (step) => {

        // console.log(step)

        switch (step) {
            case 1:
                return {
                    startTime: 0,
                    endTime: 0,
                    component: <Step_1 isAnimationPlaying={isAnimationPlaying} nextStep={() => setCurrentStep(currentStep + 1)} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }

            case 2:
                return {
                    startTime: 0,
                    endTime: 5,
                    component: <Step_2 isAnimationPlaying={isAnimationPlaying} nextStep={() => setCurrentStep(currentStep + 1)} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }

            case 3:
                return {
                    startTime: 5,
                    endTime: 9.8,
                    component: <Step_3 isAnimationPlaying={isAnimationPlaying} nextStep={() => setCurrentStep(currentStep + 1)} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }

            case 4:
                return {
                    startTime: 9.8,
                    endTime: 14.8,
                    component: <Step_4 isAnimationPlaying={isAnimationPlaying} nextStep={() => setCurrentStep(currentStep + 1)} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }

            case 5:
                return {
                    startTime: 15.5,
                    endTime: 21,
                    component: <Step_5 isAnimationPlaying={isAnimationPlaying} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }

            default:
                return {
                    startTime: 0,
                    endTime: 0,
                    component: <Step_1 isAnimationPlaying={isAnimationPlaying} nextStep={() => setCurrentStep(currentStep + 1)} profileDetails={profileDetails} setProfileDetails={setProfileDetails} />
                }
        }
    }

    const startAnimation = (step) => {

        if (animation) {

            animation.currentTime = video_steps(step).startTime;

            setAnimationPlaying(true);
            animation.play();

            const a = setTimeout(() => {
                setAnimationPlaying(false);
                animation.pause();
            }, parseInt((video_steps(step).endTime - video_steps(step).startTime) * 1000));

            return () => clearTimeout(a);

            // const interval = setInterval(() => {

            //     if (animation.currentTime >= video_steps(step).endTime) {
            //         animation.pause();

            //         animation.currentTime = video_steps(step).startTime;
            //         setTimeout(() => {
            //             animation.play();
            //         }, 500);
            //     }

            // }, 100);

            // return () => clearInterval(interval);

        }

    }

    useEffect(() => {
        setAnimation(document.getElementById('signup_animation'));
    }, []);

    useEffect(() => {
        startAnimation(currentStep);
    }, [currentStep]);

    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (

                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // position: 'fixed',
                            // top: '2%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>


                    <React.Fragment>
                        <div className="position-fixed h-100 w-100">

                            <div className="h-100 d-xl-block d-none" style={{ backgroundColor: '#079e6f', position: 'absolute', right: 0, width: '42.8%' }}></div>

                            <div className="row h-100 justify-content-centers align-items-center">
                                <div className="col-12">

                                    <video id="signup_animation" className="d-xl-block d-none" width={'100%'} height={'100%'} controls={false} muted={true}>
                                        <source src={require('../../assets/images/signup_animation.mp4')} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                    </video>

                                </div>
                            </div>
                        </div>

                        <div className="position-relative">

                            {/* Logo */}
                            <div className={height > 768 && width > 800 ? "container-fluid position-fixed m-3" : "container-fluid my-3 mr-3"}>
                                <a href="/">
                                    <img src={require('../../assets/utils/images/logo.png')} alt="logo" width='135' />
                                </a>
                            </div>

                            <div className={`container-fluid px-5 ${height > 768 && width > 800 ? 'h-100' : ''}`}>
                                <div className={`row justify-content-centers align-items-center ${height > 768 && width > 800 ? 'h-100' : ''}`}>
                                    <div className="col-xl-6 col-12">

                                        {/* Step Component */}
                                        {video_steps(currentStep).component}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                </LoadingOverlay>


            )}
        />
    )

}

export default Create_Profile