import React, { useEffect, useState } from 'react'
import styles from '../../../assets/css/rstyling.module.css'
import classnames from "classnames";
import {
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";



// ******************** This component is used in ADMIN & RESEARCHER for displaying Project Criteria *******************************

const DataTable = (props) => {

    const [activeTab, setActiveTab] = useState('');
    const [genderTab, setGenderTab] = useState(0);
    const [cityTab, setCityTab] = useState(0);

    // For Set Grid value
    const gridValueSet = (value, rowIndex, colIndex) => {
        let gridSets= [...props.data.gridSets]
        for (let i = 0; i < gridSets.length; i++) {

            if (gridSets[i].selectedGender === genderTab && gridSets[i].selectedCity === cityTab) {

                gridSets[i].incomeAgeGrid[rowIndex][colIndex] = value;

                props.setData({
                    ...props.data,
                    gridSets: [...gridSets]
                });
                break;
            }
        }

    }

    return (
        <div className={`table-responsive border mt-5 ${styles.tbl}`} style={{ borderRadius: '15px' }}>

            <div className="container-fluid">
                <div className={`row ${styles.data_table} data_table`}>

                    <div className={`col-md-2 col-sm-2 col-4 align-self-center pl-3 ${styles.bg_blue} text-white`}>
                        <div style={{ padding: props.width >= 470 ? "2.1rem 0 0 0" : "1.2rem 0 0 0", fontSize: "1rem" }}>CWE<br />INCOME<br />RANGE (SEC)<br /></div>
                    </div>

                    {/* Tabs */}
                    <div className="col-md-10 col-sm-10 col-8 border-left">

                        <div className="row align_tab border-bottom p-0 " >
                            <Nav justified>
                                {/* genders */}
                                {
                                    props.data.gender.options.length === 0 ?
                                        <NavItem className=" border-right" style={{ lineHeight: '2.5rem' }}>
                                            <NavLink href="#"
                                                className={classnames("py-2", {
                                                    active: genderTab === 'any'
                                                })}
                                                onClick={() => {
                                                }}>
                                                Any
                                            </NavLink>
                                        </NavItem>
                                        :
                                        props.data.gender.options.map((gender, index) => {
                                            return <NavItem key={index} className=" border-right" key={gender} style={{ lineHeight: '2.5rem' }}>
                                                <NavLink href="#"
                                                    className={classnames("py-2", {
                                                        active: props.data.gender.selectedIndex === index,
                                                    })}
                                                    onClick={() => {
                                                        setGenderTab(index)
                                                        props.onTabChange(index, 'gender')
                                                    }}>
                                                    {gender}
                                                </NavLink>
                                            </NavItem>
                                        })
                                }
                            </Nav>
                        </div>
                        {/* Cities */}
                        <div className="row align_tab p-0 " style={{ overflowX: 'auto' }}>
                            <Nav pills fill className="m-0">
                                {
                                    props.data.cities.options.length === 0 ?
                                        <NavItem className="border-right" style={{ lineHeight: '2rem' }}>
                                            <NavLink href="#"
                                                className={classnames("py-2", {
                                                    active: cityTab === 'any'
                                                })}
                                                onClick={() => {
                                                    // setGenderTab(gender)
                                                }}>
                                                Any
                                            </NavLink>
                                        </NavItem>
                                        :
                                        props.data.cities.options.map((city, index) => {
                                            return <NavItem key={index} className="border-right" key={city} style={{ lineHeight: '2rem' }}>
                                                <NavLink href="#"
                                                    className={classnames("py-2", {
                                                        active: props.data.cities.selectedIndex === index
                                                    })}
                                                    onClick={() => {
                                                        setCityTab(index)
                                                        props.onTabChange(index, 'cities')
                                                    }}>
                                                    {city}
                                                </NavLink>
                                            </NavItem>
                                        })
                                }
                            </Nav>
                        </div>

                    </div>

                    
                    <div className="container-fluid horizontal-scrollable">
                        <div className="row">

                            {/* Income Range */}
                                {/* Left Sidebar/ Header of the Table */}
                            <div className={`${styles.bg_blue} col-md-2 col-sm-10 col-4 p-0`}>
                                <div className="table-responsive" style={{ maxHeight: '300px' }}>
                                    <table className="table mb-0 pb-0">
                                        <tbody className="text-white mb-5">
                                            <tr style={{ height: '2.5rem' }} ><td className="border-0"></td></tr>
                                            {
                                                props.data.incomeRange.options.length === 0 ?
                                                    <tr><td>Any</td></tr>
                                                    :
                                                    props.data.incomeRange.options.map((incomeRange, index) => {
                                                        return <tr key={index} style={{ lineHeight: "1.7rem" }}><td>{incomeRange}</td></tr>
                                                    })
                                            }
                                            <tr style={{ height: '2.5rem' }} ><td>Total</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="col-md-10 col-sm-10 col-8 p-0">
                                <div className="table-responsive table-bordered table-striped" style={{ maxHeight: '300px' }}>
                                    <table className="table mb-0 pb-0">
                                {/* Header of the Table */}

                                        {/* Age Range  */}

                                        <thead>
                                            <tr className="text-center">
                                                {
                                                    props.data.ageRange.options.length === 0 ?
                                                        <th>
                                                            15 - 90
                                                        </th>
                                                        :
                                                        props.data.ageRange.options.map(ageRange => {
                                                            return <th key={ageRange} >
                                                                {ageRange}
                                                            </th>
                                                        })
                                                }
                                            </tr>
                                        </thead>

                                        {/*  Data */}

                                        <tbody>
                                            {
                                                !props.isEditable ? (

                                                    props.data.gridSets.map(grid => {
                                                        if (
                                                            grid.selectedGender === props.data.gender.selectedIndex &&
                                                            grid.selectedCity === props.data.cities.selectedIndex
                                                        ) {
                                                            return <>
                                                                {
                                                                    grid.incomeAgeGrid.map(row => {
                                                                        return <tr key={row}>
                                                                            {row.map(col => {
                                                                                return <td key={col} style={{ height: '2.8rem' }}>
                                                                                    {col}
                                                                                </td>
                                                                            })}
                                                                        </tr>

                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    })

                                                ) : (

                                                    props.data.gridSets.map((data) => {

                                                        if (data.selectedGender === genderTab && data.selectedCity === cityTab) {

                                                            return data.incomeAgeGrid.map((row, rowIndex) => {

                                                                return <tr key={rowIndex}>

                                                                    {row.map((col, colIndex) => {
                                                                        return <td key={colIndex}>

                                                                            {/* Grid input  */}
                                                                            <input type="number" className="form-control form-control-sm" style={{ height: '1.6rem' }}
                                                                                value={col}
                                                                                onChange={(e) => gridValueSet(parseInt(e.target.value), rowIndex, colIndex)}
                                                                            />
                                                                        </td>

                                                                    })}

                                                                </tr>

                                                            })
                                                        }
                                                    })

                                                )
                                            }
                                            <tr>
                                                {
                                                    props.data.ageRange.options.map(ageRange => {
                                                        return <td key={ageRange} >
                                                            0
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DataTable