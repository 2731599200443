import React, { useState } from 'react'
import styles from '../../../assets/css/rstyling.module.css'
import classnames from "classnames";
import {
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";


// ********************** This Component is used in ADMIN & RESEARCHER END in Project's Groupings Screen *************************
// ******************** To plot different graphs based on Question Type by sending Questions Array in props **********************


const Selectable = (props) => {
    const [activeTab, setActiveTab] = useState('')

    const [genderTab, setGenderTab] = useState( props.data.gender.selectedIndex )
    const [cityTab, setCityTab] = useState('lahore')
    const [ageTab, setAgeTab] = useState('35 - 40')
    const [incomeTab, setIncomeTab] = useState('26000 - 50000')

    return (

        <div className="container-fluid">
            <div className="row">

                <div className="col-2 p-0">
                    <div className="table-responsive " style={{ borderRadius: '5px 0 0 5px', maxHeight: '300px'  }}>
                        <table className="table mb-0 pb-0">
                            <tbody className={`text-white ${styles.bg_blue}`} style={{ fontSize: '12px' }}>
                                <tr><td>Gender</td></tr>
                                <tr><td>Cities</td></tr>
                                <tr><td>Age</td></tr>
                                <tr><td>Income</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="col-10 p-0">

                    <div className="table-responsive table-bordered selectable"  style={{ borderRadius: '0 5px 5px 0', maxHeight: '300px'  }}>
                        <table className="table mb-0 pb-0">

                            <tbody className="align_tab">
                                <tr>
                                    {/* Gender */}
                                    <Nav>
                                        {
                                            props.data.gender.options.length === 0 ?
                                                <NavItem className=" border-right" >
                                                    <NavLink href="#"
                                                        className={classnames("py-2", {
                                                            active: genderTab === 'any'
                                                        })}
                                                        onClick={() => {
                                                        }}>
                                                        Any
                                                    </NavLink>
                                                </NavItem>
                                                :
                                                props.data.gender.options.map( (gender, index) => {
                                                    return <NavItem className=" border-right" key={gender} >
                                                        <NavLink href="#"
                                                            className={classnames("py-2", {
                                                                active: props.data.gender.selectedIndex === index,
                                                            })}
                                                            onClick={() => {
                                                                props.onTabChange(index, 'gender')
                                                                // props.onTabChange(index)
                                                            }}>
                                                            {gender}
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                        }
                                    </Nav>
                                </tr>
                                <tr>
                                    <Nav  className="m-0">
                                        {/* Cities */}
                                        {
                                            props.data.cities.options.length === 0 ?
                                                <NavItem className="border-right" >
                                                    <NavLink href="#"
                                                        className={classnames("py-2", {
                                                            active: cityTab === 'any'
                                                        })}
                                                        onClick={() => {
                                                            // setGenderTab(gender)
                                                        }}>
                                                        Any
                                                    </NavLink>
                                                </NavItem>
                                                :
                                                props.data.cities.options.map((city, index) => {
                                                    return <NavItem className="border-right" key={city} >
                                                        <NavLink href="#"
                                                            className={classnames("py-2", {
                                                                active: props.data.cities.selectedIndex === index
                                                            })}
                                                            onClick={() => {
                                                                props.onTabChange(index, 'cities')
                                                            }}>
                                                            {city}
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                        }
                                    </Nav>
                                </tr>
                                <tr>
                                    <Nav  className="m-0">
                                        {/* Age ranges */}
                                        {
                                            props.data.ageRange.options.length === 0 ?
                                                <NavItem className="border-right" >
                                                    <NavLink href="#"
                                                        className={classnames("py-2", {
                                                            active: ageTab === 'any'
                                                        })}
                                                        onClick={() => {
                                                            // setGenderTab(gender)
                                                        }}>
                                                        15 - 90
                                                    </NavLink>
                                                </NavItem>
                                                :
                                                props.data.ageRange.options.map( (ageRange, index) => {
                                                    return <NavItem className="border-right" key={ageRange} >
                                                        <NavLink href="#"
                                                            className={classnames("py-2", {
                                                                active: props.data.ageRange.selectedIndex === index
                                                            })}
                                                            onClick={() => {
                                                                props.onTabChange(index, 'age range')
                                                            }}>
                                                            {ageRange}
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                        }
                                    </Nav>
                                </tr>
                                <tr>
                                    <Nav  className="m-0">
                                        {/* Income ranges */}
                                        {
                                            props.data.incomeRange.options.length === 0 ?
                                                <NavItem className="border-right" >
                                                    <NavLink href="#"
                                                        className={classnames("py-2", {
                                                            active: incomeTab === 'any'
                                                        })}
                                                        onClick={() => {
                                                            // setGenderTab(gender)
                                                        }}>
                                                        0 - 250,000
                                                    </NavLink>
                                                </NavItem>
                                                :
                                                props.data.incomeRange.options.map( (incomeRange, index) => {
                                                    return <NavItem className="border-right" key={incomeRange} >
                                                        <NavLink href="#"
                                                            className={classnames("py-2", {
                                                                active: props.data.incomeRange.selectedIndex === index
                                                            })}
                                                            onClick={() => {
                                                                props.onTabChange(index, 'income range')
                                                            }}>
                                                            {incomeRange}
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                        }
                                    </Nav>
                                </tr>
                            </tbody>


                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Selectable
