import React, { useState } from 'react'
import {
    Card
} from "reactstrap";

import styles from '../../css/styling.module.css';

import Dropzone from "react-dropzone";
import PhotoIcon from '../../images/attachement_icon.png'
import ResizeDetector from 'react-resize-detector';
import CrossICon from '../../images/close_icon.png'
import fileIcon from '../../images/file_icon.png'


const Create_File_Section = (props) => {


    // For changing image upload limit
    const onChangeLimit = (value) => {

        props.setContent({
            ...props.allContent,
            filesSection: { ...props.allContent.filesSection, limit: value }
        })
    }

    const onFileSelect = (file) => {
        if (file) {
            if (props.limit >= file.length + props.allContent.filesSection.files.length) {

                for (let i = 0; i < file.length; i++) {

                    // File Extension Check
                    let fileExtension = file[i].name.split('.').pop();

                    if (fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "xlsx") {

                        const files = props.allContent.filesSection.files
                        files.push(file[i])
                        props.setContent({ ...props.allContent, filesSection: { ...props.allContent.filesSection, files: files } });

                    }
                }
            }

        }
    }

    // on Cancel Image
    const onCancelFile = (index) => {
        const files = props.allContent.filesSection.files
        files.splice(index, 1)
        props.setContent({ ...props.allContent, filesSection: { ...props.allContent.filesSection, files: files } });
    }

    // For deleting a section
    const delSection = () => {

        props.setContent({
            description: props.allContent.description,
            imagesSection: props.allContent.imagesSection,
            videosSection: props.allContent.videosSection,
            participantsSection: props.allContent.participantsSection,
            filesSection: { ...props.allContent.filesSection, files: [], limit: 0 }

        })
    }


    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Card className="main-card mb-3 p-3 mt-2">
                    <div className="d-flex">
                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mr-auto">Attached File</label>
                        <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" >
                            <img src={CrossICon} alt="" width="15px" onClick={() => delSection()} />
                        </button>
                    </div>
                    <Card className="main-card mb-3 p-3 mt-2">

                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Allow Files</label><br />

                        <div className={`input-group input-group-feedback mb-3 ${styles.option_input}`}>

                            {/* Text */}

                            <input type="text" placeholder="Set no. of Files" value={props.limit} className="form-control"
                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                onChange={(e) => onChangeLimit(e.target.value)}
                            />


                            <div className={`input-group-append`}>
                                <button className="btn btn-light" >
                                    <img src={PhotoIcon} alt="" width="25px" />
                                </button>
                            </div>
                        </div>

                        {props.allContent.filesSection && props.allContent.filesSection.files.length > 0 &&
                            // Image Display
                            <div className="mx-3 mt-3 mb-4 p-2" style={{ borderRadius: '10px', maxHeight: 400 }}>

                                {props.allContent.filesSection.files.map((fileSrc, index) => {
                                    return (
                                        <div key={index} className="d-inline-block text-center align-self-center">
                                            <div className="position-relative mr-3 img-fluid border bg-light" style={{ width: '150px', borderRadius: '0.5rem' }}>
                                                <span className="questionImg_close" onClick={() => onCancelFile(index)} style={{ marginLeft: '72px' }}>&times;</span>
                                                <img src={fileIcon} alt="" width="55px" className="py-4" /><br />
                                                {/* <p className='p-2'>{fileSrc.name}</p> */}
                                                <p>File Format {typeof fileSrc === 'string' ? fileSrc.split('.').pop() : fileSrc.name.split('.').pop()}</p>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>

                        }

                        {/* // Image Drop Zone  */}
                        < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">

                            <Dropzone accept={".pdf, .xlsx, .doc"}
                                maxFiles={!Number.isNaN(parseInt(props.limit)) ? parseInt(props.limit) : 0}
                                multiple={!Number.isNaN(parseInt(props.limit)) ? (parseInt(props.limit) > 0 ? true : false) : false}
                                onDrop={file => onFileSelect(file)}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                            <div className="d-flex">
                                                <span className={styles.upload_file_icon} style={{ width: 100, height: width <= 436 ? 25 : 100, backgroundSize: width <= 436 ? 25 : 55, verticalAlign: "middle" }}></span>
                                                <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping file here, or click to select file to upload.</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>


                        </div>



                    </Card >
                </Card >

            )}
        />


    )
}

export default Create_File_Section
