
import { logPageView } from './analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location);
  }, [location]);

  return null;
};

export default RouteChangeTracker;




