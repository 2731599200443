import React, { useState } from "react";
import { TransitionGroup } from 'react-transition-group';


import styles from '../../../assets/css/styling.module.css';
import Swal from 'sweetalert';
import Back from "../../../assets/components/back/Back";
import ResizeDetector from "react-resize-detector";
import { useDispatch } from "react-redux";
import { referFreinds } from "../../../actions/referral_actions";
import SecureLS from "secure-ls";

const Add_Referral = () => {

    const ls = new SecureLS()
    const [invitedFriend, setInvitedFriend] = useState([]);
    const [email, setEmail] = useState()
    const dispatch = useDispatch()
    const [err, setErr] = useState()
    const imageLayout = (width) => {
        if (width <= 440) {
            return {
                width: '80px',
                height: '80px'
            }
        } else {
            return {
                width: '120px',
                height: '120px'
            }
        }
    }

    // send referral link through email
    const sendReferral = () => {
       if(invitedFriend.length > 0) {
        dispatch(referFreinds({ email: invitedFriend }))
        .then((response => {
            setInvitedFriend([])
            Swal({
                title: response.message ? response.message : "Sent",
                text: response.content ? response.content : "Referral Emails sent",
                icon: 'success'
            });
        }))
        .catch(err => {
            Swal({
                title: err.response ? err.response.data.name : "Error",
                text: err.response ? err.response.data.content : "Please try again!",
                icon: 'error'
            });
        })
       }
    }

    // add entered email in listing onclick of enter key
    const handleKeyDown = e => {
        var keyCode = (window.event) ? e.which : e.keyCode;
        if (keyCode === 13 && isValid()) {
            e.preventDefault();
            setInvitedFriend([...invitedFriend, email]);
            setEmail('')
        }
    };

    // removing email from the list
    const handleDel = (email) => {
        const emailInviteList = invitedFriend
        const index = invitedFriend.indexOf(email)
        emailInviteList.splice(index, 1)
        setInvitedFriend([...emailInviteList])
    }

    // email validation
    const isValid = () => {
        setErr()
        if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            return true
        } else {
            setErr('Please type a valid email address')
            return false;
        }
    }

    const getReferralLink = () => {
        if (ls.get('accessUser')) {
            return 'https://app.consumerconnect.net/choose?code=' + ls.get('accessUser').user.referralCode
        } else {
            return 'https://app.consumerconnect.net/choose'
        }
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div className="container-fluid pt-3">
                        {/* Back */}
                        <Back />

                        <div className="_referral_ __settings__ my-4">

                            {/* Top Section Card */}
                            <div className={styles.card_border + " my-4"}>
                                <div className={styles.card_border_body + " p-3"}>

                                    <div className="justify-content-center d-flex">

                                        {/* Send Invitation */}
                                        <div className={width <= 440 ? "text-center mx-3" : "text-center mx-5"}>
                                            <span className={`${styles.icon_placer} ${styles.invitation_icon}`} style={imageLayout(width)}></span>

                                            {/* text */}
                                            <h5 className="mt-2" style={{ color: "#444", fontWeight: 600, fontSize: "1.1rem" }}>
                                                Send Invitation
                                            </h5>
                                        </div>

                                        {/* Registration */}
                                        <div className={width <= 440 ? "text-center mx-3" : "text-center mx-5"}>
                                            <span className={`${styles.icon_placer} ${styles.registration_icon}`} style={imageLayout(width)}></span>

                                            {/* text */}
                                            <h5 className="mt-2" style={{ color: "#444", fontWeight: 600, fontSize: "1.1rem" }}>
                                                Registration
                                            </h5>
                                        </div>

                                        {/* Get Reward */}
                                        <div className={width <= 440 ? "text-center mx-3" : "text-center mx-5"}>
                                            <span className={`${styles.icon_placer} ${styles.reward_icon}`} style={imageLayout(width)}></span>

                                            {/* text */}
                                            <h5 className="mt-2" style={{ color: "#444", fontWeight: 600, fontSize: "1.1rem" }}>
                                                Get Reward
                                            </h5>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            {/* Invite your friends */}
                            <div className="my-4">

                                <div className="pt-3">

                                    {/* Heading */}
                                    <h5 style={{ color: "#444", fontSize: '1.15rem', fontWeight: 600, letterSpacing: '0.5px' }}>
                                        Invite your friends
                                    </h5>

                                    {/* Sub text */}
                                    <span style={{ fontSize: "1.02rem", color: '#666' }}>Send out invititions to your friends by putting in their Email.</span>

                                    <div className="mt-3">

                                        <div className="input-group refer-email-input city-settings-input p-1" style={{ border: "2px solid #ced4da", borderRadius: '10px' }}>
                                            {invitedFriend.map(item => (
                                                <div className="rbt-token rbt-token-removeable" key={item}>
                                                    {item}
                                                    <button
                                                        type="button"
                                                        className="close rbt-close rbt-token-remove-button"
                                                        onClick={() => handleDel(item)}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                            <input
                                                value={email}
                                                className="rbt-input-multi rbt-input-main form-control"
                                                placeholder="Type email address and press enter"
                                                onKeyDown={(e) => handleKeyDown(e)}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />

                                        
                                            {/* Invite Button */}
                                            <div className="my-auto p-1">
                                                <button className={styles.sent_gradient_btn} onClick={() => sendReferral()}>
                                                    <span className={styles.sent_icon}></span>
                                                </button>
                                            </div>

                                        </div>

                                        <small className="text-danger">
                                                {err}
                                            </small>

                                    </div>

                                </div>

                            </div>

                            {/* Share referral Link */}
                            <div className="my-4">
                                <div className="pt-3">

                                    {/* Heading */}
                                    <h5 style={{ color: "#444", fontSize: '1.15rem', fontWeight: 600, letterSpacing: '0.5px' }}>
                                        Share the referral link to friends
                                    </h5>

                                    {/* Sub text */}
                                    <span style={{ fontSize: "1.02rem", color: '#666' }}>Easily share your profile or referral by simply copy pasting the link and share it</span>

                                    <div className="row my-3">

                                        {/* Link Area */}
                                        <div className="col-md-9 col-sm-12 px-0s align-self-center">
                                            <div className="input-group" style={{ backgroundColor: "#e9ecef", borderRadius: '10px' }}>

                                                <input id="copy-refer-link" type="text" className="form-control align-self-center text-secondary border-0 px-4" value={getReferralLink()} disabled={true} />

                                                {/* Invite Button */}
                                                <div className="p-2">
                                                    <button className={styles.btn_outline_blue} onClick={() => { navigator.clipboard.writeText(document.getElementById('copy-refer-link').value) }}>
                                                        Copy Link
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 align-self-center">
                                            <div className={width <= 440 && "mt-5 d-flex justify-content-center"}>
                                                {/* Facebook social Link */}
                                                <div className="d-inline-flex mx-2">
                                                    <a className="link" href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" title="Facebook">
                                                        <img src={require('../../../assets/images/fb_icon.png')} alt="fb-ico" />
                                                    </a>
                                                </div>

                                                {/* Instagram social Link */}
                                                <div className="d-inline-flex mx-2">
                                                    <a className="link" href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" title="Instagram">
                                                        <img src={require('../../../assets/images/insta_icon.png')} alt="insta-ico" />
                                                    </a>
                                                </div>

                                                {/* Twitter social Link */}
                                                <div className="d-inline-flex mx-2">
                                                    <a className="link" href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer" title="Twitter">
                                                        <img src={require('../../../assets/images/twitter_icon.png')} alt="twitter-ico" />
                                                    </a>
                                                </div>

                                                {/* Whatsapp social Link */}
                                                <div className="d-inline-flex mx-2">
                                                    <a className="link" href="https://api.whatsapp.com/send?phone=+92123456789222" target="_blank" rel="noopener noreferrer" title="Whatsapp">
                                                        <img src={require('../../../assets/images/whatsapp_icon.png')} alt="whatsapp-ico" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                )}
            />
        </TransitionGroup>
    )

}

export default Add_Referral;