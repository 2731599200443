import React, { useState, useEffect } from 'react'
import MultiStep from '../../../../assets/components/multistep/MultiStep'
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import UserIcon from '../../../../assets/images/user_header_icon.png'
import Contact from './Contact';
import { TransitionGroup } from 'react-transition-group';
import Demographics from './Demographics';
import EmploymentDetails from './EmploymentDetails';
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import { editUserDetails, getUserDetails } from '../../../../actions/user_action';
import ViewResearcher from './View_Researcher';

const UserInfo = () => {
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEditMode] = useState(false)
    const params = useParams()
    const dispatch = useDispatch()
    const userState = useSelector(state => state.User)
    const [userDetails, setUserDetails] = useState({})

    const onEditUserDetails = () => {
        dispatch(editUserDetails(params.userId, userDetails))
            .then(response => {
                Swal({
                    title: response.payload.title ? response.payload.title : "Edited",
                    text: response.payload.message ? response.payload.message : 'User Details edited',
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }


    const steps = [
        { name: "Contact Details", component: <Contact userDetails={userDetails} setUserDetails={setUserDetails} isEdit={isEdit} editUser={() => onEditUserDetails()} /> },
        { name: "Demographics", component: <Demographics userDetails={userDetails} setUserDetails={setUserDetails} isEdit={isEdit} editUser={() => onEditUserDetails()} /> },
        { name: "Employment Details", component: <EmploymentDetails userDetails={userDetails} setUserDetails={setUserDetails} isEdit={isEdit} editUser={() => onEditUserDetails()} /> },
    ];



    useEffect(() => {
        dispatch(getUserDetails(params.userId))
            .then(response => {
                setUserDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                }).then(() => history('/admin/users'))
            })
    }, []);

    useEffect(() => {
        if (currentPath === `/admin/edit-user/${params.userId}`) {
            setEditMode(true)
        }
    })

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            {/* Page Header */}
            <div className="admin_header">
                {
                    isEdit ?
                        <PageHeader heading="User Personal Information" img={UserIcon}/> 
                        :
                        <PageHeader heading="User Personal Information" img={UserIcon}
                            btnText="Edit"
                            btnClick={() => setEditMode(true)}
                        />
                }

            </div>

            {/* Back Icon */}
            <span onClick={() => history("/admin/user")} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label style={{ cursor: 'pointer' }} className="ml-2">Back</label>
            </span>

            {/* Form Steps */}
            {
                !isLoading && userDetails.role &&
                <div>
                    {
                        userDetails.role === 3 ?
                            <MultiStep showNavigation={true} steps={steps} />
                            :
                            <ViewResearcher userDetails={userDetails} setUserDetails={setUserDetails} editUser={() => onEditUserDetails()} isEdit={isEdit} />
                    }
                </div>

            }

        </TransitionGroup>
    )
}

export default UserInfo
