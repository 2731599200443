import React, { useState, useEffect } from 'react';
import ResizeDetector from "react-resize-detector";
import { useDispatch, useSelector } from 'react-redux'
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Respondent_Survey } from '../../actions/auth_actions';
import Swal from 'sweetalert'
import styles from '../../assets/css/rstyling.module.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Input, Button } from 'reactstrap'
import { getSpecificOption } from '../../actions/settings_actions';
import moment from 'moment';
import SecureLS from 'secure-ls';
import { editUserDetails } from '../../actions/user_action';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';


const ThanksYouMsg = () => {

    const [isLoading, setLoading] = useState(true)

    const ls = new SecureLS
    const dispatch = useDispatch()




    const sendRespondentDataForEmail = () => {
        const userId = ls.get('accessUser').user._id
        dispatch(Respondent_Survey(userId))
            .then((response) => {
                console.log('success')
            }).catch((err) => {
                console.log('err')
            })




    }

    useEffect(() => {
        sendRespondentDataForEmail()
    }, [])



    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (
                <LoadingOverlay tag="div"
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // position: 'fixed',
                            // top: '2%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>


                    <div className="_signup_">

                        <div className="d-flex">
                            <div className="col-lg-8 col-12">

                                {/* Logo */}
                                <div className={height > 768 && width > 800 ? "container-fluid position-fixed m-3" : "container-fluid my-3 mr-3"}>
                                    <a href="/">
                                        <img src={require('../../assets/utils/images/logo.png')} alt="logo" width='135' />
                                    </a>
                                </div>

                                <div className="container">
                                    <div className={`row justify-content-center align-items-center ${height > 768 && width > 800 ? 'h-100' : 'mt-5'}`}>
                                        <div className="col-12">

                                            <h4 className="mb-5 ml-4">

                                                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Response has been Submitted  </h3>
                                                <p style={{ fontSize: '1rem' }}>Thank You for Submitting Your Response.</p>
                                            </h4>

                                            {/* <div className="row"> */}



                                            {/* </div> */}



                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-4 d-lg-block d-none side_bg px-0">
                                {/* Gradient Overlay */}
                                <div className="grad_overlay"></div>

                                <div className="text-center px-3">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="col-12">
                                            {/* <h3 className="mb-4" style={{ color: "#eee", fontWeight: '700', lineHeight: '2.65rem', letterSpacing: '2px' }}>Signup now</h3> */}
                                            <h4 style={{ color: "#eee", fontWeight: '500', lineHeight: '2.65rem', letterSpacing: '2px' }}>
                                                Genuinely Connecting Consumers to Brands.
                                                Influence change in the world with your Voice!
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div >

                    </div >

                </LoadingOverlay>



            )
            }
        />

    )

}

export default ThanksYouMsg;

