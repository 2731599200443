import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { useState} from 'react';
import SecureLS from 'secure-ls';
import axios from 'axios'
import {BACK_SERVER_URL} from '../../../config/server.js'
function WelcomeUser() {
    const [isLoading, setLoading] = useState(false)
    const [profileComp,setProfileComp] = useState(0)
    const [notificationType, setNotificationType] = useState('')
    const ls = new SecureLS()
    const navigate = useNavigate()
    const userFirstName = ls.get('accessUser').user.firstName
    const userId = ls.get('accessUser').user._id

    useEffect( ()=>{
    const url = `${BACK_SERVER_URL}api/users/profileCompletion/${userId}`
    const request = axios.get(url)
    .then(response =>{
     
        setProfileComp(response.data.content)
        setNotificationType(response.data.message)
        
    })
    .catch(err=>
        console.log(err)
        )

    },[])   
    const viewProfilePage=()=>{

        setTimeout(() => {
            navigate('/respondent/profile')
        }, 2000);
       
    }

    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>








{profileComp && profileComp <100 && notificationType==='In-progress projects not found' ?  
<div className="row mt-3">

<div className='col-12'>

    <div className={styles3.card_border_borderless} style={{ backgroundColor: '#EAF7FF' }}>



        <div className={styles.card_border_body + " pt-2 pb-3 px-0"} style={{display:'flex', overflow:'hidden'}}>
            <div style={{ height: '8rem', flex:'90%' }}>
               <p className={`${styles3.welcome_user_text_bold} container`}>Hello {userFirstName}, Welcome to Consumer Connect! 🎉</p>
                <p className={`${styles3.welcome_user_text} container`}>Join the community and complete your profile to unlock<span className={styles3.welcome_user_text_bold}> 100 Connect points</span>. Make connections that matter today!</p>
                   
            </div>
            <div className='container pt-5' style={{flex:'10%'}}>
            <a href="https://app.consumerconnect.net/respondent/profile" target="_blank" rel="noopener noreferrer">
                <button className={`${styles3.view_profile_page}`}>View Profile Page</button>
                </a>
            </div>
        </div>
    </div>


</div>



</div> :
''
}

                           












                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />
        </TransitionGroup>
    )

}

export default WelcomeUser;