import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';


const TiktokCallback = () => {
  const location = useLocation();
console.log('came in tiktok')
  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const authorizationCode = urlParams.get('code');

      if (authorizationCode) {
        const clientKey = 'sbawzit8vl9qvo8kly';
        const clientSecret = 'comCE924qAwt1gzf0iPA4V0emvVEm4yt';
        const redirectUri = 'http://localhost:3006/callback';

        try {
          const response = await axios.post('https://open-api.tiktok.com/oauth/access_token/', {
            client_key: clientKey,
            client_secret: clientSecret,
            code: authorizationCode,
            grant_type: 'authorization_code',
            redirect_uri: redirectUri,
          });

          const { data } = response;
          console.log('Access Token:', data);

          // Save the access token to your app's state or context
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
      }
    };

    fetchToken();
  }, [location.search]);

  return (
    <div>
      <h1>Logging in...</h1>
    </div>
  );
};

export default TiktokCallback;
