import React from 'react';
import { useNavigate } from 'react-router-dom'
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { useState } from 'react';
import SecureLS from 'secure-ls';
import warning from '../../../assets/images/warning-2.svg'

function ProfileNotification() {

    const ls = new SecureLS()
    const navigate = useNavigate()

    const userRole = ls.get('accessToken').role

    const viewProfilePage = () => {

        setTimeout(() => {
            navigate('/respondent/profile')
        }, 2000);

    }


    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (



                    <React.Fragment>










                        {/* <div className=""> */}

                        {/* <div className=''> */}
                        {userRole === 3 ?
                            <div className={`${styles3.card_border_borderfull}`} >


                                {window.innerWidth > 768 ? <div style={{ display: 'flex' }}>
                                    <div>

                                        <p className='container pt-2' style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                        }}><span><img src={warning} className='mr-2' /></span>Complete your profile to unlock 100 Connect</p>

                                    </div>
                                    <div className='container pt-2' style={{ flex: '10%' }}>
                                    <a href="https://app.consumerconnect.net/respondent/profile" target="_blank" rel="noopener noreferrer">
                                        <button className={`${styles3.view_profile_page_red}`}
                                     >View Profile Page</button>
                                     </a>
                                    </div>
                                </div>
                                    : 
                                    <div>
                                    <div className='row'>
                                        <div className='col-12'>

                                            <p className='container pt-2'><span><img src={warning}  /></span>Complete your profile to unlock 100 Connect</p>

                                        </div>
                                       
                                    </div>
                                    <div className='row'>
                                         <div className='col-12 pl-5 pb-2'>
                                         <a href="https://app.consumerconnect.net/respondent/profile" target="_blank" rel="noopener noreferrer">
                                            <button className={`${styles3.view_profile_page_red}`} >View Profile Page</button>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                }

                            </div>
                            : ''

                        }
                        {/* </div> */}



                        {/* </div> */}












                    </React.Fragment>




                )}

            />
        </TransitionGroup>
    )

}

export default ProfileNotification;