import React, { useState, useEffect } from 'react'
import MessageIcon from '../../../assets/images/inperson_forum_icon.png'
import Eyeicon from '../../../assets/images/eye_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import { useNavigate } from 'react-router'
import ResizeDetector from "react-resize-detector";
import Listing from '../../../assets/components/listing/Listing'
import PageHeader from '../../../assets/components/pageheader/PageHeader'
import { getAllBroadcastMsgs } from '../../../actions/broadcast_msg_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';

const BroadcastMessages = () => {

    const broadcastMsgState = useSelector(state => state.BroadcastMessages)
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const history = useNavigate()
    const [broadcastMsgColumns, setBroadcastMsgColumn] = useState([
        { Name: 'Serial Number', Type: 'serialNo', key: 'serialNo' },
        { Name: "Receivers", Type: 'number', key: 'receiver' },
        { Name: "Subject", Type: 'string', key: 'subject' },
        { Name: "Date", Type: 'date', key: 'created_at' },
        { Name: "Time", Type: 'time', key: 'created_at'  },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: '/admin/view-broadcast-message',
                    type: 'button',
                }
            ]
        }

    ]);

    const [broadcastMsgData, setBroadcastMsgData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedBroadcastMsg, setSearchedBroadcastMsg] = useState([])

    // Searching through subject
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredBroadcastMsg = broadcastMsgData.filter(row => search.test(row.subject));
        setSearchedBroadcastMsg(filteredBroadcastMsg);
    }, [searchString]);

    const getBroadcastMsgsListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getAllBroadcastMsgs(currentPage, dataLimit))
            .then(response => {
                console.log(response)
                setBroadcastMsgData(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Something went wrong",
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        getBroadcastMsgsListing()
    }, [])

    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>

                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                        {
                            <>
                                <div className="project_header">
                                    <PageHeader
                                        heading="Broadcast Messages" img={MessageIcon}
                                        btnText={width <= 350 ? "Create" : "Create Message"}
                                        btnClick={() => history("/admin/create-broadcast-message")}
                                    />
                                </div>

                                <Card className="main-card mb-3">

                                    {/* Listing Header */}
                                    <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                        <div className="text-white menu-header-title text-capitalize">Messages List</div>

                                        <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                            <ul className="navbar-nav mr-auto">
                                            </ul>
                                            <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                                <div className="input-group input-group-sm w-75">
                                                    <div className="input-group-prepend border-0">
                                                        <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                    </div>
                                                    <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search"
                                                        onChange={(e) => setSearchString(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                    </nav>

                                    {/* Listing */}
                                    <CardBody className="p-0">
                                         <LoadingOverlay tag="div" active={isLoading}
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: "#fff",
                                                    opacity: 0.5,
                                                }),
                                            }}
                                            spinner={
                                                <Loader color="#6c757d" active type="ball-pulse" />
                                            }>
                                            <CardBody className="p-0">

                                                {/* Listing */}
                                                <div className="table_border">
                                                    <Listing columnName={broadcastMsgColumns}
                                                        data={(searchString.length > 0 || searchedBroadcastMsg.length > 0) ? searchedBroadcastMsg : broadcastMsgData}
                                                        currentPage={broadcastMsgState && broadcastMsgState.currentPage ? broadcastMsgState.currentPage : 1}
                                                        totalPages={broadcastMsgState && broadcastMsgState.totalPages ? broadcastMsgState.totalPages : 1}
                                                        getListingData={(currentPage, dataLimit) => getBroadcastMsgsListing(currentPage, dataLimit)}
                                                        startLoading={() => setLoading(true)}
                                                    />
                                                </div>

                                            </CardBody>
                                         </LoadingOverlay>  
                                    </CardBody>
                                </Card>
                            </>
                        }

                    </TransitionGroup>
                </>
            )}
        />
    )
}

export default BroadcastMessages
