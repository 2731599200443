import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';

import {
    CardHeader,
    Card,
    CardBody,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import styles from '../../../../../../assets/css/rstyling.module.css'
import styles1 from '../../../../../../assets/css/styling.module.css'
import ProjectIcon from '../../../../../../assets/images/project_icon.png'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import avatar from "../../../../../../assets/images/profile_placeholder.png";
import CommentIcon from '../../../../../../assets/images/comment_icon_blue.png'
import ProfileImage from '../../../../../../assets/images/profile_image.jpg'
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import ArrowLeft from "../../../../../../assets/images/arrow_left.png";
import { useNavigate, useParams } from 'react-router';
import { delComment, delPostReply, editComments, getAllParticipantPosts, getAllReplyComments } from '../../../../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { DropdownItem, DropdownMenu, UncontrolledButtonDropdown, DropdownToggle } from 'reactstrap';
import DotsIcon from '../../../../../../assets/images/dots_icon_gray.png'
import SecureLS from 'secure-ls';
import AlertModal from '../../../../../../assets/components/modal/AlertModal';
import Write_Comment from '../../../../../Admin/Project/Activity/view/WriteComment';


// ************ This file is called on click of view button of participants listing to show users specific response of Activity Type FORUM & DIARY. **************

const JournalActivity = () => {

    const ls = new SecureLS()

    const activityParticipantsState = useSelector(state => state.ActivityParticipant)
    const commentState = useSelector(state => state.Comments)

    const params = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(true)
    const [postComments, setPostComments] = useState([])
    const [commentsPostId, setCommentPostId] = useState('')
    const [editCommentId, setEditComment] = useState('')
    const [editedCommentText, setEditedComment] = useState('')
    const [isModalOpen, setModal] = useState(false)
    const [isDelPost, setDelPostModal] = useState(false)

    const [delId, setDelId] = useState(false)
    const [delPostId, setDelPostId] = useState(false)

    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        getPosts()
    }, [])

    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    useEffect(() => {
        delPostId ? setDelPostModal(true) : setDelPostModal(false)
    }, [delPostId])

    const getPosts = (page = 1, limit = 10) => {
        dispatch(getAllParticipantPosts({ page: page, limit: limit, participantId: params.userid, activityId: params.activityid }))
            .then((response) => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const getComments = (id, isNew, page = 1, limit = 10) => {
        dispatch(getAllReplyComments({ postId: id, page: page, limit: limit }))
            .then((response) => {
                setCommentPostId(id)
                isNew ? setPostComments(response.payload.content.comments) :
                    setPostComments([...postComments, ...response.payload.content.comments])
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    // For load more data
    const next = () => {
        getPosts(activityParticipantsState.currentPage + 1, 10)
    }

    const onDelComment = () => {
        setModal(false)
        dispatch(delComment(delId))
            .then(response => {
                setDelId('')
                Swal({
                    title: "Deleted",
                    text: "Comment Deleted",
                    icon: 'info'
                }).then(() => {
                    getPosts(activityParticipantsState.currentPage);
                    getComments(commentsPostId, true, commentState.currentPage, 1)
                })
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })

    }

    const onEditComment = () => {
        dispatch(editComments(editCommentId, { description: editedCommentText }))
            .then(response => {
                setEditComment('')
                Swal({
                    title: "Edited",
                    text: "Comment Edited",
                    icon: 'info'
                }).then(() => getComments(commentsPostId, true, commentState.currentPage, 10))
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    const delResponse = () => {
        dispatch(delPostReply(delPostId))
            .then((response) => {
                setDelPostId()
                getPosts(activityParticipantsState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Response Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })



    }


    return (
        <>
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>
                {/* Delete Alerts */}
                <AlertModal isModalOpen={isModalOpen} setModal={() => setModal(!isModalOpen)}
                    onClick={onDelComment}
                />
                <AlertModal isModalOpen={isDelPost} setModal={() => setModal(!isDelPost)}
                    onClick={delResponse}
                />


                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                    <PageHeader
                        heading="Diary Activity" img={ProjectIcon}
                        btnText="Marked as Viewed"
                        btnClick={() => console.log("I'm clicked")}
                    />

                    {/* Back Icon */}
                    <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>
                    {/* Card */}
                    <div className="scroll-area-lg" style={{ userSelect: 'none' }}>
                        <PerfectScrollbar>
                            <Card className="main-card mb-3">
                                {/* Author details */}
                                {
                                    activityParticipantsState.posts && activityParticipantsState.posts.length > 0 &&
                                    <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                                        <img src={activityParticipantsState.posts[0].author.ProfileImage ? activityParticipantsState.posts[0].author.ProfileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                        {activityParticipantsState.posts[0].author.userName}, Responses
                                    </CardHeader>
                                }
                                <CardBody>

                                    <VerticalTimeline layout="1-column"
                                        className="vertical-time-simple vertical-without-time">

                                        {
                                            activityParticipantsState.posts && activityParticipantsState.posts.length > 0 ?
                                                activityParticipantsState.posts.map((post, index) => {
                                                    return <VerticalTimelineElement key={index} className="vertical-timeline-item">
                                                        <div className='d-flex'>
                                                            <h6 style={{ fontSize: '0.93rem', color: "#888", fontWeight: "600" }} className="mr-auto">{new Date(post.created_at).toLocaleDateString('en-US', options)}</h6>
                                                            <div className="float-right">
                                                                <div className="d-inline-flex align-self-center custom_drop">
                                                                    {/* Edit Del dropdown */}
                                                                    {
                                                                        <UncontrolledButtonDropdown className={'participant_btn '}>
                                                                            <DropdownToggle>
                                                                                <img src={DotsIcon} alt="" width={4} />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu style={{ marginLeft: '-12.8rem' }}>
                                                                                <DropdownItem onClick={() => console.log("Editing...")}>Edit</DropdownItem>

                                                                                <DropdownItem onClick={() => setDelPostId(post._id)}>Delete</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledButtonDropdown>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* Description */}
                                                        {
                                                            post.activityType === 'Diary' ?
                                                                <p dangerouslySetInnerHTML={{ __html: post.description }}></p> :
                                                                <p>{post.description}</p>
                                                        }

                                                        {/* Images */}
                                                        {
                                                            post.images.length > 0 &&
                                                            post.images.map(image => {
                                                                return <div className="px-4 pb-3">
                                                                    <img src={image} style={{ borderRadius: '10px' }} width="250px" height="250px" alt="post-img" />
                                                                </div>
                                                            })
                                                        }
                                                        {/* comment Count */}
                                                        {
                                                            post.comments > 0 &&
                                                            <div className={`${styles.text_blue} my-3`} onClick={() => getComments(post._id, true)}>
                                                                <span style={{ fontSize: '0.85rem', fontWeight: '600', cursor: 'pointer' }}><img src={CommentIcon} alt="" className="mr-1" width="20" />
                                                                    View {post.comments} Comments
                                                                </span>
                                                            </div>
                                                        }

                                                        {/* Comments */}
                                                        {
                                                            commentsPostId === post._id &&

                                                            <div className="my-3 ml-4" >
                                                                {
                                                                    postComments.length > 0 &&
                                                                    postComments.map((comment, index) => {
                                                                        if (comment.post === post._id) {
                                                                            return <div key={index} className="my-3" >
                                                                                {
                                                                                    editCommentId === comment._id ?
                                                                                        <div className="col align-self-center py-3 px-3 d-flex">

                                                                                            <div className="input-group mx-3" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                                                                                <input type="text"
                                                                                                    className={"form-control " + styles.reply_comment_textbox}
                                                                                                    value={editedCommentText}
                                                                                                    style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                                                                                                    onChange={(e) => setEditedComment(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='d-inline-flex align-self-cente'>

                                                                                                <button className={styles.sent_blue_btn} onClick={() => onEditComment()}>
                                                                                                    <span className={styles1.sent_icon}></span>
                                                                                                </button>
                                                                                            </div>

                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            <div className="d-flex mb-2">

                                                                                                <div className="d-flex mr-auto">
                                                                                                    <img src={comment.author.profileImage ? comment.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                                                                                    <h4 className="timeline-title mb-0 align-self-center" style={{ fontWeight: '500' }}>{comment.author.userName}</h4>
                                                                                                </div>

                                                                                                <div className="float-right">
                                                                                                    <div className="d-inline-flex align-self-center custom_drop">
                                                                                                        {/* Time */}
                                                                                                        <h5 style={{ color: "#999", fontWeight: 400, fontSize: "0.85rem" }} className="my-auto py-auto">
                                                                                                            {new Date(comment.created_at).toLocaleDateString()}
                                                                                                        </h5>

                                                                                                        {/* Edit Del dropdown */}
                                                                                                        {
                                                                                                            comment.author._id === ls.get('accessUser').user._id &&
                                                                                                            <UncontrolledButtonDropdown className={'participant_btn '}>
                                                                                                                <DropdownToggle>
                                                                                                                    <img src={DotsIcon} alt="" width={4} />
                                                                                                                </DropdownToggle>
                                                                                                                <DropdownMenu style={{ marginLeft: '-12.8rem' }}>
                                                                                                                    <DropdownItem onClick={() => { setEditComment(comment._id); setEditedComment(comment.description) }}>Edit</DropdownItem>

                                                                                                                    <DropdownItem onClick={() => setDelId(comment._id)}>Delete</DropdownItem>
                                                                                                                </DropdownMenu>
                                                                                                            </UncontrolledButtonDropdown>
                                                                                                        }

                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                            <p id="comment" style={{ userSelect: 'text' }}>{comment.description}</p>

                                                                                        </>
                                                                                }


                                                                            </div>

                                                                        }


                                                                    })
                                                                }
                                                            </div>
                                                        }


                                                        {/* Load More Data */}
                                                        {
                                                            commentsPostId === post._id && commentState.currentPage !== commentState.totalPages &&
                                                            <div className={styles.card_border} style={{ borderBottom: 0, borderRadius: 0, borderTop: 0 }}>
                                                                <div className={"pb-2 ml-5"}>
                                                                    <div className="row px-3">
                                                                        <div className="mx-2 align-self-center d-inline-flex" onClick={() => getComments(post._id, false, commentState.currentPage + 1, 10)}>

                                                                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>View More Comments</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }


                                                        {/* Add Comment */}
                                                        {
                                                            post.activityType === 'Forum' &&
                                                            <Write_Comment getComments={(id, isNew, page, limit) => getComments(id, isNew, page, limit)} postId={post._id} />
                                                        }


                                                    </VerticalTimelineElement>

                                                })
                                                :
                                                <div className={"pb-2 ml-5"}>
                                                    <div className="row px-3">
                                                        <div className="col -12 mt-3 align-self-center text-center">
                                                            {/* Comment Counts */}
                                                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Responses Available</span>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </VerticalTimeline>

                                    {
                                        activityParticipantsState.totalPages > 0 && activityParticipantsState.totalPages !== activityParticipantsState.currentPage &&
                                        <div className={"pb-2 ml-5"}>
                                            <div className="row px-3">
                                                <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                                    {/* Comment Counts */}
                                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Responses</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </CardBody>

                            </Card>
                        </PerfectScrollbar>
                    </div>
                </TransitionGroup >

            </LoadingOverlay>



        </>
    )
}

export default JournalActivity

