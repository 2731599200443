import React, { useEffect, useRef, useState } from 'react';
import { IoIosAdd, IoIosClose } from "react-icons/io";
import styles from '../../../../assets/css/styling.module.css';
import styles1 from '../../../../assets/css/rstyling.module.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Create_Question_With_Checkbox from '../../../../assets/components/create-survey-questions/Create_Question_With_Checkbox';
import Create_Question_With_Radiobtn from '../../../../assets/components/create-survey-questions/Create_Question_With_Radiobtn';
import Create_Question_With_LongText from '../../../../assets/components/create-survey-questions/Create_Question_With_Longtext';
import Create_Question_With_Image from '../../../../assets/components/create-survey-questions/Create_Question_With_Image';
import Create_Question_With_Imagetext from '../../../../assets/components/create-survey-questions/Create_Question_With_Imagetext';
import Create_Question_With_Video from '../../../../assets/components/create-survey-questions/Create_Question_With_Video';
import Create_Question_With_Videotext from '../../../../assets/components/create-survey-questions/Create_Question_With_Videotext';
import Create_Question_With_Grid from '../../../../assets/components/create-survey-questions/Create_Question_With_Grid';
import Create_Question_With_Spectrum2 from '../../../../assets/components/create-survey-questions/Create_Question_With_Spectrum2';
import ArrowLeft from '../../../../assets/images/arrow_left.png';
import { Input } from 'reactstrap';
import Dropzone from 'react-dropzone';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import CreateIcon from '../../../../assets/images/create_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import ResizeDetector from 'react-resize-detector';
import { createActivity, getActivity, updateActivities, updateActivity } from '../../../../actions/activities_actions';
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';



import CryptoJS from 'crypto-js';


const Create_Evaluation = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const param = useParams()
    const formRef = useRef()
    const Priorities = ['high', 'medium', 'low']
    const [isSave, setSave] = useState(false)
    const [isPublish, setPublishMode] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEditMode] = useState(false)
    const [isPreview, setPreviewMode] = useState(false)

    const [evaluationContent, set_evaluationContent] = useState({
        pages: [{
            pageName: "Page-1",
            questions: [],
        }],
    })

    const [screenerData, setScreenerData] = useState({
        name: '',
        description: '',
        duration: '',
        days: '',
        priority: '',
        startDate: '',
        displayResponses: "No"
    })

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedQuestionType, setSelectedQuestionType] = useState('radio');

    // For Rename Tab
    const onTabNameChange = (index, value) => {

        let pages = [...evaluationContent.pages];
        pages[index].pageName = value;
        set_evaluationContent({ ...evaluationContent, pages });

    }

    // For Remove a Tab
    const onTabRemove = (index) => {

        let pages = [...evaluationContent.pages];

        if (pages.length !== 1) {

            pages.splice(index, 1);
            set_evaluationContent({ ...evaluationContent, pages });
        }

    }

    // For Add a new Tab
    const onAddNewTab = () => {

        let pages = [...evaluationContent.pages];
        pages.push({
            pageName: `Page Name ${pages.length + 1}`,
            questions: []
        })
        set_evaluationContent({ ...evaluationContent, pages });

    }

    // Check if questions are complete
    const validateQuestions = (question) => {

        // check for question text and option text values
        if (question.questionType === "radio" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')
                    return false
            }

            return true
        }

        else if (question.questionType === "checkbox" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')

                    return false
            }

            return true
        }

        else if (question.questionType === "spectrum" && question.questionText !== '' && question.pointsOptions.length > 0) {

            for (let i = 0; i < question.pointsOptions.length; i++) {

                if (question.pointsOptions[i].text === '' && question.pointsOptions[i].image === '')

                    return false
            }

            return true
        }
        else if (question.questionType === "grid" && question.questionText !== '' && question.columns.length > 0 && question.rows.length > 0) {

            for (let i = 0; i < question.columns.length; i++) {

                if (question.columns[i].text === '' && question.columns[i].image === '')

                    return false
            }

            for (let i = 0; i < question.rows.length; i++) {

                if (question.rows[i].text === '' && question.rows[i].image === '')

                    return false
            }

            return true
        }


        // check for question text
        else if (question.questionType === "long text" && question.questionText !== '')
            return true

        else if (question.questionType === "image" && question.questionText !== '')
            return true

        else if (question.questionType === "video" && question.questionText !== '')
            return true

        else if (question.questionType === "image text" && question.questionText !== '')
            return true

        else if (question.questionType === "video text" && question.questionText !== '')
            return true
        else
            return false

    }

    // For adding a new question
    const addQuestion = () => {
        setSave(false)
        let pages = [...evaluationContent.pages];

        switch (selectedQuestionType) {
            case 'radio':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    questionFile: "",
                    mediaType: "",
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;

            case 'checkbox':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo:'',
                    mediaType: "",
                    questionFile: "",
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;

            case 'long text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    questionFile: "",
                    mediaType: "",
                    answer: "",
                    required: false,
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'image':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo:'',
                    mediaType: "",
                    questionFile: "",
                    image: [],
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    isComment: false,
                })
                break;

            case 'image text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo:'',
                    mediaType: "",
                    questionFile: "",
                    image: [],
                    answer: "",
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'video':

                
                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    mediaType: "",
                    questionFile: "",
                    video: [],
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    isComment: false,
                    questionVideo: ''
                    

                })
                break;

            case 'video text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    mediaType: "",
                    questionFile: "",
                    video: [],
                    answer: "",
                    required: false,
                    isRandomOrder: false,
                    filesSize: '',
                    filesLimit: '',
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'grid':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo:'',
                    questionFile: "",
                    mediaType: "",
                    rows: [{ text: '', image: '' }],
                    columns: [{ text: '', image: '' }],
                    uniqueSelected: false,
                    selected: [],
                    required: false,
                    isRandomOrder: false,
                    isComment: false
                })
                break;

            case 'spectrum':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo:'',
                    questionFile: "",
                    pointsOptions:[{ text: '', points: ''}],
                    //options:[],
                    selected: "",
                    required: false,
                    // isComment: false,
                    // isRandomOrder: false,
                    mediaType: ""
                })
                break;

            default:
                break;
        }

        set_evaluationContent({ ...evaluationContent, pages });

    }

    // Reordering after drag n drop
    const Reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {

        // dropped outside the list
        if (!result.destination) {
            // no-change
            return;
        }

        if (result.type === "QUESTIONS") {

            const orderedQuestions = Reorder(
                evaluationContent.pages[selectedTab].questions,
                result.source.index,
                result.destination.index
            );

            let pages = [...evaluationContent.pages]

            pages[selectedTab].questions = orderedQuestions;

            set_evaluationContent({
                ...props.evaluationContent,
                pages: [...pages]
            })

        } else {

            if (result.type.indexOf(' ') > 0) {

                let splitedValue = result.type.split(' ')

                // Rows reordering

                if (splitedValue[0] === 'row') {
                    const orderedRows = Reorder(
                        evaluationContent.pages[selectedTab].questions[parseInt(splitedValue[1])].rows,
                        result.source.index,
                        result.destination.index
                    );
                    let pages = [...evaluationContent.pages]

                    pages[selectedTab].questions[parseInt(splitedValue[1])].rows = orderedRows;

                    set_evaluationContent({
                        ...props.evaluationContent,
                        pages: [...pages]
                    })

                } else {

                    // Col Reordering

                    const orderedCol = Reorder(
                        evaluationContent.pages[selectedTab].questions[parseInt(splitedValue[1])].columns,
                        result.source.index,
                        result.destination.index
                    );
                    let pages = [...evaluationContent.pages]

                    pages[selectedTab].questions[parseInt(splitedValue[1])].columns = orderedCol;

                    set_evaluationContent({
                        ...props.evaluationContent,
                        pages: [...pages]
                    })

                }

            } else {

                // Options Reordering

                const orderedOptions = Reorder(
                    evaluationContent.pages[selectedTab].questions[parseInt(result.type)].options,
                    result.source.index,
                    result.destination.index
                );

                let pages = [...evaluationContent.pages]

                pages[selectedTab].questions[parseInt(result.type, 10)].options = orderedOptions;

                set_evaluationContent({
                    ...props.evaluationContent,
                    pages: [...pages]
                })

            }

        }
    }

    const getQuestionNo = (tabNo) => {

        if (tabNo > 0) {
            let quesNo = 0;

            for (let i = 0; i < tabNo; i++)
                quesNo += evaluationContent.pages[i].questions.length;

            return quesNo;

        } else
            return 0;

    }


    let questionNo = getQuestionNo(selectedTab);
    const questionList = (question, questionIndex, dragHandleProps, isValidQuestion) => {
        questionNo++

        switch (question.questionType) {
            case 'radio':
                return <Create_Question_With_Radiobtn
                  id={'radio' + CryptoJS.lib.WordArray.random(5)}
                    name={selectedTab + Math.floor(Math.random() * 100) + (questionIndex + 1)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    questionType={question.questionType}
                    required={question.required}
                    options={question.options}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'checkbox':

                return  <Create_Question_With_Checkbox
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionType={question.questionType}
                    questionFile={question.questionFile}
                    options={question.options}
                    questionIndex={questionIndex}
                    required={question.required}
                    pageIndex={selectedTab}
                    setContent={set_evaluationContent}
                    allContent={evaluationContent}
                    dragHandleProps={dragHandleProps}
                    id={'check' + CryptoJS.lib.WordArray.random(5)}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />
                
                

            case 'long text':

                return <Create_Question_With_LongText
                    id={'longText' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    required={question.required}
                    textLimit={question.textLimit}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'image':

                return <Create_Question_With_Image
                    id={'image' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    required={question.required}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    isComment={question.isComment}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}

                />

            case 'image text':

                return <Create_Question_With_Imagetext
                    id={'image-text' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    required={question.required}
                    textLimit={question.textLimit}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'video':

                return <Create_Question_With_Video
                    id={'video' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionFile={question.questionFile}
                    required={question.required}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    isComment={question.isComment}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    questionVideo={question.questionVideo}
                />

            case 'video text':

                return <Create_Question_With_Videotext
                    id={'video-text' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    required={question.required}
                    textLimit={question.textLimit}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'grid':

                return <Create_Question_With_Grid
                    id={'grid' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    rows={question.rows}
                    columns={question.columns}
                    uniqueSelected={question.uniqueSelected}
                    required={question.required}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    isComment={question.isComment}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'spectrum':

                return <Create_Question_With_Spectrum2
                    id={'grid' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    pointsOptions={question.pointsOptions}
                    required={question.required}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={evaluationContent}
                    setContent={set_evaluationContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    isComment={question.isComment}
                    isRandomOrder={question.isRandomOrder}
                    questionFile={question.questionFile}
                    mediaType={question.mediaType}
                />


            default:
                break;
        }
    }

    // getting data for edit
    useEffect(() => {
        const editUrl = '/admin/project/edit-evaluation/' + params.projectid + "/" + params.evaluationid
        if (currentPath === editUrl) {

            setEditMode(true)
            dispatch(getActivity(params.evaluationid))
                .then((response) => {
                    set_evaluationContent(response.payload.content.survey)
                    const survey = {
                        name: response.payload.content.name,
                        description: response.payload.content.description,
                        duration: response.payload.content.duration,
                        days: response.payload.content.days,
                        priority: response.payload.content.priority,
                        startDate: response.payload.content.startDate,
                        displayResponses: response.payload.content.displayResponses,
                    }
                    setScreenerData(survey)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)
        }
    }, [])


    const onSaveScreener = (values) => {
        setSave(true)
        let errCount = 1

        for (let i = 0; i < evaluationContent.pages.length; i++) {
            for (let k = 0; k < evaluationContent.pages[i].questions.length; k++) {
                if (validateQuestions(evaluationContent.pages[i].questions[k]) === false) {
                    errCount++
                    break;
                }

            }
            if (i === evaluationContent.pages.length - 1 && errCount === 1) {
                errCount = 0
            }

        }

        if (errCount === 0) {
            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('description', values.description)
            formData.append('duration', values.duration)
            formData.append('days', values.days)
            formData.append('priority', values.priority)
            formData.append('startDate', values.startDate)
            //formData.append('type', 8)
            formData.append('displayResponses', values.displayResponses === true ? 'Yes' : 'No')
            values.bannerImage && formData.append('bannerImage', values.bannerImage)
            formData.append('activityType', 'Screener')
            formData.append('projectId', params.projectid)
            isPreview ? formData.append('status', 'draft') : formData.append('status', 'scheduled')


            // Creating Form data
            for (let i = 0; i < evaluationContent.pages.length; i++) {
                
                formData.append(`pages[${i}][pageName]`, evaluationContent.pages[i].pageName)

                for (let j = 0; j < evaluationContent.pages[i].questions.length; j++) {
                    formData.append(`pages[${i}][questions][${j}][questionType]`, evaluationContent.pages[i].questions[j].questionType)
                    formData.append(`pages[${i}][questions][${j}][questionText]`, evaluationContent.pages[i].questions[j].questionText)
                    formData.append(`pages[${i}][questions][${j}][questionVideo]`, evaluationContent.pages[i].questions[j].questionVideo)
                    formData.append(`pages[${i}][questions][${j}][required]`, evaluationContent.pages[i].questions[j].required)
                    evaluationContent.pages[i].questions[j].uniqueSelected && formData.append(`pages[${i}][questions][${j}][isUniqueSelected]`, evaluationContent.pages[i].questions[j].uniqueSelected)
                    evaluationContent.pages[i].questions[j].isRandomOrder && formData.append(`pages[${i}][questions][${j}][isRandomOrder]`, evaluationContent.pages[i].questions[j].isRandomOrder)
                    evaluationContent.pages[i].questions[j].filesSize && formData.append(`pages[${i}][questions][${j}][filesSize]`, evaluationContent.pages[i].questions[j].filesSize)
                    evaluationContent.pages[i].questions[j].filesLimit && formData.append(`pages[${i}][questions][${j}][filesLimit]`, evaluationContent.pages[i].questions[j].filesLimit)
                    evaluationContent.pages[i].questions[j].isComment && formData.append(`pages[${i}][questions][${j}][isAdditionalComment]`, evaluationContent.pages[i].questions[j].isComment)
                    evaluationContent.pages[i].questions[j].textLimit && evaluationContent.pages[i].questions[j].textLimit.min && formData.append(`pages[${i}][questions][${j}][textLimit][min]`, evaluationContent.pages[i].questions[j].textLimit.min)
                    evaluationContent.pages[i].questions[j].textLimit && evaluationContent.pages[i].questions[j].textLimit.max && formData.append(`pages[${i}][questions][${j}][textLimit][max]`, evaluationContent.pages[i].questions[j].textLimit.max)

                    if (evaluationContent.pages[i].questions[j].mediaType === 'mp4') {
                        formData.append(`pages[${i}][questions][${j}][questionFile]`, evaluationContent.pages[i].questions[j].questionFile)
                    } else {
                        evaluationContent.pages[i].questions[j].questionFile && formData.append(`pages[${i}][questions][${j}][questionFile]`, evaluationContent.pages[i].questions[j].questionFile)
                    }

                    // we will have to add other options functionality later
                    if (evaluationContent.pages[i].questions[j].pointsOptions) {
                        console.log('options array coming here?->', evaluationContent.pages[i].questions[j].pointsOptions)
                      
 
                       for (let k = 0; k < evaluationContent.pages[i].questions[j].pointsOptions.length; k++) {
                            
                        
                        // formData.append(`pages[${i}][questions][${j}][options][${k}]`, evaluationContent.pages[i].questions[j].options[k])
                        evaluationContent.pages[i].questions[j].pointsOptions[k].text && formData.append(`pages[${i}][questions][${j}][pointsOptions][${k}][text]`, evaluationContent.pages[i].questions[j].pointsOptions[k].text)
                        evaluationContent.pages[i].questions[j].pointsOptions[k].points && formData.append(`pages[${i}][questions][${j}][pointsOptions][${k}][points]`, evaluationContent.pages[i].questions[j].pointsOptions[k].points)
                        }
                        
                        if (evaluationContent.pages[i].questions[j].isOtherAllowed) {
                            formData.append(`pages[${i}][questions][${j}][options][${evaluationContent.pages[i].questions[j].options.length}]`, 'Other')
                        }
                    }

                    if (evaluationContent.pages[i].questions[j].rows) {
                        
                        for (let k = 0; k < evaluationContent.pages[i].questions[j].rows.length; k++) {
                            evaluationContent.pages[i].questions[j].rows[k].image && formData.append(`pages[${i}][questions][${j}][rows][${k}][image]`, evaluationContent.pages[i].questions[j].rows[k].image)
                            evaluationContent.pages[i].questions[j].rows[k].text && formData.append(`pages[${i}][questions][${j}][rows][${k}][text]`, evaluationContent.pages[i].questions[j].rows[k].text)

                        }
                    }

                    if (evaluationContent.pages[i].questions[j].columns) {
                        for (let k = 0; k < evaluationContent.pages[i].questions[j].columns.length; k++) {
                            evaluationContent.pages[i].questions[j].columns[k].image && formData.append(`pages[${i}][questions][${j}][columns][${k}][image]`, evaluationContent.pages[i].questions[j].columns[k].image)
                            evaluationContent.pages[i].questions[j].columns[k].text && formData.append(`pages[${i}][questions][${j}][columns][${k}][text]`, evaluationContent.pages[i].questions[j].columns[k].text)

                        }
                    }
                    if (evaluationContent.pages[i].questions[j].options) {
                        for (let k = 0; k < evaluationContent.pages[i].questions[j].options.length; k++) {
                            formData.append(`pages[${i}][questions][${j}][options][${k}]`, evaluationContent.pages[i].questions[j].options[k])
                        }
                        if (evaluationContent.pages[i].questions[j].isOtherAllowed) {
                            formData.append(`pages[${i}][questions][${j}][options][${evaluationContent.pages[i].questions[j].options.length}]`, 'Other')
                        }
                    }


                }

            }

            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            if (isEdit) {

                dispatch(updateActivity(params.evaluationid, formData, 8))
                    .then(response => {
                        Swal.stopLoading();
                        Swal.close();
                        isPublish ?
                            Swal({
                                title: response.payload.title ? response.payload.title : "Created",
                                text: response.payload.message ? response.payload.message : 'Screener Created',
                                icon: 'success'
                            }).then(() => {
                                history('/admin/project/evaluation/' + params.projectid)
                            })
                            :
                            history({
                                pathname: `/admin/project/preview-evaluation/${params.projectid}/${response.payload.content._id}`,
                                state: { from: 'create' }
                            })
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            } else {
                dispatch(createActivity(formData,8))
                    .then(response => {
                        Swal.stopLoading();
                        Swal.close();
                        isPublish ?
                            Swal({
                                title: response.payload.title ? response.payload.title : "Created",
                                text: response.payload.message ? response.payload.message : 'Screener Created',
                                icon: 'success'
                            }).then(() => {
                                history('/admin/project/evaluation/' + params.projectid)
                            })
                            :
                            history({
                                pathname: `/admin/project/preview-evaluation/${params.projectid}/${response.payload.content._id}`,
                                state: { from: 'create' }
                            })
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            }

        }



    }

    const onPreview = () => {
        if (formRef.current) {
            setPreviewMode(true)
            formRef.current.handleSubmit()
        }
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <div className="create_header">
                        <PageHeader
                            heading={param.evaluationid ? "Edit Screener Form" : "Create Screener Form"} img={CreateIcon}
                            btnText="Preview"
                            isDisable={evaluationContent.pages[0].questions.length > 0 ? false : true}
                            btnClick={() => onPreview()}
                        />
                    </div>

                    {/* Back Icon */}
                    <span onClick={() => history("/admin/project/evaluation/" + params.projectid)} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="row">
                        <div className="col-12 form-group">

                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                        // position: 'fixed',
                                        // top: '50%'
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>

                                <Formik
                                    initialValues={screenerData}
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    onSubmit={(values) => onSaveScreener(values)}


                                    const validationSchema={Yup.object().shape({
                                        name: Yup.string().required("* Activity Name is required"),
                                        description: Yup.string().required("* Description is required"),
                                        startDate: Yup.date().required("* Start Date is required"),
                                        priority: Yup.string().required("* Priority is required"),
                                        duration: Yup.number().required("* Duration is required"),
                                        days: Yup.number().required("* Duration is required"),

                                    })}


                                >

                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        } = props;

                                        const startDate = new Date(values.startDate)
                                        values.startDate = startDate.getFullYear() + "-" + ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (startDate.getDate())).slice(-2) 

                                        return (
                                            <form autoComplete="off" onSubmit={handleSubmit}>

                                                {/* Start of form */}

                                                <div className='row _survey-steps_'>

                                                    <div className='col-12 mb-3'>
                                                        {/* Image Display & Dropzone */}
                                                        {values.bannerImage ? (

                                                            // Image Display
                                                            <div className="pt-3">
                                                                <div className="position-relative">
                                                                    <span className="questionImg_close" style={{ left: "97%", marginLeft: 0 }} onClick={() => setFieldValue('bannerImage', '')}>&times;</span>
                                                                    <img src={typeof values.bannerImage === 'string' ? values.bannerImage : URL.createObjectURL(values.bannerImage)} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', maxHeight: 400, width: 'max-content' }} alt="banner-image" />
                                                                </div>
                                                            </div>

                                                        ) : (

                                                            // Image Drop Zone 
                                                            < div className="dropzone-wrapper dropzone-wrapper-sm">
                                                                <Dropzone multiple={false} accept={".jpg, .jpeg, .png"} onDrop={file => setFieldValue('bannerImage', file[0])} value={values.bannerImage}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                                                <p>
                                                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>Try dropping image here, or click to select image to upload.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>

                                                        )}
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="activityName">Activity Name</label>
                                                        <Input type="text"
                                                            className={errors.name && touched.name ? styles1.is_invalid : 'valid'}
                                                            name="name"
                                                            placeholder="Activity Name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />

                                                        {errors.name && touched.name && (
                                                            <div>
                                                                {errors.name}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label htmlFor="description">Description</label>
                                                        <Input type="text"
                                                            className={errors.description && touched.description ? styles1.is_invalid : 'valid'}
                                                            name="description"
                                                            placeholder="Description"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />

                                                        {errors.description && touched.description && (
                                                            <div>
                                                                {errors.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration</label>
                                                        <Input type="number"
                                                            className={errors.days && touched.days ? styles1.is_invalid : 'valid'}
                                                            name="days"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.days}
                                                        />

                                                        {errors.days && touched.days && (
                                                            <div>
                                                                {errors.days}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration per Day (Minutes)</label>
                                                        <Input type="number"
                                                            className={errors.duration && touched.duration ? styles1.is_invalid : 'valid'}
                                                            name="duration"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.duration}
                                                        />

                                                        {errors.duration && touched.duration && (
                                                            <div>
                                                                {errors.duration}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="priority">Priority</label>
                                                        <Input type="select"
                                                            className={errors.priority && touched.priority ? styles1.is_invalid : 'form-control valid'}
                                                            name="priority"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.priority}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                Priorities.map(priority => {
                                                                    return <option value={priority}>{priority}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.priority && touched.priority && (
                                                            <div>
                                                                {errors.priority}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="startDate">Start Date</label>
                                                        <Input type="date"
                                                            className={errors.startDate && touched.startDate ? styles1.is_invalid : 'valid'}
                                                            name="startDate"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.startDate}
                                                        />

                                                        {errors.startDate && touched.startDate && (
                                                            <div>
                                                                {errors.startDate}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        {
                                                            <input type="checkbox"
                                                                name="displayResponses"
                                                                onChange={(e) => setFieldValue('displayResponses', e.target.checked)}
                                                                onBlur={handleBlur}
                                                                value={values.displayResponses}
                                                                defaultChecked={values.displayResponses && values.displayResponses === 'Yes' || true ? true : false}

                                                            />
                                                        }

                                                        <label for="showResponses" className='ml-2'>Allow show Responses</label>
                                                    </div>




                                                </div>


                                                {/* PAge Tabs */}
                                                <div className={`${styles.card_border} _survey-steps_`}>
                                                    <div className="w-100" style={{ backgroundColor: "#f7f6f2", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>

                                                        {evaluationContent.pages.map((page, index) => {
                                                            return (
                                                                <div key={index} className="d-inline-flex p-2" style={selectedTab === index ? { backgroundColor: "#fff", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' } : { cursor: 'pointer' }} onClick={() => setSelectedTab(index)}>
                                                                    {selectedTab === index ? (
                                                                        <div className="input-group">
                                                                            <input className={`${styles.editableTab_input} text-center`} type="text" value={page.pageName} onChange={(e) => onTabNameChange(index, e.target.value)} />
                                                                            <div className="input-group-prepend bg-white align-self-center border" style={{ cursor: 'pointer' }} onClick={() => onTabRemove(index)}>
                                                                                <IoIosClose color="#666" size="25px" strokeWidth="5px" />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={index === selectedTab - 1 ? "px-5" : 'px-5 border-right'}>
                                                                            {page.pageName}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="d-inline-flex align-self-center mx-2">
                                                            <button className="border bg-white py-1" style={{ borderRadius: "100%", padding: "0.4rem" }} type="button" onClick={() => onAddNewTab()}>
                                                                <IoIosAdd color="#666" size="18px" strokeWidth="20px" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className={styles.card_border_body + " pt-4 pb-1"}>


                                                        {selectedTab < evaluationContent.pages.length ? (

                                                            //Show Page Content

                                                            <DragDropContext
                                                                onDragEnd={onDragEnd}
                                                            >

                                                                {
                                                                    <Droppable droppableId="droppable" type="QUESTIONS">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                {evaluationContent.pages[selectedTab].questions.map((question, questionIndex) => {
                                                                                    return <Draggable
                                                                                        key={questionIndex}
                                                                                        draggableId={questionIndex.toString()}
                                                                                        index={questionIndex}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                className="mt-3"
                                                                                            >

                                                                                                {/* Questions  */}

                                                                                                {isSave ? questionList(question, questionIndex, provided.dragHandleProps, validateQuestions(question)) :
                                                                                                    questionList(question, questionIndex, provided.dragHandleProps, true)
                                                                                                }



                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                })}
                                                                                {provided.placeholder}

                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                }
                                                            </DragDropContext>

                                                        ) : (
                                                            setSelectedTab(oldTab => oldTab - 1)
                                                        )}

                                                        {/* Add a Question */}
                                                        <div className="row my-3">

                                                            <div className="col-12 mb-1">
                                                                <label style={{ color: '#666', fontSize: "0.95rem", fontWeight: '400' }}>Question Type</label>
                                                            </div>

                                                            <div className="col-8 col-sm-9 col-lg-9 pr-0">

                                                                {/* Question Type */}
                                                                <div className="form-group mb-0">

                                                                    <select className="form-control" onChange={(e) => setSelectedQuestionType(e.target.value)}>
                                                                        <option value="radio">Radio</option>
                                                                        <option value="checkbox">Checkbox</option>
                                                                        <option value="long text">Long Text</option>
                                                                        <option value="image">Image</option>
                                                                        <option value="image text">Image with Text</option>
                                                                        <option value="video">Video</option>
                                                                        <option value="video text">Video with Text</option>
                                                                        <option value="grid">Grid</option>
                                                                        <option value="spectrum">Spectrum</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 col-sm-3 col-lg-3 align-self-center">
                                                                <button className="btn btn-hover-shine text-light py-2 mr-2" style={{ backgroundColor: '#006FA7' }} type="button" onClick={() => addQuestion()}>{width <= 470 ? "Add" : "Add Question"}</button>
                                                                <button className="btn btn-hover-shine text-light py-2 " style={{ backgroundColor: '#006FA7' }} type="submit" onClick={() => setPublishMode(true)}>
                                                                    {values.status && values.status === 'draft' ? 'Publish' : 'Save'}
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }}

                                </Formik>

                            </LoadingOverlay >


                        </div>
                    </div>

                </>
            )}
        />
    )


}

export default Create_Evaluation


