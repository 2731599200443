import React, { useEffect, useState } from 'react'

import ExcerptsIcon from '../../../../../../assets/images/excerpts_icon.png'
import ArrowLeft from '../../../../../../assets/images/arrow_left.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import styles from '../../../../../../assets/css/rstyling.module.css'
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import { getExcerptDetails } from '../../../../../../actions/excerpts_actions';
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const ExcerptsPreview = () => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(true)
    const pathWithId = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const path = pathWithId.slice(0, pathWithId.lastIndexOf('/'))
    const [excerptDetails, setExcerptsDetails] = useState({})

    const goBack = () => {
        switch (path) {
            case '/admin/project/journal-activity/excerpt':
                history('/admin/project/journal-activity/' + params.projectid + "/" + excerptDetails.activity._id + "?tab=2")
                break

            case '/admin/project/survey-activity/excerpt':
                history('/admin/project/survey-activity/' + params.projectid + "/" + excerptDetails.activity._id + "?tab=2")
                break;

            case '/admin/project/forum-activity/excerpt':
                history('/admin/project/forum-activity/' + params.projectid + "/" + excerptDetails.activity._id + "?tab=2")
                break;

            default:
                history(-1)
                break;
        }
    }

    // Edit link for different activity types 
    const editLink = () => {
        switch (path) {
            case '/admin/project/journal-activity/excerpt':
                history('/admin/project/journal-activity/edit-excerpt/' + params.projectid + "/" + params.excerptid)
                break;

            case '/admin/project/survey-activity/excerpt':
                history('/admin/project/survey-activity/edit-excerpt/' + params.projectid + "/" + params.excerptid)
                break;

            case '/admin/project/forum-activity/excerpt':
                history('/admin/project/forum-activity/edit-excerpt/' + params.projectid + "/" + params.excerptid)
                break;

            default:
                history('/admin/edit-excerpt/' + params.excerptid )
                break;
        }
    }

    const getExcerpts = () => {
        dispatch(getExcerptDetails(params.excerptid))
            .then((response) => {
                setLoading(false)
                setExcerptsDetails(response.payload.content)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        getExcerpts()
    }, [])

    return (
        <>
            <div className="excerpt_header">
                <PageHeader
                    heading="Excerpts" img={ExcerptsIcon}
                    btnText="Edit"
                    btnClick={() => editLink()}
                />
            </div>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <div className="container-fluid">

                    {/* Back Icon */}
                    <span onClick={() => goBack()} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="row mt-2">

                        <div className="col-12">
                            <div className={styles.heading_light}>Excerpts</div>
                            <div className={styles.text_bold}>{excerptDetails.selectedString}</div>
                        </div>

                        <div className="col-12">
                            <div className={styles.heading_light}>Add Notes</div>
                            <div className={styles.text_bold}>{excerptDetails.note}</div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <div className={styles.heading_light}>Activity Title</div>
                            <div className={styles.text_bold}>{excerptDetails.activity && excerptDetails.activity.name}</div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <div className={styles.heading_light}>Activity Type</div>
                            <div className={styles.text_bold}>{excerptDetails.activity && excerptDetails.activity.activityType}</div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <div className={styles.heading_light}>From Response</div>
                            <div className={`d-flex ${styles.text_bold}`}>
                                <img src={excerptDetails.responseAuthor && excerptDetails.responseAuthor.profileImage} alt="" width="25" height="25" className="rounded-circle mr-2" />
                                <div className={" align-self-center"}>{excerptDetails.responseAuthor && excerptDetails.responseAuthor.userName}</div>
                            </div>
                        </div>

                    </div>

                </div>

            </LoadingOverlay>


        </>
    )
}

export default ExcerptsPreview
