import React, { useEffect, useState, useContext } from 'react'
import logo from '../../assets/images/logo.png'
import styles from '../../assets/css/rstyling.module.css'
import { IoIosEye } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import landing_page_logo from '../../assets/images/landing_page_logo.png';
import login_bg_new from "../../assets/images/login_bg2.png";
import { IoIosEyeOff } from 'react-icons/io'
import login_bg from "../../assets/images/login_bg.jpg";
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from 'axios'
import SecureLS from 'secure-ls';
import { loginUser, getLoggedInUser } from '../../actions/auth_actions';
import styles3 from '../../assets/css/newStyling.module.css';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useGoogleLogin } from '@react-oauth/google';
import { loginGoogle, loginFacebook } from '../../actions/auth_actions';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { userDetailsContext } from '../../UserDetailsProvider';






export default function Login2() {


    //const {ActivityId} = useParams();
    const [userData, setData] = useState({
        email: '',
        password: ''
    })
    const [showPassword, setPassword] = useState(false)
    const [pageToBeRedirected, setRedirectionPage] = useState('/login')

    const history = useNavigate()
    const dispatch = useDispatch()
    const [toggle, setToggle] = useState('Login')
    const state = useState()
    const ls = new SecureLS()
    const { userDetails, setUserDetails } = useContext(userDetailsContext);


    const onSubmit = (values) => {
        //clearCaches()
        console.log('values ->', values)
        dispatch(loginUser(values))

            //console.log(values)  values are coming
            .then(response => {

                if (response.payload.message === 'User not found') {
                    Swal({
                        title: "Error",
                        text: "User not found!",
                        icon: 'error'
                    });
                }

                else {
                    setUserDetails(response.payload.content)
                    ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role, isVerified: response.payload.content.isVerified });
                    ls.set('refreshToken', response.payload.content.refreshToken);
                    ls.set('userStatus', response.payload.content.status)


                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                    dispatch(getLoggedInUser())
                        .then(response1 => {

                            ls.set('accessUser', { user: response1.payload.content });

                            const page = pageToBeRedirected.split('/')[1] // check for users
console.log('aceessToken before otp ->', ls.get('accessToken'))
                            if (ls.get('accessToken').role) {
                                if (ls.get('accessToken').role === 1) {

                                    // (pageToBeRedirected !== '/login' && page === 'admin') ?
                                    //     history(pageToBeRedirected) :
                                    //     history('/admin')

                                    if (response.payload.content.isVerified === true) {


                                        history('/admin')
                                    }
                                    else {
                                        history('/email')
                                    }

                                }

                                else if (ls.get('accessToken').role === 2) {
                                    if (response.payload.content.isVerified === true) {


                                        history('/researcher')
                                    }
                                    else {
                                        history('/email')
                                    }






                                }
                                else if (ls.get('accessToken').role === 3) {

                                    // if profile is complete with otp verified
                                    if (response.payload.content.status === 'complete' && response.payload.content.isVerified === true) {


                                        history('/respondent')
                                    }

                                    // if profile is incomplete with otp verified
                                    if (response.payload.content.status === 'partially_complete' && response.payload.content.isVerified === true) {

                                        history('/set_up_profile')
                                    }

                                    // if profile is incomplete with otp not verified
                                    if (response.payload.content.status === 'partially_complete' && response.payload.content.isVerified === false) {

                                        // history('/phone')
                                        history('/email')
                                    }

                                    // if profile is complete with otp not verified
                                    if (response.payload.content.status === 'complete' && response.payload.content.isVerified === false) {

                                        history('/email')
                                        // history('/phone')
                                    }
                                }





                                // else {
                                //     (pageToBeRedirected.split('/')[2] === 'quick-survey') ?
                                //         history(pageToBeRedirected) :

                                //         history('/create_profile')
                                // }
                            } else {
                                history('/login')
                            }
                        }).catch(err => console.log(err))

                }
            })
            .catch(err => {
                Swal({
                    title: "Error",
                    text: "Incorrect email or password. Please try again!",
                    icon: 'error'
                });
            })

    }

    const handleGoogleLoginSuccess = (tokenResponse) => {
        const accessToken = tokenResponse.access_token;
        dispatch(loginGoogle(accessToken))


            .then(response => {

                setUserDetails(response.payload.content)
                ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role, otpVerified: response.payload.content.otpVerified, phone: response.payload.content.phone });
                ls.set('refreshToken', response.payload.content.refreshToken);
                ls.set('userStatus', response.payload.content.status)

                axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                dispatch(getLoggedInUser())
                    .then(response1 => {

                        ls.set('accessUser', { user: response1.payload.content });
                        const page = pageToBeRedirected.split('/')[1] // check for users
                        console.log(page)
                        if (ls.get('accessToken').role) {
                            if (ls.get('accessToken').role === 1) {
                                (pageToBeRedirected !== '/login' && page === 'admin') ?
                                    history(pageToBeRedirected) :
                                    history('/admin')
                            }
                            else if (ls.get('accessToken').role === 2) {
                                (pageToBeRedirected !== '/login' && page === 'researcher') ?
                                    history(pageToBeRedirected) :
                                    history('/researcher')
                            }
                            else if (ls.get('accessToken').role === 3)
                                if (response.payload.content.status === 'complete') {
                                    (pageToBeRedirected !== '/login' && page === 'respondent') ?
                                        history(pageToBeRedirected) :
                                        history('/respondent')
                                }
                                //if profile is complete but no phone no.
                                else if (response.payload.content.status === 'complete' && response.payload.content.phone == null) {

                                    history('/phone')
                                    //history('/email')
                                }

                                //if profile is incomplete but no phone no.
                                else if (response.payload.content.status === 'partially_complete' && response.payload.content.phone == null) {


                                    history(`/phone`)
                                    //history('/email')
                                }



                                // profile is partially complete but otp by email is verified
                                else {
                                    (pageToBeRedirected.split('/')[2] === 'quick-survey') ?
                                        history(pageToBeRedirected) :
                                        history('/set_up_profile')
                                }
                        } else {
                            history('/login')
                        }
                    }).catch(err => console.log(err))


            })
            .catch(err => {
                Swal({
                    title: "Error",
                    text: "User does not exist. Try to Signup with Google first!",
                    icon: 'error'
                });
                history(`/choose`)
            })
    }

    const FacebookLogin = (response) => {

        const accessToken = response.data.accessToken
        dispatch(loginFacebook(accessToken))

            .then(response => {
                console.log('just after fb login api ->', response.payload.content)
                setUserDetails(response.payload.content)
                ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role, otpVerified: response.payload.content.otpVerified, phone: response.payload.content.phone });
                ls.set('refreshToken', response.payload.content.refreshToken);
                ls.set('userStatus', response.payload.content.status)

                axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                dispatch(getLoggedInUser())
                    .then(response1 => {
                        console.log('response1 fb login after getLoggedInUser ->', response1)
                        ls.set('accessUser', { user: response1.payload.content });
                        const page = pageToBeRedirected.split('/')[1] // check for users
                        console.log(page)
                        if (ls.get('accessToken').role) {



                            if (ls.get('accessToken').role === 1) {
                                (pageToBeRedirected !== '/login' && page === 'admin') ?
                                    history(pageToBeRedirected) :
                                    history('/admin')
                            }



                            else if (ls.get('accessToken').role === 2) {
                                (pageToBeRedirected !== '/login' && page === 'researcher') ?
                                    history(pageToBeRedirected) :
                                    history('/researcher')
                            }


                            else if (ls.get('accessToken').role === 3)
                                if (response.payload.content.status === 'complete') {
                                    (pageToBeRedirected !== '/login' && page === 'respondent') ?
                                        history(pageToBeRedirected) :
                                        history('/respondent')
                                }

                                else if (response.payload.content.status === 'complete' && response.payload.content.phone == null) {

                                    history('/phone')
                                    //history('/email')
                                }
                                else if (response.payload.content.status === 'partially_complete' && response.payload.content.phone == null) {


                                    history(`/phone`)
                                    //history('/email')


                                }
                                // profile is partially complete 
                                else {
                                    (pageToBeRedirected.split('/')[2] === 'quick-survey') ?
                                        history(pageToBeRedirected) :
                                        history('/set_up_profile')
                                }
                        } else {
                            history('/login')
                        }
                    }).catch(err => console.log(err))


            })
            .catch(err => {
                Swal({
                    title: "Error",
                    text: "User does not exist. Try to Signup with Facebook first!",
                    icon: 'error'
                });
            })

    }






    const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });





    return (




        <>
            <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>
                        <Formik
                            initialValues={userData}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true)
                                onSubmit(values)

                            }}


                            const validationSchema={Yup.object().shape({
                                email: Yup.string().email("Not a valid email").required("Email is required"),
                                password: Yup.string().required("Password is required").min(8)
                            })}


                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue
                                } = props;
                                return (

                                    <div className="col-sm-7 col-12 bg-white align-self-center">
                                        <div className='row justify-content-center'>
                                            <div className={`${styles3.login_section}`}>

                                                <div className=" container" >



                                                    <div className="container text-center">
                                                        <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                                        <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem' }}>Welcome Back.</p>
                                                        <p style={{ color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal' }}>Please enter your email and password to login and see your survey.</p>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="my-4">
                                                                <FormGroup className="">
                                                                    {/* <Label for="email"></Label> */}
                                                                    <Input type="text"
                                                                        className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                                        style={{ paddingTop: '28px', paddingBottom: '28px' }}
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={(e) => setFieldValue('email', e.target.value.trim())}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}


                                                                    />

                                                                    {errors.email && touched.email && (
                                                                        <small className='text-danger' style={{
                                                                            position: 'relative',
                                                                            top: '-25px',
                                                                            right: '-131px'
                                                                        }}>
                                                                            {errors.email}
                                                                        </small>
                                                                    )}
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <Label className={styles.password}>
                                                                        <Input
                                                                            type={showPassword ? "text" : "password"} className={errors.password && touched.password ? styles.is_invalid + "mt-2" : 'valid mt-2'}
                                                                            style={{ paddingTop: '28px', paddingBottom: '28px' }}
                                                                            name="password"
                                                                            placeholder="Password"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.password}


                                                                        />
                                                                        <span className={styles.password_ico_position} onClick={() => setPassword(!showPassword)}>{showPassword ? <IoIosEye /> : <IoIosEyeOff />}</span>
                                                                    </Label>

                                                                    {errors.password && touched.password && (
                                                                        <small className='text-danger' >
                                                                            {errors.password}
                                                                        </small>
                                                                    )}

                                                                </FormGroup>
                                                            </div>


                                                            <div className={`${styles.text_blue} text-right`}>
                                                                <a href="/recover" style={{ color: '#006FA7' }}>
                                                                    Forgot Password
                                                                </a>
                                                            </div>

                                                            <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} type="submit" onClick={() => onSubmit}>
                                                                Login with Email
                                                            </button>
                                                        </form>
                                                        <div className='row'>
                                                            <div className='col'><hr></hr></div>
                                                            <div className='col-2'><div className='py-2' style={{ color: 'rgb(173, 167, 167)', fontWeight: 'bold' }}>Or</div></div>
                                                            <div className='col'><hr></hr></div>
                                                        </div>

                                                        <div>
                                                            <button className={`btn-hover-shine btn btn-block py-3`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} onClick={() => login()}><FcGoogle size={18} className='mx-3' />
                                                                Login with Google
                                                            </button>




                                                            {/* <LoginSocialFacebook


                                                                appId='1666970400472102'
                                                                onResolve={(response) => {
                                                                    FacebookLogin(response)
                                                                }}
                                                                onReject={(error) => { console.log(error) }}



                                                            >
                                                                <button className={`btn-hover-shine btn btn-block py-3 mt-4`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} ><IoLogoFacebook size={19} className='mx-3' style={{ color: '#4963ab' }} />
                                                                    Login with Facebook
                                                                </button>
                                                            </LoginSocialFacebook> */}

                                                        </div>
                                                        <p className="mt-4">Don't have an account? <span className={styles.text_blue}><a href={`/choose`} style={{ color: '#006FA7' }}>
                                                            Create account
                                                        </a></span> </p>












                                                    </div>

                                                </div>
                                            </div>
                                        </div>










                                    </div>

                                )
                            }}

                        </Formik>
                    </div>
                </div>
            </div>
        </>

























    )
}