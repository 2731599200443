import React, { useState } from 'react'
import {
    Card,
    NavLink,
    NavItem,
    Nav
} from "reactstrap";
import Listing from '../listing/Listing';
import classnames from "classnames";
import CrossICon from '../../images/close_icon.png'


const Create_List_Section = (props) => {
    const [participantsTab, setTab] = useState('Active')

    const [column, setColumn] = useState([
        { Name: 'ID', Type: 'number' },
        { Name: "Username", Type: 'string' },
        { Name: "Last Name", Type: 'string' },
        { Name: "Last Visit", Type: 'date' }
    ]);

    // For deleting a section
    const delSection = () => {

        props.setContent({
            description: props.allContent.description,
            imagesSection: props.allContent.imagesSection,
            videosSection: props.allContent.videosSection,
            filesSection: props.allContent.filesSection,
            participantsSection: { ...props.allContent.participantsSection, participants: false }
        })
    }

    return (
        <div>
            <Card className="main-card mb-3 p-3 mt-3">
                <div className="d-flex">
                    <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mr-auto">Participant List</label>
                    <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" >
                        <img src={CrossICon} alt="" width="15px" onClick={ () => delSection() }/>
                    </button>
                </div>

                {/* Listring */}
                <div className="container-fluid">
                    <div className=" row align_tab border p-0" style={{ borderRadius: '5px 0 0 5px' }}>
                        <Nav justified>
                            <NavItem className=" border-right" >
                                <NavLink href="#"
                                    className={classnames("py-2", {
                                        active: participantsTab === 'Active'
                                    })}
                                    onClick={() => {
                                        setTab('Active')
                                    }}>
                                    Active Participants
                                </NavLink>
                            </NavItem>

                            <NavItem >
                                <NavLink href="#"
                                    className={classnames("py-2", {
                                        active: participantsTab === 'Online'
                                    })}
                                    onClick={() => {
                                        setTab('Online')
                                    }}>
                                    Online Participants
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>

                {
                    participantsTab === 'Active' ?
                        <Listing data={[]} columnName={column} /> :
                        <Listing data={[]} columnName={column} />
                }
            </Card>

        </div>


    )
}

export default Create_List_Section
