import React, { useState, useEffect } from 'react'
import Listing from '../../../../assets/components/listing/Listing'


import { TransitionGroup } from 'react-transition-group';
import {  useParams } from "react-router-dom";
import Eyeicon from '../../../../assets/images/eye_icon.png'

import ResizeDetector from "react-resize-detector";

import AlertModal from '../../../../assets/components/modal/AlertModal';
import Swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { delSurveyReply, getAllPostParticipants } from '../../../../actions/activities_reply_actions';
//import PageHeader from '../../../../assets/components/pageheader/PageHeader';

const GeneralForumParticipants = (props) => {

    const activityParticipantState = useSelector(state => state.ActivityParticipant)

    //const history = useNavigate()
    const params = useParams()
    //const path = currentPath
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [isModalOpen, setModal] = useState(false)
    const [delId, setDelID] = useState('')

    const [participantData, setParticipantData] = useState([])


    const [participantColumn, setParticipantColumn] = useState([
        { Name: 'Respondent', Type: 'Object', key: 'author.userName' },
        { Name: "Email", Type: 'Object', key: 'author.email', width: 280 },
        { Name: "Phone No", Type: 'Object', key: 'author.phone', width: 220 },
       // { Name: "Last Login", Type: 'Date', key: 'author.logs.latestVisit' },
        { Name: "Total Post", Type: 'Object', key: 'totalPosts' },

        
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: "/admin/participant-post-activity/" + params.activityid,
                    type: 'button',
                    idObjectName: 'author'
                },

            ]
        }
    ])


    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])


    const getPostParticipants = (page) => {
        dispatch(getAllPostParticipants({ page: page, limit: 10, activityId: params.activityid }))
            .then((response) => {
                setParticipantData(response.payload.content.participants)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
                setLoading(false)
            })
    }

    const delResponse = () => {
        dispatch(delSurveyReply(delId))
            .then((response) => {
                setDelID()
                getPostParticipants(activityParticipantState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Response Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                setDelID()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })

    }

    useEffect(() => {
        getPostParticipants(1)
    }, [])

    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <>
                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={delResponse} />

                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            {/* Listing */}
                            <div className="table_border">
                                <Listing columnName={participantColumn}
                                    data={participantData}
                                    currentPage={activityParticipantState.currentPage ? activityParticipantState.currentPage : 1}
                                    totalPages={activityParticipantState.totalPages ? activityParticipantState.totalPages : 1}
                                    getListingData={(currentPage, dataLimit) => getPostParticipants(currentPage, dataLimit)}
                                    startLoading={() => setLoading(true)}
                                />
                            </div>

                        </LoadingOverlay>

                    </>
                )}

            />
        </TransitionGroup>


    )
}

export default GeneralForumParticipants



