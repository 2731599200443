import React, { useState, useEffect, useContext } from 'react';
import ResizeDetector from "react-resize-detector";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import { signup } from '../../actions/auth_actions';
import Swal from 'sweetalert'
import styles from '../../assets/css/rstyling.module.css'

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'reactstrap';
import axios from 'axios';
import { BACK_SERVER_URL } from '../../config/server';
import { userDetailsContext } from '../../UserDetailsProvider';
import { getLoggedInUser } from '../../actions/auth_actions';
import SecureLS from 'secure-ls';


const Signup = () => {

    const [isShowPassword, setShowPassword] = useState(false);
    const [phone] = useState('');
    const [simNetwork, setSimNetwork] = useState('');
    const dispatch = useDispatch()
    let { search } = useLocation();
    const history = useNavigate();
    const {token} = useParams();
    const ls = new SecureLS()
    
    const { setUserDetails} = useContext(userDetailsContext);
   
    
    const query = new URLSearchParams(window.decodeURIComponent(search));

    const setPhoneAndNetwork = (phone, countryName) => {

        let splitedPhone = phone.substring(2, 4);

        if (countryName === 'Pakistan')
            switch (splitedPhone) {
                case '30':
                    setSimNetwork('Jazz');
                    break;

                case '31':
                    setSimNetwork('Zong');
                    break;

                case '32':
                    setSimNetwork('Warid');
                    break;

                case '33':
                    setSimNetwork('Ufone');
                    break;

                case '34':
                    setSimNetwork('Telenor');
                    break;

                default:
                    setSimNetwork('');
                    break;

            }

        // swtPhoneNetwork({ ...userData, phone: phone })

    }

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        //phone: '',
    })

 
    const clearCookies = () => {
        if (document.cookie) {
          document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
        }
      };

      const clearCaches = async () => {
        // Log caches before clearing
        if ('caches' in window) {
          const cacheNamesBefore = await caches.keys();
          console.log('Caches before clearing:', cacheNamesBefore);
        }
    
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
    
        // Clear service worker caches
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(cacheNames.map((name) => caches.delete(name)));
    
          // Log caches after clearing
          const cacheNamesAfter = await caches.keys();
          console.log('Caches after clearing:', cacheNamesAfter);
        }
      };
 
    // simple signup
    const onSignUp = (values) => {
        //clearCaches()
       
        dispatch(signup(values))
         
            .then(response => {
                setUserDetails(response.payload.content)
                
                //ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role });
           
               // axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
         //dispatch(getLoggedInUser())
        //  .then(response1 => {
                 
        //      ls.set('accessUser', { user: response1.payload.content });
        //      if (ls.get('accessToken').role) {
        //          if (ls.get('accessToken').role === 3) {
        //          //history('/login')
        //          history('/otp')} } 
                
                
        //         })
                 
              
                


                Swal({
                    title: "Signed Up",
                    text: "Verification OTP code has been sent. Please verify by email!.",
                    icon: 'success'
                })
                history('/otp')
            })
            .catch(err => {
                
                if (err.response.data.name === "Duplication Error") {
                    Swal({
                        title: "Info",
                        text: "User already exists.",
                        icon: 'info'
                    });
                } else {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                }

            })
            
           
        

           
    }

    
    // useEffect(()=>{
    //     console.log('user details store->', userDetails)

    // })

    // if users signed up with referral link
    useEffect(() => {
        if (query.get('email')) {
            setUserData({ ...userData, email: query.get('email') })
        } else if (query.get('code')) {
            setUserData({ ...userData, referredBy: query.get('code') })
        }
    }, [])

   
    // const handleInput=(e)=>{
    //    onQuery(e.target.value)
    //    console.log('phone in signup->',onQuery(e.target.value))
    // }
   
        const waveStyle = {
          display: 'inline-block',
          animationName: 'wave-animation',
          animationDuration: '2.5s',
          animationIterationCount: 'infinite',
          transformOrigin: '70% 70%',
        };
    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (

                <div className="_signup_">
                    
                    <div className="d-flex">
                        <div className="col-lg-8 col-12">

                            {/* Logo */}
                            <div className="py-2npm">
                                <a href="/">
                                    <img src={require('../../assets/utils/images/logo.png')} alt="logo" width='135' />
                                </a>
                            </div>

                            <div className="container">
                                
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-12">

                                        <div className="mb-5">
                                            <h4 className="text-dark mb-2 mt-2" style={{ fontWeight: '500' }} >Welcome<span style={waveStyle}>👋</span></h4>
                                            <p style={{ fontSize: '1rem' }}>It takes only seconds to create your account</p>
                                        </div>

                                         <div className="row"> 

                                        <Formik
                                            initialValues={userData}
                                            enableReinitialize={true}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true)
                                                onSignUp(values)
                                              // onQuery(values)

                                            }}
                                            

                                            const validationSchema={Yup.object().shape({
                                                email: Yup.string().email("Not a valid email").required("Email is required"),
                                                firstName: Yup.string().required("First Name is required"),
                                                lastName: Yup.string().required("Last Name is required"),
                                               // phone: Yup.string().required("Phone Number is required").min(12),
                                                password: Yup.string().required("Password is required").min(8)
                                            })}


                                        >
                                            {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue
                                                } = props;



                                                return (
                                                    
                                                    <form autoComplete="off" onSubmit={handleSubmit}>

                                                        {/* Start of form */}
                                                        <div className='row'>
                                                       
                                                            <div className="col-md-6 col-12 mb-3">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Email</label>
                                                                    <div>
                                                                    <label style={{ fontSize: '0.8rem', fontWeight: '600', color: '#666' }}>(Note: We will send an OTP code for verification)</label>
                                                                    </div>
                                                                    <Input type="text"
                                                                        className={errors.email && touched.email ? styles.is_invalid : 'valid'}
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        onChange={(e) => setFieldValue('email', e.target.value.trim())}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                    />

                                                                    {errors.email && touched.email && (
                                                                        <small className='text-danger'>
                                                                            {errors.email}
                                                                        </small>
                                                                    )}

                                                                </div>
                                                            </div>

                                                            {/* <div className="col-md-6 col-12 mb-3">

                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Phone Number</label>

                                                                    <div className="row">
                                                                        <div className="col-8 pr-0">
                                                                            <PhoneInput
                                                                                inputStyle={{ width: '100%', height: '38px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                                country={'pk'}
                                                                                value={phone}
                                                                                onChange={(phone, country) => {
                                                                                    setPhoneAndNetwork(phone, country.name);
                                                                                    setFieldValue('phone', phone)
                                                                                }}
                                                                                enableSearch={true}
                                                                                
                                                                            />
                                                                             
                                                                            
                                                                            {errors.phone && touched.phone && (
                                                                                <small className='text-danger'>
                                                                                    {errors.phone}
                                                                                </small>
                                                                            )}
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                   
                                                                </div>
                                                            </div> */}

                                                            <div className="col-md-6 col-12 mt-4">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>First Name</label>
                                                                    <Input type="text"
                                                                        className={errors.firstName && touched.firstName ? styles.is_invalid : 'valid'}
                                                                        name="firstName"
                                                                        placeholder="First Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.firstName}
                                                                    />

                                                                 {errors.firstName && touched.firstName && (
                                                                        <small className='text-danger'>
                                                                            {errors.firstName}
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-12 mb-3">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Last Name</label>
                                                                    <Input type="text"
                                                                        className={errors.lastName && touched.lastName ? styles.is_invalid : 'valid'}
                                                                        name="lastName"
                                                                        placeholder="Last Name"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.lastName}
                                                                    />
                                                                          
                                                                    {errors.lastName && touched.lastName && (
                                                                        <small className='text-danger'>
                                                                            {errors.lastName}
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-12 mb-3">
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Password</label>
                                                                    <Input type={isShowPassword ? "text" : "password"}
                                                                        className={errors.password && touched.password ? styles.is_invalid : 'valid'}
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.password}
                                                                    />

                                                                    {errors.password && touched.password && (
                                                                        <small className='text-danger'>
                                                                            {errors.password}
                                                                        </small>
                                                                    )}
                                                                    <span className="pl-2" style={{ position: 'absolute', bottom: '17px', right: '25px', fontSize: '1.5rem', cursor: 'pointer', backgroundColor: '#fff' }} onClick={() => setShowPassword(!isShowPassword)}>{isShowPassword ? <IoIosEye /> : <IoIosEyeOff />}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12 mb-3">
                                                                <div className="text-left mb-2">
                                                                    <a href="/recover" style={{ color: '#006FA7', fontSize: '0.95rem', fontWeight: '400' }}>
                                                                        Forgot Password 
                                                                    </a>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <label style={{ fontSize: '0.95rem', fontWeight: '400', color: '#888' }}>
                                                                        By entering in our system, you agree to our
                                                                        <a href="https://consumerconnect.net/terms-conditions" target="_blank" rel="noopener noreferrer"  style={{ color: '#006FA7' }}> Terms of Service</a> and 
                                                                        <a href="https://consumerconnect.net/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: '#006FA7' }}> Privacy Policy</a>
                                                                    </label>

                                                                </div>
                                                                <div className="mb-2">
                                                                    <button className={`${styles.bg_blue} btn-hover-shine btn text-light btn-block py-2`} style={{ backgroundColor: '#006FA7' }} type="submit"> Sign Up</button>
                                                                    
                                                                </div>
                                                                
                                                                
                                                                
                                                                       
                                                                <div className="mt-3">
                                                                    <label style={{ fontSize: '0.95rem', fontWeight: '400', color: '#888' }}>
                                                                        Already have an account? &nbsp;
                                                                        <a href="/login" style={{ color: '#006FA7' }}>
                                                                            Login
                                                                        </a>
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                )
                                            }}

                                        </Formik>
                                        

                                         </div>  



                                    </div>
                                    
                                </div>
                                
                            </div>
                           

                        </div>
                        
                        
                            
                            
                        <div className="col-lg-4 d-lg-block d-none side_bg px-0">
                            {/* Gradient Overlay */}
                            <div className="grad_overlay"></div>

                            <div className="text-center px-3">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <div className="col-12">
                                        <h3 className="mb-4" style={{ color: "#eee", fontWeight: '700', lineHeight: '2.65rem', letterSpacing: '2px' }}>Signup now</h3>
                                        <h4 style={{ color: "#eee", fontWeight: '500', lineHeight: '2.65rem', letterSpacing: '2px' }}>
                                            Genuinely Connecting Consumers to Brands.
                                            Influence change in the world with your Voice!
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >

                </div >
            )}
        />

    )

}

export default Signup;