import { AVAILABLE_ACTIVITY_EXCERPTS, GET_EXCERPT_DETAILS } from "../actions/types";

let excerptsDataState = { excerpts:[], totalPages:0, currentPage: 1, excerptsDetails: {} };
export default function (state = excerptsDataState, action) {
    switch (action.type) {
        case AVAILABLE_ACTIVITY_EXCERPTS:
            if (state.excerpts.length === 0) {
                return {
                    ...state,
                    excerpts: action.payload.content.excerpt,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.excerpts =  state.excerpts.concat(action.payload.content.excerpt);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    excerpts: concatResult.excerpts,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case GET_EXCERPT_DETAILS:
            return {...state, excerptsDetails: action.payload.content }
        default:
            return state;
    }
}
