import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import {
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap';
import classnames from "classnames";
import Listing from '../../../../assets/components/listing/Listing';
import video from '../../../../assets/images/video.mp4'
import image from '../../../../assets/images/apparel_icon.png'
import fileIcon from '../../../../assets/images/file_icon.png'
import sampleFile from '../../../../assets/images/file.xlsx'
import SettingIcon from '../../../../assets/images/setting_icon_white.png'
import styles from '../../../../assets/css/rstyling.module.css'
import Search from '../../../../assets/images/search_icon.png'
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { editProjectIntro, getProjectIntro } from '../../../../actions/project_actions';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Introduction = (props) => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const projectState = useSelector(state => state.Project)
    const [projectIntro, setProjectIntro] = useState({})
    const [participantsTab, setTab] = useState('Active')
    const [searchString, setSearchString] = useState('')
    const [column, setColumn] = useState([
        { Name: 'ID', Type: 'number' },
        { Name: "Username", Type: 'string' },
        { Name: "Last Name", Type: 'string' },
        { Name: "Last Visit", Type: 'date' }
    ]);

    const projectIntroduction = () => {
        dispatch(getProjectIntro({ projectId: params.projectid }))
            .then(response => {
                setLoading(false)
                setProjectIntro(response.payload.content)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                // Swal({
                //     title: err.response ? err.response.data.name : "Error",
                //     text: err.response ? err.response.data.content : "Something went wrong",
                //     icon: 'error'
                // })
            })
    }

    useEffect(() => {
        projectIntroduction()
    }, [])

    const onChangeVisibility = (key, value) => {
        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        const formData = new FormData()
        formData.append(key, value)
        dispatch(editProjectIntro(projectState.projectIntro._id, formData))
            .then(response => {
                Swal.stopLoading();
                Swal.close();
                Swal({
                    title: response.payload.title ? response.payload.title : "Changes",
                    text: response.payload.message ? response.payload.message : 'Visibility Changed',
                    icon: 'success'
                }).then(() => {
                    projectIntroduction()
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                }).then(() => {
                    Swal.stopLoading();
                    Swal.close();
                })
            })
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <Card className="main-card mb-3">
                    <CardBody>

                        <div className="d-flex">
                            <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3 mr-auto">Description</h6>
                        </div>
                        {/* Description */}
                        {
                            projectIntro.description &&
                            <Card className="main-card mx-3 mt-3 mb-4">
                                <CardBody dangerouslySetInnerHTML={{ __html: projectIntro.description }}></CardBody>
                            </Card>
                        }


                        {/* Images Section */}
                        {
                            projectIntro.imagesSection && projectIntro.imagesSection.images.length > 0 &&
                            <><div className="d-flex">
                                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3 mr-auto">Images</h6>

                                {/* Drop down for changing images visibility */}
                                <UncontrolledButtonDropdown className="custom_drop">
                                    <DropdownToggle caret size="sm" className={`btn-icon btn-icon-only ${styles.bg_blue} text-white`}>
                                        <div className="btn-icon-wrapper">
                                            <img src={SettingIcon} alt="" />
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                        <DropdownItem
                                            active={projectIntro.imagesSection.isVisible ? (projectIntro.imagesSection.isVisible === 'All' ? true : false) : false}
                                            onClick={() => onChangeVisibility('imagesSection.isVisible', 'All')}>
                                            <span>Everyone</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            active={projectIntro.imagesSection.isVisible ? (projectIntro.imagesSection.isVisible === 'AdminResearcher' ? true : false) : false}
                                            onClick={() => onChangeVisibility('imagesSection.isVisible', 'AdminResearcher')}>
                                            <span>Admin and Researcher Only</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            active={projectIntro.imagesSection.isVisible ? (projectIntro.imagesSection.isVisible === 'Admin' ? true : false) : false}
                                            onClick={() => onChangeVisibility('imagesSection.isVisible', 'Admin')}>
                                            <span>Admin Only</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                                <div className="mx-3 mt-3 mb-4 p-2" style={{ borderRadius: '10px', maxHeight: 400, border: '2px dashed #ccc' }}>

                                    {/* Image Display & Dropzone */}

                                    {
                                        projectIntro.imagesSection.images.map((image, index) => {
                                            return <a href={image} download><div key={index} className="py-2 d-inline-block">
                                                <div className="position-relative mr-3">
                                                    <img src={image} width={150} height={100} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="" />
                                                </div>
                                            </div></a>
                                        })
                                    }

                                </div></>
                        }


                        {/* Video Section */}
                        {
                            projectIntro.videosSection && projectIntro.videosSection.videos.length > 0 &&
                            <>
                                <div className="d-flex">
                                    <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3 mr-auto">Videos</h6>
                                    
                                    {/* Drop down for changing videos visibility */}

                                    <UncontrolledButtonDropdown className="custom_drop">
                                        <DropdownToggle caret size="sm" className={`btn-icon btn-icon-only ${styles.bg_blue} text-white`}>
                                            <div className="btn-icon-wrapper">
                                                <img src={SettingIcon} alt="" />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                            <DropdownItem
                                                active={projectIntro.videosSection.isVisible ? (projectIntro.videosSection.isVisible === 'All' ? true : false) : false}
                                                onClick={() => onChangeVisibility('videosSection.isVisible', 'All')}>
                                                <span>Everyone</span>
                                            </DropdownItem>
                                            <DropdownItem
                                                active={projectIntro.videosSection.isVisible ? (projectIntro.videosSection.isVisible === 'AdminResearcher' ? true : false) : false}
                                                onClick={() => onChangeVisibility('videosSection.isVisible', 'AdminResearcher')}>
                                                <span>Admin and Researcher Only</span>
                                            </DropdownItem>
                                            <DropdownItem
                                                active={projectIntro.videosSection.isVisible ? (projectIntro.videosSection.isVisible === 'Admin' ? true : false) : false}
                                                onClick={() => onChangeVisibility('videosSection.isVisible', 'Admin')}>
                                                <span>Admin Only</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                                <div className="mx-3 mt-3 mb-4 p-2" style={{ borderRadius: '10px', maxHeight: 400, border: '2px dashed #ccc' }}>

                                    {
                                        projectIntro.videosSection.videos.map((video, index) => {
                                            return <div key={index} className="py-2 d-inline-block">
                                                <div className="position-relative mr-3">
                                                    <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 400 }}>
                                                        <source src={video} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </>
                        }


                        {/* File Section */}
                        {
                            projectIntro.filesSection && projectIntro.filesSection.files.length > 0 &&
                            <>
                                <div className="d-flex">
                                    <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3 mr-auto">Files</h6>

                                    {/* Drop down for changing files visibility */}

                                    <UncontrolledButtonDropdown className="custom_drop">
                                        <DropdownToggle caret size="sm" className={`btn-icon btn-icon-only ${styles.bg_blue} text-white`}>
                                            <div className="btn-icon-wrapper">
                                                <img src={SettingIcon} alt="" />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                            <DropdownItem
                                                active={projectIntro.filesSection.isVisible ? (projectIntro.filesSection.isVisible === 'All' ? true : false) : false}
                                                onClick={() => onChangeVisibility('filesSection.isVisible', 'All')}>
                                                <span>Everyone</span>
                                            </DropdownItem>
                                            <DropdownItem
                                                active={projectIntro.filesSection.isVisible ? (projectIntro.filesSection.isVisible === 'AdminResearcher' ? true : false) : false}
                                                onClick={() => onChangeVisibility('filesSection.isVisible', 'AdminResearcher')}>
                                                <span>Admin and Researcher Only</span>
                                            </DropdownItem>
                                            <DropdownItem
                                                active={projectIntro.filesSection.isVisible ? (projectIntro.filesSection.isVisible === 'Admin' ? true : false) : false}
                                                onClick={() => onChangeVisibility('filesSection.isVisible', 'Admin')}>
                                                <span>Admin Only</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                                <div className="mx-3 mt-3 mb-4 p-2" style={{ borderRadius: '10px', maxHeight: 400, border: '2px dashed #ccc' }}>

                                    {
                                        projectIntro.filesSection.files.map((file, index) => {
                                            return <a href={sampleFile} download><div key={index} className="d-inline-block text-center align-self-center">
                                                <div className="position-relative mr-3 img-fluid border bg-light" style={{ width: '150px', height: "150px", borderRadius: '0.5rem' }}>
                                                    <img src={fileIcon} alt="" width="55px" className="py-4" /><br />
                                                    <p>File Format {file.split('.').pop()}</p>
                                                </div>
                                            </div></a>
                                        })
                                    }



                                </div>
                            </>
                        }


                        {/* Participants Section */}
                        {
                            projectIntro.participantsSection && projectIntro.participantsSection.participants === true &&
                            <> <div className="d-flex mb-2">
                                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3 mr-auto">Participant List</h6>
                                
                                {/* Drop down for changing files visibility */}
                                <UncontrolledButtonDropdown className="custom_drop">
                                    <DropdownToggle caret size="sm" className={`btn-icon btn-icon-only ${styles.bg_blue} text-white`}>
                                        <div className="btn-icon-wrapper">
                                            <img src={SettingIcon} alt="" />
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link" style={{ marginLeft: '-12.3rem' }}>
                                        <DropdownItem
                                            active={projectIntro.participantsSection.isVisible ? (projectIntro.participantsSection.isVisible === 'All' ? true : false) : false}
                                            onClick={() => onChangeVisibility('participantsSection.isVisible', 'All')}>
                                            <span>Everyone</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            active={projectIntro.participantsSection.isVisible ? (projectIntro.participantsSection.isVisible === 'AdminResearcher' ? true : false) : false}
                                            onClick={() => onChangeVisibility('participantsSection.isVisible', 'AdminResearcher')}>
                                            <span>Admin and Researcher Only</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            active={projectIntro.participantsSection.isVisible ? (projectIntro.participantsSection.isVisible === 'Admin' ? true : false) : false}
                                            onClick={() => onChangeVisibility('participantsSection.isVisible', 'Admin')}>
                                            <span>Admin Only</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                                <div className="container-fluid">
                                    <div className=" row align_tab border p-0" style={{ borderRadius: '5px 0 0 5px' }}>
                                        <Nav justified>
                                            <NavItem className=" border-right" >
                                                <NavLink href="#"
                                                    className={classnames("py-2", {
                                                        active: participantsTab === 'Active'
                                                    })}
                                                    onClick={() => {
                                                        setTab('Active')
                                                    }}>
                                                    Active Participants
                                                </NavLink>
                                            </NavItem>

                                            <NavItem >
                                                <NavLink href="#"
                                                    className={classnames("py-2", {
                                                        active: participantsTab === 'Online'
                                                    })}
                                                    onClick={() => {
                                                        setTab('Online')
                                                    }}>
                                                    Online Participants
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    {
                                        participantsTab === 'Active' ?
                                            <Listing data={[]} columnName={column} /> :
                                            <Listing data={[]} columnName={column} />
                                    }
                                </div>
                            </>
                        }



                    </CardBody>
                </Card>

            </LoadingOverlay>



        </TransitionGroup >
    )
}

export default Introduction
