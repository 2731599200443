import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../../../assets/css/styling.module.css';

const Back = (props) => {

    const history = useNavigate();

    return (
        <div style={{cursor: "pointer"}} onClick={() => history(-1)}>

            {/* Back Arrow Icon */}
            <span className={styles.arrow_left_icon} style={{ width: 30, height: 16 }}></span>

            <span style={{ color: "#666", fontSize: '1.15rem', fontWeight: '400', letterSpacing: '1px', verticalAlign: "2px" }}>Back</span>

        </div>
    )

}

export default Back;