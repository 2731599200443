import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { CREATE_CHATROOM, DELETE_CHATROOM, EDIT_CHATROOM, GET_ALL_USER_CHATROOM, AVAILABLE_CHAT_HISTORY } from './types.js';


// ****************  These apis are used in ADMIN END inside Chats Section *********************

export const getAllUserChatroom = (data) => {

    let url = `${BACK_SERVER_URL}api/chatrooms/user`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: GET_ALL_USER_CHATROOM,
        payload: request
    }
}

export const createChatroom = (data) => {

    let url = `${BACK_SERVER_URL}api/chatrooms/`;

    const request = axios.post(url, data)
        .then(response => response.data);

        
    return {
        type: CREATE_CHATROOM,
        payload: request
    }
}

// export const getChatroom = (id) => {

//     let url = `${BACK_SERVER_URL}api/chatrooms/${id}`;

//     const request = axios.get(url)
//         .then(response => response.data);

//     return {
//         type: GET_CHATROOM,
//         payload: request
//     }
// }

export const editChatroom = (id, data) => {

    let url = `${BACK_SERVER_URL}api/chatrooms/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_CHATROOM,
        payload: request
    }
}

export const deleteChatroom = (id) => {

    let url = `${BACK_SERVER_URL}api/chatrooms/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_CHATROOM,
        payload: request
    }
}

// Returns old chat of specific chat room
export const getChatHistory = (data) => {

    let url = `${BACK_SERVER_URL}api/messages/chatroom`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_CHAT_HISTORY,
        payload: request
    }
}
