import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TransitionGroup } from 'react-transition-group';
// import { setProfile } from "../../../../actions/auth_actions";
import Select from 'react-select';
import styles from '../../../../assets/css/styling.module.css';
import { getAllInterests } from "../../../../actions/settings_actions";
import { Button } from "reactstrap";
const Interests_Details = (props) => {

    const dispatch = useDispatch();
    const { profileDetails, setProfileDetails } = props
    const [selectedOptions, setSelectedOptions] = useState([])
    const [interests, setInterests] = useState([])
    const [userInterests, setUserInterests] = useState([])
    const [selectedInterests, setSelectedInterests] = useState([])
    const [isAllowEdit, setAllowEdit] = useState(false);
    const [validationError, setValidationError] = useState('');
    //const systemOptionsState = useSelector(state => state.SystemSettings)


    const getInterestOptions = () => {
        let userId = profileDetails._id

        dispatch(getAllInterests(userId))
            .then(response => {


                const options = response.payload.content.allInterests;
                const interestNames = options.map(interest => interest.name); // Extract names
                setInterests(interestNames);

                setUserInterests(response.payload.content.userInterests)



            })
            .catch(err => {
                console.log('interests are not available')
            })


    }


    useEffect(() => {
        // if (systemOptionsState.options.length === 0) {

        getInterestOptions()




        //}
    }, [])



    const handleInterests = (options) => {


        setSelectedInterests(options.value)
        const selectedValues = options.map(option => option.value);
        setSelectedOptions(selectedValues)

        setProfileDetails({ ...profileDetails, interests: selectedValues });

    }

    // const handleChange = (options) => {
    //     // Call handleInterests to keep sync with selectedInterests
    //     handleInterests(options);

    //     // if ( (userInterests.length===0 && options.length <4) || userInterests.length <4 && (userInterests.length>1 || userInterests.length >2) && (options.length<1 || options.length <2) ) {
    //     //     setValidationError('Please select at least 4 interests');
    //     // } 
    //     if (userInterests.length === 0 && options.length < 4) {
    //         setValidationError('Please select at least 4 interests');
    //     }
    //     else if (userInterests.length < 4 && userInterests.length != 0) {
    //         console.log('optons.length ->',options.length)
    //         console.log('userinterests.length ->',userInterests.length)
    //         if (userInterests.length === 1 && options.length < 3){
    //             console.log('came here 1')
    //             setValidationError('Please select at least 4 interests')}else{  setValidationError('')}

    //         if (userInterests.length === 2 && options.length < 2){
    //             console.log('came here 2')
    //             setValidationError('Please select at least 4 interests')}else{  setValidationError('')}

    //         if (userInterests.length === 3 && options.length < 1){
    //             console.log('came here 3')
    //             setValidationError('Please select at least 4 interests');}else{  setValidationError('')}

    //     }


    //     else {
    //         console.log('also comng here?')
    //         setValidationError('');
    //     }
    // }
    const handleChange = (options) => {
        // Call handleInterests to keep sync with selectedInterests
        handleInterests(options);
      
        if (userInterests.length === 0 && options.length < 4) {
          setValidationError('Please select at least 4 interests');
        } else if (userInterests.length === 1 && options.length < 3) {
          setValidationError('Please select at least 3 more interests');
        } else if (userInterests.length === 2 && options.length < 2) {
          setValidationError('Please select at least 2 more interests');
        } else if (userInterests.length === 3 && options.length < 1) {
          setValidationError('Please select at least 1 more interest');
        } else {
          setValidationError('');
        }
      };
      






    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="mb-3">

                {/* Edit */}
                <div className="clearfix px-3">
                    <div className="text-right">
                        <button className="btn btn-link" style={{ color: "#1c6f9f", fontSize: '1.05rem', fontWeight: 600, letterSpacing: "0.5px" }} onClick={() => setAllowEdit(true)}>Edit</button>
                    </div>
                </div>

                <div className={styles.card_border + " mb-4"}>
                    <div className={styles.card_border_body + " p-4"}>

                        {/* Question */}
                        <h5 style={{ color: "#000", fontWeight: 600, fontSize: "1rem" }} className="mb-0">
                            {`Select your Area of Interest`}
                        </h5>

                        {/* Answer */}
                        {!isAllowEdit ? (
                            <div>
                            {userInterests.map((interest, index) => (
                                <div
                                    key={index}
                                    className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left"
                                    style={{ backgroundColor: '#BCE5FF' }}
                                >
                                    <h6 className='p-0 my-1' style={{ fontWeight: '500', color:'#0069AB' }}>{interest}</h6>
                                </div>
                            ))}
                        </div>
                        ) : (
                            <div className="mt-3">
                                {/* Select interests  Area */}
                                <Select

                                    isMulti
                                    name="interests"

                                    options={interests.map(interest => ({ value: interest, label: interest }))}
                                    value={selectedInterests}
                                    placeholder='Select your interests'
                                    className="basic-multi-select"
                                    classNamePrefix="Select"
                                    onChange={handleChange}

                                />
                                <div>
                                    {userInterests.map((interest, index) => (
                                        <div
                                            key={index}
                                            className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left"
                                            style={{ backgroundColor: '#BCE5FF' }}
                                        >
                                            <h6 className='p-0 my-1' style={{ fontWeight: '500', color:'#0069AB' }}>{interest}</h6>
                                        </div>
                                    ))}
                                </div>
                                {validationError === 'Please select at least 4 interests' || validationError === 'Please select at least 3 more interests' || validationError === 'Please select at least 2 more interests' || validationError === 'Please select at least 1 more interest' ? (
                                    <div className="text-danger mt-2">
                                        {validationError}
                                    </div>
                                ) : ''}
                            </div>
                        )}

                    </div>
                </div>




                {validationError != 'Please select at least 4 interests' && validationError !='Please select at least 3 more interests' && validationError !='Please select at least 2 more interests' && validationError !='Please select at least 1 more interest'  ? (
                    // <div className="row">
                    <div className="col-12 text-right">

                        {/* Save Changes button */}
                        <Button className={"float-right btn-hover-shine mt-3 px-1"} style={{ backgroundColor: '#0069AB', color: 'white', border: 'none' }} onClick={() => props.editUser()}>Save Changes</Button>

                    </div>
                    // </div>
                ) : ''}

            </div>
        </TransitionGroup>

    );

}

export default Interests_Details