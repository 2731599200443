import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import CrossIcon from '../../images/cross_icon.png'
import TickIcon from '../../images/tick_icon.png'



// ********************** This Component is used in ADMIN & RESEARCHER END in Activity Report Screen *************************
// ****************************** For listing Respondent Report in Specific Activity ****************************************

const ReportTable = (props) => {
    return (
        <div className="report_tbl">
            <div className="_survey-steps_">
                <div className="table-responsive mt-2">
                    <table className={`table table-striped table-bordered m-0`} >
                        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <tr>
                                {/* First Column Header */}
                                <th style={{ paddingRight: '10rem', zIndex: '10' }}>{props.firstColName}</th>
                                {/* { */}
                                {/* Rest of the Columns */}
                                {/* Participant Names*/}
                                {
                                    props.columnName.map((name, index) => {
                                        return <th key={index} className="text-left" >
                                            {name}
                                        </th>
                                    })
                                }

                                {/* } */}
                            </tr>
                        </thead>

                        <tbody >
                            {/* Participants Responses */}
                            {props.data.map((row, rowNo) => {
                                // Table with timeline
                                if (props.isTimeline) {
                                    return <tr key={rowNo} style={{ backgroundColor: '#fff' }} className="border-left">
                                        <VerticalTimeline layout="1-column" animate={false}
                                            className="vertical-time-simple vertical-without-time pt-0 pb-0 m-0">
                                            <VerticalTimelineElement className="vertical-timeline-item p-0 m-0"><td className="text-left"
                                                style={{ border: 'none', padding: "2rem 0 2rem 1rem", backgroundColor: rowNo % 2 === 0 ? '#E8E6E5' : 'white' }} width="600px">
                                                Interview Title {rowNo + 1}
                                            </td>
                                            </VerticalTimelineElement>
                                        </VerticalTimeline>
                                        {
                                            row.responses.map(response => {
                                                if (response === 'yes') {
                                                    return <td className="text-center" style={{ backgroundColor: rowNo % 2 === 0 ? '#E8E6E5' : 'white' }}>
                                                        <div className="mt-2">
                                                            <img src={TickIcon} alt='img' /><br />
                                                            <small>Attended</small>
                                                        </div>
                                                    </td>
                                                } else {
                                                    return <td className="text-center" style={{ backgroundColor: rowNo % 2 === 0 ? '#E8E6E5' : 'white' }}>
                                                        <div className="mt-2">
                                                            <img src={CrossIcon} alt='img' /><br />
                                                            <small>Not Attended</small>
                                                        </div>
                                                    </td>
                                                }
                                            })}
                                    </tr>

                                } else {
                                    // Table without timeline
                                    return <tr key={rowNo}>
                                        <td className="text-left">
                                            <b>Q.{rowNo + 1}</b> {row.question}
                                        </td>
                                        {
                                            row.responses.map(response => {
                                                if (response) {
                                                    return <td className="text-center">
                                                        <div className="mt-2">
                                                            <img src={TickIcon} alt='img' />
                                                        </div>
                                                    </td>
                                                } else {
                                                    return <td className="text-center">
                                                        <div className="mt-2">
                                                            <img src={CrossIcon} alt='img' />
                                                        </div>
                                                    </td>
                                                }
                                            })}
                                    </tr>
                                }
                            })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default ReportTable
