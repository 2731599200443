import React, { useState, useEffect } from 'react'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import SearchResultCard from './SearchResultCard'
import { searchPost } from '../../../../actions/project_actions'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

const Posts = () => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const params = useParams()
    let { search } = useLocation();
    const query = new URLSearchParams(window.decodeURIComponent(search));
    const [posts, setPosts] = useState([])
    const [isLoading, setLoading] = useState(true)

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPage] = useState(0)

    const getPosts = (isNew = true, page = 1) => {
        dispatch(searchPost({ searchStr: query.get('searchStr'), projectId: params.projectid, page: page, limit: 10 }))
            .then((response) => {
                isNew ? setPosts([...response.payload.content.posts]) : setPosts([...posts, ...response.payload.content.posts])
                setTotalPage(response.payload.content.totalPages)
                setPage(response.payload.content.currentPage)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })

    }

    useEffect(() => {
        getPosts()
    }, [query.get('searchStr')])

    const next = () => {
        getPosts(false, page + 1)
    }

    return (

        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <SearchResultCard data={posts} next={(e) => next(e)} totalPages={totalPages} currentPage={page}/>

        </LoadingOverlay>
    )
}

export default Posts
