import React, { useEffect, useState } from 'react';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { FaRegComment } from "react-icons/fa6";
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css'
import Group_Forum_Write_Comment from './Group_Forum_Write_Comment';
import Group_Forum_Comment_View from './Group_Forum_Comment_View';
import { getAllPostReply, getAllReplyComments } from '../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert'
import female from '../../../assets/images/group-forum-female-avatar.png'
import male from '../../../assets/images/group-forum-male-avatar.png'

const Group_Forum_Reply_View = () => {

    const activityReplyState = useSelector(state => state.ActivityReply)
    const commentState = useSelector(state => state.Comments)
    const dispatch = useDispatch()
    const params = useParams()

    const [commentClick, setCommentClick] = useState(false)
    const [commentId, setCommentId] = useState('')
    const [forumReplies, setForumReplies] = useState([])
    const [postComments, setPostComments] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isShowComment, setCommentPostId] = useState('')
    console.log('params in group forum reply view ->', params)
    
    const getForumReply = (isNew, page = 1, limit = 5) => {

        dispatch(getAllPostReply({ activityId: params.forumid, page: page, limit: limit }))
            .then((response) => {
                isNew ? setForumReplies(response.payload.content.posts) :
                    setForumReplies([...forumReplies, ...response.payload.content.posts])

                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const getComments = (id, isNew, page = 1, limit = 10) => {
        dispatch(getAllReplyComments({ postId: id, page: page, limit: limit }))
            .then((response) => {
                setCommentPostId(id)
                isNew ? setPostComments(response.payload.content.comments) :
                    setPostComments([...postComments, ...response.payload.content.comments])
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getForumReply(true)
    }, [])


    const next = () => {
        getForumReply(false, activityReplyState.currentPage + 1, 4)
    }

    const comment =(replyId)=>{
        setCommentClick(true)
        setCommentId(replyId)
    }

    const formattedDate =(postedTime)=>{
        const originalDate = new Date(postedTime);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(originalDate);
    return formattedDate
    }

    
    return (
        <React.Fragment>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Reply top */}
{console.log('forumReplies authors ->', forumReplies)}
                {
                    forumReplies.length > 0 &&
                    forumReplies.map(reply => {
                        return <>
                            <div className={" mx-3"} style={{ borderBottom: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                                <div className={styles.card_border_body}>
                                    <div className="row px-3">
                                        <div className="col">
                                            <div className="clearfix">
                                                <div className="float-left">

                                                    <div className="d-inline-flex align-self-center">
                                                        {/* User Image */}
                                                        {/* <div className="rounded-circle" style={{ backgroundImage: reply.author.profileImage ? `url(${reply.author.profileImage})` : props.profileImage, width: '28px', height: '28px' }}>
                                                        </div> */}
                                                        <div><span  >
                                                            <img src= {reply.author && reply.author.gender==='Male' ? male: female} className={`${styles3.group_forum_respondent_avatar}`} alt="Respondent Avatar"></img>
                                                        </span></div>
                                                        <div className="mx-3 align-self-center">
                                                            {/* User Name */}
                                                            <h5 style={{ color: "#AFAFAF", fontWeight: 500, fontSize: "0.95rem" }} className="">
                                                                {reply && reply.author && reply.author.userName} - {formattedDate(reply.created_at)}
                                                                
                                                            </h5>
                                                            <div className="mt-1">

                                                                {/* Reply text */}
                                                                <div className="">
                                                                    <p style={{ color: '#000', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>
                                                                        {reply.description}
                                                                    </p>
                                                                    {  reply.comments > 0 ? <span></span> : <span style={{ color: '#0069AB', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }} className='' onClick={()=>comment(reply._id)}>Reply</span>}
                                                                   
                                                                </div>

                                                                {/* Image View */}
                                                                {
                                                                    reply.images && reply.images.length > 0 &&
                                                                    reply.images.map(image => {
                                                                        return <div className="px-4">
                                                                            <img src={image} style={{ borderRadius: '10px', boxShadow: "#eee 0px 0px 6px 3px" }} width="250px" height="250px" alt="reply-img" />
                                                                        </div>
                                                                    })
                                                                }


                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                    {/* Reply body */}
                                    {/* <div className="mx-5 mt-1">

                                       
                                        <div className="px-4">
                                            <p style={{ color: '#888', fontSize: "0.96rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>
                                                {reply.description}
                                            </p>
                                        </div>

                                        
                                        {
                                            reply.images && reply.images.length > 0 &&
                                            reply.images.map(image => {
                                                return <div className="px-4">
                                                    <img src={image} style={{ borderRadius: '10px', boxShadow: "#eee 0px 0px 6px 3px" }} width="250px" height="250px" alt="reply-img" />
                                                </div>
                                            })
                                        }


                                    </div> */}

                                    {/* Reply Bottom */}
                                    {
                                        reply.comments > 0 &&
                                        <div className=" mx-5 px-4">

                                            <div className="d-flex">
                                                <div className="align-self-center d-inline-flex pb-1">
                                                    {/* Comment Icon */}
                                                    <span ><FaRegComment style={{color:'#0069AB'}}size={18}/></span>
                                                </div>

                                                <div className="mx-2 align-self-center d-inline-flex" onClick={() => getComments(reply._id, true, 1, 10)}>
                                                    {/* Comment Counts */}
                                                    <span style={{ color: '#0069AB', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>{reply.comments > 0 && `${reply.comments} comments`}</span>
                                                    <span style={{ color: '#0069AB', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }} className='mx-4' onClick={()=>comment(reply._id)}>Reply</span>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>

                            {/* Comments */}
                            {
                                isShowComment === reply._id &&
                                <Group_Forum_Comment_View comments={postComments} commentCount={reply.comments} getComments={(id, isNew, page, limit) => getComments(id, isNew, page, limit)} postId={reply._id} />

                            }

                            {
                                isShowComment === reply._id && commentState.currentPage !== commentState.totalPages &&
                                <div className={styles.card_border} style={{ borderBottom: 0, borderRadius: 0, borderTop: 0 }}>
                                    <div className={"pb-2 ml-5"}>
                                        <div className="row px-3">
                                            <div className="mx-2 align-self-center d-inline-flex" onClick={() => getComments(reply._id, false, commentState.currentPage + 1, 10)}>
                                                {/* Comment Counts */}
                                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>View More Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Comment Sent Box */}
                            {commentClick === true && commentId === reply._id &&  <div style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0, borderTopColor: '#eee' }}>
                                <div className={styles.card_border_body + " py-3"}>

                                    <div className="ml-5">

                                       
                                        <Group_Forum_Write_Comment
                                            getComments={(id, isNew, page, limit) => getComments(id, isNew, page, limit)}
                                            getForumReply={(page, limit) => getForumReply(page, limit)}
                                            postId={reply._id}
                                            placeholder={`write a reply to ${reply && reply.author && reply.author.userName}`}
                                        />

                                    </div>

                                </div>
                            </div>}
                           
                        </>
                    })
                }

                {
                    activityReplyState.totalPages > 0 && activityReplyState.totalPages !== activityReplyState.currentPage &&
                    <div className={"pb-2 ml-5"}>
                        <div className="row px-3">
                            <div className="col-12 mt-3 align-self-center text-center" onClick={() => next()} >
                                {/* Comment Counts */}
                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load more replies</span>
                            </div>
                        </div>
                    </div>
                }

            </LoadingOverlay>



        </React.Fragment >
    )

}

export default Group_Forum_Reply_View