import React, { Fragment } from 'react';

import Tabs from 'react-responsive-tabs';
import { useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'

import SmsManagement from './SmsManagement';
import SmsMarketing from './SmsMarketing';

const Sms = () => {

    const history = useNavigate()
    const tabsContent = [
        {
            title: 'SMS Marketing',
            content: <SmsMarketing />
        },
        {
            title: 'SMS Management',
            content: <SmsManagement />
        },
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    return (
        <>
            <div className="admin_header">
                <PageHeader heading="SMS" img={TemplateIcon} btnText="Create Template" btnClick={() => history("/admin/create-sms-template")} />
            </div>
            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} />
            </div>
        </>
    )

}

export default Sms;