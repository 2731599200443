import React, { useEffect, useState } from 'react'
import styles from '../../../assets/css/rstyling.module.css'
import { IoIosEye } from 'react-icons/io'
import { IoIosEyeOff } from 'react-icons/io'
import { Button } from 'reactstrap'
import { changePassword } from '../../../actions/user_action'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert'
import { Label, FormGroup, Input } from 'reactstrap'
import ResizeDetector from 'react-resize-detector'

const About = (props) => {
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const { profile, setProfile } = props
    const [isReadOnly, setReadOnly] = useState(true)
    const dispatch = useDispatch()

    const onChangePassword = () => {
        Swal({
            title: 'Are you sure you want to change password?',
            buttons: {
                catch: {
                    text: "Yes",
                    value: "catch",
                },
                cancel: "No",
            },
        }).then((value) => {
            switch (value) {

                case "catch":
                    if (password.split('').length < 8 && password === '') {
                        Swal({
                            title: "Error",
                            text: "Password must contain 8 characters.",
                            icon: 'error'
                        })
                    } else {
                        dispatch(changePassword({ password: password }))
                            .then(response => {
                                Swal({
                                    title: response.payload.title ? response.payload.title : "Password Changed",
                                    text: 'Your password has been changed.',
                                    icon: 'success'
                                })
                            })
                            .catch(err => {
                                Swal({
                                    title: err.response ? err.response.data.name : "Error",
                                    text: err.response ? err.response.data.content : "Please try again",
                                    icon: 'error'
                                })
                            })
                    }
                    break;

                default:

            }
        })

    }


    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div className="align-self-center">
                    <div className="d-flex">
                        <p className="text-muted text-uppercase mr-auto" style={{ fontWeight: 500 }}>Contact Information</p>
                        <Button className="text-right mr-5" color="link" onClick={() => setReadOnly(false)}>Edit</Button>
                    </div>
                    <div className="row">

                        <div className="col-md-2 col-sm-12 col-12">
                            <p className={styles.text_bold}>First Name</p>
                        </div>
                        <div className='col-md-10 col-sm-12 col-12'>
                            <input type="text" value={profile.firstName} className="form-control mx-3 mt-2 w-50" readOnly={isReadOnly}
                                onChange={(e) => setProfile({ ...profile, firstName: e.target.value })} />
                        </div>

                        <div className="col-md-2 col-sm-12 col-12">
                            <p className={styles.text_bold}>Last Name</p>

                        </div>
                        <div className='col-md-10 col-sm-12 col-12'>
                            <input type="text" value={profile.lastName} className="form-control mx-3 mt-2 w-50" readOnly={isReadOnly}
                                onChange={(e) => setProfile({ ...profile, lastName: e.target.value })} />
                        </div>

                        <div className="col-md-2 col-sm-12 col-12">
                            <p className={styles.text_bold}>Username</p>

                        </div>
                        <div className='col-md-10 col-sm-12 col-12'>
                            <input type="text" value={profile.userName} className="form-control mx-3 mt-2 w-50" readOnly={isReadOnly}
                                onChange={(e) => setProfile({ ...profile, userName: e.target.value })} />
                        </div>

                        <div className="col-md-2 col-sm-12 col-12">
                            <p className={styles.text_bold}>Email</p>

                        </div>
                        <div className='col-md-10 col-sm-12 col-12'>
                            <input type="text" disabled value={profile.email} className="form-control mx-3 mt-2 w-50"
                                onChange={(e) => setProfile({ ...profile, email: e.target.value })} />
                        </div>

                        {/* <div className="col-md-2 col-sm-12 col-12">
                    <p className={styles.text_bold}>Notifications</p>

                </div>
                <div className='col-md-10 col-sm-12 col-12'>
                <p className="ml-3 pt-3">{profile.notification}</p>
                </div> */}



                        <div className='col-12 text-right'>
                            <button className={`${styles.bg_blue} btn text-white my-2 px-3`} onClick={() => props.editProfile()}>Save</button>
                        </div>

                    </div>

                    <p className="text-muted text-uppercase" style={{ fontWeight: 500 }}>Change Password</p>

                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-12 text-right'>
                            <div className="d-flex">
                                <FormGroup>
                                    <Label className={`${styles.password} ${styles.text_bold} d-flex`}><span className="mt-2">Password</span>
                                        <Input type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter new password" value={password} style={{ marginLeft: '4.5rem', width: '19rem' }} />
                                        <span className={styles.password_ico_position} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <IoIosEye /> : <IoIosEyeOff />}</span>
                                    </Label>
                                </FormGroup>

                            </div>
                            <button className={`${styles.bg_blue} btn text-white`} style={{ marginTop: width >= 500 && '-6.5rem' }} onClick={() => onChangePassword()}>Change Password</button>

                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export default About
