import React, { useState } from 'react'
import styles from '../../css/styling.module.css';
import DndIcon from '../../images/dnd_icon.png'
import CrossICon from '../../images/close_icon.png'
import AttachementIcon from '../../images/attachement_icon.png'
import ImageModal from '../modal/ImageModal';
import YouTube from 'react-youtube';
import Swal from 'sweetalert'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Create_Question_With_Video = (props) => {
    const question = props.questionText
    const [isShowQuestionFileModal, setQuestionFileModal] = useState(false)
    const [typingTimer, setTypingTimer] = useState(null);

    // For changing text in question
    const onQuestionTextChange = (value2) => {
        const value = value2.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>');
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionText = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })

    }

    // For selecting question as required
    const onChangeRequired = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].required = !pages[props.pageIndex].questions[props.questionIndex].required;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For selecting question files Limit
    const onChangeFileLimit = (value) => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].filesLimit = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For selecting question files size
    const onChangeFileSize = (value) => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].filesSize = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For selecting additional comments for the question
    const onSelectAddComment = () => {
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].isComment = !pages[props.pageIndex].questions[props.questionIndex].isComment;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }

    // For deleting a question
    const delQuestion = (index) => {
        let pages = [...props.allContent.pages];
        pages[props.pageIndex].questions.splice(index, 1)

        props.setContent({ ...props.allContent, pages });
    }

    // // For changing question image
    const onQuestionImageSelect = (file) => {
        const selectedFile = file[0]; // Assuming only a single file is allowed
  const maxSize = 25 * 1024 * 1024;
  if (selectedFile && selectedFile.size <= maxSize) {

        if (file && file[0]) {

            // File Extension Check
            let fileExtension = file[0].name.split('.').pop();

            if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                // Set All Content
                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })

            } else if (fileExtension === "mp4") {

                // Set All Content
                
                let pages = [...props.allContent.pages]

                pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
                pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

                props.setContent({
                    ...props.allContent,
                    pages: [...pages]
                })
            }

        }

    }else{
        Swal({
            title: "Error",
            text: "Video size must not exceed 25MB",
            icon: 'error'
        })
    }
}
const onQuestionVideoSelect =(file)=>{
    if (file && file[0]) {

        // File Extension Check
        let fileExtension = file[0].name.split('.').pop();

        if (fileExtension === "mp4") {
console.log('came here')
    let pages = [...props.allContent.pages]

    pages[props.pageIndex].questions[props.questionIndex].questionFile = file[0];
    pages[props.pageIndex].questions[props.questionIndex].mediaType = fileExtension;

    props.setContent({
        ...props.allContent,
        pages: [...pages]
    })
} } }
    // For deleting an Question File
    const removeQuestionFile = () => {

        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionFile = "";

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }
    
    // For embedding youtube video as a question
    const onSelectEmbedVideo = (value) => {
        console.log('video id->', value)
        let pages = [...props.allContent.pages]

        pages[props.pageIndex].questions[props.questionIndex].questionVideo = value;

        props.setContent({
            ...props.allContent,
            pages: [...pages]
        })
    }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
          autoplay: 0
        },
    };

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        console.log('newContent->', newContent)
        clearTimeout(typingTimer);
        const newTypingTimer = setTimeout(() => {
            onQuestionTextChange(newContent);
        }, 2000);
        setTypingTimer(newTypingTimer)

    }

    return (
        <div className="my-0">
            <div className={`${styles.card_border} ${styles.question_card}`} style={!props.isValidQuestion ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>


                    <div style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        <div className="d-flex">
                            <label className="mr-auto">Question # {props.questionNo}</label><br />
                            <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" onClick={() => delQuestion(props.questionIndex)} type="button">
                                <img src={CrossICon} alt="" width="15px" />
                            </button>
                            <span {...props.dragHandleProps} style={{ margin: '-1.5rem -3.3rem 0 0' }} className={styles.dnd_question}>
                                <img src={DndIcon} alt="" />
                            </span>
                        </div>
                       
                        {/* Embedded YOutube video */}
                        <div>
                        <input type="text" placeholder="Write YouTube video ID here" value={props.questionVideo} className="form-control"
                            onChange={(e) => onSelectEmbedVideo(e.target.value)}
                        />
                        {/* <h3>video tag</h3> */}
                        <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>
                        
                        
                        </div>
                        
                        {/* Question text */}
                        {/* <input type="text" placeholder="Write Question here" value={props.questionText} className="form-control" 
                            onChange={(e) => onQuestionTextChange(e.target.value)}
                        /> */}
                         <CKEditor
                            editor={ClassicEditor}
                            data={question}

                            onChange={handleEditorChange}


                        />
                        
                    </div>


                    <div className="row mt-4 mx-1">

                        {/* Question Image */}
                        <div className="m-0 p-0 col-md-6 col-sm-12 col-12">
                            <label>Add Video</label>
                            <div className={`input-group mb-3 hide-file-text`}>
                                <input type="file" className="pl-1 py-1 form-control" multiple={false} accept={".jpg, .jpeg, .png, .mp4"}
                                    style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                    onChange={(e) => onQuestionImageSelect(e.target.files)}
                                />
                                {
                                    props.questionFile ?
                                        (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ?
                                            <>
                                                <div className="position-relative" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} style={{ cursor: 'pointer' }}>
                                                {<p>Video Selected</p>}
                                                    {/* <video width={200} controls className="img-fluid-border" style={{ width: "40px", height: "40px", cursor: 'pointer' }}>
                                                        <source src={props.questionFile} type="video/mp4" />
                                                        
                                                    </video> */}
                                                </div>
                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    type="video"
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />
                                            </>
                                            :
                                            <>
                                                <img src={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)} alt="" width="40px" height="40px" onClick={() => setQuestionFileModal(!isShowQuestionFileModal)} />


                                                <ImageModal
                                                    isModalOpen={isShowQuestionFileModal} setModal={() => setQuestionFileModal(!isShowQuestionFileModal)}
                                                    image={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)}
                                                    changeImage={onQuestionImageSelect}
                                                    // index={index}
                                                    delImage={removeQuestionFile}
                                                />



                                            </>

                                        :
                                        <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                            <img src={AttachementIcon} alt="" width="20px" />
                                        </label>
                                }
                            </div>
                        </div>

                        <div className="col-12">
                            <label>Upload Video</label>
                        </div>

                        {/* Video */}
                        <div className="col-12 mb-4">
                            <div className="position-relative">
                                <input type="file" placeholder="Select File" 
                                    // style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}  onChange={(e) => onQuestionVideoSelect(e.target.files)}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                            {/* Required */}
                            <div className="custom-checkbox custom-control pb-4 mr-3">
                                <input type="checkbox" id={`required ${props.questionIndex}`} className="custom-control-input" checked={props.required} onChange={() => onChangeRequired()} />
                                <label className="custom-control-label" htmlFor={`required ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Required</span>
                                </label>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            {/* Additional Comment Selection */}
                            <div className="custom-checkbox custom-control pb-4 mr-3">
                                <input type="checkbox" id={`additionalComment ${props.questionIndex}`} className="custom-control-input" checked={props.additionalComment} onChange={(e) => onSelectAddComment()} />
                                <label className="custom-control-label" htmlFor={`additionalComment ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Additional Comment Required</span>
                                </label>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                            {/* Files Limit */}
                            <div className="custom-checkbox custom-control pb-4 mr-3">
                                <input type="checkbox" id={`filesLimit ${props.questionIndex}`} className="custom-control-input" checked={props.filesLimit} />
                                <label className="custom-control-label" htmlFor={`filesLimit ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Upload Files Limit</span>
                                    <div className="d-flex justify-content-center">
                                        <input type="number" name="filesLimit" className="form-control w-50" value={props.filesLimit} onChange={(e) => onChangeFileLimit(e.target.value)} />
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                            {/* Files Size */}
                            <div className="custom-checkbox custom-control pb-4 mr-3">
                                <input type="checkbox" id={`filesSize ${props.questionIndex}`} className="custom-control-input" checked={props.filesSize} />
                                <label className="custom-control-label" htmlFor={`filesSize ${props.questionIndex}`}>
                                    <span className="ml-3" style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px' }}>Upload Files Size</span>
                                    <div className="d-flex justify-content-center">
                                        <input type="number" name="filesSize" className="form-control w-50" value={props.filesSize} onChange={(e) => onChangeFileSize(e.target.value)} />
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* Validation error */}
                        <div className="col-12">
                            {!props.isValidQuestion && (
                                <small className="text-danger">
                                    Please complete this question.
                                </small>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Create_Question_With_Video


