import React, { useState } from 'react'
import {
    Card
} from "reactstrap";

import styles from '../../css/styling.module.css';

import Dropzone from "react-dropzone";
import PhotoIcon from '../../images/photo_icon.png'
import ResizeDetector from 'react-resize-detector';
import CrossICon from '../../images/close_icon.png'


const Create_Video_Section = (props) => {

    // For changing image upload limit
    const onChangeLimit = (value) => {

        props.setContent({
            ...props.allContent,
            videosSection: { ...props.allContent.videosSection, limit: value }
        })
    }

    const onVideoSelect = (file) => {
        if (file) {

            if (props.limit >= file.length + props.allContent.videosSection.videos.length) {


                for (let i = 0; i < file.length; i++) {

                    // File Extension Check
                    let fileExtension = file[i].name.split('.').pop();

                    if (fileExtension === "mp4") {

                        const videos = props.allContent.videosSection.videos
                        videos.push(file[i])
                        props.setContent({ ...props.allContent, videosSection: { ...props.allContent.videosSection, videos: videos } });

                    }
                }
            }

        }

    }

    // on Cancel Image
    const onCancelVideo = (index) => {

        const videos = props.allContent.videosSection.videos
        videos.splice(index, 1)

        props.setContent({ ...props.allContent, videosSection: { ...props.allContent.videosSection, videos: videos } });
    }


    // For deleting a section
    const delSection = () => {

        props.setContent({
            description: props.allContent.description,
            imagesSection: props.allContent.imagesSection,
            filesSection: props.allContent.filesSection,
            participantsSection: props.allContent.participantsSection,
            videosSection: { ...props.allContent.videosSection, videos: [], limit: 0 }
        })
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Card className="main-card mb-3 p-3 mt-2">
                    <div className="d-flex">
                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mr-auto">Attached Video</label>
                        <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" >
                            <img src={CrossICon} alt="" width="15px" onClick={() => delSection()} />
                        </button>
                    </div>

                    <Card className="main-card mb-3 p-3 mt-2">

                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Allow Videos</label><br />

                        <div className={`input-group input-group-feedback mb-3 ${styles.option_input}`}>

                            {/* Text */}

                            <input type="text" placeholder="Set no. of Videos" value={props.limit} className="form-control"
                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                onChange={(e) => onChangeLimit(e.target.value)}
                            />


                            <div className={`input-group-append`}>
                                <button className="btn btn-light" >
                                    <img src={PhotoIcon} alt="" width="25px" />
                                </button>
                            </div>
                        </div>

                        {/* Video Display & Dropzone */}
                        {props.allContent.videosSection && props.allContent.videosSection.videos.length > 0 &&

                            // Video Display
                            <div className="row pt-3">

                                {props.allContent.videosSection.videos.map((videoSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionImg_close" style={{ marginLeft: '275px', zIndex: 1, boxShadow: 'none' }} onClick={() => onCancelVideo(index)}>&times;</span>
                                            <video width={300} controls className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                <source src={videoSrc} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    )
                                })}

                            </div>

                        }

                        {/* // Video Drop Zone  */}
                        < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                            <Dropzone
                                maxFiles={!Number.isNaN(parseInt(props.limit)) ? parseInt(props.limit) : 0}
                                multiple={!Number.isNaN(parseInt(props.limit)) ? (parseInt(props.limit) > 0 ? true : false) : false}
                                accept={".mp4"}
                                onDrop={file => onVideoSelect(file)}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                            <div className="d-flex">
                                                <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping file here, or click to select file to upload.</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>


                    </Card>
                </Card>

            )}
        />


    )
}

export default Create_Video_Section
