import React, { useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody } from "reactstrap";
import styles from '../../../../assets/css/rstyling.module.css'
import styles2 from '../../../../assets/css/styling.module.css'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import avatar1 from "../../../../assets/utils/images/avatars/1.jpg";
import CommentIcon from '../../../../assets/images/comment_icon_blue.png'
import ProfileImage from '../../../../assets/images/profile_image.jpg'

// ************ This screen is removed from the UI *****************


const ProjectStream = () => {

    const [stream1, setStream1] = useState([
        { Question: 'For research classification purpose, would you please tell me your range?', Answer: ['26-50 years old'] },
        { Question: 'Can you please tell me your religion inclinition?', Answer: ['Muslim'] },
        { Question: 'Please tell who is cheif wage earner of your family', Answer: ['Myself'] },
        { Question: 'Please, tell the occupation of cheif wage earner', Answer: ['Skilled Worker', 'Senior Executive'] },

    ])

    const [stream, setStream] = useState([
        {
            date: 'September 29, 2021', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            comments: [
                {
                    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    image: ProfileImage,
                    date: 'September29, 2021',
                    commentedBy: { username: 'Lorem Ipsum', image: avatar1 }
                },
                {
                    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    image: '',
                    date: 'September29, 2021',
                    commentedBy: { username: 'Lorem Ipsum', image: avatar1 }
                }
            ]
        },
        {
            date: 'September 29, 2021', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            comments: [
                {
                    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    image: '',
                    date: 'September29, 2021',
                    commentedBy: { username: 'Lorem Ipsum', image: avatar1 }
                },
                {
                    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    image: '',
                    date: 'September29, 2021',
                    commentedBy: { username: 'Lorem Ipsum', image: avatar1 }
                }
            ]
        },
        {
            date: 'September 29, 2021', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            comments: [{
                text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                image: ProfileImage,
                date: 'September29, 2021',
                commentedBy: { username: 'Lorem Ipsum', image: avatar1 }
            }]
        },
    ])


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                {/* Surveys Response */}
            <Card className="main-card mb-3">
                <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                    Survey Responses
                </CardHeader>
                <CardBody>

                    <VerticalTimeline layout="1-column"
                        className="vertical-time-simple vertical-without-time">

                        {
                            stream1.map(data => {
                                return <VerticalTimelineElement className="vertical-timeline-item">
                                    <h4 className="timeline-title" style={{ fontWeight: '500' }}>{data.Question}</h4>
                                    <ul className={`pl-3 ${styles.text_blue} ${styles.custom_list}`}>
                                        {
                                            data.Answer.map(ans => {
                                                return <li style={{ fontSize: '0.85rem' }}>{ans}</li>
                                            })
                                        }
                                    </ul>
                                </VerticalTimelineElement>
                            })
                        }

                    </VerticalTimeline>
                </CardBody>
            </Card>

            {/* Forum response */}
            <Card className="main-card mb-3">
                <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                    Forum Responses
                </CardHeader>
                <CardBody>

                    <VerticalTimeline layout="1-column"
                        className="vertical-time-simple vertical-without-time">

                        {
                            stream.map((data, index) => {
                                return <VerticalTimelineElement key={index} className="vertical-timeline-item">

                                    <h4 className="timeline-title" style={{ fontWeight: '500' }}>{data.date}</h4>
                                    <p>{data.description}</p>
                                    <div className={`${styles.text_blue}`}>
                                        <span style={{ fontSize: '0.85rem', fontWeight: '600' }}><img src={CommentIcon} alt="" className="mr-1" width="20" />
                                            View {data.comments.length} Comments
                                        </span>
                                    </div>

                                    {/* Comments */}
                                    <div className="my-3 ml-4" >
                                        {
                                            data.comments.map((comment, index) => {
                                                return <div key={index} className="my-3" >
                                                    <div className="d-flex mb-2">

                                                        <div className="d-flex mr-auto">
                                                            <img src={comment.commentedBy.image} alt="profile" width={32} className="rounded-circle mr-2" />
                                                            <h4 className="timeline-title mb-0 align-self-center" style={{ fontWeight: '500' }}>{comment.commentedBy.username}</h4>
                                                        </div>

                                                        <div className="text-right">
                                                            <small className="text-muted" style={{ fontWeight: '500' }}>{comment.date}</small>
                                                        </div>

                                                    </div>

                                                    <p id="comment" style={{ userSelect: 'text' }}>{comment.text}</p>
                                                    {comment.image !== '' &&
                                                        <img src={comment.image} alt="" width="200" style={{ borderRadius: '10px', maxHeight: 400 }} />
                                                    }

                                                </div>
                                            })
                                        }
                                    </div>
                                </VerticalTimelineElement>
                            })
                        }
                    </VerticalTimeline>
                </CardBody>
            </Card>

            {/* Diary Response */}
            <Card className="main-card mb-3">
                <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                    Diary Responses
                </CardHeader>
                <CardBody>
                    <VerticalTimeline layout="1-column"
                        className="vertical-time-simple vertical-without-time">

                        {
                            stream.map((data, index) => {
                                return <VerticalTimelineElement key={index} className="vertical-timeline-item">

                                    <h4 className="timeline-title" style={{ fontWeight: '500' }}>{data.date}</h4>
                                    <p>{data.description}</p>
                                    <div className={`${styles.text_blue}`}>
                                        <span style={{ fontSize: '0.85rem', fontWeight: '600' }}><img src={CommentIcon} alt="" className="mr-1" width="20" />
                                            View {data.comments.length} Comments
                                        </span>
                                    </div>

                                    {/* Comments */}
                                    <div className="my-3 ml-4" >
                                        {
                                            data.comments.map((comment, index) => {
                                                return <div key={index} className="my-3" >
                                                    <div className="d-flex mb-2">

                                                        <div className="d-flex mr-auto">
                                                            <img src={comment.commentedBy.image} alt="profile" width={32} className="rounded-circle mr-2" />
                                                            <h4 className="timeline-title mb-0 align-self-center" style={{ fontWeight: '500' }}>{comment.commentedBy.username}</h4>
                                                        </div>

                                                        <div className="text-right">
                                                            <small className="text-muted" style={{ fontWeight: '500' }}>{comment.date}</small>
                                                        </div>

                                                    </div>

                                                    <p id="comment" style={{ userSelect: 'text' }}>{comment.text}</p>
                                                    {comment.image !== '' &&
                                                        <img src={comment.image} alt="" width="200" style={{ borderRadius: '10px', maxHeight: 400 }} />
                                                    }

                                                </div>
                                            })
                                        }
                                    </div>

                                    {/* Add Comment */}
                                    <div className="row mx-4">

                                        <div className="d-inline-flex align-self-center">
                                            <img src={avatar1} className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }} />
                                        </div>

                                        <div className="col align-self-center">

                                            <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                                                <input type="text" className={"form-control " + styles2.reply_comment_textbox} style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }} placeholder="Write a comment" />
                                                <div className="input-group-append">
                                                    <span className={styles2.upload_media_icon}></span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="d-inline-flex align-self-center">
                                            <button className={styles.sent_blue_btn}>
                                                <span className={styles2.sent_icon}></span>
                                            </button>
                                        </div>

                                    </div>



                                </VerticalTimelineElement>
                            })
                        }

                    </VerticalTimeline>
                </CardBody>
            </Card>

        </TransitionGroup >
    )
}

export default ProjectStream
