import React, { useEffect, useState } from 'react'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import DataTable from '../../../assets/components/datatable/DataTable';
import ResizeDetector from "react-resize-detector";
import { useDispatch } from 'react-redux';
import { getProject } from '../../../actions/project_actions';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert'
import _ from 'lodash'

const Details = () => {

  const dispatch = useDispatch()
  const params = useParams()
  const [data, setData] = useState({})
  const [projectDetails, setProjectDetails] = useState({})

  const onTabChange = (index, whichData) => {
    if (whichData === 'gender')
      setData({ ...data, gender: { ...data.gender, selectedIndex: index } });
    else if (whichData === 'cities')
      setData({ ...data, cities: { ...data.cities, selectedIndex: index } });
    else if (whichData === 'age range')
      setData({ ...data, ageRange: { ...data.ageRange, selectedIndex: index } });
    else if (whichData === 'income range')
      setData({ ...data, incomeRange: { ...data.incomeRange, selectedIndex: index } });
  }

  const getProjectDetails = () => {
    dispatch(getProject(params.projectid))
      .then(response => {
        const project = response.payload.content.criteria
        // saving project criteria
        const grid = {
          gender: {
            options: project.genders,
            selectedIndex: 0
          },
          cities: {
            options: project.cities,
            selectedIndex: 0
          },
          ageRange: {
            options: project.ageRanges
          },
          incomeRange: {
            options: project.incomeRanges
          },
          gridSets: project.gridSets
        }
        setData(grid)
        setProjectDetails(response.payload.content)
      }).catch(err => {
        Swal({
          title: err.response ? err.response.data.name : "Error",
          text: err.response ? err.response.data.content : "Something went wrong",
          icon: 'error'
        })
      })
  }

  useEffect(() => {
    getProjectDetails()
  }, [])

  const calculateCriteria = (type, selectedGenderIndex, selectedCityIndex) => {
    if (type === 'all') {
      let sum = 0;
      // calculating the sum of all participants
      if (data.gridSets.length > 0) {
        for (let i = 0; i < data.gridSets.length; i++) {
          for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
            sum += _.sum(data.gridSets[i].incomeAgeGrid[j])
          }

        }
      }
      return sum
    } else if (type === 'gender') {
      let sum = 0;
      // calculating the sum of selected gender participants
      if (data.gridSets.length > 0) {
        for (let i = 0; i < data.gridSets.length; i++) {
          if (data.gridSets[i].selectedGender === selectedGenderIndex) {
            for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
              sum += _.sum(data.gridSets[i].incomeAgeGrid[j])
            }
          }

        }
      }
      return sum
    } else {
      let sum = 0
       // calculating the sum of selected gender and selected city participants
      if (data.gridSets.length > 0) {
        for (let i = 0; i < data.gridSets.length; i++) {
          if (data.gridSets[i].selectedGender === selectedGenderIndex && data.gridSets[i].selectedCity === selectedCityIndex) {
            for (let j = 0; j < data.gridSets[i].incomeAgeGrid.length; j++) {
              sum += _.sum(data.gridSets[i].incomeAgeGrid[j])
            }

          }

        }
      }
      return sum
    }
  }




  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
          transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>ResearchId:</div>
                <div className={styles.text_bold}>{projectDetails.researchId}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Project Code:</div>
                <div className={styles.text_bold}>{projectDetails.code}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Study Type:</div>
                <div className={styles.text_bold}>{projectDetails.projectType}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Client Company:</div>
                <div className={styles.text_bold}>{projectDetails.company}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Compensation:</div>
                <div className={styles.text_bold}>{projectDetails.compensation}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Duration (Days):</div>
                <div className={styles.text_bold}>{projectDetails.activeDays}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Duration Per Day (Minutes):</div>
                <div className={styles.text_bold}>{projectDetails.duration}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Priority:</div>
                <div className={styles.text_bold}>{projectDetails.priority}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Country:</div>
                <div className={styles.text_bold}>{projectDetails.country && projectDetails.country.name}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>City:</div>
                <div className={styles.text_bold}>{projectDetails.city && projectDetails.city.name}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Start Date:</div>
                <div className={styles.text_bold}>{projectDetails.startDate && new Date(projectDetails.startDate).toLocaleDateString()}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>End Date:</div>
                <div className={styles.text_bold}>{projectDetails.endDate && new Date(projectDetails.endDate).toLocaleDateString()}</div>
              </div>
              <div className="col-sm-6 col-12">
                <div className={styles.heading_light}>Project Creation Date Time:</div>
                <div className={styles.text_bold}>{projectDetails.created_at && new Date(projectDetails.created_at).toLocaleDateString()}</div>
              </div>

            </div>
            {
              projectDetails.criteria && projectDetails.criteria.gridSets.length > 0 &&
              <>
                <DataTable
                  data={data}
                  onTabChange={onTabChange}
                  width={width}
                  isEditable={false}
                />

                <div className="container-fluid mt-2">
                  <div className="row">
                    {width > 470 &&
                      <>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </>
                    }

                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee", color: "b" }}>
                      Total {data.gender && data.gender.options[data.gender.selectedIndex]}s in {data.cities && data.cities.options[data.cities.selectedIndex]}
                    </div>
                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                      {calculateCriteria('city', data.gender.selectedIndex, data.cities.selectedIndex)}
                    </div>

                  </div>
                  <div className="row">
                    {width > 470 &&
                      <>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </>
                    }
                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee" }}>
                      Total {data.gender && data.gender.options[data.gender.selectedIndex]}s
                    </div>
                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                      {calculateCriteria('gender', data.gender.selectedIndex)}
                    </div>


                  </div>
                  <div className="row">
                    {width > 470 &&
                      <>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </>
                    }
                    <div className={`${styles.text} col text-right`} style={{ backgroundColor: "#eee", color: "b" }}>
                      Total Respondents
                    </div>
                    <div className={`${styles.text} col text-left`} style={{ backgroundColor: "#eee" }}>
                      {calculateCriteria('all')}
                    </div>
                  </div>
                </div>
              </>
            }



          </div>

        </TransitionGroup>
      )}
    />
  )
}

export default Details
