import React from 'react';
import { useState, useEffect } from 'react';
import { Modal, ModalBody } from "reactstrap";

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import noTasksImage from '../../../assets/images/no-project-img.png'
import styles from '../../../assets/css/styling.module.css';
import styles3 from '../../../assets/css/newStyling.module.css';

import CircularSegmented_progress from '../../../assets/components/Progress/CircularSegmented_progress';
import MultipleCircles_progress from '../../../assets/components/Progress/MutipleCircles_progress';
import { useDispatch, useSelector } from 'react-redux';
import { editUserDetails, getUserDetails, profilePicture } from '../../../actions/user_action';
import { useParams, useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert'
import { getQuickSurvey } from '../../../actions/activities_actions';
import { participantsProjects, participantsProjectsDashboard } from '../../../actions/project_actions';
import SecureLS from 'secure-ls';
import { respondentDashboardDetails } from '../../../actions/dashboard_activities';
import { joinProject } from '../../../actions/participant_actions'
import noProjectImage from '../../../assets/images/no-project-img.png'


function Projects() {

    const ls = new SecureLS()
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)

    const [userInterests, setUserInterests] = useState([])
     const [buttonActive, setButtonActive] = useState(0)
    const [activeButtons, setActiveButtons] = useState(new Array(userInterests.length).fill(false));

    const [projectData, setProjectData] = useState([])


    const userId = ls.get('accessUser').user._id

    const [clickedInterests, setClickedInterests] = useState([]);

    useEffect(() => {



        // getting user interests 
        dispatch(getUserDetails(userId))
            .then(response => {


                setUserInterests(response.payload.content.interests)
                setLoading(false)


            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })


    }, []);

    const JoinProject = (projectId) => {

        dispatch(joinProject(projectId))
            .then(response => {
                Swal({
                    title: "Joined",
                    text: "Project Joined",
                    icon: 'success'
                }).then(() => {
                    setTimeout(() => {
                        navigate(`/respondent/project-detail-view/${response.payload.content.projectId}`)
                    }, 1500)


                }).catch(err => { console.log(err) })
            })
            .catch(err => { console.log(err) })
    }


    // useEffect(()=>{
    const handleInterestClick = (interestt, index) => {

        let currentPage = 1;
        let dataLimit = 6
        // setLoading(true)
        let interest={
            interest: interestt,
            continueProjectBlock: false
        }
        dispatch(participantsProjects(currentPage, dataLimit, interest))
            .then(response => {
                console.log(`participants projects of ${interest} ->`, response.payload.content.upcomingProjects)
                setProjectData(response.payload.content.upcomingProjects)
                if (response.payload.content.upcomingProjects.includes(null)) {


                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })

        // Create a new array with all false values initially
        const newActiveButtons = new Array(activeButtons.length).fill(false);

        // Toggle the active state for the clicked button
        newActiveButtons[index] = true;

        // Update the state to apply the active class to the clicked button and remove it from others
        setActiveButtons(newActiveButtons);

        // Check if the interest is already in the clickedInterests array
        if (clickedInterests.includes(interest)) {
            // If it is, remove it
            setClickedInterests(clickedInterests.filter((item) => item !== interest));
        } else {
            // If it's not, add it
            setClickedInterests([...clickedInterests, interest]);
        }
    };
    // },[])

    useEffect(() => {

        // Trigger the "All" button click after the component has fully rendered
        if (userInterests.length >= 3) {
            const allButtonIndex = userInterests.length;
console.log('button active length ->',allButtonIndex)
            setButtonActive(allButtonIndex)
            handleInterestClick("All", allButtonIndex);
        }
        // if (userInterests.length > 0 && userInterests.length < 3) {
        //     const initialInterestIndex = 0;
        //     setButtonActive(initialInterestIndex)

        //     handleInterestClick(userInterests[initialInterestIndex], initialInterestIndex);
        // }

    }, [userInterests]);






    const todaysDate = new Date().toLocaleDateString() // for quick survey 

    return (
        <TransitionGroup >

            <ResizeDetector
                handleWidth
                render={({ width }) => (

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                                position: 'fixed',
                                top: '20%'
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        <React.Fragment>

                            <div className="container-fluid pt-3">






                                <h5 style={{ paddingBottom: window.innerWidth > 768 ? '30px' : '', color: '#000', fontWeight: '500', fontSize: '18px' }}>New Projects</h5>
                                <div >
                                    <div className={`${styles3.new_projects_tags}`}>

                                        {userInterests.length != 0 ? userInterests && userInterests.map((interest, index) => (
                                            <div
                                                // className={styles.group} 
                                                key={`group_${index}`}

                                            >
                                                <div >

                                                    {index < 3 ? (

                                                        <button className={activeButtons[index] ? `${styles3.new_projects_tags_button_active_button}` : `${styles3.new_projects_tags_button}`} onClick={() => handleInterestClick(interest, index)}>
                                                            {interest}
                                                        </button>
                                                    ) : index === 3 && userInterests.length > 3 ? (

                                                        <button className={activeButtons[index] || buttonActive ===userInterests.length ? `${styles3.new_projects_tags_button_active_button}` : `${styles3.new_projects_tags_button}`} onClick={() => handleInterestClick("All", index)}>

                                                            All
                                                        </button>

                                                    ) : null}
                                                </div>
                                            </div>
                                        )) :
                                            <h6>No Interests Selected</h6>
                                        }


                                    </div>
                                </div>
                                <div className="row mt-3">
                                    {/* removed resize detecor layout function here */}
                                    <div className='col-12 pt-3'>
                                        {/* Projects Section */}
                                        <div className={styles.card_border}>

                                            <div className={styles.card_border_header}>
                                                Projects
                                            </div>

                                            <div className={styles.card_border_body + " pt-2 pb-3 px-0"}>
                                                <div className="scroll-area-lg" style={{ height: 'auto', minHeight: '140px' }}>
                                                    <PerfectScrollbar>




                                                        {projectData && projectData && projectData.length >0 ? projectData.map(x => {
                                                            if (!x || !x.title) {
                                                                return (
                                                                    <></>
                                                                )
                                                            }
                                                            if (x != null) {
                                                                return (
                                                                    <div className='container pt-4' key={x.id}>
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <img className={`${styles3.project_tag_banner} mb-2`} src='https://www.simplilearn.com/ice9/free_resources_article_thumb/What_Is_a_Project.jpg' alt={x.title}></img>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <p className={`${styles3.project_tag_title} pt-2 m-0`}>{x.title}</p>
                                                                            </div>
                                                                            <div className="col-md-10 col-lg-3">
                                                                                <button className={`${styles3.apply_now_button}`} onClick={() => { JoinProject(x._id) }}>

                                                                                    <p className={`${styles3.apply_now_text} pt-2`}>Join Project</p>

                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        }) : (
                                                            <div style={{textAlign:'center'}}>
                                                          
                                                                <img style={{ width: '50%', height: '50%' }} src={noTasksImage}></img>
                                                                <p className='container' style={{ color: '#8E8E8E', fontFamily: 'Poppins', fontSize: '12px' }}>No matching projects now, but stay tuned! We're crafting exciting opportunities just for you.</p>
                                                               

                                                            </div>
                                                                )
                                                        }


                                                    </PerfectScrollbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>



                            </div>










                        </React.Fragment>


                    </LoadingOverlay>

                )}

            />

        </TransitionGroup >

    )

}

export default Projects;