import React, { useState, useEffect } from 'react'
import { Card, Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import bg2 from "../../../../../assets/utils/images/dropdown-header/city2.jpg";
import styles from '../../../../../assets/css/rstyling.module.css'
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Search from '../../../../../assets/images/search_icon.png'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import Excerpts from './Excerpts/Excerpts';
import Recording from './Recordings/Recordings';
import Participant from './Participant/Participant';
import { getProjectParticipant } from '../../../../../actions/participant_actions';
import { getProjectAudiences } from '../../../../../actions/audience_actions';
import { useDispatch } from 'react-redux';
import Meeting from './Participant/Meeting.js'


// ********** This is the main file which is called on click of view button of activity listing ************

const ActivityView = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    const dispatch = useDispatch()
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const pathWithId = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const path = pathWithId.slice(0, pathWithId.lastIndexOf('/'))

    const [activeTab, setActiveTab] = useState("2")
    const [videoModal, setVideoModal] = useState(false)
    const [isShowMediaModal, setMediaModal] = useState(false)
    const [isShowParticipantModal, setParticipantModal] = useState(false)

    const [audiences, setAudience] = useState([])
    const [participants, setParticipant] = useState([])

    const [audio, setAudio] = useState('')
    const [title, setTitle] = useState('')

    const isShowExcerpt = () => {
        switch (path) {
            case '/researcher/project/interview-activity':
                return false
            case '/researcher/project/online-interview-activity':
                return false
            case "/researcher/project/inperson-forum-activity":
                return false
            case "/researcher/project/inperson-forum-activity":
                return false

            default:
                return true
        }
    }

    const isShowRecordings = () => {
        switch (path) {
            case '/researcher/project/journal-activity':
                return false

            case "/researcher/project/survey-activity":
                return false

            default:
                return true
        }
    }

    const isShowMeetings = () => {
        switch (path) {
            case '/researcher/project/online-interview-activity':
                return true
            case "/researcher/project/inperson-forum-activity":
                return true

            default:
                return false
        }
    }

    // Card Header
    const getHeader = () => {
        switch (activeTab) {
            case '1':
                if (title === 'Group Discussions' || title == 'Online In-depth Interviews') {
                    return <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '1rem' }}>
                        <button className={`btn text-white ${styles.bg_blue}`} onClick={() => setParticipantModal(true)}>Create Meeting</button>
                    </div>
                }
                break
            case '4':
                return <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '1rem' }}>
                    <button className={`btn text-white ${styles.bg_blue}`} onClick={() => setMediaModal(true)}>Upload</button>
                </div>
                break
            default:
                return <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.3rem' }}>
                    <div className="input-group input-group-sm w-75">
                        <div className="input-group-prepend border-0">
                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                        </div>
                        <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" />
                    </div>
                </div>
                break
        }
    }

    // Set Title for different activities type
    useEffect(() => {
        setActiveTab(query.get("tab"))
        if (query.get("tab") === '1' || activeTab === '1' || query.get("tab") === '4' || activeTab === '4') {
            switch (path) {
                case '/researcher/project/interview-activity':
                    setTitle('In-depth Interviews')
                    break
                case '/researcher/project/inperson-forum-activity':
                    setTitle('Group Discussions')
                    break
                case '/researcher/project/survey-activity':
                    setTitle('Survey Form')
                    break
                case '/researcher/project/forum-activity':
                    setTitle('Forum Activity')
                    break
                case '/researcher/project/journal-activity':
                    setTitle('Diary')
                    break
                case '/researcher/project/online-interview-activity':
                    setTitle('Online In-depth Interviews')
                    break
                default:
                    return 4;
            }
        }
    }, [])

    // Form preview links
    useEffect(() => {
        if (query.get("tab") === '3' || activeTab === '3') {
            switch (path) {
                case '/researcher/project/interview-activity':
                    history("/researcher/project/preview-interview/" + params.projectid + "/" + params.activityid)
                    break
                case '/researcher/project/inperson-forum-activity':
                    history("/researcher/project/preview-inpersonforum/" + params.projectid + "/" + params.activityid)
                    break
                case '/researcher/project/survey-activity':
                    history("/researcher/project/preview-survey/" + params.projectid + "/" + params.activityid)
                    break
                case '/researcher/project/forum-activity':
                    history("/researcher/project/preview-forum/" + params.projectid + "/" + params.activityid)
                    break
                case '/researcher/project/journal-activity':
                    history("/researcher/project/preview-journal/" + params.projectid + "/" + params.activityid)
                    break
                case '/researcher/project/online-interview-activity':
                    history("/researcher/project/preview-onlineinterview/" + params.projectid + "/" + params.activityid)
                    break
                default:
                    return 4;
            }
        }
    }, [activeTab])

    useEffect(() => {
        if (audio.split('.').pop() === "avi" || audio.split('.').pop() === "mpeg" || audio.split('.').pop() === "mp4") {
            setVideoModal(true)
        } else {
            setVideoModal(false)
        }
    }, [audio])

    const getAudienceList = () => {
        dispatch(getProjectAudiences(params.projectid))
            .then(response => setAudience(response.payload.content))
            .catch(err => {
            })
    }

    const getParticipantsList = () => {
        dispatch(getProjectParticipant(params.projectid))
            .then(response => setParticipant(response.payload.content))
            .catch(err => {
            })
    }

    useEffect(() => {
        getAudienceList()
        getParticipantsList()
    }, [])

    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <div className="card_radius">
                        <Card className="mb-0 mt-3">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-dark">
                                    <div className="menu-header-image"
                                        style={{
                                            backgroundImage: "url(" + bg2 + ")",
                                        }}>
                                    </div>
                                    {/* table header */}
                                    <div className="menu-header-content" style={{ display: 'flex' }}>
                                        <div className="menu-header-content-left mr-auto">
                                            <div className="menu-header-title text-white text-left" style={{ fontWeight: '500' }}>{title}</div>
                                        </div>


                                        <div className="menu-header-content-right">
                                            {/* Card Header */}
                                            {getHeader()}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={width >= 708 ? "tab_blue tab_center" : "tab_blue"}>

                                <Tabs defaultActiveKey={query.get("tab")} onChange={(key) => setActiveTab(key)}
                                    renderTabBar={() => <ScrollableInkTabBar color="primary" />}
                                    renderTabContent={() => <TabContent />} justified  >

                                    <TabPane tab="Participant" key="1">
                                        {
                                            isShowMeetings() ? <Meeting showMediaModal={isShowParticipantModal} setMediaModal={setParticipantModal} participants={participants} audiences={audiences} /> : 
                                            <Participant showMediaModal={isShowParticipantModal} setMediaModal={setParticipantModal} participants={participants} audiences={audiences} />
                                        }
                                    </TabPane>

                                    {
                                        isShowRecordings() && <TabPane tab="Recordings" key="4">
                                            <Recording showMediaModal={isShowMediaModal} setMediaModal={setMediaModal} audio={audio} setAudio={setAudio} participants={participants} audiences={audiences} />
                                        </TabPane>
                                    }

                                    {
                                        isShowExcerpt() && <TabPane tab="Excerpts" key="2" >
                                            <Excerpts />
                                        </TabPane>
                                    }

                                    <TabPane tab="View Form" key="3">
                                    </TabPane>

                                </Tabs>
                            </div>
                        </Card>

                        {/* Recording Audio */}
                        {
                            (audio.split('.').pop() === "mp3" || audio.split('.').pop() === "wav" || audio.split('.').pop() === "aac") ?
                                <div className={audio ? "d-block app-footer" : "d-none"}>
                                    <div className={'app-footer__inner'}>

                                        <div className="container-fluid mx-5">
                                            <audio controls className="w-100 py-2" >
                                                <source src={audio} type="audio/mp3" />
                                                File preview unavailable. Please download the file and view it.
                                            </audio>
                                        </div>

                                    </div>
                                </div>
                                :
                                <Modal isOpen={videoModal} toggle={() => setVideoModal(false)} className="modal-dialog-centered modal-lg shadow-none">
                                    <ModalBody >
                                        <div className='text-right' style={{ cursor: 'pointer', fontWeight: 500 }} onClick={() => setVideoModal(false)}>&times;</div>
                                        <div className="position-relative m-3">
                                            <video controls className="img-fluid- border w-100" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                <source src={audio} type="video/mp4" />
                                                File preview unavailable. Please download the file and view it.
                                            </video>
                                        </div>
                                    </ModalBody>
                                </Modal>
                        }


                    </div>
                )}

            />
        </TransitionGroup>


    )
}

export default ActivityView
