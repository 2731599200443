import React, { useState, useEffect } from 'react'
import ArrowLeft from '../../../../../../assets/images/arrow_left.png'
import ExcerptsIcon from '../../../../../../assets/images/excerpts_icon.png'
import { Button, Input } from 'reactstrap';
import { useNavigate, useParams } from 'react-router';
import styles from '../../../../../../assets/css/rstyling.module.css'
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import { editExcerpt, getExcerptDetails } from '../../../../../../actions/excerpts_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const ExcerptsEdit = () => {

    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [excerptDetails, setExcerptsDetails] = useState({})

    const getExcerpts = () => {
        dispatch(getExcerptDetails(params.excerptid))
            .then((response) => {
                setLoading(false)
                setExcerptsDetails(response.payload.content)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
                setLoading(false)
            })
    }

    const onEdit = () => {
        dispatch(editExcerpt(params.excerptid, { selectedString: excerptDetails.selectedString, note: excerptDetails.note }))
            .then((response) => {
                Swal({
                    title: "Edited",
                    text: "Excerpt Edited",
                    icon: 'success'
                }).then(() => history(-1))
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }


    useEffect(() => {
        getExcerpts()
    }, [])

    return (
        <>
            <div className="excerpt_header">
                <PageHeader
                    heading="Edit Excerpts" img={ExcerptsIcon}
                />
            </div>



            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="container-fluid mt-2">

                    <div className="row">

                        <div className="col-12 form-group">
                            <label htmlFor="excerpts">Excerpts</label>
                            <input className="form-control" type="text" name="excerpts" id="excerpts" value={excerptDetails.selectedString}
                                onChange={(e) => setExcerptsDetails({ ...excerptDetails, selectedString: e.target.value })}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="notes">Notes</label>
                            <textarea className="form-control" type="text" name="notes" id="notes" value={excerptDetails.note} rows="4" cols="50"
                                onChange={(e) => setExcerptsDetails({ ...excerptDetails, note: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 col-sm-12 col-12 form-group">
                            <label htmlFor="activityTitle">Activity Title</label>
                            <Input className="form-control" type="text" name="activityType" id="activityType" disabled
                                value={excerptDetails.activity && excerptDetails.activity.name}
                            >
                            </Input>

                        </div>

                        <div className="col-md-6 col-sm-12 col-12 form-group">
                            <label htmlFor="activityType">Activity Type</label>
                            <Input className="form-control" type="text" name="activityType" id="activityType" disabled
                                value={excerptDetails.activity && excerptDetails.activity.activityType}
                            >
                            </Input>
                        </div>

                        <div className="col-md-6 col-sm-12 col-12 form-group">
                            <label htmlFor="fromResponse">From Response</label>
                            <div className="d-flex" style={{ padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: "0.25rem" }}>
                                <img src={excerptDetails.responseAuthor && excerptDetails.responseAuthor.profileImage} alt="" width="25" height="25" className="rounded-circle mr-2" />
                                <div className="align-self-center">{excerptDetails.responseAuthor && excerptDetails.responseAuthor.userName}</div>
                            </div>
                        </div>

                        <div className="col-12 text-right">
                            <Button className={` ${styles.bg_blue} mr-3`} onClick={onEdit}>
                                Save
                            </Button>
                            <Button className={` bg-light text-muted`}
                                style={{ border: '1px solid #6c757d ' }}
                                onClick={() => history(-1)}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </div>
            </LoadingOverlay>


        </>
    )
}

export default ExcerptsEdit
