import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DropdownItem, Nav, NavItem } from 'reactstrap'
import { deleteChatroom, getAllUserChatroom } from '../../../actions/chats_action'
import Swal from 'sweetalert'
import classNames from "classnames";
import avatar from "../../../assets/images/profile_placeholder.png";
import SecureLS from 'secure-ls'
import AlertModal from '../../../assets/components/modal/AlertModal'

import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

// ********** This component is used for listing chat rooms on the left sidebar of chat screen ****************
const Chatlist = (props) => {

    const ls = new SecureLS()
    const dispatch = useDispatch()

    const chatroomState = useSelector(state => state.Chatroom)

    const [delId] = useState('')
    const [isModalOpen, setModal] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const [chatRooms, setChatList] = useState([])


    const getChatrooms = (isNew, page = 1) => {
        dispatch(getAllUserChatroom({ page: page, limit: 10 }))
            .then(response => {
                setLoading(false)
                isNew ? setChatList(response.payload.content.chatrooms) : setChatList([...chatRooms, ...response.payload.content.chatrooms])
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }



    const delChatroom = () => {
        dispatch(deleteChatroom(delId))
            .then(response => {
                Swal({
                    title: "Error",
                    text: "Chat Deleted",
                    icon: 'success'
                }).then(() => getChatrooms())
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        getChatrooms(true)
    }, [])

    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const next = () => {
        getChatrooms(false, chatroomState.currentPage + 1)
    }


    return (

        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }
            }
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <>
                <AlertModal isModalOpen={isModalOpen} setModal={() => setModal(!isModalOpen)} onClick={delChatroom} />
                <Nav vertical>
                    {
                        chatRooms.length > 0 &&
                        chatRooms.map((chatroom, index) => {
                            return <>
                                {chatroom.users.map((user, index) => {
                                    if (user._id !== ls.get('accessUser').user._id) {
                                        return <NavItem key={index} onClick={() => props.getMessages(chatroom._id, user)}>
                                            <DropdownItem className={chatroom._id === props.activeChatroom ? 'active' : ""}>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <div className="avatar-icon-wrapper">
                                                                <div className="avatar-icon">
                                                                    <img src={user.profileImage ? user.profileImage : avatar} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="d-flex">
                                                                <div className="widget-heading mr-auto">
                                                                    {user.userName}
                                                                </div>
                                                                <div className={classNames({
                                                                    'badge-success': user.role === 1,
                                                                    'badge-warning': user.role === 2,
                                                                    'badge-danger': user.role === 3,
                                                                }, 'mb-2 mr-2 badge badge-pill text-center')}>
                                                                    {user.role === 1 ? 'Admin' : user.role === 2 ? 'Researcher' : 'Respondent'}
                                                                </div>
                                                            </div>
                                                            <div style={{ color: 'transparent' }}>
                                                                Aenean vulputate eleifend tellus.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                        </NavItem>

                                    }

                                })}
                            </>
                        })
                    }

                    {
                        chatroomState.totalPages > 0 && chatroomState.totalPages !== chatroomState.currentPage &&
                        <div className={"pb-2"}>
                            <div className="row px-3">
                                <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Chats</span>
                                </div>
                            </div>
                        </div>
                    }
                </Nav>
            </>
        </LoadingOverlay >


    )
}

export default Chatlist