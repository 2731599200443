import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { Button, Card, CardBody, Label, Input, FormGroup } from "reactstrap";
import cx from "classnames";
import { useDispatch } from 'react-redux';
import { Formik } from 'formik'
import * as Yup from 'yup'
import styles from '../../../../assets/css/rstyling.module.css'
import { getSpecificOption, projectCityOptions } from '../../../../actions/settings_actions';

const BasicInfo = (props) => {


    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const [brands, setBrands] = useState([])
    const researchType = ["Qualitative", "Quantitative"]
    const projectType = ["Online", "Onsite", "Both"]
    const priorities = ["Normal", "High", "Medium"]

    const [data, setData] = useState({
        projectID: '',
        brands: '',
        projectTitle: '',
        projectCode: '',
        projectType: '',
        clientComapny: '',
        country: '',
        priority: '',
        city: '',
        document: '',
        surveyType: false,
        researchType: '',
        hideTitle: false
    })

    const handleClick = () => {
        setData({
            ...data, surveyType: !data.surveyType
        });
    }

    const dispatch = useDispatch()


    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Countries", "Cities", "Income", "Brands"] }))
            .then(response => {
                // console.log(response)
                setCities(response.payload.content.cities)
                setCountries(response.payload.content.countries)
                setBrands(response.payload.content.brands)
            })
            .catch(err => {
                setCities([])
                setCountries([])
                setBrands([])
            })
    }
    useEffect(() => {
        getSystemOptions()
    }, [])


    const handleSubmit = (values) => {
        props.next(values)
    }

    const onSelectCountry = (value) => {

       
        dispatch(projectCityOptions(value))
            .then(response => {

                setCities(response.payload.content.cities)
                //setIncomes(response.payload.content.incomes)
                //setEducations(response.payload.content.educationData)
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3 mt-3">
                <CardBody>
                    <div className="row" >
                        <div className='col-12'>

                            <Formik
                                initialValues={props.data}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}


                                const validationSchema={Yup.object().shape({
                                    title: Yup.string().required("* Title is required"),
                                    // code: Yup.string().required("* Project Code is required"),
                                    // projectType: Yup.string().required("* Project Type is required"),
                                    // researchType: Yup.string().required("* Research Type is required"),
                                    // company: Yup.string().required("* Company is required"),
                                    // country: Yup.string().required("* Country is required"),
                                    // priority: Yup.string().required("* Priority is required"),
                                    // city: Yup.string().required("* City is required"),
                                    // brand: Yup.string().required("* Brand Name is required"),
                                    // researchId: Yup.string().required("* Research Id is required"),
                                })}


                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue
                                    } = props;
                                    return (
                                        <form autoComplete="off" onSubmit={handleSubmit}>

                                            {/* Start of form */}

                                            <div className='row'>
                                                <div className="col-md-6 col-sm-12">
                                                    <FormGroup>
                                                        <Label for="projectID">Research Project ID</Label>
                                                        <Input type="text" name="researchId"
                                                            className={errors.researchId && touched.researchId ? styles.is_invalid : 'valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.researchId} />
                                                        {errors.researchId && touched.researchId && (
                                                            <div>
                                                                {errors.researchId}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <FormGroup>
                                                        <Label for="brands">Brands</Label>
                                                        <Input type="select"
                                                            className={errors.brand && touched.brand ? styles.is_invalid : 'form-control valid'}
                                                            name="brand"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.brand}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                brands.map(brand => {
                                                                    return <option value={brand._id}>{brand.name}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.researchType && touched.researchType && (
                                                            <div>
                                                                {errors.researchType}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Label for="projectTitle">Project Title</Label>
                                                    <Input type="text"
                                                        className={errors.title && touched.title ? styles.is_invalid : 'valid'}
                                                        name="title"
                                                        placeholder="Title"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.title}
                                                    />

                                                    {errors.title && touched.title && (
                                                        <div>
                                                            {errors.title}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-2 col-12 align-self-end">
                                                    <FormGroup>
                                                        <input type="checkbox" id="hideTitle" className="mr-2" value={data.hideTitle}
                                                            onChange={(e) => setData({ ...data, hideTitle: e.target.checked })}
                                                        />
                                                        <label for="hideTitle">Hide Title</label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <FormGroup>
                                                        <Label for="projectCode">Project Code</Label>
                                                        <Input type="text"
                                                            name="code"
                                                            className={errors.code && touched.code ? styles.is_invalid : 'valid'}
                                                            placeholder="Project Code"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.code}
                                                        />

                                                        {errors.code && touched.code && (
                                                            <div>
                                                                {errors.code}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <FormGroup>
                                                        <Label for="email">Project Type</Label>
                                                        <Input type="select"
                                                            name="projectType"
                                                            className={errors.projectType && touched.projectType ? styles.is_invalid : 'form-control valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.projectType}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                projectType.map(type => {
                                                                    return <option value={type}>{type}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.projectType && touched.projectType && (
                                                            <div>
                                                                {errors.projectType}
                                                            </div>
                                                        )}

                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <FormGroup>
                                                        <Label for="researchType">Research Type</Label>
                                                        <Input type="select"
                                                            className={errors.researchType && touched.researchType ? styles.is_invalid : 'form-control valid'}
                                                            name="researchType"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.researchType}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                researchType.map(type => {
                                                                    return <option value={type}>{type}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.researchType && touched.researchType && (
                                                            <div>
                                                                {errors.researchType}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FormGroup>
                                                        <Label for="clientCompany">Client Company</Label>
                                                        <Input type="text"
                                                            name="company"
                                                            className={errors.company && touched.company ? styles.is_invalid : 'valid'}
                                                            placeholder="Client Company"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.company}
                                                        />

                                                        {errors.company && touched.company && (
                                                            <div>
                                                                {errors.company}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <FormGroup>
                                                        <Label for="country">Country</Label>
                                                        <Input type="select"
                                                            name="country"
                                                            className={errors.country && touched.country ? styles.is_invalid : 'form-control valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onInput={(e)=>onSelectCountry(e.target.value)}
                                                            value={values.country}
                                                        >
                                                            <option value="">Select</option>

                                                            {
                                                                countries.map(country => {
                                                                    return <option value={country._id}>{country.name}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.country && touched.country && (
                                                            <div >
                                                                {errors.country}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12">
                                                    <FormGroup>
                                                        <Label for="priority">Priority</Label>
                                                        <Input type="select"
                                                            name="priority"
                                                            className={errors.priority && touched.priority ? styles.is_invalid : 'form-control valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.priority}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                priorities.map(type => {
                                                                    return <option value={type}>{type}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.priority && touched.priority && (
                                                            <div >
                                                                {errors.priority}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <FormGroup>
                                                        <Label for="city">City</Label>
                                                        <Input type="select"
                                                            name="city"
                                                            className={errors.city && touched.city ? styles.is_invalid : 'form-control valid'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                               cities && cities.map(city => {
                                                                    return <option value={city.name}>{city.name}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.city && touched.city && (
                                                            <div>
                                                                {errors.city}
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12 align-self-end mb-1">
                                                    <FormGroup>
                                                        <Label for="file">Upload File</Label>
                                                        <Input type="file" className="btn- btn-outline" name="file" id="file"
                                                            onChange={(event) => {
                                                                setData({ ...data, document: event.target.files[0] })
                                                                setFieldValue("document", event.target.files[0])
                                                            }}
                                                        />
                                                        <Label>{typeof values.document == 'string' ? values.document.split("/").pop() : data.document.fileName}</Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3 col-12 align-self-center mt-3 d-flex">
                                                    <div className="switch has-switch has-switch-sm mb-2 mr-2" data-on-label="ON"
                                                        data-off-label="OFF" onClick={
                                                            () => { handleClick(); setFieldValue("isClosed", !data.surveyType) }}>
                                                        <div className={cx("switch-animate", {
                                                            "switch-on": data.surveyType,
                                                            "switch-off": !data.surveyType,
                                                        })}>
                                                            <input type="checkbox" name='isClosed' value={values.isClosed}
                                                                onChange={(event) => {
                                                                    setFieldValue("isClosed", data.surveyType)
                                                                }} />
                                                            <span className="switch-left  bg-success">ON</span>
                                                            <label>&nbsp;</label>
                                                            <span className="switch-right">OFF</span>
                                                        </div>
                                                    </div>
                                                    <div>Survey Type Closed</div>
                                                </div>

                                                <div className='col-md-6 col-12 text-right'>
                                                    <Button type="submit" className={` ${styles.bg_blue} float-right mr-3`}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }}

                            </Formik>
                        </div>

                    </div>
                </CardBody>
            </Card>
        </TransitionGroup>
    )
}

export default BasicInfo
