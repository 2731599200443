import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Attachement from '../../../assets/images/attachement_icon.png'
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import Back from '../../../assets/components/back/Back'
import { Card, CardBody, FormGroup, Input, Label, } from 'reactstrap';
import styles from '../../../assets/css/rstyling.module.css'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { createBrand, createCities, createCountries, createEducation, createEmployment, createIncomeRanges, createMaritalStatus, createRelationships, createSeniorities, createTemplateVar, createPoints, getAllOptions, deletePointsSystem,createInterests } from '../../../actions/settings_actions';
import {AiFillDelete} from 'react-icons/ai'

const Settings = () => {

    const history = useNavigate('')
    const dispatch = useDispatch()
    const [selectedCities, setSlectedCities] = useState([])
    const [city, setCity] = useState('')
    const [selectedCountries, setSelectedCountries] = useState([])
    const [country, setCountry] = useState('')
    const [dropDownCountry, setDropdowwnCountry] = useState('')
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([])
    const [maritalStatus, setMaritalStatus] = useState('')
    const [selectedRelationships, setSelectedRelationships] = useState([])
    const [relationShip, setRelationShip] = useState('')
    const [selectedEducationLevel, setSelectedEduLevel] = useState([])
    const [educationLevel, setEducationLevel] = useState('')
    const [selectedSeniority, setSelectedSeniority] = useState([])
    const [seniority, setSeniority] = useState('')
    const [selectedEmployment, setSeelectedEmployment] = useState([])
    const [employment, setEmployment] = useState('')
    const [selectedBrands, setSelectedBrands] = useState([])
    const [brands, setBrands] = useState('')
    const [selectedInterests, setSelectedInterests] = useState([])
    const [interests, setInterests] = useState('')
    const [templateVar, setTemplateVar] = useState({
        name: '',
        appendable: ''
    })
    const [points, setPoints] = useState({
        badge: '',
        points: '',
        currentStatus: '',
        nextMilestone: ''
    })
    
    const [incomeRange, setIncomeRange] = useState('')
    const systemOptionsState = useSelector(state => state.SystemSettings)
    const [isLoading, setLoading] = useState(true)

    const [options, setOptions] = useState([])

    const getSystemOptions = () => {
        dispatch(getAllOptions())
            .then(response => {
               
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                });
            })
    }
    useEffect(() => {
        if (systemOptionsState.options.length === 0) {
            getSystemOptions()
        }
    }, [])

    const removeDupValues = (arr1, arr2) => {
        let filteredData = arr1.map(data1 => {
            if (!arr2.includes(data1)) {
                console.log(data1)
                console.log(arr2)

                return data1
            }
        })
        return filteredData.filter(Boolean)
    }

    const addTemplateVar = () => {
        if (templateVar.name === '' && templateVar.appendable === '') {

        } else {
            const sysTemplateVar = systemOptionsState.options.templateVariables.map(variable => variable.name)
            if (sysTemplateVar.includes(templateVar.name)) {
                Swal({
                    title: "Already Present",
                    text: "Selected Template Variable already present in system",
                    icon: 'info'
                });
            } else {
                dispatch(createTemplateVar({ items: [templateVar] }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Created",
                            text: response.content ? response.content : "Template Variable Created",
                            icon: 'success'
                        });
                        setTemplateVar({name:'', appendable: ''})
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            }

        }
    }

    const addPoints =()=> {
        if (points.badge === '' && points.points === '' && points.currentStatus === '' && points.nextMilestone === '') {

        } else {
            const sysPoints = systemOptionsState.options.points.map(variable => variable.points)
            if (sysPoints.includes(points.points)) {
                Swal({
                    title: "Points Already Present",
                    text: "Selected Points already present in system",
                    icon: 'info'
                });
            } else {
              
                dispatch(createPoints({ items: [points] }))
                .then(response => {
                    Swal({
                        title: response.message ? response.message : "Created",
                        text: response.content ? response.content : "Points System Created",
                        icon: 'success'
                    });
                    setPoints({badge:'', points: '', currentStatus:'', nextMilestone:''})
                    getSystemOptions()
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                })
        }
            }
        }

        const deletePoints=(id)=>{

            dispatch(deletePointsSystem(id))
            .then(response => {
                Swal({
                    title: response.message ? response.message : "Deleted",
                    text: response.content ? response.content : "Points System Deleted",
                    icon: 'success'
                });
                setPoints({badge:'', points: '', currentStatus:'', nextMilestone:''})
                getSystemOptions()
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again!",
                    icon: 'error'
                });
            })
           
        }
    

    

    const addSeniority = () => {
        if (selectedSeniority.length > 0) {
            const sysSeniority = systemOptionsState.options.seniority.map(seniority => seniority.name)
            const filteredSeniority = removeDupValues(selectedSeniority, sysSeniority)
            const seniority = filteredSeniority.map(seniority => ({ name: seniority }))

            if (seniority.length > 0) {
                dispatch(createSeniorities({ items: seniority }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Seniority added in system",
                            icon: 'success'
                        });
                        setSelectedSeniority([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Seniority already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addEducationLevel = () => {
        if (selectedEducationLevel.length > 0) {
            const sysEducationLevel = systemOptionsState.options.educationLevel.map(eduLevel => eduLevel.name)
            const filteredEduLevel = removeDupValues(selectedEducationLevel, sysEducationLevel)
            const educationLevel = filteredEduLevel.map(educationLevel => ({ name: educationLevel }))

            if (educationLevel.length > 0) {
                dispatch(createEducation({ items: educationLevel }))
                    .then(response => {
                        console.log('response of error in education duplicate->', response)
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Education level added",
                            icon: 'success'
                        });
                        setSelectedEduLevel([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Education Level already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addMaritalStatus = () => {
        if (selectedMaritalStatus.length > 0) {
            const sysMaritalStatus = systemOptionsState.options.maritalStatus.map(maritalStatus => maritalStatus.name)
            const filteredMaritalStatus = removeDupValues(selectedMaritalStatus, sysMaritalStatus)
            const maritalStatus = filteredMaritalStatus.map(maritalStatus => ({ name: maritalStatus }))

            if (maritalStatus.length > 0) {
                dispatch(createMaritalStatus({ items: maritalStatus }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Marital Status added in system!",
                            icon: 'success'
                        });
                        setSelectedMaritalStatus([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Marital Status already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addRelationships = () => {
        if (selectedRelationships.length > 0) {
            const sysRelationships = systemOptionsState.options.relationship ? systemOptionsState.options.relationship.map(relationship => relationship.name) : []
            const filteredRelationships = removeDupValues(selectedRelationships, sysRelationships)
            const relationShip = filteredRelationships.map(relationShip => ({ name: relationShip }))

            if (relationShip.length > 0) {
                dispatch(createRelationships({ items: relationShip }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Relationships added in system!",
                            icon: 'success'
                        });
                        setSelectedRelationships([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Relationship already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addEmployment = () => {
        if (selectedEmployment.length > 0) {
            const sysEmployment = systemOptionsState.options.employment.map(employment => employment.name)
            const filteredEmployment = removeDupValues(selectedEmployment, sysEmployment)
            const employment = filteredEmployment.map(employment => ({ name: employment }))

            if (employment.length > 0) {
                dispatch(createEmployment({ items: employment }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Employment added in system",
                            icon: 'success'
                        });
                        setSeelectedEmployment([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Employment already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addIncomeRange = () => {

        



        if (incomeRange.start === '' && incomeRange.end === '' && dropDownCountry == '') {

        } else {
            const sysIncomeRange = systemOptionsState.options.incomeRange.map(range => range.name)
            if (sysIncomeRange.includes(`${incomeRange.start} - ${incomeRange.end}`)) {
                Swal({
                    title: "Already Present",
                    text: "Selected Income Range already present in system",
                    icon: 'info'
                });
            } 
            // else if (incomeRange.start >= incomeRange.end) {
            //     Swal({
            //         title: "Error",
            //         text: "Income range start can't be greater than income range end",
            //         icon: 'error'
            //     });
            // } 
            else {
                dispatch(createIncomeRanges({ items: [{ name: `${incomeRange.start} - ${incomeRange.end}` , countryName: dropDownCountry}] }))
                    .then(response => {
                        console.log(response)
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Income Range added in System",
                            icon: 'success'
                        });
                        setIncomeRange({})
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            }

        }
    }

    const addCountries = () => {
        if (selectedCountries.length > 0) {
            const sysCountries = systemOptionsState.options.countries.map(country => country.name)
            const filteredCountries = removeDupValues(selectedCountries, sysCountries)
            const countries = filteredCountries.map(countries => ({ name: countries }))
            if (countries.length > 0) {
                dispatch(createCountries({ items: countries }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Countries added in system!",
                            icon: 'success'
                        });
                        setSelectedCountries([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Countries already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    useEffect(() => {
        setOptions(systemOptionsState.options)
    }, [systemOptionsState])

    const addCities = () => {
        if (selectedCities.length > 0 && dropDownCountry !== '') {
            const sysCities = systemOptionsState.options.cities.map(city => city.name)
            const filteredCities = removeDupValues(selectedCities, sysCities)
            
            const cities = filteredCities.map(city => ({ name: city, countryName: dropDownCountry }))

            if (cities.length > 0) {
                dispatch(createCities({ items: cities }))
                    .then(response => {
                        console.log(response)
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Cities added in system",
                            icon: 'success'
                        });
                        setDropdowwnCountry('')
                        setSlectedCities([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Cities already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addBrands = () => {
        if (selectedBrands.length > 0) {
            const sysBrands = systemOptionsState.options.brands.map(brand => brand.name)
            const filteredBrands = removeDupValues(selectedBrands, sysBrands)
            const brand = filteredBrands.map(brand => ({ name: brand }))

            if (brand.length > 0) {
                dispatch(createBrand({ items: brand }))
                    .then(response => {
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Brands added in system!",
                            icon: 'success'
                        });
                        setSelectedBrands([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Brands already present in system",
                    icon: 'info'
                });
            }

        } else {

        }
    }

    const addInterests = () => {
        if (selectedInterests.length > 0) {
            const sysInterests = systemOptionsState.options.interests.map(interest => interest.name)
            const filteredInterests = removeDupValues(selectedInterests, sysInterests)
            const interest = filteredInterests.map(interest => ({ name: interest }))

             if (interest.length > 0) {
                dispatch(createInterests({ items: interest }))
                    .then(response => {
                       
                        Swal({
                            title: response.message ? response.message : "Added",
                            text: response.content ? response.content : "Interest added in system!",
                            icon: 'success'
                        });
                        setSelectedInterests([])
                        getSystemOptions()
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again!",
                            icon: 'error'
                        });
                    })
            } else {
                Swal({
                    title: "Already Present",
                    text: "Selected Interest already present in system",
                    icon: 'info'
                });
            }

         } 
         else {

          }
   }


    // adds options to the list when user press enter key
    const handleKeyDown = (e, selectedList, setSelectedList, value, setValue) => {
        var keyCode = (window.event) ? e.which : e.keyCode;
        if (keyCode === 13 && value !== '') {
            e.preventDefault();
            setSelectedList([...selectedList, value]);
            setValue('')
        }
        console.log(setSelectedList)
    };

    // Removes option from the list
    const handleRemove = (selectedList, valueToRemove, setList) => {
        const list = selectedList
        const index = selectedList.indexOf(valueToRemove)
        list.splice(index, 1)
        setList([...list])
    }

    return (
        <>
        
            <div className="admin_header">
                <PageHeader heading="Settings" img={Attachement} />
            </div>

            <Back />

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                
                <div className='row mt-3'>
                    {/* Cities */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Cities</div>
                            </nav>

                            <CardBody>
                                <div className='row '>
                                    <div className='col-6'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="city1">New Cities</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedCities.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedCities, item, setSlectedCities)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={city}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter city and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedCities, setSlectedCities, city, setCity)}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    id="city1"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup>
                                            <Label for="countryList">Country Select</Label>
                                            <Input type="select" name="countryList" id="countryList" value={dropDownCountry} className={ 'form-control valid'}
                                                onChange={(e) => setDropdowwnCountry(e.target.value)}>
                                                <option value=''>Select</option>
                                                {
                                                    options.countries && options.countries.map((country, index) => {
                                                        return <option key={index} value={country._id}>{country.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addCities}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="cities">System Cities</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.cities && options.cities.map((city, index) => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" key={index} style={{ backgroundColor: '#eee' }}>
                                                        <h6 style={{ fontWeight: '500' }}>{city.name}</h6>
                                                        <p className='m-0'>{city.countryName && city.countryName.name}</p>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Country */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Countries</div>
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="country1">New Countries</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {console.log("bahar",selectedCountries)}
                                                {selectedCountries.map(item => {
                                                   return <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedCountries, item, setSelectedCountries)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
})}
                                                <input
                                                    value={country}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter country and press enter" 
                                                    onKeyDown={(e) => handleKeyDown(e, selectedCountries, setSelectedCountries, country, setCountry)}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    id="country1"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addCountries}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Countries</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.countries && options.countries.map((country, index) => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" key={index} style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{country.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Income Ranges */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Income Ranges</div>
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Label for="incomeRange">New Income Range</Label>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup>
                                            <Label for="start">Income Range Start</Label>
                                            <Input type="text" value={incomeRange.start} placeholder='Income Range Start' id="start"
                                                onChange={(e) => setIncomeRange({ ...incomeRange, start: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup>
                                            <Label for="end">Income Range End</Label>
                                            <Input type="text" value={incomeRange.end} placeholder='Income Range End' id="end"
                                                onChange={(e) => setIncomeRange({ ...incomeRange, end: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup>
                                            <Label for="countryList">Country Select</Label>
                                            <Input type="select" name="countryList" id="countryList" value={dropDownCountry} className={ 'form-control valid'}
                                                onChange={(e) => setDropdowwnCountry(e.target.value)}>
                                                <option value=''>Select</option>
                                                {
                                                    options.countries && options.countries.map((country, index) => {
                                                        return <option key={index} value={country._id}>{country.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addIncomeRange}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Income Ranges</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.incomeRange && options.incomeRange.map((incomeRange, index) => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" key={index} style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{incomeRange.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>


                    {/* Employement */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Employment</div>
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="employment">New Employment Options</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedEmployment.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedEmployment, item, setSeelectedEmployment)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={employment}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter emplyement and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedEmployment, setSeelectedEmployment, employment, setEmployment)}
                                                    onChange={(e) => setEmployment(e.target.value)}
                                                    id="employment"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addEmployment}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Employment</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.employment && options.employment.map(employ => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{employ.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Marital Status */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Marital Status</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="employment">New Marital Status</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedMaritalStatus.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedMaritalStatus, item, setSelectedMaritalStatus)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={maritalStatus}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter marital status and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedMaritalStatus, setSelectedMaritalStatus, maritalStatus, setMaritalStatus)}
                                                    onChange={(e) => setMaritalStatus(e.target.value)}
                                                    id="maritalStatus"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addMaritalStatus}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Countries</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.maritalStatus && options.maritalStatus.map(maritalStatus => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{maritalStatus.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                     {/* Relationships */}
                     <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Relationships</div>
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="relationship">New Relationships</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedRelationships.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedRelationships, item, setSelectedRelationships)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={relationShip}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter marital status and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedRelationships, setSelectedRelationships, relationShip, setRelationShip)}
                                                    onChange={(e) => setRelationShip(e.target.value)}
                                                    id="relationship"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addRelationships}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Countries</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.relationship && options.relationship.map(relationShip => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{relationShip.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* EducationLevel */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Education Level</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="education">New Education Level</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedEducationLevel.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedEducationLevel, item, setSelectedEduLevel)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={educationLevel}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter eduvation level and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedEducationLevel, setSelectedEduLevel, educationLevel, setEducationLevel)}
                                                    onChange={(e) => setEducationLevel(e.target.value)}
                                                    id="education"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    {/* <div className='col-6'>
                                        <FormGroup>
                                            <Label for="countryList">Country Select</Label>
                                            <Input type="select" name="countryList" id="countryList" value={dropDownCountry}
                                                onChange={(e) => setDropdowwnCountry(e.target.value)}>
                                                <option value=''>Select</option>
                                                {
                                                    options.countries && options.countries.map((country, index) => {
                                                        return <option key={index} value={country._id}>{country.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </div> */}
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addEducationLevel}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Education Level</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.educationLevel && options.educationLevel.map(educationLevel => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{educationLevel.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Seniority */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Seniority</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="seniority">New Seniority</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedSeniority.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedSeniority, item, setSelectedSeniority)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={seniority}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter seniority and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedSeniority, setSelectedSeniority, seniority, setSeniority)}
                                                    onChange={(e) => setSeniority(e.target.value)}
                                                    id="seniority"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addSeniority}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Seniority</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.seniority && options.seniority.map(seniority => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{seniority.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* Template Variable */}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Template Variable</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-6'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="tempVarName">Template Variable Name</Label>
                                            <Input type="text" value={templateVar.name} placeholder='Variable Name' id="tempVarName"
                                                onChange={(e) => setTemplateVar({ ...templateVar, name: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="tempVarVal">Template Variable Value</Label>
                                            <Input type="text" value={templateVar.value} placeholder='Variable Value' id="tempVarVal"
                                                onChange={(e) => setTemplateVar({ ...templateVar, appendable: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addTemplateVar}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Template Variable</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.templateVariables && options.templateVariables.map(templateVar => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{templateVar.name}</h6>
                                                        <p className='m-0'>{templateVar.appendable && templateVar.appendable}</p>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>


                    {/* Brands */}
                     <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Brands</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="employment">New Brands</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedBrands.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedBrands, item, setSelectedBrands)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={brands}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter brands and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedBrands, setSelectedBrands, brands, setBrands)}
                                                    onChange={(e) => setBrands(e.target.value)}
                                                    id="maritalStatus"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addBrands}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Brands</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.brands && options.brands.map(brands => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{brands.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    {/* points */}
                    <div className='col-md-12 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Points System</div>
                                {/* icon */}
                            </nav>

                            <CardBody >
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="badgeName">Badge</Label>
                                            <Input type="text" value={points.badge} placeholder='Enter Badge Name' id="badgeName"
                                                onChange={(e) => setPoints({ ...points, badge: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-4'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="pointsVal">Points</Label>
                                            <Input type="text" value={points.value} placeholder='Enter Points Value' id="pointsVal"
                                                onChange={(e) => setPoints({ ...points, points: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="currentStatus">Message</Label>
                                            <Input type="text" value={points.value} placeholder='Enter Message' id="currentStatus"
                                                onChange={(e) => setPoints({ ...points, currentStatus: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead custom_drop'>
                                            <Label for="nextMilestone">Achievement Message</Label>
                                            <Input type="text" value={points.value} placeholder='Enter Achievment Message' id="nextMilestone"
                                                onChange={(e) => setPoints({ ...points, nextMilestone: e.target.value })} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addPoints}>Save</button>
                                    </div>

                                    <div className='col-12 '>
                                        <Label for="sysCountries">Points System</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.points && options.points.map(points => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                         <AiFillDelete size={18} style={{cursor:'pointer'}} onClick={(e)=>{deletePoints(points._id)}}  />
                                                        <h5 className='p-0 my-1' style={{ fontWeight: '500' }}>{points.badge}</h5>
                                                        <h6 className='m-0' style={{ fontWeight: '500' }}>{points.points && points.points}</h6>
                                                        <h6 className='m-0'>{points.currentStatus && points.currentStatus}</h6>
                                                        <h6 className='m-0'>{points.nextMilestone && points.nextMilestone}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    {/*Interests*/}
                    <div className='col-md-6 col-sm-12'>
                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Area of Interests</div>
                                {/* icon */}
                            </nav>

                            <CardBody>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FormGroup className='light_typeahead __settings__'>
                                            <Label for="interests">Interests</Label>
                                            <div className="input-group city-settings-input" style={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}>
                                                {selectedInterests.map(item => (
                                                    <div className="rbt-token m-1 rbt-token-removeable" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="close rbt-close rbt-token-remove-button"
                                                            onClick={() => handleRemove(selectedInterests, item, setSelectedInterests)}
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                ))}
                                                <input
                                                    value={interests}
                                                    style={{ border: 'none' }}
                                                    className="rbt-input-multi rbt-input-main city-settings-input form-control"
                                                    placeholder="Enter area of interests and press enter"
                                                    onKeyDown={(e) => handleKeyDown(e, selectedInterests, setSelectedInterests, interests, setInterests)}
                                                    onChange={(e) => setInterests(e.target.value)}
                                                    id="interest"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button className={`btn ${styles.bg_blue} text-white`} onClick={addInterests}>Save</button>
                                    </div>
                                    <div className='col-12 '>
                                        <Label for="sysCountries">System Area of Interests</Label>
                                        <div style={{ border: '1px solid rgba(32, 39, 140, 0.125)', borderRadius: '0.25rem' }}>
                                            {
                                                options.interests && options.interests.map(interests => {
                                                    return <div className="pr-5 pl-2 mx-2 my-1 badge text-secondary text-capitalize text-left" style={{ backgroundColor: '#eee' }}>
                                                        <h6 className='p-0 my-1' style={{ fontWeight: '500' }}>{interests.name}</h6>
                                                    </div>
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                </div>
            </LoadingOverlay>



        </>
    )

}

export default Settings;