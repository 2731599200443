import { EMAIL_TEMPLATE_AVAILABLE, EMPTY_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_DETAILS, LOADING_START, LOADING_STOP } from '../actions/types';

let emailTemplateDataState = { emailTemplates:[], totalPages:0, currentPage: 1, loading:false, emailTemplateDetails: {} };
export default function (state = emailTemplateDataState, action) {
    switch (action.type) {
        case LOADING_START:
            return {...state, loading:true};
        case LOADING_STOP:
            return {...state, loading:false};
        case EMAIL_TEMPLATE_AVAILABLE:
            if (state.emailTemplates.length === 0) {
                return {
                    ...state,
                    emailTemplates: action.payload.content.templates,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.emailTemplates =  state.emailTemplates.concat(action.payload.content.templates);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    emailTemplates: concatResult.emailTemplates,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case EMPTY_EMAIL_TEMPLATE:
            return {...state, emailTemplates: [], totalPages: 0, currentPage: 1 };
        case GET_EMAIL_TEMPLATE_DETAILS:
            return {...state, emailTemplateDetails: action.payload.content }
        default:
            return state;
    }
}
