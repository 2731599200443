import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import axios from 'axios'
import Swal from'sweetalert'
import { BACK_SERVER_URL } from '../../config/server'
// import { useQuery } from 'react-router'

const AccountVerification = () => {

    let { search } = useLocation();
    const history = useNavigate()
    const query = new URLSearchParams(window.decodeURIComponent(search));
    useEffect(() => {
        axios.put(`${BACK_SERVER_URL}api/users/verify?token=` + query.get("token"))
        .then(response => {
            Swal({
                title: "Account Verified",
                text: " Your account has been verified. ",
                icon: 'success'
            })
            .then( () => history('/login'));
        })
        .catch( err => {
            Swal({
                title: "Account Verified",
                text: " Your account has been verified. ",
                icon: 'success'
            })
            .then( () => history('/login'));
            // Swal({
            //     title: err.response ? err.response.data.name : "Error",
            //     text: err.response ? err.response.data.content : "Something went wrong",
            //     icon: 'error'
            // })
        })
    }, [])

    return (
        <p> 
        </p>
    )
}

export default AccountVerification
