import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from '../../css/rstyling.module.css'


// ***************** This Component is used for showing / uploading new Images and Videos in Modal *****************

const ImageModal = (props) => {

  const toggle = () => {
    props.setModal(props.index, false)
  }

  const removeImage = () => {
    props.delImage(props.index)
    props.setModal(props.index, false)
  }

  return (

    <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal ${props.index}`} className="modal-dialog-centered shadow-none">
      <ModalHeader className="bg-white" toggle={toggle}>Image Viewer</ModalHeader>
      <ModalBody>
        {
          props.type && props.type === 'video' ?
            <video width="465px" controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
              <source src={props.image} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            :
            <img src={props.image} alt="" width="465px" height="465px" />
        }
      </ModalBody>
      <ModalFooter className="bg-white">
        <label for={`img-upload${props.index}`} className={`${styles.bg_blue} text-white btn btn-light mt-2`} style={{ padding: ' 0.53rem 0.6rem', lineHeight: '0.9rem' }}>
          Upload New Image
        </label>
        <input type="file" multiple={false} accept={".jpg, .jpeg, .png"} className="form-control" style={{ display: 'none' }} id={`img-upload${props.index}`}
          onChange={(e) => props.changeImage(e.target.files, props.index)}
        />
        <Button className={`btn-light text-muted`} style={{ border: '1px solid #6c757d' }} onClick={removeImage}>Remove Image</Button>

      </ModalFooter>
    </Modal>

  )
}

export default ImageModal
