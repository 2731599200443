import React, { Fragment, useEffect } from "react";
import Tabs from 'react-responsive-tabs';
import { useNavigate, useParams, useLocation } from "react-router";
import { TransitionGroup } from 'react-transition-group';
import PageHeader from "../../../../../assets/components/pageheader/PageHeader";
import Projecticon from '../../../../../assets/images/project_icon.png'
import Files from "./Files";
import Graphcharts from "./Graphcharts";
import ScreenerAnalytics from "./Stream/ScreenerAnalytics";
import Stream from "./Stream/Stream";
import WordCloud from "./WordCloud";
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import Grouping from "../../Grouping";
import { exportPostResponses, exportSurveyResponses } from "../../../../../actions/activities_reply_actions";
import Swal from "sweetalert";
import { useDispatch } from "react-redux";


// ********** Only Survey, Diary and Forum Activity Types has analytics ***************
// ********* Online Inperson Interview Focus Group Discussion doesn't have analytics screen *******

const Analytics = () => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    const dispatch = useDispatch()
    const path = currentPath.slice(0, currentPath.lastIndexOf('/'))

    const surveyTabsContent = [
        {
            title: 'Stream',
            content: <ScreenerAnalytics /> 
        },
        {
            title: 'Word Cloud',
            content: <WordCloud />
        },
        {
            title: 'Files',
            content: <Files />
        },
        {
            title: 'Graph Charts',
            content: <Graphcharts />
        },
        {
            title: 'Groupings',
            content: <Grouping />
        },
        
    ];

    const quickSurveyTabsContent = [
        {
            title: 'Stream',
            content: <ScreenerAnalytics />
        },
        {
            title: 'Word Cloud',
            content: <WordCloud />
        },
        {
            title: 'Files',
            content: <Files />
        },
        {
            title: 'Graph Charts',
            content: <Graphcharts />
        }

    ];

    // for diary and forum activity type
    const tabsContent = [
        {
            title: 'Stream',
            content: <Stream />
        },
        {
            title: 'Word Cloud',
            content: <WordCloud />
        },
        {
            title: 'Files',
            content: <Files />
        }
    ];

    function getTabs() {
        if (path === "/admin/project/survey-analytics/" + params.projectid) {
            return surveyTabsContent.map((tab, index) => ({
                title: tab.title,
                getContent: () => tab.content,
                key: index,
            }));
        } else if (path === "/admin/minisurvey-analytics") {
            return quickSurveyTabsContent.map((tab, index) => ({
                title: tab.title,
                getContent: () => tab.content,
                key: index,
            }));
        } else {
            return tabsContent.map((tab, index) => ({
                title: tab.title,
                getContent: () => tab.content,
                key: index,
            }));
        }

    }

    // For changing page header in different tab
    const getPageTitle = () => {
        switch (path) {
            case "/admin/project/survey-analytics/" + params.projectid:
                return <PageHeader heading="Survey Analytics" img={Projecticon}
                    btnText="Export"
                    btnClick={() => exportSurveyStream()}
                />
            case "/admin/minisurvey-analytics":
                return <PageHeader heading="Quick Survey Analytics" img={Projecticon}
                    btnText="Export"
                    btnClick={() => exportSurveyStream()}
                />
            case "/admin/generalforum-analytics":
                return <PageHeader heading="General Forum Analytics" img={Projecticon}
                    btnText="Export"
                    btnClick={() => exportPostStream()}
                />
            case "/admin/project/journal-analytics/" + params.projectid:
                return <PageHeader heading="Journal Analytics" img={Projecticon}
                    btnText="Export"
                    btnClick={() => exportPostStream()}
                />
            case "/admin/project/forum-analytics/" + params.projectid:
                return <PageHeader heading="Forum Analytics" img={Projecticon}
                    btnText="Export"
                    btnClick={() => exportPostStream()}
                />
            default:
                break;
        }
    }

    // for exporting Dairy and Forums stream
    const exportPostStream = () => {
        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });

        dispatch(exportPostResponses(params.activityid))
            .then((response) => {

                const url = response.payload.content.link;
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();

                Swal.stopLoading()
                Swal.close()
            })
            .catch(err => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: "Error",
                    text: "Please try again",
                    type: "error"
                })
            })
    }

  
    // for exporting survey stream
    const exportSurveyStream = () => {

        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        
        dispatch(exportSurveyResponses(params.activityid))
            .then((response) => {
               
console.log('response from exporting file ->', response.payload.content)
                const url = response.payload.content.link;
                console.log('url 1->', url)
               const url2 = url.replace(/(?<!D):\\/g, (match) => match.replace(/\\/g, ''));
               console.log('url 2->', url2)
                const link = document.createElement('a');
                link.href = url2;
                link.download = response.payload.content.fileName;
                document.body.appendChild(link);
console.log('final link ->', link)
                
                link.click();
            
                // Removing the anchor element from the DOM
                document.body.removeChild(link);
                Swal.stopLoading()
                Swal.close()
            })
            .catch(err => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: "Error",
                    text: "Please try again",
                    type: "error"
                })
            })
    }

   

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            {getPageTitle()}
            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>
            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} />
            </div>



        </TransitionGroup>
    )
}

export default Analytics


