import React from 'react'
import { Card } from "reactstrap";
import bg2 from "../../../assets/utils/images/dropdown-header/city2.jpg";
import styles from '../../../assets/css/rstyling.module.css'
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Search from '../../../assets/images/search_icon.png'
import { TransitionGroup } from 'react-transition-group';
import ResizeDetector from "react-resize-detector";
import ScreenerAttachment from './Attachments/ScreenerAttachment';
import ResearchAttachment from './Attachments/ResearchAttachment';
import ScreenerReplyAttachment from './Attachments/ScreenerReplyAttachment';
import RecordingAttachment from './Attachments/RecordingAttachment';
const Attachment = () => {

  return (

    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
          transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

          <div className="card_radius">
            <Card className="mb-0 mt-3">
              <div className="dropdown-menu-header">
                <div className="dropdown-menu-header-inner bg-dark">
                  <div className="menu-header-image"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}>
                  </div>
                  <div className="menu-header-content" style={{ display: 'flex' }}>
                    <div className="menu-header-content-left mr-auto">
                      <div className="menu-header-title text-white text-left" style={{ fontWeight: '500' }}>Research attachments</div>
                    </div>
                    <div className="menu-header-content-right">
                      <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.3rem' }}>
                        <div className="input-group input-group-sm w-75">
                          <div className="input-group-prepend border-0">
                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                          </div>
                          <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              {/* Tabs */}
              <div className={width > 708 ? "tab_blue tab_center" : "tab_blue"}>
                <Tabs defaultActiveKey="1" renderTabBar={() => <ScrollableInkTabBar color="primary" style={{ margin: width >= 884 && '0 1rem' }} />} renderTabContent={() => <TabContent />} justified >
                  <TabPane tab="Research" key="1">
                    <ResearchAttachment  />
                  </TabPane>
                  <TabPane tab="Screener" key="2">
                    <ScreenerAttachment  />
                  </TabPane>
                  <TabPane tab="Screener Response" key="3">
                    <ScreenerReplyAttachment  />
                  </TabPane>
                  <TabPane tab="Recordings" key="6">
                    <RecordingAttachment  />
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </div>
        </TransitionGroup>
      )}
    />
  )
}

export default Attachment
