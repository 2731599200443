import { ACTIVE_PROJECTS_FOR_DASHBOARD } from "../actions/types";

let dashboardDataState = { activeProjects: [], totalPages: 0, currentPage: 1 };

export default function (state = dashboardDataState, action) {

    switch (action.type) {
        case ACTIVE_PROJECTS_FOR_DASHBOARD:
            if (state.activeProjects.length === 0) {
                return {
                    ...state,
                    activeProjects: action.payload.content.projects,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.activeProjects = state.activeProjects.concat(action.payload.content.projects);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    activeProjects: concatResult.activeProjects,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

       
        default:
            return state;
    }
}

