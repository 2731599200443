import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import {  AVAILABLE_ACTIVITY_RECORDINGS, CREATE_RECORDING, DELETE_RECORDING, EDIT_RECORDING, GET_RECORDING_DETAILS } from './types.js';

// ****************  These apis are used in ADMIN & RESEARCHER END inside view of Activity Section in Projects  *********************


// Returns an array of recordings of specific activity to display in listing
export const getActivityRecordings = (data) => {

    let url = `${BACK_SERVER_URL}api/recordings/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_RECORDINGS,
        payload: request
    }
}

// Uploads Recording on the server
export const createRecording = (data) => {

    let url = `${BACK_SERVER_URL}api/recordings`;

    const request = axios.post(url, data)
        .then(response => response.data);

        
    return {
        type: CREATE_RECORDING,
        payload: request
    }
}

// Returns an object containing recording details against the id we pass in the param
export const getRecordingDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/recordings/${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_RECORDING_DETAILS,
        payload: request
    }
}

// updates recording against the id we pass in the param
export const editRecording = (id, data) => {

    let url = `${BACK_SERVER_URL}api/recordings/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_RECORDING,
        payload: request
    }
}

// deletes recording against the id we pass in the param
export const deleteRecording = (id) => {

    let url = `${BACK_SERVER_URL}api/recordings/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_RECORDING,
        payload: request
    }
}
