import React, { useState } from 'react';

import Tabs from 'react-responsive-tabs';
import { useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'

import EmailMaanagement from './EmailManagement';
import EmailMarketing from './EmailMarketing';

const Email = () => {

    const history = useNavigate('')
    const tabsContent = [
        {
            title: 'Email Marketing',
            content: <EmailMarketing />
        },
        {
            title: 'Email Management',
            content: <EmailMaanagement />
        },
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    return (
        <>
            <div className="admin_header">
                <PageHeader heading="Emails" img={TemplateIcon} btnText="Create Template" btnClick={() => history("/admin/create-email-template")} />
            </div>
            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} />
            </div>
        </>
    )

}

export default Email;