import { LOGGED_IN_USER_DATA, LOGIN_USER } from "../actions/types";

export default function(state={ login_user: {}, logged_in_user_data:{}}, action) {
    switch(action.type) {
        case LOGIN_USER: 
            return { ...state, login_user: action.payload}
        case LOGGED_IN_USER_DATA:
            return { ...state, logged_in_user_data: action.payload.content }

         
        // case RESET_PASSWORD: 
        //     return { ...state, reset_password: action.payload }

        // case CHANGE_PASSWORD: 
        //     return { ...state, change_password: action.payload }

        default:
            return state;
    }
}