import {  BROADCAST_MSG_AVAILABLE, EMPTY_BROADCAST_MSG, GET_BROADCAST_MSG_DETAILS } from '../actions/types';

let broadcastMsgDataState = { broadcastMsgs:[], totalPages:0, currentPage: 1, broadcastMsgDetails: {} };
export default function (state = broadcastMsgDataState, action) {
    switch (action.type) {
        case BROADCAST_MSG_AVAILABLE:
            if (state.broadcastMsgs.length === 0) {
                return {
                    ...state,
                    broadcastMsgs: action.payload.content,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.broadcastMsgs =  state.broadcastMsgs.concat(action.payload.content);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    broadcastMsgs: concatResult.broadcastMsgs,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case EMPTY_BROADCAST_MSG:
            return {...state, broadcastMsgs: [], totalPages: 0, currentPage: 1 };
        case GET_BROADCAST_MSG_DETAILS:
            return {...state, broadcastMsgDetails: action.payload.content }
        default:
            return state;
    }
}
