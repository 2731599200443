import { PARTICIPANT_AVAILABLE, PROJECT_PARTICIPANTS } from '../actions/types';

let projectParticipantState = { participants:[], participantListing:[], totalPages:0, currentPage: 1 };
export default function (state = projectParticipantState, action) {
    switch (action.type) {
        case PARTICIPANT_AVAILABLE:
            if (state.participantListing.length === 0) {
                return {
                    ...state,
                    participantListing: action.payload.content.participants,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.participantListing = state.participantListing.concat(action.payload.content.participants);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    participantListing: concatResult.participantListing,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

        case PROJECT_PARTICIPANTS:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    participants: []
                }
            } else {
                return {
                    ...state,
                    participants: action.payload.content,
                }
            }
               
        default:
            return state;
    }
}
