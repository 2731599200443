import axios from 'axios';
import { GET_ALL_OPTIONS, GET_SPECIFIC_OPTION, CREATE_CITIES, CREATE_COUNTRIES, CREATE_EDUCATION_LEVEL, CREATE_EMPLOYMENT, CREATE_INCOME_RANGES, CREATE_MARITAL_STATUS, CREATE_SENIORITIES, CREATE_TEMPLATE_VARIABLE, CREATE_BRANDS, CREATE_RELATIONSHIPS, CREATE_POINTS_SYSTEM, DELETE_POINTS_SYSTEM, CITY_OPTIONS, INCOME_OPTIONS,CREATE_INTERESTS, GET_INTERESTS } from './types';
import { BACK_SERVER_URL } from '../config/server.js';

// ********** Used in both RESEARCHER and ADMIN End **********
// for getting specific option list based on the parameter you pass
// to show option list in dropdown in creating Broadcast msg, create project, create temp, create profile,
// Takes array of options in params
// Returns object containing arrays of specific options
// @example - if you pass ["Cities", "Countries"] as a param it will return an array of objects for cities and countries
export const getSpecificOption = (option) => {

    let url = `${BACK_SERVER_URL}api/options/specific`;

    const request = axios.post(url, option)
        .then(response => response.data);

    return {
        type: GET_SPECIFIC_OPTION,
        payload: request
    }
}

// used for getting all options list ( countries, cities, education, seniority, income, employment, marital status, brands, relation, temp var )
// used in settings screen and users management of ADMIN SECTION to list down the options
export const getAllOptions = () => {

    let url = `${BACK_SERVER_URL}api/options`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_ALL_OPTIONS,
        payload: request
    }
}


// *********  Below apis are used on the SETTINGS screen of ADMIN ************ 
// for adding different countries, cities, education level, seniority, 
// income ranges, employment options, martial status, brands, relationships and template variables in the system

export const createCountries = (countriesData) => {

    let url = `${BACK_SERVER_URL}api/options/country`;

    const request = axios.post(url, countriesData)
        .then(response => response.data);

    return {
        type: CREATE_COUNTRIES,
        payload: request
    }
}

export const createCities = (citiesData) => {

    let url = `${BACK_SERVER_URL}api/options/city`;

    const request = axios.post(url, citiesData)
        .then(response => response.data);

    return {
        type: CREATE_CITIES,
        payload: request
    }
}
export const cityOptions =(country)=>{

  
    let url = `${BACK_SERVER_URL}api/options/cityOptions`;

    const request = axios.post(url, country)
        .then(response => response.data);

    return {
        type: CITY_OPTIONS,
        payload: request
    }
}
export const projectCityOptions =(country)=>{

  
    let url = `${BACK_SERVER_URL}api/options/projectCityOptions`;

    const request = axios.post(url, country)
        .then(response => response.data);

    return {
        type: CITY_OPTIONS,
        payload: request
    }
}
export const incomeOptions =(country)=>{

  
    let url = `${BACK_SERVER_URL}api/options/incomeOptions`;

    const request = axios.post(url, country)
        .then(response => response.data);

    return {
        type: INCOME_OPTIONS,
        payload: request
    }
}

export const createEducation = (data) => {

    let url = `${BACK_SERVER_URL}api/options/education`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_EDUCATION_LEVEL,
        payload: request
    }
}

export const createSeniorities = (data) => {

    let url = `${BACK_SERVER_URL}api/options/seniority`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_SENIORITIES,
        payload: request
    }
}

export const createIncomeRanges = (data) => {
console.log('income ranges data->', data)
    let url = `${BACK_SERVER_URL}api/options/income`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_INCOME_RANGES,
        payload: request
    }
}

export const createEmployment = (data) => {

    let url = `${BACK_SERVER_URL}api/options/employment`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_EMPLOYMENT,
        payload: request
    }
}

export const createMaritalStatus = (data) => {

    let url = `${BACK_SERVER_URL}api/options/marital-status`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_MARITAL_STATUS,
        payload: request
    }
}

export const createTemplateVar = (data) => {
    
    let url = `${BACK_SERVER_URL}api/options/template-variables`;

    const request = axios.post(url, data)
        .then(response => response.data);
     
    return {
        type: CREATE_TEMPLATE_VARIABLE,
        payload: request
    }
}

export const createPoints = (data)=>{
    // reached here
    console.log('data->', data)
    let url = `${BACK_SERVER_URL}api/options/points`;

    const request = axios.post(url, data)
    .then(response => response.data)

    return {
        type: CREATE_POINTS_SYSTEM,
        payload: request
    }
}

export const deletePointsSystem = (id)=>{
    // reached here
    
    let url = `${BACK_SERVER_URL}api/options/points/delete/${id}`;

    const request = axios.delete(url)
    .then(response => response.data)

    return {
        type: DELETE_POINTS_SYSTEM,
        payload: request
    }
}


export const createBrand = (data) => {

    let url = `${BACK_SERVER_URL}api/options/brand`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_BRANDS,
        payload: request
    }
}

export const createInterests = (data) => {
console.log('interest frontend->', data)
    let url = `${BACK_SERVER_URL}api/options/interest`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_INTERESTS,
        payload: request
    }
}
export const getAllInterests = (id) => {
   
        let url = `${BACK_SERVER_URL}api/options/getAllInterests/${id}`;
    
        const request = axios.get(url, id)
            .then(response => response.data);
    
        return {
            type: GET_INTERESTS,
            payload: request
        }
    }

export const createRelationships = (data) => {

    let url = `${BACK_SERVER_URL}api/options/relationship`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_RELATIONSHIPS,
        payload: request
    }
}
