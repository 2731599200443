import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Nav from "../AppNav/Nav/Nav";

import { TransitionGroup } from 'react-transition-group';

import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

import styles from "../../assets/css/styling.module.css";

class AppSidebar extends Component {
	state = {};
navigate = this.props.navigate
	toggleMobileSidebar = () => {
		let { enableMobileMenu, setEnableMobileMenu } = this.props;
		setEnableMobileMenu(!enableMobileMenu);
	};

	render() {
		let {
			backgroundColor,
			enableBackgroundImage,
			enableSidebarShadow,
			backgroundImage,
			backgroundImageOpacity,
			width
		} = this.props;

		return (
			<Fragment>
				
				<div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
				<TransitionGroup component="div"
					className={cx("app-sidebar", backgroundColor, {
						"sidebar-shadow": enableSidebarShadow,
					})}
					style={width > 1250 ? { marginTop: 0, paddingTop: 90, borderRight: 'none', padding: '90px 2em 0px 2em' } : {}}
					transitionname="SidebarAnimation" transitionAppear={true} transitionAppearTimeout={1500}
					transitionEnter={false} transitionLeave={false}>
					{/* <HeaderLogo /> */}

					{/* Sidebar Logo */}
					{width > 1250 && (
						<div className={styles.logo_container}>
							<div className={styles.sidebar_logo}></div>
						</div>
					)}

					<PerfectScrollbar>
						<div className="app-sidebar__inner px-0 mt-3">
							<Nav navigate={this.navigate} />
						</div>
					</PerfectScrollbar>
					<div className={cx("app-sidebar-bg", backgroundImageOpacity)}
						style={{
							backgroundImage: enableBackgroundImage
								? "url(" + backgroundImage + ")"
								: null,
						}}>
					</div>
				</TransitionGroup>
			</Fragment>

		);
	}
}

const mapStateToProps = (state) => ({
	enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
	enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	backgroundColor: state.ThemeOptions.backgroundColor,
	backgroundImage: state.ThemeOptions.backgroundImage,
	backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
