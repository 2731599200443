import React, { useEffect, useState } from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import styles from '../../assets/css/rstyling.module.css';
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../actions/auth_actions';
import SecureLS from 'secure-ls';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { signupPhone } from '../../actions/auth_actions';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useLocation, useParams} from 'react-router-dom';

export default function PhoneExternalSurveys2() {



    const [phone, setPhone] = useState('');
    const [simNetwork, setSimNetwork] = useState('');
    const dispatch = useDispatch()
    let { search } = useLocation();
    const history = useNavigate();
    const ls = new SecureLS()
    const{ActivityId, UserId} = useParams()

    const setPhoneAndNetwork = (phone, countryName) => {

        let splitedPhone = phone.substring(2, 4);

        if (countryName === 'Pakistan')
            switch (splitedPhone) {
                case '30':
                    setSimNetwork('Jazz');
                    break;

                case '31':
                    setSimNetwork('Zong');
                    break;

                case '32':
                    setSimNetwork('Warid');
                    break;

                case '33':
                    setSimNetwork('Ufone');
                    break;

                case '34':
                    setSimNetwork('Telenor');
                    break;

                default:
                    setSimNetwork('');
                    break;

            }

        // swtPhoneNetwork({ ...userData, phone: phone })

    }

    const [userData, setUserData] = useState({
        
        phone: '',
    })

 
 
 
    const onSignUp = (values) => {
       
       

        const phoneData ={
         userId: UserId,
         phone: values
        }
         dispatch(signupPhone(phoneData))
          
             .then(response => {
                 //history(`/otp/${ActivityId}/${UserId}`)
 
                 if(response.payload.content.status == 'partially_complete'){history(`/setup_profile/${ActivityId}`)}
                 if(response.payload.content.status == 'complete'){
                    
                    //history(`/sharable-quick-survey/${ActivityId}/${UserId}`)
                    if(ActivityId === '66a0c91fcdeb83aa15887497'){
                        history(`/oula/${UserId}`)
                        //history(`/sharable-quick-survey/${ActivityId}/${response.payload.content.userId}`)
                        } 
                        else{
                            history(`/sharable-quick-survey/${ActivityId}/${UserId}`)
                        }
                 //temperory oula route
                 //history('/oula')
                }  
                 Swal({
                     title: "Success",
                     text: "Phone number has been added",
                     icon: 'success'
                 })
             })
             .catch(err => {
                 
                 if (err.response.data.name === "Duplication Error") {
                     Swal({
                         title: "Error",
                         text: "Phone Number already exists.",
                         icon: 'error'
                     });
                 } else {
                     Swal({
                         title: err.response ? err.response.data.name : "Error",
                         text: err.response ? err.response.data.content : "Please try again!",
                         icon: 'error'
                     });
                 }
 
             })
             
             //history('/otp')
         
 
            
     }



    return (
        <div className="container-fluid p-2 px-4">
            <div className='row justify-content-center'>
                <div className={`row p-2 ${styles3.login_container}`}>

                    <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                        <div className={`${styles3.login_img}`}
                            style={{
                                backgroundImage: "url(" + login_bg_new + ")",
                            }} />


                    </div>

                    <div className="col-sm-7 col-12 bg-white align-self-center">
                        <div className='row justify-content-center'>
                            <div className={`${styles3.login_section}`}>
                                {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                <div className=" container" >



                                    <div className="container text-center">
                                        <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                        <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop: '50px' }}>Phone Number Required.</p>
                                        <p style={{ color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal' }}>Please enter your phone number below for our record.</p>

                                        <Formik
                                            initialValues={userData}
                                            enableReinitialize={true}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setSubmitting(true)
                                                onSignUp(values)


                                            }}


                                            const validationSchema={Yup.object().shape({

                                                phone: Yup.string().required("Phone Number is required"),

                                            })}


                                        >
                                            {props => {
                                                const {
                                                    values,
                                                    touched,
                                                    errors,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    setFieldValue
                                                } = props;

                                                return (
                                                    <div>


                                                        <form autoComplete="off" onSubmit={handleSubmit}>
                                                            <div>

                                                                <PhoneInput
                                                                    inputStyle={{ width: '100%', height: '38px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                    country={'pk'}
                                                                    value={phone}
                                                                    onChange={(phone, country) => {
                                                                        setPhoneAndNetwork(phone, country.name);
                                                                        setFieldValue('phone', phone)
                                                                    }}
                                                                    enableSearch={true}

                                                                />
                                                                {errors.phone && touched.phone && (
                                                                    <small className='text-danger'>
                                                                        {errors.phone}
                                                                    </small>
                                                                )}
                                                            </div>





                                                            <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} >
                                                                Submit Phone Number
                                                            </button>
                                                        </form>

                                                    </div>
                                                )

                                            }}
                                        </Formik>









                                    </div>
                                </div>
                            </div>
                        </div>










                    </div>
                </div>
            </div>
        </div>
    )
}