import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../../../../../assets/css/styling.module.css';
import styles1 from '../../../../../assets/css/rstyling.module.css';
import Swal from 'sweetalert'
import { createComments } from '../../../../../actions/activities_reply_actions';


// ************* This component is used in ADMIN &RESEARCHER END for writing comments in Journal Activity Screen *****************

const Write_Comment = (props) => {


    const [comment, setComment] = useState('')
    const dispatch = useDispatch()

    const onSubmitComment = (postId) => {
        dispatch(createComments({ post: postId, description: comment }))
            .then((response) => {
                // props.getReplies()
                setComment('')
                props.getComments(postId, true, 1 , 10)
                // props.getForumReply(1, 5)
                Swal({
                    title: response.payload.title ? response.payload.title : "Created",
                    text: response.payload.message ? response.payload.message : 'Comment Created',
                    icon: 'success'
                })
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    return (

        <div className="row px-3 my-2">

            <div className="d-inline-flex align-self-center">
                <div className="rounded-circle" style={{ backgroundColor: "#e8e6e5", width: '38px', height: '38px' }}>
                </div>
            </div>

            <div className="col align-self-center">

                <div className="input-group" style={{ border: '2px solid #ddd', borderRadius: '8px', backgroundColor: "#f7f6f2", padding: '0.2rem' }}>
                    <input type="text"
                        className={"form-control " + styles.reply_comment_textbox}
                        style={{ border: 0, boxShadow: 'none', backgroundColor: 'transparent' }}
                        placeholder={"Write Comment"}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    {/* <div className="input-group-append">
                        <span className={styles.upload_media_icon}></span>
                    </div> */}
                </div>

            </div>

            <div className="d-inline-flex align-self-center">
                <button className={styles1.sent_blue_btn} onClick={() => onSubmitComment(props.postId)}>
                    <span className={styles.sent_icon}></span>
                </button>
            </div>

        </div>

    )

}

export default Write_Comment;