import ThemeOptions from "./ThemeOptions";
import Project from "./project_reducers";
import Referral from "./referral_reducer";
import EmailTemplates from './email_template_reducers'
import SmsTemplates from './sms_template_reducers'
import BroadcastMessages from './broadcast_msg_reducers'
import ProjectParticipants from './particiapnt_reducer'
import SystemSettings from './settings_reducer'
import User from './user_reducer'
import Audience from './audience_reducer'
import Campaign from './campaign_reducers'
import Auth from './auth_reducer'
import Notifications from "./notification_reducer";
import Activity from './activity_reducer'
import ActivityReply from './activity_reply_reducer'
import Comments from './comments_reducer'
import SurveyReply from './survey_reply_reducer'
import ActivityParticipant from './activity_participants_reducer'
import Excerpts from './excerpt_reducer'
import Chat from './chat_reducer'
import Chatroom from './chatroom_reducer'
import Recording from './recordings_reducer'
import Dashboard from './dashboard_reducer'
import WordCloud from './activity_wordcloud_reducer'
import Attachments from './activity_attachments_reducer'
import ProjectAttachment from './project_attachments_reducer'
import ProjectScreenerAttachment from './project_screener_attachment_reducer'
import ProjectScreenerReplyAttachment from './project_screener_reply_attachment_reducer'
import ProjectRecordingsAttachment from './project_recordings_attachment_reducer'
import ResearchHistory from './history_reducer'
import ActivityMeetings from './activity_meetings_reducer'

export default {
  ThemeOptions,
  Project,
  Referral,
  EmailTemplates,
  SmsTemplates,
  BroadcastMessages,
  ProjectParticipants,
  SystemSettings,
  User,
  Audience,
  Campaign,
  Auth,
  Notifications,
  Activity,
  ActivityReply,
  Comments,
  SurveyReply,
  ActivityParticipant,
  Excerpts,
  Chat,
  Chatroom,
  Recording,
  Dashboard,
  WordCloud,
  Attachments,
  ProjectAttachment,
  ProjectScreenerAttachment,
  ProjectScreenerReplyAttachment,
  ProjectRecordingsAttachment,
  ResearchHistory,
  ActivityMeetings
};
