import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody, Button } from "reactstrap";
import styles from '../../../assets/css/rstyling.module.css'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import avatar from "../../../assets/images/profile_placeholder.png";
import SecureLS from 'secure-ls';
import Back from '../../../assets/components/back/Back';
import { editSurveyReply, getSurveyReply, getActivityReplyImagesRespondent } from '../../../actions/activities_reply_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate, useParams } from 'react-router-dom';
import maleUserAvatar from '../../../assets/images/male-avatar.svg'
import femaleUserAvatar from '../../../assets/images/female-user-avatar.png'
import ReactPlayer from 'react-player'
import RenderFile from './RenderFile';

// *********************** This file used to display participants responses once the teh participants submit the survey *********************


const SurveyAnalysis = (props) => {
    
    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()

    const [isLoading, setLoading] = useState(true)

    const [questions, setQuestions] = useState([]);
    const [additionalAnswer, setAdditionalAnswer] = useState('')
    const [surveyReply, setSurveyReply] = useState({})
    const [points, setPoints] = useState(0)
    const ls = new SecureLS()
  
  const userGender =  ls.get('accessUser').user.gender
    
    useEffect(() => {
        getSurveyResponse()
    }, [])

    

       
    
    const getSurveyResponse = () => {
      
        dispatch(getSurveyReply(params.responseid, params.surveyid))
            .then((response) => {
                if(response.payload.message === 'All Replies Found'){
                    console.log('all replies found ->',response.payload.content)
                setSurveyReply(response.payload.content)
                //setPoints(response.payload.points)
                setLoading(false) }
                else if(response.payload.message === 'Pending Replies Found'){
                    console.log('pending replies found')
                    let page= response.payload.content
                    history(`/respondent/survey/${params.surveyid}/${page}`)
                }
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
            
       
            
    }
   
   


    // const createData = () => {
        
    //     if (surveyReply.activity) {
    //         const survey = surveyReply.activity && surveyReply.activity.survey
            
    //        const replies =surveyReply.pages[0].questions
            
    //         let result = []
    //         for (let i = 0; i < survey.pages.length; i++) {
    //             for (let j = 0; j < survey.pages[i].questions.length; j++) {
    //                 replies.map(reply => {
    //                     // merging questions and their answers to show in listing
    //                     if (reply.questionId === survey.pages[i].questions[j]._id) {
    //                         result.push({ ...survey.pages[i].questions[j], ...reply, _id: survey.pages[i].questions[j]._id })
    //                     }
    //                 })
    //             }

    //         }
    //         setQuestions([...result])
    //     }

    // }

    // const getFile=(file)=>{
       
    //     dispatch(getActivityReplyImagesRespondent(file))
    //     .then(response =>{
    //         console.log('file data from backend ->',response.payload.content)
            
    //         setResponseFile(response.payload.content.fileData)
    //        //return responseF
    //     })
       
    // }

    const createData = () => {
        if (surveyReply.activity) {
            const survey = surveyReply.activity.survey;
            
           
            // Define an array to collect all replies from all pages
            const allReplies = [];
    
            // Iterate over all pages of replies
            surveyReply.pages.forEach((page) => {
                const replies = page.questions;
    
                // Iterate over all pages in the survey
                survey.pages.forEach((surveyPage) => {
                    // Iterate over all questions in the page
                    surveyPage.questions.forEach((question) => {
                        // Find the corresponding reply based on questionId
                        const reply = replies.find((reply) => reply.questionId === question._id);
    
                        // If a matching reply is found, merge it with the question
                        if (reply) {
                            allReplies.push({ ...question, ...reply });
                        }
                    });
                });
            });
    
            setQuestions([...allReplies]);
        }
    };
    
    
    

    // Additional questions reply
    const saveAnswer = (index) => {
        console.log(index)
        const formData = new FormData()
        formData.append(`additionalAnswers[0]`, additionalAnswer)
        setAdditionalAnswer('')

        dispatch(editSurveyReply(params.responseid, formData))
            .then((response) => {
                getSurveyResponse()
                Swal({
                    title: "Added",
                    text: "Answer Added",
                    icon: 'info'
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        createData()
    }, [surveyReply])


    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>


                <div className="container-fluid">

                    {/* Back */}
                    {/* <Back /> */}

                    {/* Heading */}
                    <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }} className="my-4">
                        {surveyReply.activity && surveyReply.activity.name}

                    </h5>

                    <Card className="main-card mb-3">
                        <CardHeader className="text-capitalize" style={{ fontWeight: 600, fontSize: '0.9rem', color:'#000' }}>
                            <div className='mr-auto'>
                                <img src={surveyReply.author && surveyReply.author.profileImage ? surveyReply.author.profileImage : userGender ==='Male'? maleUserAvatar:femaleUserAvatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                {surveyReply.author && surveyReply.author.userName}, Responses
                            </div>

                        </CardHeader>
                        {/* {points ?
                            <span className='px-2'>Total Points<h3>{points}</h3></span> : ''
                        } */}
                        <CardBody>

                            <VerticalTimeline layout="1-column"
                                className="vertical-time-simple vertical-without-time">

                                {
                                    questions.length > 0 && questions.map(question => {
                                        return <VerticalTimelineElement className="vertical-timeline-item">
                                            <h4 className="timeline-title" style={{ fontWeight: 500, color:'#000' }}>{question.questionText.replace(/<\/?span(?:\s+[^>]*)?>/g, '')}</h4>

                                            <ul className={`pl-3  ${styles.custom_list}`} style={{color:'#0069AB'}}>

                                                {
                                                    (question.questionType === 'radio' || question.questionType === 'radio' || question.questionType === 'checkbox') &&
                                                    question.selectedOptions.length > 0 && question.selectedOptions.map(option => {
                                                        return <li style={{ fontSize: '0.85rem' }}>{option}</li>
                                                    })
                                                }

                                              
                                                {

                                                    (question.questionType === 'spectrum') &&
                                                    question.selectedPointsOptions.length > 0 && question.selectedPointsOptions.map(({ text, points }) => {

                                                        return <li style={{ fontSize: '0.85rem' }}>{text}</li>
                                                    })

                                                }
                                               
                                                {

                                                    (question.questionType === 'spectrum') &&
                                                    question.selectedOptions.length > 0 && question.selectedOptions.map(option => {
                                                        return <li style={{ fontSize: '0.85rem' }}>{option}</li>
                                                    })
                                                }

                                                {
                                                    question.questionType === 'long text' &&
                                                    question.responseText && <li style={{ fontSize: '0.85rem' }}>{question.responseText}</li>
                                                }
                                                {
                                                    question.questionType === 'image' && question.responseFiles.length > 0 &&
                                                    question.responseFiles.map(file => {
                                                        return <div className="position-relative">
                                                            <img src={file} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                                                        </div>
                                                    })
                                                }
                                                {
                                                    question.questionType === 'video' && question.responseFiles.length > 0 &&
                                                    question.responseFiles.map(file => {
                                                       
                                                        // getFile(file)
                                                       
                                                        return <div className="position-relative" key={file}>
                                                           <RenderFile filePath={file}/>
                                                        </div>
                                                    })
                                                }
                                                {
                                                    question.questionType === 'video text' &&
                                                    <>
                                                        {question.responseText && <li>{question.responseText}</li>}
                                                        {
                                                            question.responseFiles.map(file => {
                                                                <div className="position-relative" key={file}>
                                                                <RenderFile filePath={file}/>
                                                             </div>
                                                              
                                                            })
                                                        }
                                                    </>

                                                }
                                                {
                                                    question.questionType === 'image text' &&
                                                    <>
                                                        {question.responseText && <li>{question.responseText}</li>}
                                                        {
                                                            question.responseFiles.map(file => {
                                                                return <div className="position-relative my-3">
                                                                    <img src={file} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="Survey Reponse Image" width="200px" />
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                }

                                                {
                                                    question.questionType === 'grid' && question.gridResponse.length > 0 && question.gridResponse !=null ? 

                                                    question.gridResponse.filter(grid => grid != null).map(grid => {
                                                        return <li><span style={{ fontWeight: 500, fontSize: '15px' }}>{grid.row}</span> : {grid.column} </li>
                                                    }):''

                                                }
                                            </ul>
                                        </VerticalTimelineElement>
                                    })
                                }

                            </VerticalTimeline>

                        </CardBody>
                    </Card>

                    {/* Question */}

                    {
                        surveyReply.additionalQuestions && surveyReply.additionalQuestions.length > 0 &&
                        <Card className="main-card mb-3 p-3">
                            <h6 style={{ color: "#888", fontWeight: 500, fontSize: "1rem" }} className="mb-3">
                                Question
                            </h6>
                            {
                                surveyReply.additionalQuestions.map((question, index) => {
                                    return <div className='my-2'>
                                        <p style={{ color: '#888', fontSize: "0.96rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">
                                            {`Q.${index + 1}: ${question}`}
                                        </p>

                                        {
                                            surveyReply.additionalAnswers[index] ?
                                                <li style={{ color: '#006FA7', fontSize: "0.96rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className=" mt-2 ml-2">
                                                    {surveyReply.additionalAnswers[index]}
                                                </li>
                                                :
                                                <>
                                                    <div className="my-3">
                                                        {/* Text  Area */}
                                                        <textarea style={{ resize: 'none', height: 70 }} className="form-control" placeholder="Write answer here" onChange={(e) => setAdditionalAnswer(e.target.value)} />
                                                    </div>

                                                    <div className="clearfix">
                                                        <div className="float-right">
                                                            <Button className={`${styles.bg_blue} btn btn-lg px-5`} onClick={() => saveAnswer()}>Save</Button>
                                                        </div>
                                                    </div>
                                                </>

                                        }
                                    </div>
                                })
                            }
                        </Card>

                    }

                </div>


            </LoadingOverlay>


        </TransitionGroup >
    )
}

export default SurveyAnalysis
