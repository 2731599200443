import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Card, CardBody, Button } from 'reactstrap'
import { getActivityReplyGraphs } from '../../../../../actions/activities_actions'
import { getSurveyWordCloud } from '../../../../../actions/activities_reply_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import pptxgen from 'pptxgenjs';


export default function GraphCartsWordCloud(props) {
   
    const [wordCloud, setWordCloud] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isShowWordCloud, setShowWordCloud] = useState(false)
const dispatch = useDispatch()
    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
        fontFamily: 'Poppins',
    fontSizes:[20,60]
        
    };
    const resizeStyle = {
        display: "flex",
        alignItems: "center",
     justifyContent: "center",
        margin: 'auto',
        border: "none",
        background: "#ffffff",

    };

    const getSurveyQuestionWordCloud = () => {
        
       
        dispatch(getSurveyWordCloud({ questionId: props.questionId, activityId: props.activityId }))
            .then((response) => {
            
                
                setWordCloud([...response.payload.content.wordCloud])
                setShowWordCloud(true)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })


    }
  

    // const getSurveyQuestionWordCloud = (questionId, activityId) => {
        
    //     // setLoading(true)
    //     // let questionId = []
    //     // if (isShowQuestion) {
    //     //     questionId = selectedQuestion.map(question => question.questionId)
    //     // }
    //     console.log('activity id ->',props.activityId)
    //     console.log('question id ->',props.questionId)
    //     dispatch(getSurveyWordCloud({ questionId: questionId, activityId: activityId }))
    //         .then((response) => {
    //             console.log('response of wordcloud->', response.payload.content)
                
    //             setWordCloud([...response.payload.content.wordCloud])
    //             setShowWordCloud(true)
    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //         })


    // }
    // useEffect(()=>{
    //     getSurveyQuestionWordCloud()
    // },[])

const downloadPPT = async() =>{

    try{


const pptx = new pptxgen();
const wordCloud = [
   
    { text: "fruit", value: 4 },
    { text: "chat", value: 3 },
    { text: "chaat", value: 2 },
    { text: "prefer", value: 2 },
    { text: "good", value: 1 },
    { text: "pakoras", value: 1 },
    { text: "dahi", value: 1 },
    { text: "bhallay", value: 1 },
    { text: "chana", value: 1 },
    { text: "higher", value: 1 },
    { text: "protein", value: 1 },
    { text: "curry", value: 1 },
    { text: "chicken", value: 1 },
    { text: "karahi", value: 1 },
    { text: "eat", value: 1 },
    { text: "fried", value: 1 },
    { text: "cultural", value: 1 },
    { text: "dishes", value: 1 },
    { text: "samosa", value: 1 },
    { text: "pakora", value: 1 },
    { text: "consume", value: 1 },
    { text: "frequently", value: 1 },
    { text: "ramadan", value: 1 },
    { text: "compared", value: 1 },
    { text: "normal", value: 1 },
    { text: "month", value: 1 },
    { text: "talbeena", value: 1 },
    { text: "kichra", value: 1 },
    { text: "masnoon", value: 1 },
    { text: "food", value: 1 },
    { text: "desi", value: 1 },
    { text: "cuisine", value: 1 },
    { text: "helps", value: 1 },
    { text: "fast", value: 1 },
    { text: "pakistani", value: 1 },
    { text: "pakoray", value: 1 },
    { text: "pakore", value: 1 },
    { text: "borde", value: 1 },
    { text: "abv", value: 1 },
    { text: "easily", value: 0 }
  ];

    

  const slide = pptx.addSlide();

  // Add scatter chart
  const scatterChartOpts = {
    x: 100, // X-coordinate of top-left corner of the chart
    y: 100, // Y-coordinate of top-left corner of the chart
    w: 500, // Width of the chart
    h: 300, // Height of the chart
    scatterChart: {
      data: wordCloud.map(point => [point.text, point.value]), // Data points
      xType: 'text',
    
      legend: ['Scatter Chart'], // Optional: Legend
      showLabel: true, // Optional: Show data point labels
    },
  };
  slide.addChart(pptx.charts.SCATTER, scatterChartOpts);
  pptx.writeFile({ fileName: "BubbleChart.pptx" });
  } catch (error) {
    console.error('Error generating PPT:', error);
  }
};








    return (
        <>
        <div className='text-center'>
        <Button  onClick={() => getSurveyQuestionWordCloud()}>Generate WordCloud</Button>

        </div>
                                                    {
        isShowWordCloud === true ? <Resizable
            defaultSize={{
                width: 'auto',
                height: 'auto'
            }}
            style={resizeStyle}
        >
            <div style={{ width: "100%", height: "100%" }}>
                {/* Word cloud */}
               
               {/* {getSurveyQuestionWordCloud()} */}
             
                <ReactWordcloud words={wordCloud} options={options}  />
            </div>
        </Resizable>
        :
        <div style={{height: "150px" }}>

        </div>
    }
   

    </>
    
  )
}
