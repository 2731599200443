import React, { useState, useEffect } from 'react'
import Listing from '../../../../assets/components/listing/Listing'
import { TransitionGroup } from 'react-transition-group';
import Eyeicon from '../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../assets/images/delete_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../assets/components/modal/AlertModal';
import { delSurveyReply, getAllSurveyParticipants } from '../../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useParams } from 'react-router-dom';

const MiniSurveyParticipant = (props) => {

    const activityParticipantState = useSelector(state => state.ActivityParticipant)

    const params = useParams()
    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(true)
    const [delId, setDelID] = useState()

    const [participantColumn, setColumn] = useState([
        { Name: 'Respondent Name', Type: 'object', key: 'author', value: 'userName' },
        { Name: "Email", Type: 'object', key: 'author', value: 'email', width: 200 },
        { Name: "Phone No", Type: 'object', key: 'author', value: 'phone' },
        { Name: "Last Login", Type: 'date', key: 'author.logs.latestVisit' },
        { Name: "Date", Type: 'date', key: 'createdAt' },
        { Name: "Time", Type: 'time', key: 'createdAt' },
        { Name: "Progress", Type: 'progress', key: 'progress' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    
                    icon: Eyeicon,
                    url: '/admin/minisurvey/responses/' + params.activityid ,
                
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },
            ]
        }
    ])

    const [participantData, setParticipantData] = useState([])

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const getSurveyParticipants = (page = 1) => {
        dispatch(getAllSurveyParticipants({ page: page, limit: 10, activityId: params.activityid }))
            .then((response) => {
                setParticipantData(response.payload.content.participants)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
                setLoading(false)
            })
    }
console.log('delId ->',delId)
    const delResponse = () => {
        dispatch(delSurveyReply(delId))
            .then((response) => {
                setDelID()
                getSurveyParticipants(activityParticipantState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Response Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                setDelID()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })

    }

    useEffect(() => {
        getSurveyParticipants()
    }, [])



    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <>
                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={delResponse} />
                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            {/* Listing */}
                            <div className="table_border">
                                <Listing columnName={participantColumn}
                                    data={participantData}
                                    currentPage={activityParticipantState.currentPage ? activityParticipantState.currentPage : 1}
                                    totalPages={activityParticipantState.totalPages ? activityParticipantState.totalPages : 1}
                                    getListingData={(currentPage, dataLimit) => getSurveyParticipants(currentPage, dataLimit)}
                                    startLoading={() => setLoading(true)}
                                />
                            </div>

                        </LoadingOverlay>
                    </>
                )}
            />
        </TransitionGroup>


    )
}

export default MiniSurveyParticipant
