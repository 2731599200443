import {  CAMPAIGN_AVAILABLE, GET_CAMPAIGN_DETAIL } from '../actions/types';

let campaignDataState = { campaigns:[], totalPages:0, currentPage: 1, campaignDetails: {} };
export default function (state = campaignDataState, action) {
    switch (action.type) {
        case CAMPAIGN_AVAILABLE:
            if (state.campaigns.length === 0) {
                return {
                    ...state,
                    campaigns: action.payload.content.campaigns,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.campaigns =  state.campaigns.concat(action.payload.content.campaigns);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    campaigns: concatResult.campaigns,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case GET_CAMPAIGN_DETAIL:
            return {...state, campaignDetails: action.payload.content }
        default:
            return state;
    }
}
