import axios from 'axios';
import { BROADCAST_MSG_AVAILABLE, CREATE_SEND_BROADCAST_MSG, GET_BROADCAST_MSG_DETAILS } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

// ****************  These apis are used in ADMIN END in BROADCAST MESSAGES SECTION *********************


// Returns an array containing all sent messages
export const getAllBroadcastMsgs = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/broadcastMsg?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: BROADCAST_MSG_AVAILABLE,
        payload: request
    }
}


// Takes message id as param
// Returns an object containing message details of specific id
export const getBroadcastMsgDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/broadcastMsg/` + id;

    const request = axios.get(url)
        .then(response => response.data);

        
    return {
        type: GET_BROADCAST_MSG_DETAILS,
        payload: request
    }
}


// Takes an object containing a new message
// Used to send messages to participants
export const createSendBroadcastMsg = (broadcastMsg) => {

    let url = `${BACK_SERVER_URL}api/broadcastMsg/`;

    const request = axios.post(url, broadcastMsg)
        .then(response => response.data);

    return {
        type: CREATE_SEND_BROADCAST_MSG,
        payload: request
    }
}
