import React, { useState, useEffect, useRef } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../assets/components/pageheader/PageHeader'
import CreateIcon from '../../../../../assets/images/create_icon.png'
//import CKEditor from ""; 
import { useNavigate, useParams, useLocation } from 'react-router';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styles from '../../../../../assets/css/rstyling.module.css'
import { Typeahead } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert'
import SecureLS from 'secure-ls';
import { useDispatch } from 'react-redux';
import { createSmsTemplate, editSmsTemplateDetails, getSmsTemplateDetails } from '../../../../../actions/sms_template_actions';
import { getSpecificOption } from '../../../../../actions/settings_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { Formik } from 'formik'
import * as Yup from 'yup'
import ArrowLeft from '../../../../../assets/images/arrow_left.png'

const CreateSmsTemplate = () => {

    const params = useParams()
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const dispatch = useDispatch()
    const ls = new SecureLS()
    const formRef = useRef()

    // const smsTemplateState = useSelector(state => state.SmsTemplates)
    const [selectedVariable, setVariable] = useState([])
    const [variableList, setVariableList] = useState([])

    const [isLoading, setLoading] = useState(true)

    const [smsTemplate, setSmsTemplate] = useState({
        title: '',
        dynamicVariables: [],
        variableValue: '',
        smsContent: '',
        author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
    })


    const onCreateSmsTemplate = (values) => {

        let smsContent = values.smsContent

        for (let i = 0; i < selectedVariable.length; i++) {
            smsContent = smsContent.replaceAll(selectedVariable[i], `{{${selectedVariable[i]}}}`)
        }

        const dynamicVar = selectedVariable.map(variable => {
            return variable._id
        })

        const smsTemplateData = {
            title: values.title,
            dynamicVariables: dynamicVar.filter(v => v),
            smsContent: smsContent,
            variableValue: values.variableValue,
            author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
        }
        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        dispatch(createSmsTemplate(smsTemplateData))
            .then(response => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: "Created",
                    text: response.message ? response.message : "Template Created",
                    icon: 'success'
                }).then(() => {
                    history('/admin/templates/sms')
                })

            })
            .catch(err => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    const editSmsTemplate = (values) => {
        if (params.templateid) {
            let smsContent = values.smsContent

            for (let i = 0; i < selectedVariable.length; i++) {
                smsContent = smsContent.replaceAll(selectedVariable[i], `{{${selectedVariable[i]}}}`)
            }

            const dynamicVar = selectedVariable.map(variable => {
                return variable._id
            })

            const smsTemplateData = {
                title: values.title,
                dynamicVariables: dynamicVar.filter(v => v),
                smsContent: smsContent,
                variableValue: values.variableValue,
                author: ls.get('accessUser') && ls.get('accessUser').user ? ls.get('accessUser').user._id : ''
            }
            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });
            dispatch(editSmsTemplateDetails(params.templateid, smsTemplateData))
                .then(response => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: "Edited",
                        text: response.content ? response.content : "Template Edited",
                        icon: 'success'
                    }).then(() => {
                        history('/admin/templates/sms')
                    })
                })
                .catch(err => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err ? err : "Error",
                        text: "Please try again!",
                        icon: 'error'
                    });
                })

        }
    }

    const onSave = (values) => {
        const editUrl = '/admin/edit-sms-template/' + params.templateid

        if (params.templateid) {
            if (currentPath === editUrl)
                editSmsTemplate(values);
            else
                onCreateSmsTemplate(values)

        } else {
            onCreateSmsTemplate(values)
        }
    }

    // For geting details of template in case of edit or copy template
    useEffect(() => {
        if (params.templateid) {
            // getting template details
            dispatch(getSmsTemplateDetails(params.templateid))
                .then(response => {
                    setSmsTemplate({
                        title: response.payload.content.title,
                        smsContent: response.payload.content.smsContent,
                        author: response.payload.content.author,
                        variableValue: response.payload.content.variableValue
                    })
                    setVariable([...response.payload.content.dynamicVariables])
                    // setSmsTemplateContent(response.payload.content.smsContent)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    Swal({
                        title: err ? err : "Error",
                        text: "Something went wrong",
                        icon: 'error',
                    });
                    history('/admin/templates/sms')
                })
        } else {
            setLoading(false)
        }

    }, [])


    const getSystemOptions = () => {
        dispatch(getSpecificOption({ options: ["Template Variables"] }))
            .then(response => {
                // setSystemVariables(response.payload.content.templateVariables)
                // const variables = response.payload.content.templateVariables.map(variable => variable.name);
                setVariableList(response.payload.content.templateVariables)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getSystemOptions()
    }, []);


    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/edit-sms-template/' + params.templateid:
                return <PageHeader heading="Edit Template" img={CreateIcon} />

            default:
                return <PageHeader heading="Create Template" img={CreateIcon} />
        }
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '20%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <div className="create_header">
                    {getPageHeader()}
                </div>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="container-fluidd mt-3">
                    {/* <div className="row"> */}


                    <Formik
                        initialValues={smsTemplate}
                        enableReinitialize={true}
                        innerRef={formRef}
                        onSubmit={(values) => {

                            onSave(values)

                        }}

                        const validationSchema={Yup.object().shape({
                            title: Yup.string().required("Sms Title is required"),
                            smsContent: Yup.string().required("Sms Content is required"),

                        })}

                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                
                            } = props;

                            return (
                                <form autoComplete="off" onSubmit={handleSubmit}>

                                    {/* Start of form */}

                                    <div className='row'>

                                        <div className="col-12">
                                            <FormGroup>
                                                <Label for="emailTitle">Template Title</Label>
                                                <Input type="text"
                                                    className={errors.title && touched.title ? styles.is_invalid : 'valid'}
                                                    name="title"
                                                    placeholder="Title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.title}
                                                />
                                                {errors.title && touched.title && (
                                                    <div className="text-danger">
                                                        {errors.title}
                                                    </div>
                                                )}

                                            </FormGroup>
                                        </div>
                                        {
                                            selectedVariable.length > 0 &&
                                            <div className="col-12">
                                                <FormGroup className="light_typeahead custom_drop">
                                                    <Label for="sendto">Avaiable Strings</Label><br />
                                                    {
                                                        selectedVariable.map(variable => {
                                                            return <span key={variable.appendable} className="mb-2 mr-2 rbt-token" style={{ backgroundColor: 'rgb(232, 240, 254)' }}>
                                                                {variable.appendable}
                                                            </span>
                                                        })
                                                    }
                                                </FormGroup>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <FormGroup className="light_typeahead custom_drop">
                                                <Label for="sendto">Variables</Label>
                                                <Typeahead
                                                    id="variables"
                                                    multiple
                                                    onChange={setVariable}
                                                    selected={selectedVariable}
                                                    options={variableList}
                                                    placeholder="Variables"
                                                    defaultSelected={selectedVariable}
                                                    labelKey={(option) => `${option.name}`}
                                                />
                                            </FormGroup>
                                        </div>
                                        {
                                            selectedVariable.length > 0 &&
                                            <div className="col-6">
                                                <FormGroup className="light_typeahead custom_drop">
                                                    <Label for="varValue">{"Please provide a value for selected variable"}</Label>
                                                    <Input
                                                        className={errors.variableValue && touched.variableValue ? styles.is_invalid : 'valid'}
                                                        name="variableValue"
                                                        placeholder="Variable Value"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.variableValue}
                                                    />
                                                </FormGroup>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <FormGroup>
                                                <Label for="emailTemplate">SMS Template</Label>
                                                <div className="text_editor">
                                                    {
                                                        !isLoading &&
                                                        <>
                                                            <textarea
                                                                type="text"
                                                                className={errors.smsContent && touched.smsContent ? styles.is_invalid : 'valid'}
                                                                name="smsContent"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.smsContent}
                                                                maxlength="400"
                                                                rows={10}
                                                                cols={140}
                                                            >
                                                            </textarea>
                                                            {errors.smsContent && touched.smsContent && (
                                                                <div className="text-danger">
                                                                    {errors.smsContent}
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div className="col-12 text-right">
                                            <button className={` ${styles.bg_blue} text-white mr-3 btn`} type="submit">
                                                Save
                                            </button>
                                            <button className={` bg-light btn`}
                                                style={{ border: '1px solid #6c757d ' }} type="button" onClick={() => history('/admin/templates/sms')}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            )
                        }}

                    </Formik>

                </div>

            </LoadingOverlay >



        </TransitionGroup >

    )
}

export default CreateSmsTemplate
