import React, { useEffect, useState } from 'react'
import { CardHeader, Card, CardBody, FormGroup, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Chart from 'react-apexcharts'
import { Typeahead } from 'react-bootstrap-typeahead'
import styles from '../../../../assets/css/rstyling.module.css'
import Listing from '../../../../assets/components/listing/Listing'
import Filter from '../../../../assets/images/filter_icon.png'
import Search from '../../../../assets/images/search_icon.png'
import ResizeDetector from 'react-resize-detector'
import { TransitionGroup } from 'react-transition-group';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProjectParticipant } from '../../../../actions/participant_actions'
import { getProjectAudiences } from '../../../../actions/audience_actions'
import { projectParticipantsReport } from '../../../../actions/project_actions'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const ParticiapntReport = () => {

    // Typeahead Values
    const [participantList, setParticipants] = useState([])
    const [audienceList, setAudienceList] = useState([])

    const dispatch = useDispatch()
    const params = useParams()

    const [isShowParticipantList, showParticipantList] = useState(false)
    const [isShowAudienceList, showAudienceList] = useState(false)
    const [isShowReport, showReport] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isAllParticipants, setAllParticipants] = useState(false)

    const [selectedParticipant, setSelectedParticipant] = useState([])
    const [selectedAudience, setSelectedAudience] = useState([])

    // Listing Filter Dropdown
    const [users, setUsers] = useState([])

    const [reportCharts, setReportChart] = useState({})
    const [report, setReport] = useState({})

    const [column] = useState([
        { Name: 'Activity Name', Type: 'string', key: 'activityName' },
        { Name: 'Activity Type', Type: 'string', key: 'activityType' },
        { Name: "Status", Type: 'string', key: 'status' },
        { Name: "Start Date", Type: 'date', key: 'startDate' },
        { Name: "Start Time", Type: 'time', key: 'startDate' },
        { Name: "Completed", Type: 'number', key: 'completed' },
        { Name: "Project Activities", Type: 'number', key: 'projectActivities' },
    ]);

    const [activitiesData, setActivitiesData] = useState([])

    const getParticipants = () => {
        dispatch(getProjectParticipant(params.projectid))
            .then(response => {
                setParticipants(response.payload.content)

            }).catch(err => {
                console.log(err)
            })
    }

    const getAudience = () => {
        dispatch(getProjectAudiences(params.projectid))
            .then(response => {
                setAudienceList(response.payload.content)

            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getParticipants()
        getAudience()
    }, [])

    const getReport = () => {
        if (isAllParticipants || isShowAudienceList || isShowParticipantList) {
            setLoading(true)
            let data = {}
            if (isShowParticipantList) {
                const participants = selectedParticipant.map(participant => participant._id)
                // report for selected participants
                data = {
                    projectId: params.projectid,
                    participants: participants,
                    audiences: []
                }
            } else if (isShowAudienceList) {
                const audience = selectedAudience.map(audience => audience._id)
                // report for selected audience
                data = {
                    projectId: params.projectid,
                    participants: [],
                    audiences: audience
                }
            } else {
                // report for all participants
                data = {
                    projectId: params.projectid,
                    participants: [],
                    audiences: []
                }
            }
            dispatch(projectParticipantsReport(data))
                .then(response => {

                    setActivitiesData(response.payload.content.reports[0].activities)
                    setReport(response.payload.content.reports)
                    const report = response.payload.content.reports
                    let totalCompleted = []
                    let activityType = []
                    let totalNoActivities = 0
                    let userName = []

                    report.map(user => {
                        userName.push(user.userName)
                        let completed = []
                        user.activities.map(activity => {
                            activityType.push(activity.activityType)
                            completed.push(activity.completed)
                        })
                        totalNoActivities = user.activities.length > totalNoActivities ? user.activities.length : totalNoActivities
                        totalCompleted.push(completed)
                    })

                    // Graph Series 
                    let graphSeries = totalCompleted.map((series, index) => {
                        if (series[0] !== undefined) {
                            return {
                                name: userName[index],
                                data: series
                            }
                        }
                    })
                    console.log(graphSeries)
                    setUsers(userName)
                    graphSeries = graphSeries.filter(Boolean)

                    // saving data to plot graph
                    setReportChart({
                        series: graphSeries,
                        options: {
                            chart: {
                                type: 'bar',
                                height: 350,
                                stacked: true,
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                },
                            },
                            stroke: {
                                width: 1,
                                colors: ['#fff']
                            },
                            xaxis: {
                                categories: activityType.splice(0, totalNoActivities),
                            },
                            yaxis: {
                                categories: activityType.splice(0, totalNoActivities),
                            },
                            fill: {
                                opacity: 1
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                                offsetX: 40,
                            }
                        },
                    })
                    setLoading(false)
                    showReport(true)
                }).catch(err => {
                    showReport(true)
                    setLoading(false)
                    console.log(err)
                })
        }
    }

    // Searching
    const [searchString, setSearchString] = useState('')
    const [searchedResult, setSearchedResult] = useState([])

    // Listing Search
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredActivities = activitiesData.filter(row => {
            return (
                search.test(row.activityName) ||
                search.test(row.status) ||
                search.test(row.startDate) ||
                search.test(row.activityType)
            );
        });
        setSearchedResult(filteredActivities);
    }, [searchString]);

    // Filter listing by user
    const filterActivities = (user) => {
        report.map((report, index) => {
            if (report.userName === user) {
                setActivitiesData(report.activities)

            }
        })
    }


    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            <div className="container-fluid">
                                {/* Form */}
                                <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                                    <CardHeader className={styles.text_blue}>Participants Report</CardHeader>
                                    <CardBody>

                                        <p className={styles.text_bold}>Participants</p>

                                        <div className="col-12">
                                            <FormGroup>
                                                <input type="checkbox" id="allParticipants" className="mr-2" checked={isAllParticipants}
                                                    onChange={(e) => setAllParticipants(e.target.checked)}
                                                />
                                                <label for="allParticipants">All Participants</label>
                                            </FormGroup>
                                        </div>
                                        <div className="col-12">
                                            <FormGroup>
                                                <input type="checkbox" id="participant" className="mr-2" checked={isShowParticipantList}
                                                    onChange={(e) => { showParticipantList(e.target.checked); showAudienceList(!e.target.checked) }}
                                                />
                                                <label for="participant">Select Participants</label>
                                            </FormGroup>
                                        </div>
                                        {
                                            isShowParticipantList &&
                                            <div className="col-12">
                                                <FormGroup className="light_typeahead custom_drop">
                                                    <Typeahead
                                                        id="participantList"
                                                        multiple
                                                        onChange={setSelectedParticipant}
                                                        selected={selectedParticipant}
                                                        options={participantList}
                                                        labelKey={(option) => `${option.userName}`}
                                                        placeholder="Select Participants"
                                                    />
                                                </FormGroup>
                                            </div>
                                        }
                                        <div className="col-12">
                                            <FormGroup>
                                                <input type="checkbox" id="focusGroups" className="mr-2" value={isShowAudienceList}
                                                    onChange={(e) => { showAudienceList(e.target.checked); showParticipantList(!e.target.value) }}
                                                />
                                                <label for="focusGroups">Select Focus Groups</label>
                                            </FormGroup>
                                        </div>
                                        {
                                            isShowAudienceList &&
                                            <div className="col-12">
                                                <FormGroup className="light_typeahead custom_drop">
                                                    <Typeahead
                                                        id="participantList"
                                                        multiple
                                                        onChange={setSelectedAudience}
                                                        selected={selectedAudience}
                                                        options={audienceList}
                                                        labelKey={(option) => `${option.title}`}
                                                        placeholder="Select Audience"
                                                    />
                                                </FormGroup>
                                            </div>
                                        }

                                    </CardBody>
                                </Card>

                                <div className="row">
                                    <div className="col-12 align-self-center pt-3 text-right" >
                                        <Button className={`${styles.bg_blue} px-5 w-25`} onClick={getReport}>
                                            Generate Report
                                        </Button>
                                    </div>
                                </div>

                                {
                                    isShowReport &&
                                    <>
                                        {/* Chart */}
                                        <div className="my-3">
                                            <div id="chart" style={{ overflowX: 'scroll' }}>
                                                <Chart options={reportCharts.options} series={reportCharts.series} type="bar" height={350} width={reportCharts.series.length <= 25 ? "100%" : reportCharts.series.length * 45} />
                                            </div>
                                        </div>

                                        {/* Listing */}
                                        <Card className="main-card mb-3">
                                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                                <div className="text-white menu-header-title text-capitalize">Activities List</div>

                                                <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                                    <ul className="navbar-nav mr-auto">
                                                    </ul>
                                                    <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                                                        <div className="input-group input-group-sm w-75">
                                                            <div className="input-group-prepend border-0">
                                                                <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                            </div>
                                                            <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                                        </div>
                                                    </form>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle className="btn btn-primary" style={{ borderRadius: '0.2rem', border: '1px solid white', color: 'white', backgroundColor: '#006FA7' }}>Sort By <img src={Filter} alt="" /></DropdownToggle>
                                                        <DropdownMenu>
                                                            {
                                                                users.map(users => {
                                                                    return <DropdownItem onClick={() => filterActivities(users)}>{users}</DropdownItem>
                                                                })
                                                            }
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </nav>

                                            <CardBody className="p-0">
                                                <div className="table_border">
                                                    <Listing columnName={column}
                                                        data={(searchString.length > 0 || searchedResult.length > 0) ? searchedResult : activitiesData}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </>
                                }
                            </div>

                        </LoadingOverlay>



                    </TransitionGroup>
                </>
            )}
        />
    )
}

export default ParticiapntReport
