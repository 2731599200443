import React, { Component, Fragment, useEffect } from "react";
import {Link, withRouter, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../../reducers/ThemeOptions";
import SecureLS from "secure-ls";
import { Respondent_Nav_Content, Admin_Nav_Content, Researcher_Nav_Content } from './Nav_Content';
import Help from '../../../assets/images/HelpQuestion.svg'
import SignOut from '../../../assets/images/SignOut.svg'
import Styles3 from '../../../assets/css/newStyling.module.css'
import Styles from '../../../assets/css/styling.module.css'
import maleUserAvatar from '../../../assets/images/male-avatar.svg'
import femaleUserAvatar from '../../../assets/images/female-user-avatar.png'
import axios from 'axios'
import {BACK_SERVER_URL} from '../../../config/server.js'

class Nav extends Component {

    state = {};

    toggleMobileSidebar = () => {
        
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

     logout = () => {
        let data = {isActive: false}
     let url = `${BACK_SERVER_URL}api/logs`
     const request = axios.put(url, data)
     .then(response =>{
        window.localStorage.clear();
        setTimeout(()=>{
            this.props.navigate('/login')
        
        }, 1000)
     }).catch(err=>{console.log(err)})
       
  
  
      
    }

    

    getNavContent = () => {
        const ls = new SecureLS()
    
        this.userName = ls.get('accessUser').user.firstName
        this.userGender = ls.get('accessUser').user.gender
        
        if (ls.get('accessToken').role) {
            if (ls.get('accessToken').role === 1)
                return Admin_Nav_Content
            else if (ls.get('accessToken').role === 2)
                return Researcher_Nav_Content
            else if (ls.get('accessToken').role === 3)
                return Respondent_Nav_Content
            else
                return
        }
    }

    paddingControl =()=>{
        if(window.innerWidth > 768){
            return '30px'
        }
        else{
            return '5px'
        }
    }

    formatDate() {
		const date = new Date();

		const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		const months = [
			'January', 'February', 'March', 'April', 'May', 'June', 'July',
			'August', 'September', 'October', 'November', 'December'
		];

		const currentDay = daysOfWeek[date.getDay()];
		const currentDate = date.getDate();
		const currentMonth = months[date.getMonth()];
		const currentYear = date.getFullYear();

		return `${currentDay}, ${currentDate} ${currentMonth} ${currentYear}`;
	}




    render() {
        const formattedDate = this.formatDate();

        return (
            <Fragment>
{window.innerWidth < 768 &&
<div className='text-center' >
<img style={{width:'120px'}}  src={this.userGender ==='Male'? maleUserAvatar:femaleUserAvatar } class="rounded-circle" alt=""/>

        <h4 className='mt-2' style={{color: '#3e435d', fontSize: '20px', fontWeight: '500', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>Welcome, <span>{this.userName}</span></h4>
        <p style={{ color: '#ada7a7', fontWeight: '600' }}>{formattedDate}</p>
        </div>
    }
                <MetisMenu content={this.getNavContent()} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
               
              
              className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
              
                <nav>
                    

                        <li style={{ listStyle: 'none', paddingTop: '65px', display: 'flex', alignItems: 'center' }}>
                            <Link to="/respondent/faqs" className={`${Styles3.last_two_nav_text}`} style={{ textDecoration: 'none' }} >
                                <img src={Help} alt="Help image" style={{ marginRight: '12px', paddingLeft: window.innerWidth > 768 ? '34px': '9px'}} />
                                Help
                            </Link>
                        </li>
                        

                        <li style={{ listStyle: 'none', paddingTop: '10px', display: 'flex', alignItems: 'center' }}>
                            <Link to="" className={`${Styles3.last_two_nav_text}`} style={{ textDecoration: 'none' }} onClick={this.logout}>
                                <img src={SignOut} alt="Log out image" style={{ marginRight: '12px',  paddingLeft: window.innerWidth > 768 ? '34px': '9px' }} onClick={this.logout}/>
                                Log Out
                            </Link>
                        </li>

                   
                </nav>
                {/* <MetisMenu content={ComponentsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
                    className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

                <MetisMenu content={WidgetsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
                className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

                <MetisMenu content={FormsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
                className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

                <MetisMenu content={ChartsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
                className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}


const mapStateToProps = (state) => ({
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
//export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));

// const Nav = ({ enableMobileMenu, setEnableMobileMenu }) => {
//     const location = useLocation();

//     useEffect(() => {
//       const toggleMobileSidebar = () => {
//         setEnableMobileMenu(!enableMobileMenu);
//       };

//       const ls = new SecureLS();
//       const getNavContent = () => {
//         if (ls.get("accessToken").role) {
//           if (ls.get("accessToken").role === 1) return Admin_Nav_Content;
//           else if (ls.get("accessToken").role === 2) return Researcher_Nav_Content;
//           else if (ls.get("accessToken").role === 3) return Respondent_Nav_Content;
//           else return null;
//         }
//       };

//       const navContent = getNavContent();

//       return () => {
//         // Cleanup function
//         // Perform any necessary cleanup here
//       };
//     }, [enableMobileMenu, setEnableMobileMenu]);

//     const toggleMobileSidebar = () => {
//       setEnableMobileMenu(!enableMobileMenu);
//     };

//     return (
//       <Fragment>
//         <MetisMenu
//           content={navContent}
//           onSelected={toggleMobileSidebar}
//           activeLinkFromLocation
//           className="vertical-nav-menu"
//           iconNamePrefix=""
//           classNameStateIcon="pe-7s-angle-down"
//         />
//       </Fragment>
//     );
//   };

//   const mapStateToProps = (state) => ({
//     enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//   });

//   const mapDispatchToProps = (dispatch) => ({
//     setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
//   });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    
)(Nav);


 