import axios from 'axios';
import { CREATE_SMS_TEMPLATE, GET_SMS_TEMPLATE_DETAILS, SMS_TEMPLATE_AVAILABLE, DELETE_SMS_TEMPLATE, EDIT_SMS_TEMPLATE_DETAILS, GET_USER_SMS_TEMPLATES_TITLE } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

// **************  These apis are used in ADMIN END in SMS SECTION of Templates  *********************


// Takes an object as param containing template details
// creates a new sms template in db
export const createSmsTemplate = (smsTemplate) => {

    let url = `${BACK_SERVER_URL}api/smsTemplates/`;

    const request = axios.post(url, smsTemplate)
        .then(response => response.data);

    return {
        type: CREATE_SMS_TEMPLATE,
        payload: request
    }
}

// Returns an array of all sms templates
export const getAllSmsTemplates = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/smsTemplates?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: SMS_TEMPLATE_AVAILABLE,
        payload: request
    }
}

// Takes template id as param
// Returns object containing details of a specific template like title, description, author and template variables
export const getSmsTemplateDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/smsTemplates/` + id;

    const request = axios.get(url)
        .then(response => response.data);

        
    return {
        type: GET_SMS_TEMPLATE_DETAILS,
        payload: request
    }
}

// Takes id and an object (updated/edited template) as param 
// updates the template against the id we pass as params
export const editSmsTemplateDetails = (id, smsTemplateData) => {

    let url = `${BACK_SERVER_URL}api/smsTemplates/` + id;

    const request = axios.put(url, smsTemplateData)
        .then(response => response.data);

    return {
        type: EDIT_SMS_TEMPLATE_DETAILS,
        payload: request
    }
}

// deletes sms template against the id we pass in the param
export const delSmsTemplate = (id) => {

    let url = `${BACK_SERVER_URL}api/smsTemplates/` + id;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_SMS_TEMPLATE,
        payload: request
    }
}

// Returns an array of objects containing template titles and their ids 
// use to display templates dropdown in campaigns screen
export const getUserSmsTemplateTitle = () => {

    let url = `${BACK_SERVER_URL}api/smsTemplates/userTemplates`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_USER_SMS_TEMPLATES_TITLE,
        payload: request
    }
}


