import React, { useEffect, useState } from 'react';

import Tabs from 'react-responsive-tabs';
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { useNavigate, useParams } from 'react-router';
import SearchIcon from '../../../../assets/images/grouping_header_icon.png';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import WordCloud from './WordCloud';
import Posts from './Posts';
import Images from './Images';
import Videos from './Videos';
import Comments from './Comments';
import { getProject } from '../../../../actions/project_actions';
import { useDispatch } from 'react-redux';

const SearchResults = (props) => {

    const [projectTitle, setProjectTitle] = useState('')
    const tabsContent = [
        {
            title: 'Word Cloud',
            content: <WordCloud />
        },
        {
            title: 'Posts',
            content: <Posts />
        },
        {
            title: 'Comments',
            content: <Comments />
        },
        // {
        //     title: 'Videos',
        //     content: <Videos/>
        // },
        {
            title: 'Attachments',
            content: <Images />
        }
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()
    
    const getProjectDetails = () => {
        dispatch(getProject(params.projectid))
            .then(response => {
                setProjectTitle(response.payload.content.title)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getProjectDetails()
    }, [])

    return (
        <>

            <div className="admin_header">
                <PageHeader
                    heading={`${projectTitle} Search Results`} img={SearchIcon}
                />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false}
                    items={getTabs()}
                />
            </div>
        </>
    )

}

export default SearchResults;