import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProjectRecordingAttachments } from '../../../../actions/project_actions'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Listing from '../../../../assets/components/listing/Listing';

const RecordingAttachment = () => {

    const dispatch = useDispatch()
    const params = useParams()

    const [isLoading, setLoading] = useState(true)
    const attachmentState = useSelector(state => state.ProjectRecordingsAttachment)

    const attachmentColumn = [
        { Name: 'Activity', Type: 'string', key: 'activity' },
        { Name: "Activity Type", Type: 'string', key: 'activityType' },
        { Name: "Attachment", Type: 'attachment', key: 'attachment', width: 220 },
        { Name: "Date", Type: 'date', key: 'createdAt' },
        { Name: "Time", Type: 'time', key: 'createdAt' },
        { Name: "File Type", Type: 'file', key: 'attachment', width: 120 }
    ]

    const [attachment, setAttachment] = useState([])

    const getListing = (page = 1, limit = 5) => {
        dispatch(getProjectRecordingAttachments({ page: page, limit: limit, projectId: params.projectid }))
            .then((response) => {
                setAttachment(response.payload.content.attachments)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getListing()
    }, [])

    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>

            {/* Listing */}
            <div className="table_border">
                <Listing columnName={attachmentColumn}
                    data={attachment}
                    currentPage={attachmentState.currentPage ? attachmentState.currentPage : 1}
                    totalPages={attachmentState.totalPages ? attachmentState.totalPages : 1}
                    getListingData={(currentPage, dataLimit) => getListing(currentPage, dataLimit)}
                    startLoading={() => setLoading(true)}
                />
            </div>

        </LoadingOverlay>
    )
}

export default RecordingAttachment