import React, { useState, useEffect } from 'react'
import ReferralIcon from '../../../assets/images/referral_header_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import Listing from '../../../assets/components/listing/Listing'
import ResizeDetector from "react-resize-detector";
import avatar from '../../../assets/images/profile_image.jpg'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { getAllReferrals, stopLoader, startLoader } from '../../../actions/referral_actions';
import { useDispatch, useSelector } from 'react-redux';


const Referral = () => {

    const [isLoading, setLoading] = useState(true)
    const referralState = useSelector(state => state.Referral)
    const dispatch = useDispatch()

    const [referralColumn, setReferralColumn] = useState([
        { Name: 'ID No', Type: 'serialNo', key: 'serialNo' },
        { Name: "invitee", Type: 'object', key: 'referrerId', value: 'userName' },
        { Name: "email", Type: 'string', key: 'email' },
        { Name: "status", Type: 'string', key: 'status' },
        { Name: "Date", Type: 'date', key: 'created_at' },
    ])

    const [referralData, setReferralData] = useState([])

    const [searchString, setSearchString] = useState('')
    const [searchedReferralResult, setSearchedReferralResult] = useState([])

    // Global filtering
    useEffect(() => {
        let search = new RegExp(searchString, 'i');
        let filteredReferral = referralData.filter(row => {
            return (
                search.test(row._id) ||
                search.test(row.email) ||
                search.test(row.status) ||
                search.test(row.email) ||
                search.test(row.date)
            );
        });
        setSearchedReferralResult(filteredReferral);
    }, [searchString]);

    
    const getReferralListing = (currentPage = 1, dataLimit = 15) => {
        dispatch(getAllReferrals(currentPage, dataLimit))
            .then(response => {
                console.log(response)
                setReferralData(response.payload.content.referrals)
                dispatch(stopLoader())
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getReferralListing()
    }, [])

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>

                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                        <div className="admin_header">
                            <PageHeader img={ReferralIcon} heading="Referral List" />
                        </div>

                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Referral List</div>

                                <div className={width > 470 && "collapse navbar-collapse"}>
                                    <ul className="navbar-nav mr-auto">
                                    </ul>
                                    <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2.5rem' }}>
                                        <div className="input-group input-group-sm w-75">
                                            <div className="input-group-prepend border-0">
                                                <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                            </div>
                                            <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                        </div>
                                    </form>
                                </div>
                            </nav>

                            {/* Loader */}
                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>
                                <CardBody className="p-0">

                                    {/* Listing */}
                                    <div className="table_border">
                                        <Listing columnName={referralColumn}
                                            data={(searchString.length > 0 || searchedReferralResult.length > 0) ? searchedReferralResult : referralData}
                                            currentPage={referralState.currentPage ? referralState.currentPage : 1}
                                            totalPages={referralState.totalPages ? referralState.totalPages : 1}
                                            getListingData={(currentPage, dataLimit) => getReferralListing(currentPage, dataLimit)}
                                            startLoading={() => dispatch(startLoader())}
                                        />
                                    </div>

                                </CardBody>
                            </LoadingOverlay>
                        </Card>



                    </TransitionGroup>

                </>
            )}
        />
    )
}

export default Referral
