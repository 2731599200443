import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { searchComments } from '../../../../actions/project_actions';
import Avatar from '../../../../assets/images/profile_placeholder.png'

const Comments = () => {

    const dispatch = useDispatch()
   // const history = useNavigate()
    const params = useParams()
    let { search } = useLocation();
    const query = new URLSearchParams(window.decodeURIComponent(search));
    const [comments, setComments] = useState([])
    const [isLoading, setLoading] = useState(true)

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPage] = useState(0)

    const getComments = (isNew = true, page = 1) => {
        dispatch(searchComments({ searchStr: query.get('searchStr'), projectId: params.projectid, page: page, limit: 10 }))
            .then((response) => {
                isNew ? setComments([...response.payload.content.comments]) : setComments([...comments, ...response.payload.content.comments])
                setTotalPage(response.payload.content.totalPages)
                setPage(response.payload.content.currentPage)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })

    }

    useEffect(() => {
        getComments()
    }, [query.get('searchStr')])

    const next = () => {
        getComments(false, page + 1)
    }


    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            {
                comments.length > 0 ? comments.map(data => {
                    return <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                        <CardBody className="p-0">
                            <div className="widget-content px-3">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left font-size-md" style={{ fontSize: '0.8rem' }}>
                                        <img src={data.author.profileImage ? data.author.profileImage : Avatar} alt="profile" width={40} height={40} className="rounded-circle mr-2" />
                                    </div>
                                    <div className="widget-content-left">
                                        <div className="widget-subheading mt-1 opacity-10">
                                            <b>{data.author.userName}</b>  <span className={classNames({
                                                'badge-success': data.author.role === 1,
                                                'badge-warning': data.author.role === 3,
                                                'badge-danger': data.author.role === 2,
                                            }, 'badge badge-pill text-right')}>{data.author.role === 1 ? 'Admin' : data.author.role === 2 ? 'Researcher' : 'Respondent'}</span>
                                        </div>
                                        <div className="text-muted" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                            {new Date(data.created_at).toLocaleDateString()} {new Date(data.created_at).toLocaleTimeString()}
                                        </div>
                                    </div>

                                    {/* <div className="widget-content-right">
                                        <span className="font-size-sm px-3" style={{ backgroundColor: '#e7f4ff', fontWeight: 500, borderRadius: '0.2rem' }}>
                                            {data.activityType}
                                        </span>
                                    </div> */}
                                </div>
                                <div className="widget-content-wrapper">
                                    <p className="mb-0 pb-1 pt-3 d-block">{data.description}</p>

                                </div>
                            </div>
                            {/* <hr /> */}
                            {/* <div className="text-muted mb-2 px-3" style={{ fontWeight: 500, fontSize: '0.7rem' }}> */}
                                {/* <img src={CommentIcon} alt="" width="20" /> {data.noOfComments} comments */}
                            {/* </div> */}
                            {/* {
                                data.comments.map(comment => {
                                    return <> <div className="widget-content-wrapper d-flex px-3">
                                        <div className="widget-content-left font-size-md" style={{ fontSize: '0.8rem' }}>
                                            <img src={comment.profile} alt="profile" width={40} className="rounded-circle mr-2" />
                                        </div>
                                        <div className="widget-content-left">
                                            <div className="widget-subheading mt-1 opacity-10">
                                                <b>{comment.userName}</b>  <span className={classNames({
                                                    'badge-success': comment.role === 'Admin',
                                                    'badge-warning': comment.role === 'Respondent',
                                                    'badge-danger': comment.role === 'Researcher',
                                                }, 'badge badge-pill text-right')}>{comment.role}</span>
                                            </div>
                                            <div className="text-muted" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                                {comment.date}
                                            </div>
                                        </div>
                                    </div>
                                        <div className="widget-content-wrapper mb-3 px-3">
                                            <p className="mb-0 pb-1 pt-3 d-block">{comment.comment}</p>
                                        </div>
                                    </>
                                })
                            } */}

                        </CardBody>
                    </Card>
                }) :
                    <div className='col-12 text-center my-2'>No Comments Found</div>
            }

            {
                totalPages > 0 && page !== totalPages &&
                <div className={"pb-2 ml-5"}>
                    <div className="row px-3">
                        <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                            {/* Comment Counts */}
                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More</span>
                        </div>
                    </div>
                </div>
            }
        </LoadingOverlay>
    )
}

export default Comments
