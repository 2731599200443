
export const BACK_SERVER_URL = 'https://app.consumerconnect.net/ccapi/';

//export const BACK_SERVER_URL = 'http://localhost:3000/';

//'http://192.168.0.111:8000/'; //'https://app.consumerconnect.net:3000/'; //'http://192.168.0.109:5000/' //"https://app.consumerconnect.net:3000/"   //'http://localhost:3000/'   //'https://cc.tedamatic.com:3000/'  //'http://localhost:3000/'   //'http://cc.tedamatic.com:3000/' //'http://ec2-13-127-88-187.ap-south-1.compute.amazonaws.com:3000/'  //Server
//'http://localhost:3000/'



