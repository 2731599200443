import React from "react";
import { Button } from "reactstrap";

import styles from '../../../../../../assets/css/rstyling.module.css';

// styling based on progress of the form
const getNavStates = (indx, length) => {
    let styles = [];
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push("done");
        } else if (i === indx) {
            styles.push("doing");
        } else {
            styles.push("todo");
        }
    }
    return { current: indx, styles: styles };
};

// to check which button (next, previous and submit ) to display based on current step  
const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            showPreviousBtn: true,
            showNextBtn: true,
            showSubmitBtn: false
        };
    } else if (currentStep === 0) {
        return {
            showPreviousBtn: false,
            showNextBtn: true,
            showSubmitBtn: false
        };
    } else if (currentStep === stepsLength - 1) {
        return {
            showPreviousBtn: true,
            showNextBtn: false,
            showSubmitBtn: true
        };
    } else {
        return {
            showPreviousBtn: true,
            showNextBtn: false,
            showSubmitBtn: false
        };
    }
};

export default class SurveyFormSteps extends React.Component {
    state = {
        showPreviousBtn: false,
        showNextBtn: true,
        showSubmitBtn: false,
        compState: 0,
        navState: getNavStates(0, this.props.steps.length),
    };

    setNavState = (next) => {
        this.setState({
            navState: getNavStates(next, this.props.steps.length),
        });
        if (next < this.props.steps.length) {
            this.setState({ compState: next });
        }
        this.setState(checkNavState(next, this.props.steps.length));
    };

    next = () => {

        // Set Current Page
        this.props.steps[this.state.compState].setCurrentPage(this.state.compState + 1);
        this.setNavState(this.state.compState + 1);
    };

    previous = () => {
        if (this.state.compState > 0) {

            // Set Current Page
            this.props.steps[this.state.compState].setCurrentPage(this.state.compState - 1);
            this.setNavState(this.state.compState - 1);
        }
    };

    getClassName = (className, i) => {
        return className + "-" + this.state.navState.styles[i];
    };

    renderSteps = () => {
        return this.props.steps.map((s, i) => (
            <li
                className={this.getClassName("form-wizard-step", i)}
                key={i}
                value={i}
            >
                <em style={s.percentage > 99 ? { fontSize: '0.75rem' } : { fontSize: '0.9rem' }}>{s.percentage + "%"}</em>
                <span>{this.props.steps[i].name}</span>
            </li>
        ));
    };
    render() {
        console.log(this.props)

        return (
            <div className="_survey-steps_">

                {/* Steps Strip */}
                {
                    this.props.showNavigation && (
                        <div className={this.props.width <= 470 ? `mx-3` : `mx-3 px-5`}>
                            <ol className="forms-wizard">{this.renderSteps()}</ol>
                        </div>
                    )
                }

                {/* Steps Content */}
                {this.props.steps[this.state.compState].component}

                {/* Buttons Prev and Next */}
                {this.props.showNavigation && (
                    <div className="clearfix pt-3">
                        {this.state.showPreviousBtn && (
                            <Button className={` bg-light text-muted float-left ml-3`} style={{ border: '1px solid #6c757d ' }} outline onClick={this.previous}>
                                Previous
                            </Button>
                        )}
                        {this.state.showNextBtn && (
                            <Button className={` ${styles.bg_blue} float-right mr-3`} onClick={this.next}>
                                Next
                            </Button>
                        )}
                        {this.state.showSubmitBtn && (
                            <Button className={styles.bg_blue + " text-light float-right btn-hover-shine px-5 py-2"} onClick={this.next}>
                                Finish
                            </Button>
                        )}
                    </div>
                )}

            </div>
        );
    }
}

SurveyFormSteps.defaultProps = {
    showNavigation: true,
};
