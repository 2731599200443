import React from 'react'
import styles from '../../../assets/css/rstyling.module.css'
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";


// ******************** This component is used in all Screen of ADMIN & RESEARCHER to show Page Headers *******************************

const PageHeader = (props) => {
    return (
        <div className="page_header">
            <div className="app-page-title text-left">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">

                        {/* Image */}
                        <div className="page-title-icon" >
                            <img src={props.img} alt="icon" />
                        </div>

                        {/* Heading */}
                        <div>
                            {props.heading}
                            <div className="page-title-subheading" style={{ display: props.isSubheading ? 'block' : 'none' }}>
                                {props.subHeadingText}
                            </div>
                        </div>
                    </div>

                    {props.btnText && <div className="page-title-actions">

                        {
                            props.isDrop ?
                                // Dropdown
                                <UncontrolledButtonDropdown className={`mb-2 custom_drop ${styles.bg_blue}`}>
                                    <DropdownToggle caret>
                                        {props.btnText}
                                    </DropdownToggle>
                                    <DropdownMenu style={{ marginLeft: '-7rem' }}>
                                        {
                                            props.droplist.map((list, index) => {
                                                return <DropdownItem key={index} onClick={() => list.onClick()}>
                                                    <img src={list.icon} alt="" width="15px" className="mr-2" /> {list.text}
                                                </DropdownItem>
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                :
                                // Buttons 
                                < div className="d-flex">
                                    <Button className={`${styles.bg_blue} btn btn-lg`} onClick={props.btnClick} disabled={props.isDisable}>{props.btnText}</Button>
                                    <Button className={`ml-3 btn btn-lg text-dark`} style={{ backgroundColor: '#ccc', display: props.btn2 ? 'block' : 'none' }} onClick={props.btn2Click}>{props.btn2Text}</Button>
                                </div>
                        }

                    </div>
                    }
                    {/* Responsive Views Button for Preview Screens */}
                    {
                        props.btnGroup &&
                        <div className="page-title-actions">
                            <div className="btn-group">
                                <button type="button" className={`btn ${styles.bg_blue} text-white`} onClick={props.btn1Click}>PC</button>
                                <button type="button" className={`btn ${styles.bg_blue} text-white`} onClick={props.btn2Click}>Tab</button>
                                <button type="button" className={`btn ${styles.bg_blue} text-white`} onClick={props.btn3Click}>Mobile</button>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div >
    )
}

export default PageHeader
