import { GET_ALL_USER_CHATROOM } from "../actions/types";

let chatroomDataState = { chatrooms:[], totalPages:0, currentPage: 1};
export default function (state = chatroomDataState, action) {
    switch (action.type) {
        case GET_ALL_USER_CHATROOM:
            if (state.chatrooms.length === 0) {
                return {
                    ...state,
                    chatrooms: action.payload.content.chatrooms,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.chatrooms =  state.chatrooms.concat(action.payload.content.chatrooms);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    chatrooms: concatResult.chatrooms,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        default:
            return state;
    }
}
