import { initializeApp } from 'firebase/app';
import {  getMessaging, getToken } from "firebase/messaging";

export const vapidKey = "BAS8zBr06BL-iKUR-97JmMD2kZuHw5IHo_NFY_H2Pddm6MFgtOD2lg_OLK7SsPVCjjsyZkA2NeUfZQUK5BUfDAc"
const firebaseConfig = {
  apiKey: "AIzaSyB7tX9YZCVi7RBit_fGuz23Pjj8NzXzU5c",
  authDomain: "consumer-connect-58667.firebaseapp.com",
  projectId: "consumer-connect-58667",
  storageBucket: "consumer-connect-58667.appspot.com",
  messagingSenderId: "392696596508",
  appId: "1:392696596508:web:4f084d06c403e87399f7d9",
  measurementId: "${config.measurementId}"
};


initializeApp(firebaseConfig);

export const messaging = getMessaging();
