import React from 'react'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'
import Avatar from '../../../../assets/images/profile_placeholder.png'

const SearchResultCard = ({ data, next, totalPages, currentPage }) => {
    return (
        <div>
            {
                data.length > 0 ? data.map(data => {
                    return <Card className="shadow-none mb-2" style={{ borderRadius: '0.4rem' }}>
                        <CardBody>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left font-size-md" style={{ fontSize: '0.8rem' }}>
                                        <img src={data.author && data.author.profileImage ? data.author.profileImage : Avatar} alt="profile" width={40} height={40} className="rounded-circle mr-2" />
                                    </div>
                                    {
                                        data.author &&
                                        <div className="widget-content-left">
                                            <div className="widget-subheading mt-1 opacity-10">
                                                <b>{data.author.userName}</b>  <span className={classNames({
                                                    'badge-success': data.author.role === 1,
                                                    'badge-warning': data.author.role === 3,
                                                    'badge-danger': data.author.role === 2,
                                                }, 'badge badge-pill text-right')}>{data.author.role === 1 ? 'Admin' : data.author.role === 2 ? 'Researcher' : 'Respondent'}</span>
                                            </div>
                                            <div className="text-muted" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                                {new Date(data.created_at).toLocaleDateString()} {new Date(data.created_at).toLocaleTimeString()}
                                            </div>
                                        </div>
                                    }

                                    <div className="widget-content-right">
                                        <span className="font-size-sm px-3" style={{ backgroundColor: '#e7f4ff', fontWeight: 500, borderRadius: '0.2rem' }}>
                                            {data.activityType}
                                        </span>
                                    </div>
                                </div>
                                <div className="widget-content-wrapper">
                                    <p className="mb-0 pb-1 pt-3 d-block" dangerouslySetInnerHTML={{ __html:data.description }}></p>

                                </div>
                            </div>
                            <div className="widget-content-wrapper">
                                {
                                    data.images && data.images.map(image => {
                                        return <img src={image} alt="" style={{ borderRadius: '0.5rem' }} className="mr-3 mt-2" width="200px" />

                                    })
                                }
                                {
                                    data.video && data.video.map(video => {
                                        return <video width={200} height={100} controls className="img-fluid- rounded mr-3 mt-2"  >
                                            <source src={video} type="video/mp4" />
                                        </video>
                                    })
                                }
                            </div>
                        </CardBody>
                    </Card>
                }) :
                    <div className='col-12 text-center'>No Result Found</div>
            }

            {
                totalPages > 0 && currentPage !== totalPages &&
                <div className={"pb-2 ml-5"}>
                    <div className="row px-3">
                        <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                            {/* Comment Counts */}
                            <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More</span>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default SearchResultCard
