import React, { useEffect } from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import logo from '../../assets/images/logo.png'
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux'
import landing_bg_new from "../../assets/images/landing_page.png";
import oula_logo from '../../assets/images/oula_logo.jpg';
import pepsico_logo from '../../assets/images/pepsico_logo.png'
import { signup, SignupGoogle, signupFacebook } from '../../actions/auth_actions';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook } from 'reactjs-social-login';
import login_bg_new from "../../assets/images/login_bg2.png";
import { Formik } from 'formik'
import * as Yup from 'yup'
import styles from '../../assets/css/styling.module.css'
import { Button } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import { getLoggedInUser } from '../../actions/auth_actions';
import axios from 'axios';
import Swal from 'sweetalert';
import SecureLS from 'secure-ls';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function OulaLandingPage() {

   
const params = useParams()
const userId = params.UserId

    return (
        <div>
            <>
                <div className="container-fluid p-2 px-4">
                <img  src={oula_logo} alt="logo" width="70px" />
                    <div className='row justify-content-center' >
                        
                        <div className={`row p-2 ${styles3.login_container}`} >

                          

                            <div className="col-sm-7 col-12 bg-white align-self-center" >
                                <div className='row justify-content-center'>
                                    <div className={`${styles3.login_section} mt-2`} >
                                      
                                        <div className=" container" >



                                            <div className="container text-center">
                                                <img className={`styles3.login_logo}`} src={pepsico_logo} alt="logo" width="350px" />
                                                <p  style={{ color: '#000000', fontWeight: '700', fontSize: '1.8rem', paddingTop: '50px' }}>Welcome to Consumer Sprint 2024</p>



                                                <div class="mt-5">
                                                    <div id="accordion">

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne" style={{backgroundColor:'#fad71c'}}>
                                                                <h5 class="mb-0">
                                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        <p style={{fontSize:'1.5rem', fontWeight:'700', color:'black', paddingTop:'15px'}}>See Me</p>
                                                                    </button>
                                                                </h5>
                                                            </div>

                                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion" style={{backgroundColor:'#fad71c'}}>
                                                                <div class="card-body" >
                                                                    <ul className='text-left'>
                                                                        <li><a href={`/sharable-quick-survey/66a0c91fcdeb83aa15887497/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Visit to the restaurant</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a0c9efcdeb83aa158874ad/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>A visit to a dhaba</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a0cab9cdeb83aa15887544/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>A visit to a grocery store</a></li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="card">
                                                            <div class="card-header" id="headingTwo" style={{backgroundColor:'#fad71c'}}>
                                                                <h5 class="mb-0">
                                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    <p style={{fontSize:'1.5rem', fontWeight:'700', color:'black', paddingTop:'15px'}}>Live Like Me</p>
                                                                    </button>
                                                                </h5>
                                                            </div>

                                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion" style={{backgroundColor:'#fad71c'}}>
                                                                <div class="card-body">
                                                                    <ul className='text-left'>
                                                                        <li><a href={`/sharable-quick-survey/66a0cc62cdeb83aa15887590/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Adopt a budget</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a0cdebcdeb83aa1588760f/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Watch your calories</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a3bba7191555682b447d6b/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Consume what's trending</a></li>
                                                                       
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="card">
                                                            <div class="card-header" id="headingThree"  style={{backgroundColor:'#fad71c'}}>
                                                                <h5 class="mb-0">
                                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    <p style={{fontSize:'1.5rem' , fontWeight:'700', color:'black', paddingTop:'15px'}}>Understand Me</p>
                                                                    </button>
                                                                </h5>
                                                            </div>

                                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion" style={{backgroundColor:'#fad71c'}}>
                                                                <div class="card-body">
                                                                    <ul className='text-left'>
                                                                        <li><a href={`/sharable-quick-survey/66a3bc03191555682b447da1/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Session with nutritionist</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a3bca7191555682b447e11/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Session with psychologist</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a3bc71191555682b447dd7/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Session with Life bloggers</a></li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card" >
                                                            <div class="card-header" id="headingFour" style={{backgroundColor:'#fad71c'}}>
                                                                <h5 class="mb-0">
                                                                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" >
                                                                    <p style={{fontSize:'1.5rem', fontWeight:'700', color:'black', paddingTop:'15px'}}>Walk With Me</p>
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            </div>
                                                            <div class="card" >
                                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion" style={{backgroundColor:'#fad71c'}}>
                                                                <div class="card-body">
                                                                    <ul className='text-left'>
                                                                        <li><a href={`/sharable-quick-survey/66a3bd78191555682b447ee1/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>Interaction with retailers</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a3be04191555682b448000/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>One on one with consumers - Gen Z</a></li>
                                                                        <li><a href={`/sharable-quick-survey/66a3be5a191555682b448010/${userId}`} style={{fontSize:'1.2rem', color:'black'}}>One on one with consumers - House Wives</a></li>
                                                                        
                                                                    </ul>
                                                                    
                                                                       
                                                                    
                                                                </div>
                                                            </div>
                                                            </div>
                                                      
                                                    </div>
                                                </div>














                                            </div>
                                        </div>
                                    </div>
                                </div>










                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

