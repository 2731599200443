import axios from 'axios';
import { ADD_PROJECT_INTRO, CREATE_PROJECT, DEL_PROJECT, EDIT_PROJECT_INTRO, EDIT_PROJECT_STATUS, GET_PROJECT_DETAILS, PARTICIPANTS_PROJECTS, PROJECT_ACTIVITIES_REPORT, PROJECT_ATTACHMENTS, PROJECT_AVAILABLE, PROJECT_EXCERPTS_REPORT, PROJECT_INTRODUCTION, PROJECT_PARTICIPANTS_REPORT, PROJECT_RECORDINGS_ATTACHMENTS, PROJECT_REPORT, PROJECT_SCREENER_ATTACHMENTS, PROJECT_SCREENER_REPLY_ATTACHMENTS, SEARCH_PROJECT_COMMENTS, SEARCH_PROJECT_POSTS, SEARCH_PROJECT_POST_ATTACHMENTS, SEARCH_PROJECT_WORD_CLOUD, UPDATE_PROJECT, PARTICIPANTS_PROJECTS_COMPLETION_PERCENTAGE } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

// ****************  These apis are used in ADMIN & RESEARCHER END in Projects Section *********************

// creates new project in the system
export const createProject = (projectData) => {


    let url = `${BACK_SERVER_URL}api/projects/`;

    
    const request = axios.post(url, projectData)
        .then(response => response.data);

    return {
        type: CREATE_PROJECT,
        payload: request
    }
}

// Returns an array of projects to display project listing
export const getAllProjects = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/projects?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: PROJECT_AVAILABLE,
        payload: request
    }
}

// Returns an object containing project details against specific id 
export const getProject = (id) => {

    let url = `${BACK_SERVER_URL}api/projects/` + id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_PROJECT_DETAILS,
        payload: request
    }
}

// deletes project against the id we pass in the param
export const delProject = (id) => {

    let url = `${BACK_SERVER_URL}api/projects/` + id;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DEL_PROJECT,
        payload: request
    }
}

// updates project against the id we pass in the param
export const updateProject = (id, data) => {

    let url = `${BACK_SERVER_URL}api/projects/` + id;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: UPDATE_PROJECT,
        payload: request
    }
}

// Used in create project section after creating a project if user uploads any document
export const addProjectDocument = (id, data) => {

    let url = `${BACK_SERVER_URL}api/projects/upload-doc/` + id;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: UPDATE_PROJECT,
        payload: request
    }
}

// Used for changing project status to complete
export const changeProjectStatus = (id, data) => {

    let url = `${BACK_SERVER_URL}api/projects/status/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_PROJECT_STATUS,
        payload: request
    }
}

// Used to display active projects in dropdown of BROADCAST MESSAGES & CAMPAIGN TEMPLATE
// Returns an array of active projects 
export const getAllActiveProjects = () => {

    let url = `${BACK_SERVER_URL}api/projects/active`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: UPDATE_PROJECT,
        payload: request
    }
}

// ******* Used inside Project Introduction Section of PROJECTS for both ADMIN & RESEARCHER END *********
export const getProjectIntro = (data) => {

    let url = `${BACK_SERVER_URL}api/introductions/project/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_INTRODUCTION,
        payload: request
    }
}

export const addProjectIntro = (data) => {
    console.log('data project intro api add->', data)
    let url = `${BACK_SERVER_URL}api/introductions/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ADD_PROJECT_INTRO,
        payload: request
    }
}

export const editProjectIntro = (id, data) => {
    console.log('data project intro api edit->', data)
    let url = `${BACK_SERVER_URL}api/introductions/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_PROJECT_INTRO,
        payload: request
    }
}


// ************* Used inside Attachments Section of Projects for both ADMIN & RESEARCHER **************
// Used to display different files, images, videos of a project in listing

export const ProjectScreenerAttachment = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/screener/attachments/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_SCREENER_ATTACHMENTS,
        payload: request
    }
}

export const getProjectAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/attachments`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_ATTACHMENTS,
        payload: request
    }
}

export const getProjectScreenerReplyAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/screener/attachments/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_SCREENER_REPLY_ATTACHMENTS,
        payload: request
    }
}

export const getProjectRecordingAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/recordings/attachments/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_RECORDINGS_ATTACHMENTS,
        payload: request
    }
}

// ********** Used inside Report Section of Projects for both ADMIN & RESEARCHER **************
// Used to generate graphs and activities listing

export const projectParticipantsReport = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/participants/report`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_PARTICIPANTS_REPORT,
        payload: request
    }
}

export const projectActivitiesReport = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/activities/report`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_ACTIVITIES_REPORT,
        payload: request
    }
}

export const projectExcerptReport = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/excerpts/report`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_EXCERPTS_REPORT,
        payload: request
    }
}

// Returns downloadable link to export an excel file of project report
export const projectReport = (projectId) => {

    let url = `${BACK_SERVER_URL}api/projects/report/export/${projectId}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: PROJECT_REPORT,
        payload: request
    }
}


// Following apis used to search result in attachments, word cloud, posts and comments against a search string and a project we pass in the param
export const searchPostAttachments = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/search/attachments`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SEARCH_PROJECT_POST_ATTACHMENTS,
        payload: request
    }
}

export const searchWordCloud = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/search/word-cloud`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SEARCH_PROJECT_WORD_CLOUD,
        payload: request
    }
}

export const searchPost = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/search/posts`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SEARCH_PROJECT_POSTS,
        payload: request
    }
}

export const searchComments = (data) => {

    let url = `${BACK_SERVER_URL}api/projects/search/comments`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SEARCH_PROJECT_COMMENTS,
        payload: request
    }
}

// *************** Used in RESPONDENT & RESEARCHER END ****************

// Returns participants active, upcoming, invited projects to display in projects listing
export const participantsProjects = (page, limit, data) => {


    let url = `${BACK_SERVER_URL}api/projects/participant-projects?page=${page}&limit=${limit}&todaysDate=${new Date().toISOString()}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PARTICIPANTS_PROJECTS,
        payload: request
    }
}


export const ResearcherProjects = (page, limit, data) => {


    let url = `${BACK_SERVER_URL}api/projects/researcher-projects?page=${page}&limit=${limit}&todaysDate=${new Date().toISOString()}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PARTICIPANTS_PROJECTS,
        payload: request
    }
}

export const participantsProjectsDashboard = () => {


    let url = `${BACK_SERVER_URL}api/projects/participant-projects-dashboard`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: PARTICIPANTS_PROJECTS,
        payload: request
    }
}

export const  getProjectsCompletionPercentage=(data, userId) =>{

let url = `${BACK_SERVER_URL}api/projects/projectCompletionPercentage/${userId}`
const request = axios.post(url,data)
.then(response => response.data)
return {
    type: PARTICIPANTS_PROJECTS_COMPLETION_PERCENTAGE,
    payload: request
}
}