import React from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap'



// ********************** This Component is used in ADMIN & RESEARCHER END in Project's Groupings Screen *************************
// ****************** Used To plot different graphs based on Question Type by sending Questions Array in props **********************

const SurveyCharts = (props) => {

    const { surveyResponse } = props

    const getRadioCheckboxGraphs = (response) => {
        let series = []
        let label = []
        response.availableOptions.length > 0 && response.availableOptions.map(option => {
            series.push(option.count)
            label.push(option.text)
        })

        return {
            options: {
                chart: {
                    id: 'apexchart-example-2'
                },
                labels: label,
            },
            series: series,
        }

    }

    const getImageVideoGraph = (response) => {
        return {
            options: {
                labels: ['Responses'],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
            },
            series: [response.count],
        }
    }

    const getLongTextGraph = (response) => {
        return {
            series: [
                {
                    name: 'Responses',
                    data: [response.longResponse.count, response.shortResponse.count]
                }
            ],
            options: {
                chart: {
                    id: 'apexchart-example-3',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                width: "20",
                xaxis: {
                    categories: ['Long Responses', 'Short Responses']
                },
            }
        }
    }

    const getImageVideoTextGraph = (response) => {
        return {
            series: [
                {
                    name: 'Responses',
                    data: [response.fileResponse.count, response.textResponse.count]
                }
            ],
            options: {
                chart: {
                    id: 'apexchart-example-3',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                width: "20",
                xaxis: {
                    categories: ['File Responses', 'Text Responses']
                },
            }
        }
    }

    const getGridGraph = (response) => {
        let rowName = []
        let responseData = []
        let colName = []
        response.availableGridOptions.map((gridOption, index) => {
            let colCount = []

            gridOption.options.map((option, optionIndex) => {
                // console.log(option, optionIndex)
                colCount.push(option.count)
                colName.push(option.text)

            })

            responseData.push(colCount)
            rowName.push(gridOption.text)
        })

        const transpose = (array, arrayLength) => {
            var newArray = [];
            for (var i = 0; i < array.length; i++) {
                newArray.push([]);
            };

            for (var i = 0; i < array.length; i++) {
                for (var j = 0; j < arrayLength; j++) {
                    newArray[j].push(array[i][j]);
                };

                if (arrayLength < array[0].length) {
                    for (let index = arrayLength; index < array[0].length; index++) {
                        newArray.push([])
                        newArray[index].push(array[i][index]);

                    }
                }
            };

            return newArray;
        }
        
        // transposing col array to fit data as per graphs requirement
        let transposedData = transpose(responseData, responseData.length)

        // let series = _.zip(responseData)
        let graphSeries = transposedData.map((series, index) => {
            if (series[0] !== undefined) {
                return {
                    name: colName[index],
                    data: series
                }
            }
        })

        graphSeries = graphSeries.filter(Boolean)
        // console.log(graphSeries)

        return {
            series: graphSeries,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: rowName,
                },
                legend: {
                    position: 'top',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                stroke: {
                    show: true,
                    width: 25,
                    colors: ['transparent'],
                    dashArray: 10
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return + val + " Responses"
                        }
                    },

                }
            }
        }
    }
    // const getGridGraph = (response) => {
    //     let rowName = []
    //     let responseData = []
    //     let colName = []
    //     response.availableGridOptions.map((gridOption, index) => {
    //         let colCount = []

    //         gridOption.options.map((option, optionIndex) => {
    //             colCount.push(option.count)
    //             colName.push(option.text)
    //         })

    //         responseData.push(colCount)
    //         rowName.push(gridOption.text)
    //     })

    //     let series = _.zip(responseData)

    //     const graphSeries = series.map( (series, index) => {
    //         return {
    //             name: colName[index],
    //             data: series[0]
    //         }
    //     })

    //     return {
    //         series: graphSeries,
    //         options: {
    //             chart: {
    //                 type: 'bar',
    //                 height: 350
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     horizontal: false,
    //                     columnWidth: '55%',
    //                     endingShape: 'rounded'
    //                 },
    //             },
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             stroke: {
    //                 show: true,
    //                 width: 2,
    //                 colors: ['transparent']
    //             },
    //             xaxis: {
    //                 categories: rowName,
    //             },
    //             yaxis: {
    //                 title: {
    //                     // text: '$ (thousands)'
    //                 }
    //             },
    //             fill: {
    //                 opacity: 1
    //             },
    //             tooltip: {
    //                 y: {
    //                     formatter: function (val) {
    //                         return + val + " Responses"
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    return (
        <div>
            {
                surveyResponse.length > 0 &&
                surveyResponse.map((response, index) => {
                    return <div className="col-12 pb-3 px-2">
                        <Card className="main-card" style={{ height: '100%' }}>
                            <CardBody className="">
                                <p style={{ fontWeight: '500' }}>Q{index + 1}. {response.questionText}</p>

                                {
                                    (response.questionType === 'radio' || response.questionType === 'checkbox' || response.questionType === 'spectrum') &&

                                    <div className="donut text-center">
                                        <Chart options={getRadioCheckboxGraphs(response).options} series={getRadioCheckboxGraphs(response).series} type="donut" width="100%" />
                                    </div>
                                }

                                {
                                    (response.questionType === 'image' || response.questionType === 'video') &&
                                    <div className="radialBar">
                                        <Chart options={getImageVideoGraph(response).options} series={getImageVideoGraph(response).series} type="radialBar" width="100%" />
                                    </div>
                                }

                                {
                                    response.questionType === 'long text' &&
                                    <div className="radialBar">
                                        <Chart options={getLongTextGraph(response).options} series={getLongTextGraph(response).series} type="bar" width="100%" />
                                    </div>
                                }

                                {
                                    (response.questionType === 'image text' || response.questionType === 'video text') &&
                                    <div className="radialBar">
                                        <Chart options={getImageVideoTextGraph(response).options} series={getImageVideoTextGraph(response).series} type="bar" width="100%" />
                                    </div>
                                }

                                {
                                    response.questionType === 'grid' &&

                                    <div style={{ overflowX: 'scroll' }}>
                                        <Chart options={getGridGraph(response).options} series={getGridGraph(response).series} type="bar" height="400" width={getGridGraph(response).series[0].data.length * 65} />
                                    </div>
                                }

                            </CardBody>
                        </Card>


                    </div>
                })
            }
        </div>
    )
}

export default SurveyCharts