import React, { useState } from 'react'
import styles from '../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import { Button, Card, CardBody } from "reactstrap";
import { useSelector } from 'react-redux';
const EmploymentDetails = (props) => {

    const { userDetails, setUserDetails, isEdit } = props
    const systemVariables = useSelector( state => state.SystemSettings.specificOptions )

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <Card className="main-card mb-3 mt-4" >
                <CardBody>
                    <p style={{ fontWeight: '500' }}>Q1. Relation with Chief Wage Earner CWE</p>
                    {!isEdit ? (
                        <p className="text-muted">{userDetails.cwe}</p>

                    ) : (
                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <select className="form-control" value={userDetails.cwe}
                                    onChange={(e) => setUserDetails({ ...userDetails, cwe: e.target.value })}>
                                    <option selected={true} disabled={true} value='' >Select</option>
                                    {
                                        systemVariables.relationship && systemVariables.relationship.map(relationship => {
                                            return <option value={relationship.name}>{relationship.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>

            <Card className="main-card mb-3 mt-4" >
                <CardBody>
                    <p style={{ fontWeight: '500' }}>Q2. What is the name of the company you/ your CWE work for?</p>
                    {!isEdit ? (
                        <p className="text-muted">{userDetails.company}</p>

                    ) : (
                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <input className="form-control" rows="3" style={{ resize: 'none' }} placeholder="Answer here" value={userDetails.company}
                                    onChange={(e) => setUserDetails({ ...userDetails, company: e.target.value })} />
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>

            <Card className="main-card mb-3 mt-4" >
                <CardBody>
                    <p style={{ fontWeight: '500' }}>Q3. What is your/ your CWE job title?</p>
                    {!isEdit ? (
                        <p className="text-muted">{userDetails.jobRole}</p>

                    ) : (
                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <input className="form-control" rows="3" style={{ resize: 'none' }} placeholder="Answer here" value={userDetails.jobRole}
                                    onChange={(e) => setUserDetails({ ...userDetails, jobRole: e.target.value })} />
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>

            {
                isEdit &&
                <div className="col-12 text-right">
                    <Button className={` ${styles.bg_blue} text-white mr-3 btn`} onClick={() => props.editUser()}>Save</Button>
                </div>
            }

        </TransitionGroup>
    )
}

export default EmploymentDetails
