import { AVAILABLE_ACTIVITY_ATTACHMENTS } from "../actions/types";

let activityAttachmentsDataState = { attachments: [], totalPages: 0, currentPage: 1 };

export default function (state = activityAttachmentsDataState, action) {

    switch (action.type) {
        case AVAILABLE_ACTIVITY_ATTACHMENTS:
            if (state.attachments.length === 0) {
                return {
                    ...state,
                    attachments: action.payload.content.attachments,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.attachments = state.attachments.concat(action.payload.content.attachments);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    attachments: concatResult.attachments,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                }
            }

        default:
            return state;
    }
}

