import React from 'react';
import styles3 from '../../../assets/css/newStyling.module.css'
import styles from '../../../assets/css/styling.module.css';

const Group_Forum_Details_View = (props) => {




 
    return (
        <div className="col-12 mb-4 mt-2">
            <p style={{ color: "#000", fontWeight: 400, fontSize: '45px' }} className="mb-0">
                {props.title}
            </p>
            <div className={styles.card_border}>

                <div className={styles.card_border_body + "py-4"} style={{display:'flex'}}>
                    <span >
                        <img src='https://www.svgrepo.com/show/382106/male-avatar-boy-face-man-user-9.svg' className={`${styles3.group_forum_admin_avatar} ml-md-5 ml-0 my-2`} alt="Admin Avatar"></img>
                    </span>
                    {/* Forum Topic Heading */}
                    <span>
                    <h5 style={{ color: "#000", fontWeight: 500, fontSize: "20px" }} className="my-2 mx-4 mt-3">
                        {props.question}
                    </h5>
                    </span>
                    

                </div>

               

            </div>
            {props.postedTime && <div className='d-flex' style={{justifyContent:'end'}}>
                
                <p style={{color: '#ada7a7',fontWeight: '500'}}>{new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(props.postedTime))}
 - {props.admin}</p>
            </div>}
            
        </div>
    )

}

export default Group_Forum_Details_View;