import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody } from "reactstrap";
import styles from '../../../../../../assets/css/rstyling.module.css'
import { VerticalTimeline } from "react-vertical-timeline-component";
import avatar from "../../../../../../assets/images/profile_placeholder.png";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { editSurveyReply, getSurveyReply} from '../../../../../../actions/activities_reply_actions';
import ArrowLeft from "../../../../../../assets/images/arrow_left.png";
import CrossICon from '../../../../../../assets/images/close_icon.png'
import SurveyResponsesView from '../../../../../Admin/Project/Activity/view/SurveyResponsesView';


// *************** This file is called on click of view button of participants listing to show users specific response of Activity Type Survey. ****************

const SurveyAnalysis = (props) => {

    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()

    const [isLoading, setLoading] = useState(true)
    const [surveyReply, setSurveyReply] = useState({})
    // for additional question
    const [question, addQuestion] = useState('');
    const [questionText, setQuestionText] = useState('')

    useEffect(() => {
        getSurveyResponse()
    }, [])
    console.log(params)
    const getSurveyResponse = () => {
        dispatch(getSurveyReply(params.userid, params.activityid))
            .then((response) => {

                // Formatting questions and answers
                const survey = response.payload.content.activity
                const replies = response.payload.content.pages
                let result = []
                for (let i = 0; i < survey.survey.pages.length; i++) {
                    for (let j = 0; j < survey.survey.pages[i].questions.length; j++) {
                        replies.map(replyy => {
                          
                            replyy.questions.map(reply => {
                                
                            if (reply.questionId === survey.survey.pages[i].questions[j]._id) {
                                result.push({ ...survey.survey.pages[i].questions[j], ...reply, _id: survey.survey.pages[i].questions[j]._id })
                            }})
                        })
                    }

                    
                }
                setSurveyReply({
                    author: response.payload.content.author,
                    activityName: response.payload.content.activity.name,
                    result: result,
                    additionalQuestions: response.payload.content.additionalQuestions,
                    additionalAnswers: response.payload.content.additionalAnswers
                })
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    // For asking additional questions
    const saveQuestion = () => {
        const formData = new FormData()
        formData.append(`additionalQuestions[0]`, questionText)

        addQuestion('')
        setQuestionText('')

        dispatch(editSurveyReply(params.userid, formData))
            .then((response) => {
                getSurveyResponse()
                Swal({
                    title: "Added",
                    text: "Question Added",
                    icon: 'info'
                })
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }


    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="container-fluid">


                    {/* Heading */}
                    <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }} className="my-4">
                        {surveyReply.activityName}

                    </h5>

                    <Card className="main-card mb-3">
                        <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                            <div className='mr-auto'>
                                <img src={surveyReply.author && surveyReply.author.profileImage ? surveyReply.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                {surveyReply.author && surveyReply.author.userName}, Responses
                            </div>

                        </CardHeader>

                        <CardBody>

                            <VerticalTimeline layout="1-column"
                                className="vertical-time-simple vertical-without-time">

                                {
                                    surveyReply.result && surveyReply.result.length > 0 && surveyReply.result.map((question, index) => {
                                        return <SurveyResponsesView question={question} questionIndex={index} author={surveyReply.author.userName}/>
                                    })
                                }

                            </VerticalTimeline>

                        </CardBody>
                    </Card>

                    {/* Question */}

                    {
                        surveyReply.additionalQuestions && surveyReply.additionalQuestions.length > 0 &&
                        <Card className="main-card mb-3 p-3">

                            <h6 style={{ color: "#888", fontWeight: 500, fontSize: "1rem" }} className="mb-3">
                                Question
                            </h6>

                            {
                                surveyReply.additionalQuestions && surveyReply.additionalQuestions.map((question, index) => {
                                    return <div className='my-2'>
                                        <p style={{ color: '#888', fontSize: "0.96rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">
                                            {`Q.${index + 1}: ${question}`}
                                        </p>
                                        {/* Answer   */}

                                        {
                                            surveyReply.additionalAnswers[index] &&
                                            <li style={{ color: '#006FA7', fontSize: "0.96rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className=" mt-2 ml-2">
                                                {surveyReply.additionalAnswers[index]}
                                            </li>
                                        }
                                    </div>

                                })
                            }
                        </Card>

                    }
                </div>

                {/* Additional Questions */}
                {/* <div className="col-12 text-right mb-2">
                    <button className={`btn ${styles.bg_blue} text-white`} onClick={() => addQuestion('Write Question here')}>Add Additional Questions</button>
                </div> */}

                {
                    question && <>
                        <div className="d-flex mt-3 mx-3">
                            <div className={`mr-auto ml-2 mb-2 ${styles.text_bold}`}>Question</div>
                            <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" onClick={() => addQuestion('')}>
                                <img src={CrossICon} alt="" width="15px" />
                            </button>
                        </div>
                        <div className='mx-3'>
                            <input type="text" placeholder="Write Question here" value={questionText} className="form-control mb-3"
                                onChange={(e) => setQuestionText(e.target.value)}
                            />
                        </div>


                        <div className="col-12 text-right">
                            <button className={`btn ${styles.bg_blue} text-white`} onClick={() => saveQuestion()}>Save</button>
                        </div> </>
                }
            </LoadingOverlay>


        </TransitionGroup >
    )
}

export default SurveyAnalysis
