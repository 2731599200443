import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import styles from '../../../../../assets/css/rstyling.module.css'
import avatar from "../../../../../assets/images/profile_placeholder.png";
import {
    CardHeader,
    Card,
} from "reactstrap";
import { getAllPostReplyAttachments, getAllSurveyReplyAttachments } from '../../../../../actions/activities_reply_actions';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";

const Files = () => {

    const attachmentsState = useSelector(state => state.Attachments)
    const [isLoading, setLoading] = useState(true)
    const [isSurvey, setSurveyMode] = useState(false)
    const params = useParams()
    const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const path = currentPath

    const [attachments, setAttachments] = useState([])

    const getAttachments = (page = 1, limit = 10) => {
        if (path == `/admin/project/journal-analytics/${params.projectid}/${params.activityid}` || path == `/admin/generalforum-analytics/${params.activityid}` || path == `/admin/project/forum-analytics/${params.projectid}/${params.activityid}`) {
            dispatch(getAllPostReplyAttachments({ page: page, limit: limit, activityId: params.activityid }))
                .then((response) => {
                    setAttachments(response.payload.content.attachments)
                    setSurveyMode(false)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        } else {
            dispatch(getAllSurveyReplyAttachments({ page: 1, limit: 10, activityId: params.activityid }))
                .then((response) => {
                    setAttachments(response.payload.content.attachments)
                    setSurveyMode(true)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }

    }

    const next = () => {
        getAttachments(attachmentsState.currentPage + 1, 10)
    }

    useEffect(() => {
        getAttachments()
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {
                    attachments.length > 0 ? attachments.map((attachment, index) => {
                        return <Card className="main-card mb-3">
                            <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                                <img src={attachment.author && attachment.author.profileImage ? attachment.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                {attachment.author && attachment.author.userName}, Responses
                            </CardHeader>

                            {
                                isSurvey ?
                                // For Activity Type Survey, Quick Survey or Screener
                                    (attachment.replies && attachment.replies.length > 0 ? attachment.replies.map(reply => {
                                        return <VerticalTimeline layout="1-column"
                                            className="vertical-time-simple vertical-without-time">
                                            <VerticalTimelineElement className="vertical-timeline-item" >

                                                <div className="widget-content ">
                                                    <div className="widget-content-wrapper mb-2">

                                                        <div className="widget-content-left">
                                                            <div className="widget-subheading mt-1 opacity-10">
                                                                <h4 className="timeline-title" style={{ fontWeight: '500' }}>Q. {reply.questionText}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right">
                                                            <span className="font-size-sm text-muted">
                                                                {new Date(attachment.created_at).toLocaleDateString()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-wrapper audio_without_timeline">
                                                        <div className={` ${styles.text_blue}`} style={{ paddingLeft: '15px' }}>
                                                            {
                                                                reply.responseFiles.length > 0 && reply.responseFiles.map(file => {
                                                                    if (file.split('.').pop() === 'mp4') {
                                                                        return <video width={300} controls className="img-fluid- rounded" >
                                                                            <source src={file} type="video/mp4" />
                                                                        </video>
                                                                    } else {
                                                                        return <a href={file} download><img src={file} width="250px" height="250px" alt="" className="rounded" /></a>
                                                                    }
                                                                })
                                                            }
                                                        </div>

                                                    </div>
                                                </div>

                                            </VerticalTimelineElement>
                                        </VerticalTimeline>
                                    }) :
                                        <div className={"pb-2 ml-5"}>
                                            <div className="row px-3">
                                                <div className="col -12 mt-3 align-self-center text-center">
                                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Files Found</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    // For Activity Type Diary or forum
                                    (attachment.posts && attachment.posts.length > 0 ? attachment.posts.map(post => {
                                        return <VerticalTimeline layout="1-column"
                                            className="vertical-time-simple vertical-without-time">
                                            <VerticalTimelineElement className="vertical-timeline-item" >

                                                <div className="widget-content ">
                                                    <div className="widget-content-wrapper mb-2">
                                                        {
                                                            post.images.length > 0 && post.images.map(file => {
                                                                return <a href={file} download><img src={file} width="250px" height="250px" alt="" className="rounded" /></a>
                                                            })
                                                        }
                                                        <div className="widget-content-right">
                                                            <span className="font-size-sm text-muted">
                                                                {new Date(post.createdAt).toLocaleDateString()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </VerticalTimelineElement>
                                        </VerticalTimeline>
                                    }) :
                                        <div className={"pb-2 ml-5"}>
                                            <div className="row px-3">
                                                <div className="col -12 mt-3 align-self-center text-center">
                                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Files Found</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </Card>
                    }) :
                        <div className={"pb-2 ml-5"}>
                            <div className="row px-3">
                                <div className="col -12 mt-3 align-self-center text-center">
                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Files Found</span>
                                </div>
                            </div>
                        </div>
                }

                {
                    attachmentsState.totalPages > 0 && attachmentsState.totalPages !== attachmentsState.currentPage &&
                    <div className={"pb-2 ml-5"}>
                        <div className="row px-3">
                            <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Files</span>
                            </div>
                        </div>
                    </div>
                }


            </LoadingOverlay>

        </TransitionGroup >
    )
}

export default Files
