import React, { useState, useEffect } from 'react';
import { Input, Button, Card, CardBody, InputGroup } from 'reactstrap';
import styles from '../../../../../../assets/css/rstyling.module.css'
import ArrowLeft from '../../../../../../assets/images/arrow_left.png';
import InterviewIcon from '../../../../../../assets/images/online_interview_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import { getActivity, getActivityImg } from '../../../../../../actions/activities_actions';
import ResizeDetector from 'react-resize-detector';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const OnlineInterviewPreview = (props) => {

    const location = useLocation();
    const history = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [interviewDetails, setInterviewDetails] = useState({})
    const [bannerImg, setBannerImg] = useState({})
    useEffect(() => {

        dispatch(getActivityImg(params.onlineinterviewid))
        .then((response) =>{
            try{
            console.log('banner img in preview mode indepth->',response.payload.content.fileData)
            setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        })
        if (params.onlineinterviewid !== 'undefined') {
            dispatch(getActivity(params.onlineinterviewid))
                .then((response) => {
                    setInterviewDetails(response.payload.content)
                    setLoading(false)
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)

        }

    }, [])

    const [currentWidth, setWidth] = useState(1026)

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // top: '50%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>
                    <>
                        <div className="activity_header">
                            <PageHeader
                                heading="Online In-depth Interview Activity" img={InterviewIcon}
                                btnGroup={true}
                                btn1Click={() => setWidth(1026)}
                                btn2Click={() => setWidth(470)}
                                btn3Click={() => setWidth(400)}
                            />
                        </div>

                        {/* Back Icon */}
                        <span onClick={() => location.state && location.state.from === 'create' ?
                                history(`/researcher/project/edit-onlineinterview/${params.projectid}/${params.onlineinterviewid}`) :
                                history(-1)} style={{ cursor: 'pointer' }}>
                            <img src={ArrowLeft} alt="" width="8px" />
                            <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                        </span>

                        <div className="container-fluid" style={{ width: currentWidth }}>
                            {/* Image Display */}

                            {
                                interviewDetails.bannerImage &&
                                <div className="mx-3 mb-4">
                                    <div className="pt-3">
                                        <div className="position-relative">
                                            <img src={interviewDetails.bannerImage} className={`img-fluid border ${styles.banner_image}`} alt="img" width={200} />
                                        </div>
                                    </div>
                                </div>

                            }

                            <Card className="main-card mx-3 mt-3 mb-4">
                                <CardBody>
                                    <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Description</h6>
                                    {interviewDetails.description}
                                </CardBody>
                            </Card>
                            <div style={{ fontWeight: 'bold', fontSize: "1rem" }} className="text-muted mx-3">{interviewDetails.name}</div>

                            <div className="row mx-2">

                                <div className="col-sm-6 col-12">
                                    <div className={styles.heading_light}>Duration (Days):</div>
                                    <div className={styles.text_bold}>{interviewDetails.days}</div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className={styles.heading_light}>Duration Per Day (Minutes)</div>
                                    <div className={styles.text_bold}>{interviewDetails.duration}</div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className={styles.heading_light}>Priority:</div>
                                    <div className={styles.text_bold}>{interviewDetails.priority}</div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className={styles.heading_light}>Start date:</div>
                                    <div className={styles.text_bold}>{new Date(interviewDetails.startDate).toLocaleDateString()}</div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className={styles.heading_light}>Meeting Link:</div>
                                    <div className={styles.text_bold}>{interviewDetails.meetingLink}</div>
                                </div>
                            </div>

                            <div className="row mt-5 mx-2">
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold', fontSize: "1rem" }}>Interview Meeting Address</div>
                                    <div className="mb-2">Easily copy pasting the link to join the meeting</div>
                                    <div className="d-flex m-0">
                                        <div className="col-md-10 col-sm-8 col-8 p-0">
                                            <InputGroup className="custom_input_group">
                                                <Input type="text" name="city" id="city" readOnly value={interviewDetails.meetingLink} />
                                                <InputGroup addonType="append">
                                                    <Button outline style={{ border: '1px solid #006FA7', borderRadius: '0.5rem' }} className={`${styles.bg_gray} ${styles.text_blue}`}>Copy Link</Button>{" "}
                                                </InputGroup>
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-2 col-sm-4 col-4 ">
                                            <Button className={`btn ${styles.gradient_grbl}`}>Join Meeting</Button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </>
                </LoadingOverlay>

            )}
        />
    )


}

export default OnlineInterviewPreview


