import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";

import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';
import "./assets/base.scss";
import "./assets/css/rstyle.css";
import './assets/css/style.css';
import { Provider } from "react-redux";
import {GoogleOAuthProvider} from "@react-oauth/google";
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import Reducer from './config/configureStore';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore);





root.render(
	<Provider
		store={createStoreWithMiddleware(
			Reducer
			,window.__REDUX_DEVTOOLS_EXTENSION__ &&
			window.__REDUX_DEVTOOLS_EXTENSION__()
		)}
	>
		{/* <BrowserRouter> */}
		<GoogleOAuthProvider
        clientId={`777718830601-vq8tg3hi6upbt6a6jnoin6s3nbgv3dob.apps.googleusercontent.com`}
		//clientId={`589679233232-6k6bdo69l01qn1psq2ksnonmu3g0mk3e.apps.googleusercontent.com`}
				>
					
       <App />
	  
       </GoogleOAuthProvider>
	 
		{/* </BrowserRouter> */}
	</Provider>
	// , document.getElementById('root')
);

serviceWorker.unregister();