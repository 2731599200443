import React, {useState, useEffect} from 'react';
import Slider from "rc-slider";
import YouTube from 'react-youtube';
import styles from '../../css/styling.module.css';
import { getActivityVideo2, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Question_With_Spectrum2 = (props) => {

    // On option change
    const onOptionChange = (value1, value2) => {
       



props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected={text: '', points: ''}
        props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected ={
            text: value1,
            points: value2
        }
        //props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected2 = value2;
        props.setContent({ ...props.allContent });
       
        

    }

    const handleMarks = () => {

let result1 = props.pointsOptions.map(a => a.text);
let result2 = props.pointsOptions.map(b=> b.points);
        let textt = result1.reduce((arr, val, index) => ({
            ...arr,
            [parseInt(((index + 1) / result1.length) * 100)]: val

        }), {})
        let pointss = result2.reduce((arr, val, index) => ({
            ...arr,
            [parseInt(((index + 1) / result1.length) * 100)]: val

        }), {})


        return {
            text: textt,
            points: pointss
        }
    }
//     const optionSelected = ()=>{
//         let select = props.pointsOptions.map(a => a.text);
//         let select2 = props.pointsOptions.map(b => b.points);

//         return select;
// // return {
// // text: select,
// // //points: select2
// // };
//     }
    const opts = {
        width: '640',
        height: '360',
        playerVars: {
          autoplay: 0
        },
    };

    const params = useParams();
    const dispatch = useDispatch();
   
    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);

    const catId = props.categoryId
    const questionIndex = props.questionIndex
    useEffect(() => {
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo2(params.surveyid || params.ActivityId || params.activityid, catId, questionIndex))
                .then((response) => {
                    try {
                    
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('question video not available') }
                    setIsLoading(false);

                })

           
        }

}, []);

const makeLinksOpenInNewTab = (htmlContent) => {
    // Create a DOM element to parse the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    // Find all anchor tags within the parsed HTML content
    const anchorTags = tempElement.getElementsByTagName('a');

    // Iterate through the anchor tags and set the target attribute to '_blank'
    for (const anchorTag of anchorTags) {
        anchorTag.setAttribute('target', '_blank');
        // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
        anchorTag.setAttribute('rel', 'noopener noreferrer');
    }

    // Return the modified HTML content
    return tempElement.innerHTML;
}

    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {props.questionText &&
                          <span  style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question video */}
                        {props.questionVideo && (
                        <div>
                            
                            <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>
                            
                        </div>
                    )}

                    </p>

                   {/* Question Video */}
                   {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                   <b>Loading Video...</b>
                                                       ) : qVideo.data ? (
                                                        <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <ReactPlayer
                                                            url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                            controls
                                                            width ='100%'
                                                        />
                                                        </div>
                                                     ) : (
                                         <b>Error: No video with supported format and mime type found.</b>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mb-3 mt-3">
                                            
                                    {qVideo.data &&

                                        // Image Display
                                        <div>
                                            <div className="position-relative">
                                                <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </>


                    }


                    {/* Options */}
                    <div className="table-responsive">
                        <div>
                            <div className="mx-4" style={{ height:'160px' , marginTop: 20, textAlign: "center" }}>

                                
                                
                                <Slider
                                    dots
                                     included={false}
                                    min={parseInt((1 / props.pointsOptions.length) * 100) - 10}
                                    
                                    
                                    max={110}
                                    marks={handleMarks().text}
                                    
                                    //  value={(parseInt(((props.pointsOptions.indexOf(props.selected) + 1) / props.pointsOptions.length) * 100))}
                                      //value={(parseInt(((optionSelected().indexOf(props.selected) + 1) / optionSelected().length) * 100)) }
                                    step={null}
                                    onChange={e => onOptionChange(handleMarks().text[e], handleMarks().points[e])}
                                />

                            </div>
                           

                        </div>
                    </div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_Spectrum2;
