import { AVAILABLE_ACTIVITY_RECORDINGS, GET_RECORDING_DETAILS,  } from "../actions/types";

let recordingsDataState = { recordings:[], totalPages:0, currentPage: 1, recordingsDetails: {} };
export default function (state = recordingsDataState, action) {
    switch (action.type) {
        case AVAILABLE_ACTIVITY_RECORDINGS:
            if (state.recordings.length === 0) {
                return {
                    ...state,
                    recordings: action.payload.content.recordings,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.recordings =  state.recordings.concat(action.payload.content.recordings);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    recordings: concatResult.recordings,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case GET_RECORDING_DETAILS:
            return {...state, recordingsDetails: action.payload.content }
        default:
            return state;
    }
}
