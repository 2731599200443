import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import avatar from '../../../../assets/images/profile_placeholder.png'
import { useParams } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'
import { useNavigate } from 'react-router-dom';
import { getEmailTemplateDetails } from '../../../../actions/email_template_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert';
import ArrowLeft from '../../../../assets/images/arrow_left.png'

const ViewEmail = () => {
    const [emailTemplateDetails, setEmailTemplateDetails] = useState({})

    const emailTemplateState = useSelector(state => state.EmailTemplates)
    console.log(emailTemplateState)
    const [isLoading, setLoading] = useState(true)

    const params = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getEmailTemplateDetails(params.templateid))
            .then(response => {
                setEmailTemplateDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Something went wrong",
                    icon: 'error',
                }).then(() => history('/admin/templates/email'))


            })
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="admin_header">
                <PageHeader heading={`Email Title: ${emailTemplateDetails.title}`} img={TemplateIcon} />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid mt-3">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                            <img width={38} className="rounded-circle" src={avatar} alt="" />
                        </div>
                        <div className="widget-content-left">
                            <div className="widget-heading text-dark" style={{ fontSize: '1rem' }}>
                                {emailTemplateDetails.author && emailTemplateDetails.author.firstName + " " + emailTemplateDetails.author.lastName}
                            </div>
                            <div className="widget-subheading opacity-10" style={{ fontSize: '0.7rem' }}>
                                {emailTemplateDetails.author && emailTemplateDetails.author.userName}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5" dangerouslySetInnerHTML={{ __html: emailTemplateDetails.emailContent && emailTemplateDetails.emailContent }}>
                </div>
            </div>
        </TransitionGroup>

    )
}

export default ViewEmail
