import React, { useState, useEffect } from 'react'
import '../../../../../../assets/components/pageheader/PageHeader'
import styles from '../../../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import { Modal, ModalHeader, ModalBody, Button, Input, Label } from "reactstrap";
import { useNavigate } from 'react-router';
import DownloadIcon from '../../../../../../assets/images/download_icon.png'
import PlayIcon from '../../../../../../assets/images/play_icon.png'
import DeleteIcon from '../../../../../../assets/images/delete_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../../../assets/components/modal/AlertModal';
import Listing from '../../../../../../assets/components/listing/Listing';
import Dropzone from 'react-dropzone';
import { createRecording, deleteRecording, getActivityRecordings, getRecordingDetails } from '../../../../../../actions/recordings_action';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'
import { useParams, useLocation } from 'react-router-dom';


// ************* This file is only for Activity Type Online/ Inperson Interview and Focus Group Discussion ********************

const Recording = (props) => {


    const recordingState = useSelector(state => state.Recording)
    

    const dispatch = useDispatch()
    const params = useParams()
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const [isLoading, setLoading] = useState(true)

    const pathWithId = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const path = pathWithId.slice(0, pathWithId.lastIndexOf('/'))


    const [newRecording, setNewRecording] = useState({})
    const [recordingData, setRecordingsData] = useState([])
    const [downloadFile, setDownloadFile] = useState('')
    const [audioId, setAudioId] = useState('')
    const [delId, setDelID] = useState()

    const [uploadedAudio, uploadAudio] = useState('')
    const [isModalOpen, setModal] = useState(false)

    const [column] = useState([
        { Name: "Username", Type: 'object', key: 'author', value: 'userName' },
        { Name: "Role", Type: 'userRole', },
        { Name: "Title", Type: 'string', key: 'title' },
        { Name: "Date", Type: 'date', key: 'created_at' },
        { Name: "Time", Type: 'time', key: 'created_at' },
        { Name: "File Type", Type: 'file', key: 'file', width: 124 },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: DownloadIcon,
                    type: 'delete',
                    setID: setDownloadFile
                },
                {
                    icon: PlayIcon,
                    type: 'audio',
                    setAudio: setAudioId
                },
                {
                    icon: DeleteIcon,
                    setID: setDelID,
                    type: 'delete'
                },
            ]
        }
    ])


    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    // Show / hide modal of audio
    useEffect(() => {
        props.setAudio(audioId)
    }, [audioId])

    // Download file
    useEffect(() => {
        if (downloadFile) {
            dispatch(getRecordingDetails(downloadFile))
                .then((response) => {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + response.payload.content.file);
                    element.setAttribute('download', response.payload.content.file);
                    document.body.appendChild(element);
                    element.click();
                    element.remove()
                })
                .catch(err => {
                })

        }
    }, [downloadFile])



    const getRecordingsListing = (page = 1, limit = 5) => {
        dispatch(getActivityRecordings({ page: page, limit: limit, activityId: params.activityid }))
            .then((response) => {
                setRecordingsData(response.payload.content.recordings)
                setLoading(false)
            })
            .catch(err => {
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
                setLoading(false)
            })
    }

    const onDelRecording = () => {
        dispatch(deleteRecording(delId))
            .then((response) => {
                setDelID()
                getRecordingsListing(recordingState.currentPage)
                Swal({
                    title: "Deleted",
                    text: "Excerpt Deleted",
                    icon: 'success'
                })
            })
            .catch(err => {
                setDelID()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const onSaveRecording = () => {
        if (newRecording.title !== '' && uploadedAudio !== '') {
            const formData = new FormData()
            const activity = getActivityType()
            formData.append('activityType', activity)
            formData.append('activity', params.activityid)
            formData.append('title', newRecording.title)
            newRecording.activity && formData.append('activity', newRecording.activity)
            newRecording.respondent && formData.append('respondent', newRecording.respondent)
            formData.append('file', uploadedAudio)

            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            dispatch(createRecording(formData))
                .then((response) => {
                    Swal.stopLoading()
                    Swal.close()
                    setNewRecording({})
                    getRecordingsListing()
                    Swal({
                        title: "Saved",
                        text: "Recording Saved",
                        icon: 'success'
                    }).then(() => props.setMediaModal(false))
                })
                .catch(err => {
                    Swal.stopLoading()
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        }
    }


    const onSelectVideo = (file) => {
        uploadAudio(file)
    }

    useEffect(() => {
        getRecordingsListing()
    }, [])

    const getActivityType = () => {
        switch (path) {
            case '/admin/project/interview-activity':
                return 'In-Depth Interview'
            case "/admin/project/online-interview-activity":
                return 'Online In-Depth Interview'
            case "/admin/project/inperson-forum-activity":
                return 'Focus Group Discussion'

               
        }
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                    {/* Delete Modal */}
                    <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={onDelRecording} />

                    {/* Upload Recording Modal */}
                    <Modal isOpen={props.showMediaModal} toggle={() => props.setMediaModal(!props.showMediaModal)} className="modal-dialog-centered modal-lg shadow-none" >
                        <ModalHeader className="bg-white" toggle={() => props.setMediaModal(!props.showMediaModal)}>Upload Media</ModalHeader>
                        <ModalBody>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <Label for='respondent'>Title</Label>
                                        <Input type="text" value={newRecording.title}
                                            onChange={(e) => setNewRecording({ ...newRecording, title: e.target.value })} >

                                        </Input>
                                    </div>
                                    {
                                        (getActivityType() === 'In-Depth Interview' || getActivityType() === 'Online In-Depth Interview') &&
                                        <div className='col-md-6 col-sm-12'>
                                            <Label for='respondent'>Participant</Label>
                                            <Input type="select" value={newRecording.respondent}
                                                onChange={(e) => setNewRecording({ ...newRecording, respondent: e.target.value })} >
                                                <option value="">Select</option>
                                                {
                                                    props.participants.map(participant => {
                                                        return <option value={participant._id}>{participant.userName}</option>
                                                    })
                                                }
                                            </Input>
                                        </div>
                                    }
                                    {

                                        getActivityType() === 'Focus Group Discussion' &&
                                        <div className='col-md-6 col-sm-12'>
                                            <Label for='audience'>Audience</Label>
                                            <Input type="select" value={newRecording.audience}
                                                onChange={(e) => setNewRecording({ ...newRecording, audience: e.target.value })} >
                                                <option value="">Select</option>
                                                {
                                                    props.audiences.map(audience => {
                                                        return <option value={audience._id}>{audience.title}</option>
                                                    })
                                                }
                                            </Input>
                                        </div>
                                    }

                                    <div className='col-12 mt-2 _survey-steps_'>
                                        {uploadedAudio ? (

                                            // Video Display
                                            <div className="pt-3">
                                                <div className="position-relative text-center">
                                                    <span className="questionVideo_close" style={{ marginLeft: '600px', zIndex: '2' }} onClick={() => uploadAudio()}>&times;</span>
                                                    {

                                                        uploadedAudio.name.split('.').pop() === 'mp4' ?
                                                            <video width={'85%'} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                                <source src={URL.createObjectURL(uploadedAudio)} type={uploadedAudio.type} />

                                                                Your browser does not support the video tag.
                                                            </video> :
                                                            <audio controls style={{ borderRadius: '10px', maxHeight: 500, width: '85%' }}>
                                                                <source src={URL.createObjectURL(uploadedAudio)} type={uploadedAudio.type} />

                                                                Your browser does not support the video tag.
                                                            </audio>
                                                        //     <source src={uploadedAudio} type="video/mp4" /> :
                                                    }

                                                </div>
                                            </div>

                                        ) : (

                                            // Video Drop Zone 

                                            < div className="dropzone-wrapper dropzone-wrapper-sm">
                                                <Dropzone multiple={false} accept={".mp3, .wav, aac, .avi, .mp4, .mpeg"} onDrop={file => onSelectVideo(file[0])}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                                <div className="d-flex">
                                                                    <span className="pe-7s-video ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                                    <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Drop your audio video here or click to upload.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </div>

                                        )}

                                    </div>
                                </div>

                            </div>

                            <div className="text-right mt-2">
                                <Button className={` ${styles.bg_blue} mr-3`} onClick={onSaveRecording}>
                                    Save
                                </Button>
                                <Button className={` bg-light text-muted`}
                                    style={{ border: '1px solid #6c757d ' }}
                                    onClick={() => props.setMediaModal(!props.showMediaModal)}>
                                    Cancel
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <LoadingOverlay tag="div" active={isLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: "#fff",
                                opacity: 0.5,
                            }),
                        }}
                        spinner={
                            <Loader color="#6c757d" active type="ball-pulse" />
                        }>

                        {/* Listing */}
                        <div className="table_border">
                            <Listing columnName={column}
                                data={recordingData}
                                currentPage={recordingState.currentPage ? recordingState.currentPage : 1}
                                totalPages={recordingState.totalPages ? recordingState.totalPages : 1}
                                getListingData={(currentPage, dataLimit) => getRecordingsListing(currentPage, dataLimit)}
                                startLoading={() => setLoading(true)}
                            />
                        </div>

                    </LoadingOverlay>

                </TransitionGroup>
            )}
        />
    )
}

export default Recording

