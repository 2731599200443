import React, { Fragment } from 'react';

import Tabs from 'react-responsive-tabs';
import { useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'
import CampaignManagement from './CampaignManagement';
import CampaignMarketing from './CampaignMarketing';
import ResizeDetector from "react-resize-detector";

const Campaign = () => {

    const history = useNavigate()
    const tabsContent = [
        {
            title: 'Campaign Marketing',
            content: <CampaignMarketing />
        },
        {
            title: 'Campaign Management',
            content: <CampaignManagement />
        },
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <div className="admin_header">
                        <PageHeader heading="Campaigns" img={TemplateIcon} btnText={width > 350 ? "Create Template" : "Create"} btnClick={() => history("/admin/create-campaign-template")} />
                    </div>
                    <div className="tab_blue">
                        <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} />
                    </div>
                </>
            )}
        />
    )

}

export default Campaign;