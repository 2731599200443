import React, { useState, useEffect } from 'react'
import styles from '../../../../../assets/css/styling.module.css'
import GroupuserIcon from '../../../../../assets/images/group_user_ico.png'
import { Modal, UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap'
import DotsIcon from '../../../../../assets/images/horizontal_dots_icon.png'
import { useNavigate } from 'react-router'
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { editAudience, getProjectAudienceDetails, getProjectAudiences } from '../../../../../actions/audience_actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert'
import { ModalContent } from '../Main'

const FocusGroups = (props) => {

    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const audienceState = useSelector(state => state.Audience)
    const participantState = useSelector(state => state.ProjectParticipants)

    const [audiences, setAudience] = useState([])
    const [selectedParticipant, setParticipant] = useState([])
    const [audienceIdToEdit, setAudienceId] = useState('')
    const [newAudienceData, setNewAudienceData] = useState({
        projectId: '',
        title: '',
        description: '',
        members: []
    })

    const [isModalOpen, setModal] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const showModal = (audienceId) => {
        setModal(!isModalOpen)
        audienceId && setAudienceId(audienceId)
    }

    const getAudienceList = () => {
        dispatch(getProjectAudiences(params.projectid))
            .then(response => setLoading(false))
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
                setLoading(false)
            })
    }

    // For showing data on edit Audience Form
    const getAudienceDetails = () => {
        dispatch(getProjectAudienceDetails(audienceIdToEdit))
            .then(response => {
                setNewAudienceData(response.payload.content)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
            })
    }

    useEffect(() => {
        getAudienceList()
    }, [])

    useEffect(() => {
        getAudienceDetails()
    }, [audienceIdToEdit])

    useEffect(() => {
        if (audienceState.audience !== undefined) {
            setAudience(audienceState.audience)
        }
    }, [audienceState])

    const onEditAudience = () => {
        if (newAudienceData.title) {
            Swal({
                title: "Please wait!",
                text: "Updating Audience...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            const newMembersId = participantState.participants.map(participant => {
                if (selectedParticipant.includes(participant.email)) {
                    return participant._id
                }
            })

            const audience = {
                title: newAudienceData.title,
                description: newAudienceData.description,
                members: newMembersId.filter(member => member),
                projectId: params.projectid
            }

            dispatch(editAudience(audienceIdToEdit, audience))
                .then(response => {
                    Swal.close()
                    setModal(false)
                    setNewAudienceData({})
                    getAudienceList()
                    Swal({
                        title: response.payload.title ? response.payload.title : "Edited",
                        text: 'Focus Group Edited',
                        icon: 'success'
                    })
                })
                .catch(err => {
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            Swal({
                title: "Error",
                text: "Please add audience title",
                icon: 'error'
            })
        }

    }




    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                    // position: 'fixed',
                    // top: '20%'
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <div className="container-fluid">

                {/* Back Icon */}
                <span onClick={() => history("/admin/project")} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                {/* Modal */}
                <Modal isOpen={isModalOpen} toggle={showModal} className="modal-dialog-centered shadow-none" size="lg">
                    <ModalContent newAudienceData={newAudienceData} setNewAudienceData={setNewAudienceData} ProjectParticipants={props.participantEmail}
                        setParticipant={setParticipant} selectedParticipant={selectedParticipant} setModal={showModal} onSave={onEditAudience} title="Edit Focus Group" />
                </Modal>

                <div className="row">

                    {
                        !isLoading && (audiences.length > 0 ? audiences.map(audience => {
                            return <div className={"col-md-4 col-sm-12 col-12 mb-3"}>

                                {/* Project Box Header */}
                                <div className={styles.card_border_header + " pb-0 d-flex custom_drop"} style={{ padding: '0.68rem', fontSize: '1.05rem', border: 2, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', backgroundColor: '#006fa7', color: '#fff' }}>
                                    {/* Title */}
                                    {audience.title}
                                    <div className="mr-auto"></div>
                                    <UncontrolledButtonDropdown className="mb-2 participant_btn">
                                        <DropdownToggle>
                                            <img className="ml-2 mr-2" src={DotsIcon} width="12px" alt="" />
                                        </DropdownToggle>
                                        <DropdownMenu style={{ marginLeft: '-12.5rem' }}>
                                            <DropdownItem onClick={() => showModal(audience._id)}>Edit</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>

                                {/* Project Box Body */}
                                <div className={styles.card_border} style={{ borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, cursor: 'pointer' }} onClick={() => history("/admin/project/group/" + params.projectid + "/" + audience._id)}>

                                    <div className={styles.card_border_body + " py-3 text-justify"}>
                                        {/* Project Details */}
                                        <p style={{ color: '#666', fontSize: "0.93rem", fontWeight: '400', whiteSpace: 'pre-wrap' }}>
                                            {audience.description}
                                        </p>

                                        {/* Bottom Content */}
                                        <div className="clearfix pt-1">
                                            <div className="float-left">

                                                <div className="d-flex">
                                                    <div className="d-inline-flex">

                                                        {/* Members Icon */}
                                                        <img src={GroupuserIcon} alt='img' />
                                                    </div>

                                                    <div className="d-inline-flex align-self-center mx-2">

                                                        <div style={{ fontSize: '0.95rem', color: '#666' }}>

                                                            {/* No. of Members  */}
                                                            <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>{audience.members.length} Members</span>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        })
                            :
                            <div className="col-12 text-center">No Focus Groups Found</div>)
                    }
                </div>
            </div>
        </LoadingOverlay >

    )
}

export default FocusGroups
