import { AVAILABLE_USERS, FIND_USER, GET_USER_DETAILS } from "../actions/types";

let userDataState = { users:[], totalPages:0, currentPage: 1, userDetails: {} };

export default function(state = userDataState, action) {
    switch(action.type) {
        case AVAILABLE_USERS:
            if (state.users.length === 0) {
                return {
                    ...state,
                    users: action.payload.content.users,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                    
                     
                }
            } else {
                let concatResult = {};
                concatResult.users =  state.users.concat(action.payload.content.projects);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                
                
                return {
                    ...state,
                    users: concatResult.users,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                   
                    
                }
            }
        case GET_USER_DETAILS:
            return {...state, userDetails: action.payload.content }
        default:
            return state;
    }
}