

import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-D9N92607TJ'; 
ReactGA.initialize(MEASUREMENT_ID);

export const logPageView = (location) => {
  //console.log('Logging page view for:', location.pathname); // Log to the console
  ReactGA.send({ hitType: "pageview", page: location.pathname });
};

export default ReactGA;



