import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { AUDIENCE_AVAILABLE, CREATE_AUDIENCE, EDIT_AUDIENCE, GET_AUDIENCE_DETAILS, MOVE_REMOVE_AUDIENCE_MEMBERS, PROJECT_AUDIENCE_AVAILABLE } from './types.js';


// ****************  These apis are used in ADMIN & RESEARCHER END inside Focus Groups in Participants Section of Projects  *********************
// ****** Focus Groups and Audiences are same ( In UI or project folder structure focus groups are used in place of audience ) *********


export const getAudiences = () => {

    let url = `${BACK_SERVER_URL}api/audience`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: AUDIENCE_AVAILABLE,
        payload: request
    }
}

// Returns an array of audiences against project id
export const getProjectAudiences = (id) => {

    let url = `${BACK_SERVER_URL}api/audience/project/${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: PROJECT_AUDIENCE_AVAILABLE,
        payload: request
    }
}

// Returns an object containing audience details of specific audience
export const getProjectAudienceDetails = (audienceId) => {

    let url = `${BACK_SERVER_URL}api/audience/${audienceId}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_AUDIENCE_DETAILS,
        payload: request
    }
}

// Used for moving members from one audience to another
// Takes id of current audience and an object containing members array and audienceId in which members have to move as param
export const moveAudienceMember = (audienceId, members) => {

    let url = `${BACK_SERVER_URL}api/audience/moveMembers/${audienceId}`;

    const request = axios.put(url, members)
        .then(response => response.data);

    return {
        type: MOVE_REMOVE_AUDIENCE_MEMBERS,
        payload: request
    }
}

// Takes an object containing new audience details as param and create new audience 
export const createAudience = (data) => {

    let url = `${BACK_SERVER_URL}api/audience`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_AUDIENCE,
        payload: request
    }
}

// updates audience details against the id we pass in the param
export const editAudience = (audienceId, data) => {

    let url = `${BACK_SERVER_URL}api/audience/${audienceId}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_AUDIENCE,
        payload: request
    }
}

