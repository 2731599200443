import React, { useEffect, useState } from 'react'
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import CreateIcon from '../../../../assets/images/create_icon.png'
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams, useLocation } from 'react-router';
import FormSteps from './FormSteps';
import { useDispatch } from 'react-redux';
import { getProject } from '../../../../actions/project_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';


const CreateProject = () => {
    const params = useParams();
    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const dispatch = useDispatch()
    const [project, setProject] = useState({})
    const [isEdit, setEditMode] = useState(false)
    const [isCopy, setCopyMode] = useState(false)
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        const editUrl = '/admin/edit-project/' + params.projectid
        if (params.projectid) {
            dispatch(getProject(params.projectid))
                .then(response => {
                    setProject(response.payload.content)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)

                })
            if (editUrl === currentPath) {
                setEditMode(true)
            } else {
                setCopyMode(true)
            }
        } else {
            setLoading(false)
        }
    }, []);


    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/edit-project/' + params.projectid:
                return <PageHeader heading="Edit Project" img={CreateIcon} />

            default:
                return <PageHeader heading="Create Project" img={CreateIcon} />
        }
    }


    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="create_header">
                {
                    getPageHeader()
                }
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <FormSteps project={project} isEdit={isEdit} isCopy={isCopy} />

            </LoadingOverlay>

        </TransitionGroup>
    )
}

export default CreateProject
