import React, { useState } from 'react'
import {
    Card
} from "reactstrap";

import styles from '../../css/styling.module.css';

import Dropzone from "react-dropzone";
import PhotoIcon from '../../images/photo_icon.png'
import ResizeDetector from 'react-resize-detector';
import CrossICon from '../../images/close_icon.png'


const Create_Image_Section = (props) => {

    // For changing image upload limit
    const onChangeLimit = (value) => {

        props.setContent({
            ...props.allContent,
            imagesSection: { ...props.allContent.imagesSection, limit: value }
        })
    }

    // For deleting a section
    const delSection = () => {

        props.setContent({
            description: props.allContent.description,
            filesSection: props.allContent.filesSection,
            videosSection: props.allContent.videosSection,
            participantsSection: props.allContent.participantsSection,
            imagesSection: { ...props.allContent.imagesSection, images: [], limit: 0 }

        })
    }

    const onImageSelect = (file) => {
        if (file) {
            if (props.limit >= file.length + props.allContent.imagesSection.images.length) {
                for (let i = 0; i < file.length; i++) {

                    // File Extension Check
                    let fileExtension = file[i].name.split('.').pop();

                    if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {

                        const images = props.allContent.imagesSection.images
                        images.push(file[i])
                        props.setContent({ ...props.allContent, imagesSection: { ...props.allContent.imagesSection, images: images } });

                    }
                }
            }


        }

    }

    // on Cancel Image
    const onCancelImg = (index) => {

        const images = props.allContent.imagesSection.images
        images.splice(index, 1)

        props.setContent({ ...props.allContent, imagesSection: { ...props.allContent.imagesSection, images: images } });
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Card className="main-card mb-3 p-3 mt-2">
                    <div className="d-flex">
                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mr-auto">Attached Image</label>
                        <button className="btn btn-light bg-white border-0 mb-1" height="2.5rem" >
                            <img src={CrossICon} alt="" width="15px" onClick={() => delSection()} />
                        </button>
                    </div>

                    <Card className="main-card mb-3 p-3 mt-2">

                        <label style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Allow Images</label><br />

                        <div className={`input-group input-group-feedback mb-3 ${styles.option_input}`}>

                            {/* Text */}

                            <input type="text" placeholder="Set no. of Images" value={props.limit} className="form-control"
                                style={{ color: '#666', fontSize: "0.96rem", fontWeight: '400', verticalAlign: '-6px', height: '2.5rem' }}
                                onChange={(e) => onChangeLimit(e.target.value)}
                            />


                            <div className={`input-group-append`}>
                                <button className="btn btn-light" >
                                    <img src={PhotoIcon} alt="" width="25px" />
                                </button>
                            </div>
                        </div>


                        {/* Image Display & Dropzone */}
                        {props.allContent.imagesSection && props.allContent.imagesSection.images.length > 0 &&
                            // Image Display
                            <div className="row pt-3">

                                {props.allContent.imagesSection.images.map((imgSrc, index) => {
                                    return (
                                        <div key={index} className="position-relative mx-3 mb-3">
                                            <span className="questionImg_close" onClick={() => onCancelImg(index)}>&times;</span>
                                            <img src={typeof imgSrc === 'string' ? imgSrc : URL.createObjectURL(imgSrc)} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                                        </div>
                                    )
                                })}

                            </div>

                        }

                        {/* // Image Drop Zone  */}
                        < div className="dropzone-wrapper dropzone-wrapper-sm mb-3">
                            <Dropzone
                                maxFiles={!Number.isNaN(parseInt(props.limit)) ? parseInt(props.limit) : 0}
                                multiple={!Number.isNaN(parseInt(props.limit)) ? (parseInt(props.limit) > 0 ? true : false) : false}
                                accept={".jpg, .jpeg, .png"}
                                onDrop={file => onImageSelect(file)}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                            <div className="d-flex">
                                                <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>
                                                <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Try dropping image here, or click to select image to upload.</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>



                    </Card>
                </Card>

            )}
        />


    )
}

export default Create_Image_Section
