import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { TransitionGroup } from 'react-transition-group';
import avatar from '../../../../assets/images/profile_placeholder.png'
import { useParams, useNavigate } from 'react-router';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import TemplateIcon from '../../../../assets/images/template_header_icon.png'
import styles from '../../../../assets/css/rstyling.module.css'
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { getCampaignDetails } from '../../../../actions/campaign_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'

const ViewCampaign = () => {

    const [campaignDetails, setCampaignDetails] = useState({})

    const campaignState = useSelector(state => state.Campaign)
    const dispatch = useDispatch()

    const params = useParams()
    const history = useNavigate()

    useEffect(() => {
        dispatch(getCampaignDetails(params.campaignId))
            .then(response => {
                setCampaignDetails(response.payload.content)
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Something went wrong",
                    icon: 'error',
                }).then(() => history('/admin/templates/campaign'))


            })
    }, [])
    const date = new Date(campaignDetails.scheduledDate)

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="admin_header">
                <PageHeader heading={`Campaign`} img={TemplateIcon} />
            </div>

            {/* Back Icon */}
            <span onClick={() => history('/admin/templates/campaign')} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid mt-3">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                            <img width={38} height={38} style={{ borderRadius: '100%' }} src={campaignDetails.author && campaignDetails.author.profileImage ? campaignDetails.author.profileImage : avatar} alt="" />
                        </div>
                        <div className="widget-content-left">
                            <div className="widget-heading text-dark" style={{ fontSize: '1rem' }}>
                                {campaignDetails.author && campaignDetails.author.userName}
                            </div>
                            <div className="widget-subheading opacity-10" style={{ fontSize: '0.7rem' }}>
                                {campaignDetails.author && campaignDetails.author.email}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-6 col-12">
                        <div className={styles.heading_light}>Campaign Name:</div>
                        <div className={styles.text_bold}>{campaignDetails.title && campaignDetails.title}</div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className={styles.heading_light}>Audience:</div>
                        <div className={styles.text_bold}>{campaignDetails.audience && campaignDetails.audience === "all" ? "All Participants" : campaignDetails.project && campaignDetails.project.title}</div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className={styles.heading_light}>Scheduled Date:</div>
                        <div className={styles.text_bold}>{
                        campaignDetails.scheduledDate && date.toLocaleDateString()
                        }</div>
                    </div>
                    {
                        campaignDetails.emailTemplate &&
                        <div className="col-12 mt-2">
                            <div className={styles.heading_light}>Email Template:</div>
                            <div className="mt-3" dangerouslySetInnerHTML={{ __html: campaignDetails.emailTemplate.emailContent }}>
                                {/* {
                                    campaignDetails.emailTemplate.emailContent
                                } */}
                            </div>
                        </div>
                    }

                    {
                        campaignDetails.smsTemplate &&
                        <div className="col-12 mt-2">
                            <div className={styles.heading_light}>SMS Template:</div>
                            <div className="mt-3">
                                {
                                    campaignDetails.smsTemplate.smsContent
                                }
                            </div>
                        </div>
                    }

                </div>
            </div>
        </TransitionGroup>

    )
}

export default ViewCampaign
