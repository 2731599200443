import React from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import logo from '../../assets/images/logo.png'
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux'
import landing_bg_new from "../../assets/images/landing_page.png";
import landing_page_logo from '../../assets/images/landing_page_logo.png';
import { signup, SignupGoogle, signupFacebook } from '../../actions/auth_actions';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook } from 'reactjs-social-login';
import login_bg_new from "../../assets/images/login_bg2.png";
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom';


export default function NormalSignupFinalized() {


    
    const history = useNavigate()


const handleClick =() =>{

    history(`/respondent`)

}

    return (
        <div>
            <>
                <div className="container-fluid p-2 px-4">
                    <div className='row justify-content-center'>
                        <div className={`row p-2 ${styles3.login_container}`}>

                            <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                                <div className={`${styles3.login_img}`}
                                    style={{
                                        backgroundImage: "url(" + login_bg_new + ")",
                                    }} />


                            </div>

                            <div className="col-sm-7 col-12 bg-white align-self-center">
                                <div className='row justify-content-center'>
                                    <div className={`${styles3.login_section}`}>
                                        {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                        <div className=" container" >



                                            <div className="container text-center">
                                                <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="120px" />
                                                <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1.5rem', paddingTop: '50px' }}>Thank you for the signup. </p>
                                                <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ fontWeight: '500', fontSize: '1rem', color: 'rgb(168, 168, 168)', }}> You already got some points for completing the signup process. We will send you new and upcoming survey notifications through email from now on.</p>



                                                <button className={`btn-hover-shine btn text-light py-1 mt-4 px-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }}  onClick={() => {handleClick()}}>
                                                    Go To Dashboard
                                                </button>










                                            </div>
                                        </div>
                                    </div>
                                </div>










                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}