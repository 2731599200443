import React, { useState, useEffect } from 'react'
import Listing from '../../../../../../assets/components/listing/Listing'

import { TransitionGroup } from 'react-transition-group';
import { useNavigate,  useParams, useLocation } from "react-router-dom";
import Eyeicon from '../../../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../../../assets/images/delete_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../../../assets/components/modal/AlertModal';
import TickIcon from '../../../../../../assets/images/user_icon.png'
import { delSurveyReply, editSurveyReply, getAllPostParticipants, getAllSurveyParticipants } from '../../../../../../actions/activities_reply_actions';
import Swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Participant = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()

    const [delId, setDelID] = useState('')
    const [approvedId, setApprovedId] = useState('')
    const [participantColumn, setParticipantColumn] = useState([

    ])

    const [participantData, setParticipantData] = useState([])

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])


    const path = currentPath
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const activityParticipantState = useSelector(state => state.ActivityParticipant)

    const [listingName, setListingName] = useState('')

    // For setting listing columns based on activity type
    useEffect(() => {

        if (path === `/admin/project/survey-activity/${params.projectid}/${params.activityid}`) {
            setParticipantColumn([
                { Name: 'Serial No', Type: 'String', key: 'serialNo' },
                { Name: 'Respondent Name', Type: 'Object', key: 'author.userName' },
                { Name: "Email", Type: 'Object', key: 'author.email', width: 280 },
                { Name: "Phone No", Type: 'Object', key: 'author.phone', width: 220 },
                { Name: "Last Login", Type: 'date', key: 'author.logs.latestVisit' },
                { Name: "Date", Type: 'date', key: 'createdAt' },
                { Name: "Time", Type: 'time', key: 'createdAt' },
                { Name: "Progress", Type: 'progress', key: 'progress' },
                {
                    Name: "Action", Type: 'action', options: [
                        {
                            icon: Eyeicon,
                            url: "/admin/project/participant-survey-activity/" + params.activityid,
                            type: 'button'
                        },
                        {
                            icon: TickIcon,
                            setID: setApprovedId,
                            type: 'delete'
                        },
                        {
                            icon: Deleteicon,
                            setID: setDelID,
                            type: 'delete'
                        },


                    ]
                }
            ])
            setListingName('Survey')
            getSurveyParticipants()
        } else if (path === `/admin/project/journal-activity/${params.projectid}/${params.activityid}`) {
            setParticipantColumn([
                { Name: 'Respondent Name', Type: 'Object', key: 'author.userName' },
                { Name: "Email", Type: 'Object', key: 'author.email',  width: 280 },
                { Name: "Phone No", Type: 'Object', key: 'author.phone',width: 220 },
                { Name: "Last Login", Type: 'date', key: 'logs.latestVisit' },          
                { Name: "Latest Post", Type: 'date', key: 'created_at'  },
                { Name: "Entries Completed", Type: 'string', key: 'totalPosts' },
                {
                    Name: "Action", Type: 'action', options: [
                        {
                            icon: Eyeicon,
                            url: "/admin/project/participant-post-activity/" + params.activityid,
                            type: 'button',
                            idObjectName: 'author'
                        },
                        {
                            icon: TickIcon,
                            setID: setApprovedId,
                            type: 'delete'
                        },

                    ]
                }
            ])
            setListingName('Post')
            getPostParticipants()
        } else if (path === `/admin/project/forum-activity/${params.projectid}/${params.activityid}`) {
            setParticipantColumn([
                { Name: 'Respondent Name', Type: 'Object', key: 'author.userName' },
                { Name: "Email", Type: 'Object', key: 'author.email', width: 280 },
                { Name: "Phone No", Type: 'Object', key: 'author.phone', width: 180 },
                { Name: "Last Login", Type: 'date', key: 'logs.latestVisit' },            
                { Name: "Total Post", Type: 'string', key: 'totalPosts' },
                {
                    Name: "Action", Type: 'action', options: [
                        {
                            icon: Eyeicon,
                            url: "/admin/project/participant-post-activity/" + params.activityid,
                            type: 'button',
                            idObjectName: 'author'
                        },
                        {
                            icon: TickIcon,
                            setID: setApprovedId,
                            type: 'delete'
                        },

                    ]
                }
            ])
            setListingName('Post')
            getPostParticipants()
        }

    }, [])


    const getSurveyParticipants = (page = 1, limit = 5) => {
        dispatch(getAllSurveyParticipants({ page: page, limit: limit, activityId: params.activityid }))
            .then((response) => {
              
               
                setParticipantData(response.payload.content.participants)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
                setLoading(false)
            })
    }

    // for diary and forum type activity
    const getPostParticipants = (page = 1, limit = 10) => {
        dispatch(getAllPostParticipants({ page: page, limit: limit, activityId: params.activityid }))
            .then((response) => {
                setParticipantData(response.payload.content.participants)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
                setLoading(false)
            })
    }

    const delResponse = () => {
        if (listingName === 'Survey') {
            dispatch(delSurveyReply(delId))
                .then((response) => {
                    setDelID()
                    getSurveyParticipants(activityParticipantState.currentPage)
                    Swal({
                        title: "Deleted",
                        text: "Response Deleted",
                        icon: 'success'
                    })
                })
                .catch(err => {
                    setDelID()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })

        }
    }

    useEffect(() => {
        // Approve responses
        if (approvedId) {
            const formData = new FormData()
            formData.append('isApproved', true)
            dispatch(editSurveyReply(approvedId, formData))
                .then((response) => {
                    Swal({
                        title: 'Approved',
                        text: "Participant approved",
                        icon: 'success'
                    })
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        }

    }, [approvedId])


    return (

        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <>
                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={delResponse} />

                        <LoadingOverlay tag="div" active={isLoading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: "#fff",
                                    opacity: 0.5,
                                }),
                            }}
                            spinner={
                                <Loader color="#6c757d" active type="ball-pulse" />
                            }>

                            {/* Listing */}
                            <div className="table_border">
                                <Listing columnName={participantColumn}
                                    data={participantData}
                                    currentPage={activityParticipantState.currentPage ? activityParticipantState.currentPage : 1}
                                    totalPages={activityParticipantState.totalPages ? activityParticipantState.totalPages : 1}
                                    getListingData={(currentPage, dataLimit) => listingName === 'Survey' ? getSurveyParticipants(currentPage, dataLimit) : getPostParticipants(currentPage, dataLimit)}
                                    startLoading={() => setLoading(true)}
                                />
                            </div>

                        </LoadingOverlay>
                    </>
                )}

            />
        </TransitionGroup>


    )
}

export default Participant
