import {  CAMPAIGN_AVAILABLE, GET_CAMPAIGN_DETAIL, MY_NOTIFICATIONS } from '../actions/types';

let notificationDataState = { myNotification:[], totalPages:0, currentPage: 1, count: 0 };
export default function (state = notificationDataState, action) {
    switch (action.type) {
        case MY_NOTIFICATIONS:
            if (state.myNotification.length === 0) {
                return {
                    ...state,
                    myNotification: action.payload.content.notifications,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                    count: action.payload.content.count
                }
            } else {
                let concatResult = {};
                concatResult.myNotification =  state.myNotification.concat(action.payload.content.notifications);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                concatResult.currentPage = action.payload.content.count

                return {
                    ...state,
                    myNotification: concatResult.myNotification,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                    count: concatResult.count
                }
            }
        default:
            return state;
    }
}
