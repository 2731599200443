import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Input,
    ModalBody,
    ModalHeader,
    Modal,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import PageHeader from '../../../../../assets/components/pageheader/PageHeader';
import Projecticon from '../../../../../assets/images/project_icon.png'
import avatar from '../../../../../assets/images/profile_placeholder.png'
import bg4 from "../../../../../assets/utils/images/dropdown-header/city2.jpg";
import ArrowLeft from '../../../../../assets/images/arrow_left.png'
import { useNavigate, useParams } from 'react-router';
import styles from '../../../../../assets/css/rstyling.module.css'
import ResizeDetector from 'react-resize-detector';
import { Typeahead } from 'react-bootstrap-typeahead';
import { editAudience, getProjectAudienceDetails, getProjectAudiences, moveAudienceMember } from '../../../../../actions/audience_actions';
import { getProjectParticipant } from '../../../../../actions/participant_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import classNames from 'classnames';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const FocusGroupDetail = (props) => {

    const history = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [audienceDetails, setAudienceDetails] = useState([])
    const audienceState = useSelector(state => state.Audience)
    const participantState = useSelector(state => state.ProjectParticipants)
    const [projectParticipantEmail, setProjectParticipantEmails] = useState([])
    const [selectedParticipant, setSelectedParticipant] = useState([])
    const [selectedAudience, setSelectedAudience] = useState('')
    const [selectedMembers, setSelectedMembers] = useState([])
    const [modalContent, setModalContent] = useState('')
    const [isLoading, setLoading] = useState(true)
    const [isModalOpen, setModal] = useState(false)

    const showModal = (whichModal) => {
        setModalContent(whichModal)
        setModal(!isModalOpen)
    }


    const onMemberSelect = (member, isCheck) => {
        let members = selectedMembers

        if (isCheck) {
            members.push(member)
        } else {
            members.splice(members.indexOf(member))
        }
        setSelectedMembers([...members])

    }

    const getAudienceDetails = () => {
        dispatch(getProjectAudienceDetails(params.audienceId))
            .then(response => {
                console.log(response)
                setAudienceDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                if (err.response.data.name !== 'Not Found') {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                }
            })
    }

    useEffect(() => {
        getAudienceDetails()
        getAudience()
        getParticipants()
    }, [])

    const delAudienceMembers = () => {
        Swal({
            title: "Please wait!",
            text: "Removing members...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        dispatch(moveAudienceMember(params.audienceId, { members: selectedMembers }))
            .then(response => {
                Swal.close()
                showModal(false)
                getAudienceDetails()
                setSelectedMembers([])
                Swal({
                    title: response.payload.title ? response.payload.title : "Member Removed",
                    text: 'Selected members removed from audience',
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal.close()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }

    const moveSelected = () => {
        Swal({
            title: "",
            text: "Moving members",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        dispatch(moveAudienceMember(params.audienceId, { audienceIdtoMove: selectedAudience, members: selectedMembers }))
            .then(response => {
                Swal.close()
                showModal(false)
                getAudienceDetails()
                setSelectedMembers([])
                Swal({
                    title: response.payload.title ? response.payload.title : "Members Moved",
                    text: 'Selected members moved to new focus group',
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal.close()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Please try again",
                    icon: 'error'
                })
            })
    }

    // For showing project participant in add participant form of audience
    const getParticipants = () => {
        dispatch(getProjectParticipant(params.projectid))
            .then(response => {
                const emails = response.payload.content.map(participant => participant.email)
                setProjectParticipantEmails(emails)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // For showing audience in move members form
    const getAudience = () => {
        dispatch(getProjectAudiences(params.projectid))
            .then(response => { console.log(response) })
            .catch(err => {
                console.log(err)
            })
    }

    // For adding participant in existing audience
    const addParticipant = () => {
        if (selectedParticipant.length > 0) {
            Swal({
                title: "Please wait!",
                text: "Adding Participants...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            const newMembersId = participantState.participants.map(participant => {
                if (selectedParticipant.includes(participant.email)) {
                    return participant._id
                }
            })

            const audience = {
                members: newMembersId.filter(member => member),
                projectId: params.projectid
            }

            dispatch(editAudience(params.audienceId, audience))
                .then(response => {
                    Swal.close()
                    setModal(false)
                    getAudienceDetails()
                    Swal({
                        title: response.payload.title ? response.payload.title : "Added",
                        text: 'New Participant added in focus group',
                        icon: 'success'
                    })
                })
                .catch(err => {
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        }
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (


                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // position: 'fixed',
                            // top: '20%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>

                    <>
                        <PageHeader
                            heading="Focus Group" img={Projecticon}
                            btnText={selectedMembers.length > 0 ? "Move" : "Add Participant"}
                            btnClick={selectedMembers.length > 0 ? () => showModal('select') : () => showModal('add')}
                            btn2={selectedMembers.length > 0 ? true : false}
                            btn2Text={width <= 470 ? "Delete" : "Delete"}
                            btn2Click={() => showModal('delete')}
                        />

                        {/* Modal */}
                        <ModalContent
                            setModal={showModal}
                            isModalOpen={isModalOpen}
                            modalContent={modalContent}
                            // for deleting 
                            delMember={delAudienceMembers}
                            // for adding participant
                            participantEmails={projectParticipantEmail}
                            selectedEmail={selectedParticipant}
                            setSelectedEmail={setSelectedParticipant}
                            addParticipant={addParticipant}
                            // For move selected members
                            availableAudience={audienceState.audience}
                            selectedAudience={selectedAudience}
                            setSelectedAudience={setSelectedAudience}
                            moveMembers={moveSelected}
                        />

                        {/* Back Icon */}
                        <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                            <img src={ArrowLeft} alt="" width="8px" />
                            <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                        </span>

                        {/* Description */}
                        <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="ml-3">{audienceDetails.title}</h6>
                        <Card className="main-card mx-3 mt-3 mb-4">
                            <CardBody>
                                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Description</h6>
                                {audienceDetails.description}
                            </CardBody>
                        </Card>

                        {/* Member Card */}
                        <h6 className="ml-3" style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >Focus Group Members</h6>

                        <div className="row mx-2 member_card">
                            {
                                !isLoading && (audienceDetails.members && audienceDetails.members.length > 0 ? audienceDetails.members.map(member => {
                                    return <div className="col-md-4 col-sm-12 col-12 my-3">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner participant_card" style={{ borderRadius: ' 0.5rem 0.5rem 0 0', backgroundColor: '#020202' }}>
                                                <div className="menu-header-image"
                                                    style={{
                                                        backgroundImage: "url(" + bg4 + ")",
                                                    }} />
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3" style={{ zIndex: '15' }}>
                                                            <div className="widget-content-left" >
                                                                <input type="checkbox" name="select" onChange={(e) => onMemberSelect(member._id, e.target.checked)} className="mr-2" />
                                                                <img width={32} height={32} style={{ borderRadius: '100%' }} src={member.profileImage ? member.profileImage : avatar} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className={`widget-content-left flex2 ${styles.cursor}`} style={{ zIndex: '15' }}>
                                                            <div className="widget-heading text-white">{member.userName}</div>
                                                            <div style={{ fontSize: '0.7rem' }}
                                                                className={classNames({
                                                                    'badge-success': member.role === 1,
                                                                    'badge-warning': member.role === 2,
                                                                    'badge-danger': member.role === 3,
                                                                }, 'mb-2 mr-2 badge badge-pill ')}>
                                                                {member.role === 2 ? 'Researcher' : member.role === 1 ? 'Admin' : 'Participant'}
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right d-flex custom_drop font-weight-bold">
                                                            <div className={member.logs && member.logs.isActive ? `text-success widget-heading mt-1 mr-3` : `text-white widget-heading mt-1 mr-3`} style={{ fontSize: '0.7rem' }}>
                                                                {
                                                                    (member.logs && member.logs.isActive) ?
                                                                        'Active' : 'Away'
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid-menu border-left border-right border-bottom" style={{ borderRadius: ' 0 0 0.5rem 0.5rem' }}>
                                            <div className="no-gutters row">
                                                <div className="col-4 text-center border-bottom border-right py-2">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Joined Study</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSlabelace: 'pre-wrap' }} >{member.logs && new Date(member.logs.currentProjectJoined).toLocaleDateString()}</label>
                                                </div>
                                                <div className="col-4 text-center py-2 border-right border-bottom">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Study Visits</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{member.logs && member.logs.totalVisits}</label>
                                                </div>
                                                <div className="col-4 text-center py-2 border-bottom">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Visit</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(member.logs && member.logs.lastVisit) ? new Date(member.logs.lastVisit).toLocaleDateString() : '-'}</label>
                                                </div>
                                                <div className="col-6 text-center py-2 border-right" >
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Email Opened</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(member.logs && member.logs.lastEmailOpened) ? new Date(member.logs.lastEmailOpened).toLocaleDateString() : '-'}</label>
                                                </div>
                                                <div className="col-6 py-2 text-center">
                                                    <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Activities Completed</label><br />
                                                    <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{(member.logs && member.logs.activitiesDone) ? member.logs.activitiesDone : 0}</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }) :
                                    <div className="col-12 text-center">No members found</div>)
                            }
                        </div>

                    </>
                </LoadingOverlay >
            )}
        />
    )
}

export default FocusGroupDetail


export const ModalContent = (props) => {

    const { availableAudience, selectedAudience, setSelectedAudience } = props
    const toggle = () => {
        props.setModal()
    }

    const getContent = () => {
        if (props.modalContent === 'delete') {
            return <>
                <ModalHeader className="bg-white" toggle={toggle}>Delete Selected</ModalHeader>
                <ModalBody className="text-center">
                    <p> Are you sure want to delete selected? </p>
                    <Button className={` ${styles.bg_blue} mr-3`} onClick={() => props.delMember()}>
                        Yes
                    </Button>
                    <Button className={` bg-light text-muted ml-3`}
                        style={{ border: '1px solid #6c757d ' }}>
                        No
                    </Button>
                </ModalBody>
            </>
        } else if (props.modalContent === 'add') {
            return <>
                <ModalHeader className="bg-white" toggle={toggle}>Add Participants</ModalHeader>
                <ModalBody className="mx-3">
                    <FormGroup>
                        <Label for="email">Enter Email here</Label>
                        <Typeahead className="light_typeahead custom_drop"
                            id="email"
                            labelKey="email"
                            multiple
                            onChange={props.setSelectedEmail}
                            options={props.participantEmails}
                            placeholder="Email"
                            selected={props.selectedEmail}
                            emptyLabel="No participants Found. Kindly add participants to create audience."
                        />
                    </FormGroup>
                    <div className="text-right">
                        <Button className={` ${styles.bg_blue} mr-3`} onClick={props.addParticipant}>
                            Save
                        </Button>
                        <Button className={` bg-light text-muted`}
                            style={{ border: '1px solid #6c757d ' }} onClick={() => toggle()}>
                            Cancel
                        </Button>
                    </div>

                </ModalBody>
            </>
        } else {
            return <>
                <ModalHeader className="bg-white" toggle={toggle}>Move Selected</ModalHeader>
                <ModalBody>
                    <label for="group">Select a Focus Group</label>
                    <Input type="select" id="group" name="group" value={selectedAudience} onChange={(e) => setSelectedAudience(e.target.value)}>
                        {
                            availableAudience.map(audience => {
                                return <option value={audience._id}>{audience.title}</option>
                            })
                        }
                    </Input>
                    <div className="text-right mt-3">
                        <Button className={` ${styles.bg_blue} mr-3`} onClick={() => props.moveMembers()}>
                            Transfer
                        </Button>
                        <Button className={` bg-light text-muted`}
                            style={{ border: '1px solid #6c757d ' }}>
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </>
        }
    }

    return (

        <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" >
            {getContent()}
        </Modal>

    )
}
