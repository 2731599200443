import React,{useState, useEffect} from 'react'
import { editSurveyReply, getSurveyReply, getActivityReplyImagesRespondent } from '../../../actions/activities_reply_actions';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player'
export default function RenderFile(props) {
    const [ResponseFile, setResponseFile] = useState({})
    const dispatch = useDispatch()
    //const getFile = (file) => {

    useEffect(()=>{

        dispatch(getActivityReplyImagesRespondent(props.filePath))
        .then(response => {
          

            setResponseFile(response.payload.content.fileData)
            //return responseF
        })

    },[])
        

   // }

    return (
        <div>
            <ReactPlayer
                url={`data:${ResponseFile.type};base64,${ResponseFile.data}`}
                controls
                width='100%'
            />
        </div>
    )
}
