import axios from 'axios';
import { JOIN_PROJECT,PARTICIPANT_AVAILABLE, INVITE_PARTICIPANT, REMOVE_PARTICIPANT, ACCEPT_INVITE_NOTIFICATION, REJECT_INVITE_NOTIFICATION, PROJECT_PARTICIPANTS } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

// ****************  These apis are used in ADMIN & RESEARCHER END inside Participants Section in Projects  *********************


// Used for inviting researcher and participants in a project
export const inviteParticipant = (participantList) => {

    console.log('invite researcher in frontend->', participantList)
    let url = `${BACK_SERVER_URL}api/participants/inviteParticipant`;

    const request = axios.post(url, participantList)
        .then(response => response.data);

    return {
        type: INVITE_PARTICIPANT,
        payload: request
    }
}

// Returns an array of all projects participants to display in dropdown
export const getProjectParticipant = (projectId) => {

    let url = `${BACK_SERVER_URL}api/participants/${projectId}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: PROJECT_PARTICIPANTS,
        payload: request
    }
}

// Returns an array of projects participants to display listing ( paginated )
export const getProjectParticipantPaginated = (projectId, data) => {

    let url = `${BACK_SERVER_URL}api/participants/${projectId}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PARTICIPANT_AVAILABLE,
        payload: request
    }
}

// Deletes participant from a project
export const removeParticipant = (projectId, participant) => {

    let url = `${BACK_SERVER_URL}api/participants/${projectId}`;

    const request = axios.put(url, participant)
        .then(response => response.data);

    return {
        type: REMOVE_PARTICIPANT,
        payload: request
    }
}

// ****************  These apis are used in NOTIFICATIONS for RESPONDENT & RESEARCHER  *********************

// used to accept project invite through notification
export const acceptInviteNotification = (data) => {
   

    let url = `${BACK_SERVER_URL}api/participants/acceptInvite/notification`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ACCEPT_INVITE_NOTIFICATION,
        payload: request
    }
}


// used to reject project invite through notification
export const rejectInviteNotification = (data) => {

    let url = `${BACK_SERVER_URL}api/participants/rejectInvite/notification`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: REJECT_INVITE_NOTIFICATION,
        payload: request
    }
}


// ****************  These apis are used in RESPONDENT END inside PROJECTS SECTION  *********************

// used to join current active or upcoming project 
export const joinProject = (data) => {

    let url = `${BACK_SERVER_URL}api/participants/joinProject`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: JOIN_PROJECT,
        payload: request
    }
}
