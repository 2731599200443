import React, { useState, useContext } from 'react';
import styles from '../../../assets/css/rstyling.module.css'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { cityOptions } from '../../../actions/settings_actions';
import { userDetailsContext } from '../../../UserDetailsProvider';
const Step_1 = (props) => {

    const dispatch = useDispatch();
    const { profileDetails, setProfileDetails } = props
    const {userDetails, setUserDetails} = useContext(userDetailsContext);
    const systemVariables = useSelector(state => state.SystemSettings.specificOptions)
    
    const [cities, setCities] = useState([])
    const [err, setError] = useState({
        userName: '',
        birthday: '',
        city: '',
        area: '',
        gender: '',
        maritalStatus: ''
    })

    const removeErr = () => {
        setError({
            userName: '',
            birthday: '',
            city: '',
            country: '',
            area: '',
            gender: '',
            maritalStatus: ''
        })
    }

    const checkValidation = () => {
        removeErr()
        let notCompleted = false;

        // if (broadcastMsg.title === '') {
        //     setError((prevState) => ({ ...prevState, title: 'Title is required.' }))
        // }
        if (profileDetails.userName === '') {
            setError((prevState) => ({ ...prevState, userName: 'Username is required.' }))
            notCompleted = true
        }
        if (profileDetails.birthday === '') {
            setError((prevState) => ({ ...prevState, birthday: 'Date of Birth is required.' }))
            notCompleted = true
        }
        if (profileDetails.city === '') {
            setError((prevState) => ({ ...prevState, city: 'City is required.' }))
            notCompleted = true
        }
        if (profileDetails.country === '') {
            setError((prevState) => ({ ...prevState, country: 'Country is required.' }))
            notCompleted = true
        }
        if (profileDetails.area === '') {
            setError((prevState) => ({ ...prevState, area: 'Area is required.' }))
            notCompleted = true
        }
        if (profileDetails.gender === '') {
            setError((prevState) => ({ ...prevState, gender: 'Gender is required.' }))
            notCompleted = true
        }
        if (profileDetails.maritalStatus === '') {
            setError((prevState) => ({ ...prevState, maritalStatus: 'Marital Status is required.' }))
            notCompleted = true
        }
        return !notCompleted

    }

    const next = () => {
        if (checkValidation()) {
            props.nextStep()
        }
    }


    const onSelectDOB = (dateString) => {
        removeErr()
        if (dateString !== "") {
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            let day = today.getDate() - birthDate.getDate();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (m < 0) {
                m += 12;
            }
            if (day < 0) {
                day += 30;
            }
            if (age < 13) {
                setError((prevState) => ({ ...prevState, birthday: 'Min age required 13.' }))

            } else {
                setProfileDetails({ ...profileDetails, birthday: dateString })
            }
        }
    }

    const onSelectCountry =(value)=>{
      
        setProfileDetails({ ...profileDetails, country: value })

        dispatch(cityOptions(value))
        .then(response => {
           
            setCities(response.payload.content.cities)
            setUserDetails(response.payload.content.incomes)
        })
        .catch(err => {
            console.log(err)
        })
}
        
    
    return (

        <React.Fragment>
            <h4 className="mt-3 mb-5">
                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Set up your Profile</h3>
                <p style={{ fontSize: '1rem' }}>It takes only seconds to set up your Profile</p>
            </h4>

            <div className="row">

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Username</label>
                        <input type="text" placeholder="Enter your Username here" value={profileDetails.userName}
                            className={err.userName ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, userName: e.target.value })} />
                        <small className="text-danger">
                            {err.userName}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Date of Birth</label>
                        <input type="date" placeholder="Select your Date of Birth here" value={profileDetails.birthday} max={moment().format("YYYY-MM-DD")}
                            className={err.birthday ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => onSelectDOB(e.target.value)} />
                        <small className="text-danger">
                            {err.birthday}
                        </small>
                    </div>
                </div>

               

                {/* Country selection */}
                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Country</label>
                        <select value={profileDetails.country}
                             className={err.country ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => onSelectCountry(e.target.value)}>
                            <option selected={true} disabled={true} value='' >Select</option>
                            {
                                systemVariables.countries && systemVariables.countries.map(country => {
                                    return <option value={country.name}>{country.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.country}
                        </small>
                    </div>
                </div>

                 {/* Cities selection based on country */}
                 <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>City</label>
                        <select value={profileDetails.city}
                            className={err.city ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, city: e.target.value })} >
                            <option selected={true} disabled={true} value='' >Select</option>
                            {
                                // systemVariables.cities && systemVariables.cities.map(city => {
                                //     return <option value={city.name}>{city.name}</option>
                                // })
                                cities && cities.map(city=>{
                                    return <option value={city.name}>{city.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.city}
                        </small>
                    </div>
                </div>


                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Area</label>
                        <input type="text" placeholder="Enter your Area here" value={profileDetails.area}
                            className={err.area ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, area: e.target.value })} />
                        <small className="text-danger">
                            {err.area}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Gender</label>
                        <select value={profileDetails.gender}
                            className={err.gender ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, gender: e.target.value })}>
                            <option value='' >Select</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            {/* <option value='other'>Other</option> */}
                        </select>
                        <small className="text-danger">
                            {err.gender}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Marital Status</label>
                        <select value={profileDetails.maritalStatus}
                            className={err.maritalStatus ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, maritalStatus: e.target.value })}>
                            <option value='' >Select</option>
                            {
                                systemVariables.maritalStatus && systemVariables.maritalStatus.map(status => {
                                    return <option value={status.name}>{status.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.maritalStatus}
                        </small>
                    </div>
                </div>

            </div>

            <div className="clearfix">
                <div className="float-right">
                    <div className="mb-2">
                        <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={() => next()} disabled={props.isAnimationPlaying}>Next</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}

export default Step_1