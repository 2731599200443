import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import Photo from '../../../../../assets/images/photo_icon.png'
import CKEditor from "@ckeditor/ckeditor5-react";
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import styles from '../../../../../assets/css/rstyling.module.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";

import Back from '../../../../../assets/components/back/Back';
import { getRespondentActivityDetails, getActivityImg } from '../../../../../actions/activities_actions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import { createPostReply, getAllPostReply } from '../../../../../actions/activities_reply_actions';
import { Formik } from 'formik'
import * as Yup from 'yup'
import ImageModal from '../../../../../assets/components/modal/ImageModal';


const Journal = (props) => {

    const params = useParams()
    const dispatch = useDispatch()

    const [isLoading, setLoading] = useState(true)
    const [isShowImageModal, setImageModal] = useState(false)
    const [journalDetails, setJournalDetails] = useState({})
    const [bannerImg, setBannerImg] = useState({})
    const activityReplyState = useSelector(state => state.ActivityReply)
    const [journalEntries, setJournalEntries] = useState([])
    const [journalEntry, setJournalEntry] = useState({
        description: '',
        images: []
    })

    const getJournalDetails = () => {

        dispatch(getActivityImg(params.journalid))
        .then((response) =>{
            try{
            console.log('banner img in respondant mode diary->',response.payload.content.fileData)
            setBannerImg(response.payload.content.fileData)}catch(err){console.log('img not available')}
            
        }).catch(err=>{
            console.log('no banner image available for respondent mode diary')
        })
        dispatch(getRespondentActivityDetails(params.journalid))
            .then((response) => {
                setJournalDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const createJournalReply = (values) => {
        const formData = new FormData()
        formData.append('activity', params.journalid)
        formData.append('activityType', 'Diary')
        formData.append('description', values.description)

        if (values.images) {
            for (let i = 0; i < values.images.length; i++) {
                formData.append(`images[${i}]`, values.images[i])

            }
        }

        Swal({
            title: "Please wait!",
            text: "Saving data...",
            type: "info",
            buttons: false,
            closeModal: false,
            closeOnClickOutside: false
        });
        dispatch(createPostReply(formData))
            .then((response) => {
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: response.payload.title ? response.payload.title : "Created",
                    text: response.payload.message ? response.payload.message : 'Entry Created',
                    icon: 'success'
                })
            }).then(() => getJournalReply()
            )
            .catch(err => {
                console.log(err)
                Swal.stopLoading()
                Swal.close()
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const getJournalReply = (page = 1, limit = 10) => {

        dispatch(getAllPostReply({ activityId: params.journalid, page: page, limit: limit }))
            .then((response) => {
                setJournalEntries(response.payload.content.posts)
            })
            .catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        getJournalDetails()
        getJournalReply()
    }, [])

    const next = () => {
        getJournalReply(activityReplyState.currentPage + 1, 10)
    }

    return (
        <>
            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                {/* Back */}
                <Back />

                <div className="pt-4 mb-2 mx-3 pl-1">
                    {/* Journal */}
                    <h5 style={{ color: "#666", fontWeight: 600, fontSize: "1rem" }}>{journalDetails.name}</h5>
                </div>

                <Card className="main-card mx-3 mt-3 mb-4">
                    <CardBody>
                        <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Description</h6>
                        <p>{journalDetails.description}</p>
                        <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Question</h6>
                        <p dangerouslySetInnerHTML={{ __html: journalDetails.query }}></p>
                        <div className="py-2 d-inline-block">
                            <div className="position-relative mr-3">
                                {
                                    journalDetails.additionalFiles && journalDetails.additionalFiles.length > 0 &&
                                    <img src={journalDetails.additionalFiles[0]} width={200} height={100} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="" />
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>


                <Formik
                    initialValues={journalEntry}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        createJournalReply(values)
                    }}

                    const validationSchema={Yup.object().shape({
                        description: Yup.string().required("Description is required"),

                    })}


                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        } = props;



                        return (
                            <form autoComplete="off" onSubmit={handleSubmit}>

                                {/* Start of form */}

                                <Card className="main-card mx-3 mt-3 mb-4">
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }}>Create New Entry</h6>
                                                <label for="emailTemplate">CK Editor</label>
                                                <div className="text_editor">
                                                    <CKEditor
                                                        initData={values.description}
                                                        onChange={(e) => setFieldValue('description', e.editor.getData())}
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: [{
                                                                name: 'document',
                                                                items: ['Print']
                                                            },
                                                            {
                                                                name: 'clipboard',
                                                                items: ['Undo', 'Redo']
                                                            },
                                                            {
                                                                name: 'styles',
                                                                items: ['Format', 'Font', 'FontSize']
                                                            },
                                                            {
                                                                name: 'colors',
                                                                items: ['TextColor', 'BGColor']
                                                            },
                                                            {
                                                                name: 'align',
                                                                items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                                                            },
                                                                '/',
                                                            {
                                                                name: 'basicstyles',
                                                                items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
                                                            },
                                                            {
                                                                name: 'links',
                                                                items: ['Link', 'Unlink']
                                                            },
                                                            {
                                                                name: 'paragraph',
                                                                items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                                                            },
                                                            {
                                                                name: 'insert',
                                                                items: ['Image', 'Table']
                                                            },
                                                            {
                                                                name: 'tools',
                                                                items: ['Maximize']
                                                            },
                                                            {
                                                                name: 'editing',
                                                                items: ['Scayt']
                                                            }
                                                            ],
                                                            extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
                                                            // Adding drag and drop image upload.
                                                            extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
                                                        }}
                                                    />
                                                    {errors.description && touched.description && (
                                                        <small className='text-danger'>
                                                            {errors.description}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12 mt-2">
                                                <label>File</label>
                                                <div className={`input-group mb-3 hide-file-text`}>
                                                    <input type="file"
                                                        className={errors.images && touched.images ? styles.is_invalid + " form-control" : 'valid form-control'}
                                                        name="images"
                                                        onChange={(e) => setFieldValue('images', e.target.files)}
                                                        onBlur={handleBlur}
                                                    />
                                                    {
                                                        values.images && values.images[0] ?

                                                            <>
                                                                <img src={typeof values.images[0] === 'string' ? values.images[0] : URL.createObjectURL(values.images[0])} alt="" width="40px" height="40px"
                                                                    onClick={() => setImageModal(!isShowImageModal)} />
                                                                <ImageModal
                                                                    isModalOpen={isShowImageModal} setModal={() => setImageModal(!isShowImageModal)}
                                                                    image={typeof values.images[0] === 'string' ? values.images[0] : URL.createObjectURL(values.images[0])}
                                                                    changeImage={(files) => setFieldValue('images', files)}
                                                                    // index={index}
                                                                    delImage={() => setFieldValue('images', [])}
                                                                />

                                                            </>
                                                            :
                                                            <label className="btn btn-light" style={{ padding: ' 0.53rem 0.6rem' }}>
                                                                <img src={Photo} alt="" width="20px" />
                                                            </label>
                                                    }



                                                    {errors.images && touched.images && (
                                                        <small className='text-danger'>
                                                            {errors.images}
                                                        </small>
                                                    )}
                                                    {/* <div className={`input-group-append`}>
                                                        <button className="btn btn-light" style={{ height: "2.5rem" }} >
                                                            <img src={Photo} alt="" width="15px" />
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12 mt-2">
                                                <label>Date</label>
                                                <input className="form-control" type="date" name="date" />
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>

                                <div className="mx-3 mt-3 mb-4">
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            <Button className={`${styles.bg_blue} btn-hover-shine text-light mr-3 px-4 py-2`} type="submit" disabled={new Date(journalDetails.startDate) >= Date.now() ? true :  false}>
                                                Done
                                            </Button>
                                            <Button className={` bg-light text-muted px-4 py-2`}
                                                style={{ border: '1px solid #6c757d ' }} type="button" onClick={() => setJournalEntry({ description: '', images: [] })}
                                            >
                                                Discard
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        )
                    }}

                </Formik>

                {/* Previous Entries */}
                <Card className="main-card mx-3 mt-3 mb-4">
                    <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                        Previous Entries
                    </CardHeader>
                    <CardBody>
                        <VerticalTimeline layout="1-column"
                            className="vertical-time-simple vertical-without-time">
                            {
                                journalEntries.length > 0 ?
                                journalEntries.map(entry => {
                                    return <VerticalTimelineElement className="vertical-timeline-item mb-3">

                                        {/* Data */}
                                        <h6 style={{ fontSize: '0.93rem', color: "#888", fontWeight: "600" }}>{new Date(entry.created_at).toLocaleDateString()}</h6>

                                        {/* Details */}
                                        <p style={{ fontSize: '0.93rem', color: "#0389d0", fontWeight: "500" }} dangerouslySetInnerHTML={{ __html: entry.description }}></p>

                                        {/* Image */}
                                        {
                                            entry.images && entry.images.length > 0 &&
                                            entry.images.map(image => {
                                                return <div className="py-2 d-inline-block">
                                                    <div className="position-relative mr-3">
                                                        <img src={image} width={200} height={100} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="" />
                                                    </div>
                                                </div>

                                            })
                                        }

                                    </VerticalTimelineElement>
                                }):
                                <div className={"pb-2 ml-5"}>
                                <div className="row px-3">
                                    <div className="col -12 mt-3 align-self-center text-center">
                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Entries Found</span>
                                    </div>
                                </div>
                            </div>

                            }
                        </VerticalTimeline>

                        {
                            activityReplyState.totalPages > 0 && activityReplyState.totalPages !== activityReplyState.currentPage &&
                            <div className={"pb-2 ml-5"}>
                                <div className="row px-3">
                                    <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                        {/* Comment Counts */}
                                        <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Entries</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </CardBody>
                </Card>
            </LoadingOverlay >


        </>
    )


}

export default Journal


