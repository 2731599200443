import React, { useEffect, useState } from 'react';
import Tabs from 'react-responsive-tabs';
import Projecticon from '../../../../assets/images/project_icon.png'
import ResearcherIcon from '../../../../assets/images/researcher_icon.png'
import RespondentIcon from '../../../../assets/images/respondent_icon.png'
import FocusGroups from './FocusGroup/FocusGroups.js';
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import Participants from './Participants';
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Button } from 'reactstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate, useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectParticipant, inviteParticipant } from '../../../../actions/participant_actions';
import Swal from "sweetalert"
import { findUser } from '../../../../actions/user_action';
import { createAudience, getProjectAudiences } from '../../../../actions/audience_actions';
import { Typeahead } from 'react-bootstrap-typeahead'
import Dropzone from 'react-dropzone';
import fileIcon from '../../../../assets/images/file_icon.png'
import styles from '../../../../assets/css/rstyling.module.css'

const Main = () => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const path = currentPath.slice(0, currentPath.lastIndexOf('/'))
    const params = useParams()
    const dispatch = useDispatch();
    const [isModalOpen, setModal] = useState(false)

    const participantState = useSelector(state => state.ProjectParticipants)

    const [ProjectParticipants, setProjectParticipant] = useState([])
    // to send invitation
    const [selectedParticipant, setParticipant] = useState([])
    const [uploadedParticipantList, setParticipantList] = useState()
    const [systemEmails, setSystemEmails] = useState([])
    const [userRole, setUserRole] = useState()

    const [newAudienceData, setNewAudienceData] = useState({
        projectId: '',
        title: '',
        description: '',
        members: []
    })

    const tabsContent = [
        {
            title: 'Participants',
            content: <Participants />
        },
        {
            title: 'Focus Groups',
            content: <FocusGroups participantEmail={ProjectParticipants} />
        },
    ];

    // For participants tab
    const [emailList, setEmailList] = useState([])
    const [selectedEmail, setEmail] = useState([])

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    // Setting values empty when popup closes
    const toggle = (userRole) => {
        setModal(!isModalOpen)
        setUserRole(userRole)
        setParticipantList()
        setEmail([])    
    }

    const getActiveTab = () => {
        switch (path) {
            case '/admin/project/participants':
                return 0
            case '/admin/project/groups':
                return 1

            default:
                return '0';
        }
    }

    // For changing the tab
    const setActiveTab = (key) => {
        switch (key) {
            case 0:
                history('/admin/project/participants/' + params.projectid)
                break;
            case 1:
                history('/admin/project/groups/' + params.projectid)
                break;

            default:
                return '/admin/project/participants/' + params.projectid;
        }
    }

    // Uploading Participant List
    const onFileSelect = (file) => {

        if (file && file[0]) {

            // File Extension Check
            let fileExtension = file[0].name.split('.').pop();

            if (fileExtension === "xlsx" || fileExtension === "xls" || fileExtension === "csv") {
                setParticipantList(file[0])
            }
        }
    }

    // on Cancel Video
    const onCancelFile = () => {
        setParticipantList()
    }

    // Invite Participant in Project
    const addParticipants = () => {
        if (selectedEmail.length > 0 && params.projectid) {
            const userInvited = []
            const emailInvited = []
            const invitedResearcher = []
            selectedEmail.filter(email => {
                console.log(systemEmails)
                if (systemEmails.indexOf(email) !== -1) {
                    userInvited.push(systemEmails[systemEmails.indexOf(email)]._id)
                } else {
                    if (userRole === 2)
                        invitedResearcher.push(email.email)
                    else
                        emailInvited.push(email.email)
                }
            })
            const participantData = {
                projectId: params.projectid,
                userInvited: userInvited,
                emailInvited: emailInvited,
                invitedResearcher: invitedResearcher
            }
            invite(participantData)

        } else if (uploadedParticipantList) {
            const formData = new FormData()
            formData.append('file', uploadedParticipantList)
            formData.append('projectId', params.projectid)
            userRole === 2 && formData.append('isResearcher', true)

            invite(formData)

        } else {
            Swal({
                title: "Error",
                text: "Add emails to invite participant",
                icon: 'error'
            });
        }
    }

    const invite = (data) => {
        dispatch(inviteParticipant(data))
            .then(response => {
                Swal({
                    title: response.payload.title ? response.payload.title : "Created",
                    text: response.payload.message ? response.payload.message : 'Forum Created',
                    icon: 'success'
                })
                setModal(false)
                setParticipantList()

            }).catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    // SearchEmail for add participant dropdown
    const serachEmail = (searchStr) => {
        if (searchStr.length === 3) {
            dispatch(findUser({ searchStr: searchStr, role: userRole }))
                .then(response => {
                    setEmailList(response.payload.content.user)
                    setSystemEmails([...systemEmails, ...response.payload.content.user])
                })
                .catch(err => console.log(err))
        }
    }

    // Project Participants to add in members listing of create audience form
    const getParticipants = () => {
        dispatch(getProjectParticipant(params.projectid))
            .then(response => {
                const emails = response.payload.content.map(participant => participant.email)
                setProjectParticipant(emails)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // const getAudienceList = () => {
    //     dispatch(getProjectAudiences(params.projectid))
    //         .then(response => console.log(response))
    //         .catch(err => {
    //             if (err.response.data.name !== 'Not Found') {
    //                 Swal({
    //                     title: err.response ? err.response.data.name : "Error",
    //                     text: err.response ? err.response.data.content : "Something went wrong",
    //                     icon: 'error'
    //                 })
    //             }
    //         })
    // }

    useEffect(() => {
        getParticipants()
    }, []);

    // Create Audience
    const addAudience = () => {
        if (newAudienceData.title !== '') {
            Swal({
                title: "Please wait!",
                text: "Creating audience..",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            const newMembersId = participantState.participants.map(participant => {
                if (selectedParticipant.includes(participant.email)) {
                    return participant._id
                }
            })

            const audience = {
                title: newAudienceData.title,
                description: newAudienceData.description,
                members: newMembersId.filter(member => member),
                projectId: params.projectid
            }

            dispatch(createAudience(audience))
                .then(response => {
                    Swal.close()
                    setModal(false)
                    setNewAudienceData({})
                    // getAudienceList()
                    Swal({
                        title: response.payload.title ? response.payload.title : "Created",
                        text: 'Focus Group Created',
                        icon: 'success'
                    })
                })
                .catch(err => {
                    Swal.close()
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again",
                        icon: 'error'
                    })
                })
        } else {
            Swal({
                title: "Error",
                text: "Please add title of Audience",
                icon: 'error'
            })
        }

    }


    return (
        <>
            {
                getActiveTab() === 0 ?
                    <PageHeader
                        heading="Participants" img={Projecticon}
                        btnText="Add Participants"
                        btnClick={() => console.log("I'm clicked")}
                        isDrop={true}
                        droplist={[
                            { text: 'Respondents', icon: RespondentIcon, onClick: () => toggle(3) },
                            { text: 'Researcher', icon: ResearcherIcon, onClick: () => toggle(2) },
                        ]}
                    /> :
                    <PageHeader
                        heading="Focus Group" img={Projecticon}
                        btnText="Add Focus Group"
                        btnClick={() => toggle()}
                    />
            }


            <div className="tab_blue">
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false}
                    selectedTabKey={getActiveTab()} items={getTabs()} onChange={(item) => { setActiveTab(item) }}
                />
            </div>

            {/* Modal */}
            {
                getActiveTab() === 0 ?
                    <Modal isOpen={isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none modal-lg">
                        <ModalHeader className="bg-white" toggle={toggle}>{userRole === 3 ? 'Add Participants' : 'Add Researcher'}</ModalHeader>
                        <ModalBody>

                            {uploadedParticipantList ? (
                                <>
                                    {/* File Display */}
                                    <div className="pt-3 _survey-steps_ text-center">
                                        <div className="position-relative">
                                            <span className="questionImg_close" style={{ marginLeft: '55px', zIndex: '1' }} onClick={onCancelFile}>&times;</span>
                                            <div className="align-self-center">
                                                <div className="m-auto img-fluid border bg-light" style={{ width: '150px', borderRadius: '0.5rem' }}>
                                                    <img src={fileIcon} alt="" width="55px" className="py-4" /><br />
                                                    <p>File Format {typeof fileSrc === 'string' ? uploadedParticipantList.split('.').pop() : uploadedParticipantList.name.split('.').pop()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            ) : (
                                <>
                                    {/* Add participant through emails */}
                                    <FormGroup>
                                        <Label for="email">Enter Email here</Label>
                                        <AsyncTypeahead className="light_typeahead custom_drop"
                                            id="email"
                                            labelKey="email"
                                            multiple
                                            onChange={setEmail}
                                            options={emailList}
                                            placeholder="Email"
                                            selected={selectedEmail}
                                            allowNew
                                            onSearch={(searchStr) => serachEmail(searchStr)}
                                            newSelectionPrefix=""
                                        />
                                    </FormGroup>

                                    <hr/>

                                    {/* File Drop Zone  */}
                                    <Label for="dropzone">Import Participants from file</Label>
                                    < div className="dropzone-wrapper dropzone-wrapper-sm">
                                        <Dropzone multiple={false} accept={".xlsx, .xls, .csv"} onDrop={file => onFileSelect(file)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                        <div className="d-flex">
                                                            <span className="pe-7s-link ml-1 mr-3" style={{ fontSize: "2rem", verticalAlign: "middle" }}></span>
                                                            <span className="text-left align-self-center" style={{ verticalAlign: "middle" }}>Drop your file here or click to upload.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                </>

                            )}

                            <div className="text-right mt-2">
                                <Button className={` ${styles.bg_blue} mr-3`} onClick={addParticipants}>
                                    Save
                                </Button>
                                <Button className={` bg-light text-muted`}
                                    style={{ border: '1px solid #6c757d ' }}
                                    onClick={() => setModal(!isModalOpen)}>
                                    Cancel
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    :
                    <Modal isOpen={isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" size="lg">
                        <ModalContent newAudienceData={newAudienceData} setNewAudienceData={setNewAudienceData} ProjectParticipants={ProjectParticipants}
                            setParticipant={setParticipant} selectedParticipant={selectedParticipant} setModal={toggle} onSave={addAudience} title="Add Focus Group" />
                    </Modal>
            }


        </>
    )

}

export const ModalContent = (props) => {

    const { newAudienceData, setNewAudienceData, ProjectParticipants, setParticipant, selectedParticipant } = props

    const toggle = () => {
        props.setModal()
    }

    return (
        <>
            <ModalHeader className="bg-white" toggle={toggle}>{props.title}</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-12">
                        <FormGroup>
                            <Label for="grpTitle">Group Title</Label>
                            <Input type="text" name="grpTitle" id="grpTitle" value={newAudienceData.title}
                                onChange={(e) => setNewAudienceData({ ...newAudienceData, title: e.target.value })}>

                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input type="text" name="description" id="description" value={newAudienceData.description}
                                onChange={(e) => setNewAudienceData({ ...newAudienceData, description: e.target.value })}>

                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-12">
                        <FormGroup className="custom_drop">
                            <Label for="participants">Participants</Label>
                            <Typeahead className="light_typeahead  small_typeahead"
                                id="participant"
                                labelKey="participant"
                                multiple
                                onChange={setParticipant}
                                options={ProjectParticipants}
                                placeholder="Select Participant"
                                selected={selectedParticipant}
                                emptyLabel="No participants Found. Kindly add participants to create audience."
                            />

                        </FormGroup>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <Button className={`mr-3`} style={{ border: '1px solid #006FA7 ', backgroundColor: "#006FA7" }} onClick={() => props.onSave()}>
                        Save
                    </Button>
                    <Button className={` bg-light text-muted`}
                        style={{ border: '1px solid #6c757d ' }} onClick={() => toggle()}>
                        Cancel
                    </Button>
                </div>
            </ModalBody>

        </>
    )
}

export default Main;