import { AVAILABLE_CHAT_HISTORY } from "../actions/types";

let chatDataState = { chats: [], totalPages: 0, currentPage: 1, count: 0 };

export default function (state = chatDataState, action) {
    switch (action.type) {
        case AVAILABLE_CHAT_HISTORY:
            if (state.chats.length === 0) {
                return {
                    ...state,
                    chats: action.payload.content.messages,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                    count: action.payload.content.messagesCount
                }
            } else {
                let concatResult = {};
                concatResult.chats = state.chats.concat(action.payload.content.messages);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                concatResult.count = action.payload.content.messagesCount;
                return {
                    ...state,
                    chats: concatResult.chats,
                    totalPages: concatResult.totalPages,
                    currentPage: concatResult.currentPage,
                    count: concatResult.count
                }
            }
        default:
            return state;
    }
}

