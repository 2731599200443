import { AVAILABLE_PARTICIPANTS_POST, AVAILABLE_PARTICIPANTS_REPLY_STREAM, AVAILABLE_POST_PARTICIPANTS, AVAILABLE_SURVEY_PARTICIPANTS } from "../actions/types";

let activityParticipantsDataState = { participants: [], posts: [], userStreams: [], totalPages: 0, currentPage: 1 };

export default function (state = activityParticipantsDataState, action) {
    switch (action.type) {
        case AVAILABLE_POST_PARTICIPANTS:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    participants: []
                }
            } else {
                if (state.participants.length === 0) {
                    return {
                        ...state,
                        participants: action.payload.content.participants,
                        totalPages: action.payload.content.totalPages,
                        currentPage: parseInt(action.payload.content.currentPage),
                    }
                } else {
                    let concatResult = {};
                    concatResult.participants = state.participants.concat(action.payload.content.participants);
                    concatResult.totalPages = action.payload.content.totalPages;
                    concatResult.currentPage = parseInt(action.payload.content.currentPage);
                    return {
                        ...state,
                        participants: concatResult.participants,
                        totalPages: concatResult.totalPages,
                        currentPage: concatResult.currentPage,
                    }
                }
            }


        case AVAILABLE_SURVEY_PARTICIPANTS:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    participants: []
                }
            } else {
                if (state.participants.length === 0) {
                    return {
                        ...state,
                        participants: action.payload.content.participants,
                        totalPages: action.payload.content.totalPages,
                        currentPage: parseInt(action.payload.content.currentPage),
                    }
                } else {
                    let concatResult = {};
                    concatResult.participants = state.participants.concat(action.payload.content.participants);
                    concatResult.totalPages = action.payload.content.totalPages;
                    concatResult.currentPage = parseInt(action.payload.content.currentPage);
                    return {
                        ...state,
                        participants: concatResult.participants,
                        totalPages: concatResult.totalPages,
                        currentPage: concatResult.currentPage,
                    }
                }
            }

        case AVAILABLE_PARTICIPANTS_POST:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    participants: []
                }
            } else {
                if (state.posts.length === 0) {
                    return {
                        ...state,
                        posts: action.payload.content.posts,
                        totalPages: action.payload.content.totalPages,
                        currentPage: parseInt(action.payload.content.currentPage),
                    }
                } else {
                    let concatResult = {};
                    concatResult.posts = state.posts.concat(action.payload.content.posts);
                    concatResult.totalPages = action.payload.content.totalPages;
                    concatResult.currentPage = parseInt(action.payload.content.currentPage);
                    return {
                        ...state,
                        posts: concatResult.posts,
                        totalPages: concatResult.totalPages,
                        currentPage: concatResult.currentPage,
                    }
                }
            }
        case AVAILABLE_PARTICIPANTS_REPLY_STREAM:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    participants: []
                }
            } else {
                if (state.userStreams.length === 0) {
                    return {
                        ...state,
                        userStreams: action.payload.content.userStreams,
                        totalPages: action.payload.content.totalPages,
                        currentPage: parseInt(action.payload.content.currentPage),
                    }
                } else {
                    let concatResult = {};
                    concatResult.userStreams = state.userStreams.concat(action.payload.content.userStreams);
                    concatResult.totalPages = action.payload.content.totalPages;
                    concatResult.currentPage = parseInt(action.payload.content.currentPage);
                    return {
                        ...state,
                        userStreams: concatResult.userStreams,
                        totalPages: concatResult.totalPages,
                        currentPage: concatResult.currentPage,
                    }
                }
            }
        default:
            return state;
    }
}

