import React, { useState } from 'react'
import '../../../assets/components/pageheader/PageHeader'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import Listing from '../../../assets/components/listing/Listing'
import { useNavigate } from 'react-router';

import ResizeDetector from "react-resize-detector";



// ************************** This screen is removed from the UI ******************************


const Sheduled = () => {

    
    const [column] = useState([
        { Name: 'Serial No', Type: 'number' },
        { Name: "Username", Type: 'string' },
        { Name: "User Email", Type: 'string' },
        { Name: "Mobile No", Type: 'number' },
        { Name: "City", Type: 'string' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    badgeText: 'Shedule',
                    type: 'badge'
                }]
        }
    ])

    const [data] = useState([
        { SerialNo: '04', Username: 'Johnclarke', UserEmail: 'abc@gmail.com', MobileNo: '06677888899', City: 'Karachi' },
        { SerialNo: '04', Username: 'Johnclarke', UserEmail: 'abc@gmail.com', MobileNo: '06677888899', City: 'Karachi' },
        { SerialNo: '04', Username: 'Johnclarke', UserEmail: 'abc@gmail.com', MobileNo: '06677888899', City: 'Karachi' },
        { SerialNo: '04', Username: 'Johnclarke', UserEmail: 'abc@gmail.com', MobileNo: '06677888899', City: 'Karachi' },
        { SerialNo: '04', Username: 'Johnclarke', UserEmail: 'abc@gmail.com', MobileNo: '06677888899', City: 'Karachi' },
    ])

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                    <Card className="main-card mb-3">
                        <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                            <div style={{ overflowX: width < 470 && 'auto', display: 'flex', width: '100%' }}>

                                <div className="text-white menu-header-title text-capitalize align-self-center">Sheduled Interview List</div>

                                <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                    <ul className="navbar-nav mr-auto">
                                    </ul>
                                    <div className="form-inline my-2 my-sm-0 " style={{ marginRight: '-2.5rem' }}>
                                        <div className="input-group input-group-sm w-75">
                                            <div className="input-group-prepend border-0">
                                                <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                            </div>
                                            <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </nav>

                        <CardBody className="p-0">
                            <Listing columnName={column} data={data} />
                        </CardBody>
                    </Card>
                </TransitionGroup>
            )}
        />
    )
}

export default Sheduled
