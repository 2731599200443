import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { 
    ACTIVITY_REPLY_GRAPHS, 
    ACTIVITY_REPLY_GROUPINGS, 
    AVAILABLE_ACTIVITY, 
    AVAILABLE_ACTIVITY_MEETINGS, 
    AVAILABLE_GENERAL_FORUM, 
    AVAILABLE_QUICK_SURVEY, 
    AVAILABLE_RESPONDENT_ACTIVITY, 
    AVAILABLE_RESPONDENT_FORUM, 
    CREATE_ACTIVITY, 
    CREATE_ACTIVITY_MEETING, 
    DELETE_ACTIVITY, 
    DELETE_MEETING, 
    EDIT_MEETING, 
    GET_ACTIVITY, 
    GET_MEETING_DETAILS, 
    PROJECT_ALL_ACTIVITIES, 
    RESPONDENT_ACTIVITY_DETAILS, 
    UPDATE_ACTIVITY,
    CATEGORIZE_ACTIVITY,
    CREATE_CATEGORIES,
    GET_CATEGORIES,
    SCRIPT_CATEGORIES 
} from './types.js';

// ****************  These apis are used in ADMIN & RESEARCHER END inside Activity Section of Projects *********************

// Returns object containing one activity details we pass in param
export const getActivity = (id) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_ACTIVITY,
        payload: request
    }
}

export const createCategories =(id, category)=>{
    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/categories/makeCategories`;

    const request = axios.post(url, category)
        .then(response => response.data);

    return {
        type: CREATE_CATEGORIES,
        payload: request
    }
}

export const getAllCategories =(id,surveyId)=>{
    console.log('surveyId ->', surveyId)
    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/${surveyId}/categories/getCategories`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_CATEGORIES,
        payload: request
    }
}


export const scriptCategories =(id,surveyId, data)=>{
   
    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/${surveyId}/categories/scriptCategories`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: SCRIPT_CATEGORIES,
        payload: request
    }
}

// get categoies without any condition
export const activityQuestionsCategories = (id) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/categories`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: CATEGORIZE_ACTIVITY,
        payload: request
    }
}


export const getActivityCategoryWise = (id, option,checkboxOptions,unChecked_opt, questionId) => {
   

    
let data={
    selectedOption: option && option !='' ? option : 'no radio option',
    selectedCkOptions:  checkboxOptions && checkboxOptions.length !=0 ? checkboxOptions : 'no checkbox array',
    unchecked_opt: unChecked_opt && unChecked_opt!=undefined ? unChecked_opt: 'no unchecked option',
    selectedQuestionId:questionId
}

console.log('req.body frontend ->', data)
    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/categories/abc`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CATEGORIZE_ACTIVITY,
        payload: request
    }
}

// Following are the images of activities downloaded in backend and then being accessed here using individual routes
export const getActivityImg = (id) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/activityImage`;

    const request = axios.get(url)
        .then(response => response.data
            
            );

    return {
        type: GET_ACTIVITY,
        payload: request
    }
}
export const getActivityVideo = (id, pageNo, questionNo) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/activityVideo/${pageNo}/${questionNo}`;

    const request = axios.get(url)
        .then(response => response.data
            
            );

    return {
        type: GET_ACTIVITY,
        payload: request
    }
}

export const getActivityVideo2 = (id, catId, questionNo) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/activityVideo/scripted/${catId}/${questionNo}`;

    const request = axios.get(url)
        .then(response => response.data
            
            );

    return {
        type: GET_ACTIVITY,
        payload: request
    }
}
export const getActivityQuestionImage = (id) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}/activityQuestionImage`;

    const request = axios.get(url)
        .then(response => response.data
            
            );

    return {
        type: GET_ACTIVITY,
        payload: request
    }
}



export const createActivity = (data, activity) => {
     console.log('create activity->', data)
    let url = `${BACK_SERVER_URL}api/activities/${activity}`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_ACTIVITY,
        payload: request
    }
}

// Returns all Project Activities to display in listing ( paginated )
export const getProjectActivity = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/project/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY,
        payload: request
    }
}

// Returns all Project Activities ( without pagination ) to display in dropdown in Project Report Section 
export const getProjectAllActivity = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/project-activities/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: PROJECT_ALL_ACTIVITIES,
        payload: request
    }
}

// Returns all project screenings to display in listing
export const getProjectScreeners = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/project-screener/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY,
        payload: request
    }
}

export const updateActivity = (id, data, activity) => {

    let url = `${BACK_SERVER_URL}api/activities/${activity}/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: UPDATE_ACTIVITY,
        payload: request
    }
}

export const delActivity = (id) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_ACTIVITY,
        payload: request
    }
}

// Used to generate graphs of each questions of surveys  
export const getActivityReplyGraphs = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activity/graphs`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ACTIVITY_REPLY_GRAPHS,
        payload: request
    }
}

// Used to generate graphs of each questions of surveys based on criteria selection
export const surveyGroupings = (data) => {

    let url = `${BACK_SERVER_URL}api/replies/activity/groupings`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: ACTIVITY_REPLY_GROUPINGS,
        payload: request
    }
}


// ****************  Used inside view/Participant/Meeting of Activity Section in Projects  *********************
// Used to create and manage zoom meetings for participants ( only available in interviews and focus group discussion )

export const createActivityMeeting = (data) => {

    let url = `${BACK_SERVER_URL}api/meetings/`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: CREATE_ACTIVITY_MEETING,
        payload: request
    }
}

export const meetingDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/meetings/${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_MEETING_DETAILS,
        payload: request
    }
}

export const deleteMeeting = (id) => {

    let url = `${BACK_SERVER_URL}api/meetings/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_MEETING,
        payload: request
    }
}

export const editMeeting = (id, data) => {

    let url = `${BACK_SERVER_URL}api/meetings/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_MEETING,
        payload: request
    }
}

export const getAllActivityMeetings = (data) => {

    let url = `${BACK_SERVER_URL}api/meetings/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_MEETINGS,
        payload: request
    }
}

// *********** Used in Admin & RESPONDENT Section in Mini Survey **************
// ****** Quick Surveys & Mini Surveys are same *******

// Returns all quick surveys to display in listing
export const getQuickSurvey = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/quick-surveys`;

    const request = axios.post(url, data)
        .then(response => response.data,
           
            );

    return {
        type: AVAILABLE_QUICK_SURVEY,
        payload: request
    }
}

// ******* Used in RESPONDENT SECTION inside projects *********
// Used to display all respondent activities of a project
export const getRespondentActivity = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/respondent`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_RESPONDENT_ACTIVITY,
        payload: request
    }
}

// Used inside Activity Section inside Project of RESPONDENT to display activity details, form ready to be filled
export const getRespondentActivityDetails = (id) => {
    
    let url = `${BACK_SERVER_URL}api/activities/:activity/respondent/`+ id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: RESPONDENT_ACTIVITY_DETAILS,
        payload: request
    }
}

export const getRespondentActivityDetails2 = (id, user) => {

    // let data = user 
    // console.log('user id ->', data)
    let url = `${BACK_SERVER_URL}api/activities/:activity/random/${user}/respondent/` +id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: RESPONDENT_ACTIVITY_DETAILS,
        payload: request
    }
}

export const getRespondentActivityDetailsSharable =(id) =>{
    let url = `${BACK_SERVER_URL}api/activities/:activity/sharable/respondent/` +id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: RESPONDENT_ACTIVITY_DETAILS,
        payload: request
    }
}

// Used inside Group Forums in RESPONDENT END ( project specific forums )
export const getRespondentForum = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/respondent/forums`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_RESPONDENT_FORUM,
        payload: request
    }
}

// Used inside Group Forums in RESPONDENT END ( general forums )
export const getAllGeneralForum = (data) => {

    let url = `${BACK_SERVER_URL}api/activities/:activity/general-forums`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_GENERAL_FORUM,
        payload: request
    }
}




