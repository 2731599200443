import { GET_AUDIENCE_DETAILS, PROJECT_AUDIENCE_AVAILABLE } from "../actions/types";

let audienceDataState = { audience: [], audienceDetails: {} };
export default function (state = audienceDataState, action) {
    switch (action.type) {
        case PROJECT_AUDIENCE_AVAILABLE:
            if (action.payload.content === undefined) {
                return {
                    ...state,
                    audience: []
                }
            } else {
                return {
                    ...state,
                    audience: action.payload.content,
                }
            }
        case GET_AUDIENCE_DETAILS:
            return { ...state, audienceDetails: action.payload.content }

        default:
            return state;
    }
}

