import React, { useState } from 'react';

import Tabs from 'react-responsive-tabs';
// import { useNavigate } from 'react-router';
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import InterviewIcon from '../../../assets/images/interview_header_icon.png'
import SheduleInterview from './create/SheduleInterview';
import Recording from './Recordings';
import Sheduled from './Sheduled';
import ResizeDetector from "react-resize-detector";


// ************************** This screen is removed from the UI ******************************

const Interview = () => {

    const [activeTab, setActiveTab] = useState(0)
    const [isModalOpen, setModal] = useState(false);
    const tabsContent = [
        {
            title: 'Sheduled Interviews',
            content: <Sheduled />
        },
        {
            title: 'Interview Recordings',
            content: <Recording />
        },
    ];

    function getTabs() {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index,
        }));
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <div className="project_header">
                        {
                            activeTab === 0 ?
                                <PageHeader heading="Interview" img={InterviewIcon} btnText={width > 350 ? "Shedule an Interview" : "Shedule"} btnClick={() => setModal(true)} /> :
                                <PageHeader heading="Interview" img={InterviewIcon} />
                        }
                    </div>

                    <div className="tab_blue">
                        <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} onChange={(item) => setActiveTab(item)} />
                    </div>

                    {isModalOpen && <SheduleInterview isModalOpen={isModalOpen} setModal={setModal} />}
                </>
            )}
        />
    )

}

export default Interview;