import React, { useRef, useState, useEffect } from 'react';
import { Input, Button } from 'reactstrap';
import styles from '../../../../../../assets/css/rstyling.module.css'
import Dropzone from 'react-dropzone';
import CreateIcon from '../../../../../../assets/images/create_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import PageHeader from '../../../../../../assets/components/pageheader/PageHeader';
import OnlineInterviewIcon from '../../../../../../assets/images/online_interview_icon.png'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import { createActivity, getActivity, updateActivity } from '../../../../../../actions/activities_actions';
import { Formik } from 'formik'
import * as Yup from 'yup'
import ArrowLeft from '../../../../../../assets/images/arrow_left.png';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const OnlineInterview = (props) => {

    const history = useNavigate()
    const location = useLocation();
const currentPath = location.pathname;
    const params = useParams()
    const dispatch = useDispatch()
    const formRef = useRef()
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEditMode] = useState(false)
    const [isPreview, setPreviewMode] = useState(false)
    const [isPublish, setPublishMode] = useState(false)
    const Priorities = ['low', "medium", "high"] //  These values are set to show in dropdown as per backend requirements

    const [data, setData] = useState({
        bannerImage: '',
        name: '',
        description: '',
        duration: '',
        days: '',
        priority: '',
        startDate: '',
    })



    const onPreview = () => {
        if (formRef.current) {
            setPreviewMode(true)
            formRef.current.handleSubmit()
        }
    }

    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/project/create-onlineinterview/' + params.projectid:
                return <PageHeader
                    heading={"Create Online In-depth Interview Activity"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                />
                break;

            case '/admin/project/edit-onlineinterview/' + params.projectid:
                return <div className="activity_header"><PageHeader
                    heading={"Edit Online In-depth Interview Activity"} img={OnlineInterviewIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                /></div>
                break;

            default:
                return <PageHeader
                    heading={"Create Online In-depth Interview Activity"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={false}
                    btnClick={() => onPreview()}
                />
                break;
        }
    }

    const getInterviewDetails = () => {
        dispatch(getActivity(params.onlineinterviewid))
            .then((response) => {
                setData(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    // getting data for edit
    useEffect(() => {
        const editUrl = `/admin/project/edit-onlineinterview/${params.projectid}/${params.onlineinterviewid}`
        const copyUrl = `/admin/project/create-onlineinterview/${params.projectid}/${params.onlineinterviewid}`

        if (currentPath === editUrl) {
            getInterviewDetails()
            setEditMode(true)

        } else if (currentPath === copyUrl) {
            getInterviewDetails()
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <>
            <div className="create_header">
                {getPageHeader()}
            </div>

            {/* Back Icon */}
            <span onClick={() => history("/admin/project/activity/" + params.projectid)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid mt-3">

                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="activityBanner">Activity Banner</label>
                        <div className="mx-3 mt-3 mb-4 _survey-steps_">


                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                        // position: 'fixed',
                                        // top: '20%'
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>

                                <Formik
                                    initialValues={data}
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    onSubmit={(values, { setSubmitting }) => {

                                        setSubmitting(true);

                                        const formData = new FormData()

                                        formData.append('projectId', params.projectid)
                                        formData.append('name', values.name)
                                        values.bannerImage && formData.append('bannerImage', values.bannerImage)
                                        formData.append('description', values.description)
                                        formData.append('activityType', 'Online In-Depth Interview')
                                        formData.append('priority', values.priority)
                                        isPreview ? formData.append('status', 'draft') : formData.append('status', 'scheduled')
                                        formData.append('startDate', values.startDate)
                                        formData.append('duration', values.duration)
                                        values.days && formData.append('days', values.days)

                                        Swal({
                                            title: "Please wait!",
                                            text: "Saving data...",
                                            type: "info",
                                            buttons: false,
                                            closeModal: false,
                                            closeOnClickOutside: false
                                        });

                                        if (isEdit) {
                                            dispatch(updateActivity(params.onlineinterviewid, formData, 4))
                                                .then(response => {
                                                    Swal.stopLoading();
                                                    Swal.close();
                                                    isPublish ?
                                                        Swal({
                                                            title: response.payload.title ? response.payload.title : "Created",
                                                            text: response.payload.message ? response.payload.message : 'Online In-depth Interview Created',
                                                            icon: 'success'
                                                        }).then(() => {
                                                            history('/admin/project/activity/' + params.projectid)
                                                        })
                                                        :
                                                        history({
                                                            pathname: `/admin/project/preview-onlineinterview/${params.projectid}/${response.payload.content._id}`,
                                                            state: { from: 'create' }
                                                        })
                                                })
                                                .catch(err => {
                                                    Swal({
                                                        title: err.response ? err.response.data.name : "Error",
                                                        text: err.response ? err.response.data.content : "Please try again",
                                                        icon: 'error'
                                                    }).then(() => {
                                                        Swal.stopLoading();
                                                        Swal.close();
                                                    })
                                                })
                                        } else {
                                            dispatch(createActivity(formData, 4))
                                                .then(response => {
                                                    Swal.stopLoading();
                                                    Swal.close();
                                                    isPublish ?
                                                        Swal({
                                                            title: response.payload.title ? response.payload.title : "Created",
                                                            text: response.payload.message ? response.payload.message : 'Online In-depth Interview Created',
                                                            icon: 'success'
                                                        }).then(() => {
                                                            history('/admin/project/activity/' + params.projectid)
                                                        })
                                                        :
                                                        history({
                                                            pathname: `/admin/project/preview-onlineinterview/${params.projectid}/${response.payload.content._id}`,
                                                            state: { from: 'create' }
                                                        })
                                                })
                                                .catch(err => {
                                                    Swal({
                                                        title: err.response ? err.response.data.name : "Error",
                                                        text: err.response ? err.response.data.content : "Please try again",
                                                        icon: 'error'
                                                    }).then(() => {
                                                        Swal.stopLoading();
                                                        Swal.close();
                                                    })
                                                })
                                        }


                                    }}


                                    const validationSchema={Yup.object().shape({
                                        name: Yup.string().required("* Activity Name is required"),
                                        description: Yup.string().required("* Description is required"),
                                        startDate: Yup.date().required("* Start Date is required"),
                                        // location: Yup.string().required("* Location is required"),
                                        priority: Yup.string().required("* Priority is required"),
                                        duration: Yup.number().required("* Duration is required"),
                                    })}


                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        } = props;

                                        const startDate = new Date(values.startDate)
                                        values.startDate = startDate.getFullYear() + "-" + ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (startDate.getDate())).slice(-2) 

                                        return (
                                            <form autoComplete="off" onSubmit={handleSubmit}>

                                                {/* Start of form */}

                                                <div className='row'>

                                                    <div className='col-12 mb-3'>
                                                        {/* Image Display & Dropzone */}
                                                        {values.bannerImage ? (

                                                            // Image Display
                                                            <div className="pt-3">
                                                                <div className="position-relative">
                                                                    <span className="questionImg_close" style={{ left: "97%", marginLeft: 0 }} onClick={() => setFieldValue('bannerImage', '')}>&times;</span>
                                                                    <img src={typeof values.bannerImage === 'string' ? values.bannerImage : URL.createObjectURL(values.bannerImage)} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', maxHeight: 400, width: 'max-content' }} alt="img" />
                                                                </div>
                                                            </div>

                                                        ) : (

                                                            // Image Drop Zone 
                                                            < div className="dropzone-wrapper dropzone-wrapper-sm">
                                                                <Dropzone multiple={false} accept={".jpg, .jpeg, .png"} onDrop={file => setFieldValue('bannerImage', file[0])} value={values.bannerImage}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                                                <p>
                                                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>Try dropping image here, or click to select image to upload.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>

                                                        )}
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="activityName">Activity Name</label>
                                                        <Input type="text"
                                                            className={errors.name && touched.name ? styles.is_invalid : 'valid'}
                                                            name="name"
                                                            placeholder="Activity Name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />

                                                        {errors.name && touched.name && (
                                                            <div>
                                                                {errors.name}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label htmlFor="description">Description</label>
                                                        <Input type="text"
                                                            className={errors.description && touched.description ? styles.is_invalid : 'valid'}
                                                            name="description"
                                                            placeholder="Description"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />

                                                        {errors.description && touched.description && (
                                                            <div>
                                                                {errors.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration</label>
                                                        <Input type="number"
                                                            className={errors.days && touched.days ? styles.is_invalid : 'valid'}
                                                            name="days"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.days}
                                                        />

                                                        {errors.days && touched.days && (
                                                            <div>
                                                                {errors.days}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration per Day (Minutes)</label>
                                                        <Input type="number"
                                                            className={errors.duration && touched.duration ? styles.is_invalid : 'valid'}
                                                            name="duration"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.duration}
                                                        />

                                                        {errors.duration && touched.duration && (
                                                            <div>
                                                                {errors.duration}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="priority">Priority</label>
                                                        <Input type="select"
                                                            className={errors.priority && touched.priority ? styles.is_invalid : 'valid'}
                                                            name="priority"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.priority}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                Priorities.map(priority => {
                                                                    return <option value={priority}>{priority}</option>
                                                                })
                                                            }
                                                        </Input>

                                                        {errors.priority && touched.priority && (
                                                            <div>
                                                                {errors.priority}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="startDate">Start Date</label>
                                                        <Input type="date"
                                                            className={errors.startDate && touched.startDate ? styles.is_invalid : 'valid'}
                                                            name="startDate"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.startDate}
                                                        />

                                                        {errors.startDate && touched.startDate && (
                                                            <div>
                                                                {errors.startDate}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 text-right">
                                                        <Button className={` ${styles.bg_blue} mr-3`} type="submit" onClick={() => setPublishMode(true)}>
                                                            {values.status && values.status === 'draft' ? 'Publish' : 'Save'}
                                                        </Button>
                                                        <Button className={` bg-light text-muted ml-3`} type="button"
                                                            onClick={() => history('/admin/project/activity/' + params.projectid)}
                                                            style={{ border: '1px solid #6c757d ' }}>
                                                            Cancel
                                                        </Button>
                                                    </div>

                                                </div>
                                            </form>
                                        )
                                    }}

                                </Formik>

                            </LoadingOverlay>


                        </div>
                    </div>

                </div>
            </div>
        </>
    )


}

export default OnlineInterview


