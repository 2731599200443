import React, { useState, useEffect } from 'react'
import styles from '../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../../assets/images/search_icon.png'
import { Card, CardBody } from "reactstrap";
import Listing from '../../../../assets/components/listing/Listing';
import GraphIcon from '../../../../assets/images/graph_icon.png'
import Eyeicon from '../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../assets/images/delete_icon.png'
import Editicon from '../../../../assets/images/edit_icon.png'
import ReportIcon from '../../../../assets/images/report_icon.png'
import ResizeDetector from "react-resize-detector";
import { useParams } from 'react-router';
import AlertModal from '../../../../assets/components/modal/AlertModal';
import { delActivity, getProjectScreeners } from '../../../../actions/activities_actions';
import Loader from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay-ts';
import Swal from 'sweetalert'

const Evaluation = () => {

    const params = useParams()
    const [delId, setDelID] = useState()
    const [isLoading, setLoading] = useState(true)
    const activityState = useSelector(state => state.Activity)
    const dispatch = useDispatch()

    const [screenerColumn, setColumn] = useState([
        { Name: 'Researcher', Type: 'string' },
        { Name: "Project", Type: 'string' },
        { Name: "Form Name", Type: 'string', key: 'name' },
        { Name: "URL", Type: 'url' },
        { Name: "Date", Type: 'screenerdate', key: 'startDate' },
        { Name: "Time", Type: 'screenertime', key: 'startDate' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: "/admin/project/preview-evaluation/" + params.projectid,
                    type: 'button',
                },
                {
                    icon: GraphIcon,
                    url: "/admin/project/survey-analytics/" + params.projectid,
                    type: 'button',
                },
                {
                    icon: ReportIcon,
                    url: "/admin/project",
                    type: 'button',
                    isDifferentUrl: true,
                    linkFor: 'activity report',
                    id: params.projectid
                },
                {
                    icon: Editicon,
                    url: "/admin/project/edit-evaluation/" + params.projectid,
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },

            ]
        }
    ])

    const [screenersData, setScreenersData] = React.useState([])

    const [isModalOpen, setModal] = useState(false)
    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide delete modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    const [searchString, setSearchString] = useState('')
    const [searchedScreenersResult, setSearchedScreenerData] = useState([])

    // Searching
    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredScreener = screenersData.filter(row => {
            return (
                search.test(row.Researcher) ||
                search.test(row.Project) ||
                search.test(row.FormName) ||
                search.test(row.URL)
            );
        });
        setSearchedScreenerData(filteredScreener);
    }, [searchString]);

    const getScreenersListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(getProjectScreeners({ page: currentPage, limit: dataLimit, projectId: params.projectid }))
            .then(response => {
                setScreenersData(response.payload.content.activities)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        getScreenersListing()
    }, [])

    const deleteActivity = () => {
        dispatch(delActivity(delId))
        .then(response => {
            setDelID()
            getScreenersListing(activityState.currentPage, activityState.dataLimit)
            Swal({
                title: "Deleted",
                text: "Activity Deleted",
                icon: 'info'
            });
        })
        .catch(err => {
            Swal({
                title: err.content ? err.content : "Error",
                text: "Please try again!",
                icon: 'error'
            });
        })
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>


                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


                        {/* Delete Modal */}
                        <AlertModal isModalOpen={isModalOpen} setModal={showModal} onClick={deleteActivity}/>


                        <Card className="main-card mb-3">
                            <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                                <div className="text-white menu-header-title text-capitalize">Screening List</div>

                                <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                    <ul className="navbar-nav mr-auto">
                                    </ul>
                                    <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2.5rem' }}>
                                        <div className="input-group input-group-sm w-75">
                                            <div className="input-group-prepend border-0">
                                                <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                            </div>
                                            <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }}
                                                onChange={(e) => setSearchString(e.target.value)} />
                                        </div>

                                    </form>
                                </div>
                            </nav>

                            <CardBody className="p-0">
                                {/* Loader */}
                                <LoadingOverlay tag="div" active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "#fff",
                                            opacity: 0.5,
                                        }),
                                    }}
                                    spinner={
                                        <Loader color="#6c757d" active type="ball-pulse" />
                                    }>
                                    <CardBody className="p-0">

                                        {/* Listing */}
                                        <div className="table_border">
                                            <Listing columnName={screenerColumn}
                                                data={(searchString.length > 0 || searchedScreenersResult.length > 0) ? searchedScreenersResult : screenersData}
                                                currentPage={activityState.currentPage ? activityState.currentPage : 1}
                                                totalPages={activityState.totalPages ? activityState.totalPages : 1}
                                                getListingData={(currentPage, dataLimit) => getScreenersListing(currentPage, dataLimit)}
                                                startLoading={() => setLoading(true)}
                                            />
                                        </div>

                                    </CardBody>
                                </LoadingOverlay>
                            </CardBody>
                        </Card>


                    </TransitionGroup>

                </>
            )}
        />
    )
}

export default Evaluation
