import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Input, Button } from 'reactstrap'
import { getSpecificOption } from '../../actions/settings_actions';
import moment from 'moment';
import SecureLS from 'secure-ls';
import { editUserDetails } from '../../actions/user_action';
import { cityOptions } from '../../actions/settings_actions';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from '../../assets/css/styling.module.css'
import PhoneInput from 'react-phone-input-2';
import ResizeDetector from "react-resize-detector";
import Swal from 'sweetalert';
import { Representative_G_Signup_additional_questions } from '../../actions/auth_actions'
import axios from 'axios'
import behbud_logo from '../../assets/images/behbud_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";
import styles3 from '../../assets/css/newStyling.module.css'
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

const ConceptTesting_G_signup_additional = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [phone, setPhone] = useState('');
    const systemVariables = useSelector(state => state.SystemSettings.specificOptions)
    const [isLoading, setLoading] = useState(false)
    const [cities, setCities] = useState([])
    const [incomes, setIncomes] = useState([])
    const [educations, setEducations] = useState([])
    const ls = new SecureLS()
    const params = useParams()
    const [isShowPassword, setShowPassword] = useState(false);
    const activityId = params.ActivityId
    const userId = params.UserId
    const [userData, setUserData] = useState({
       
        gender: '',
        
        birthday: '',
       
        phone: '',
    })


    const onSubmit = (values) => {

        let userAge = 0
        // calculating user age
        if (values.birthday !== "") {
            const today = new Date();
            const formattedBirthDate = new Date(values.birthday);
            let age = today.getFullYear() - formattedBirthDate.getFullYear();
            let m = today.getMonth() - formattedBirthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < formattedBirthDate.getDate())) {
                age--;
            }
            userAge = age
        }
        values.age = userAge


        // ls.set('userType', 'guest2')
        values.status = 'complete'
        values.role = 3
values.user_id = userId



        dispatch(Representative_G_Signup_additional_questions(values))
            .then(response => {
                let userId = response.payload.content.userId

                if (response.payload.message === 'Representative Complete Google Signup Successful') {


                    Swal({
                        title: "Success",
                        text: "Details added successfully!",
                        icon: 'success'
                    });

                    navigate(`/sharable-quick-survey/${activityId}/${userId}`)
                    

                }
                else if(response.payload.message === 'Something went wrong'){


                    Swal({
                        title: "Error",
                        text: "Something went wrong!",
                        icon: 'error'
                    });


                }

            })
            .catch(err => {

              
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                

            })



    }

    useEffect(() => {

        dispatch(getSpecificOption({ options: ["Education", "Income", "Countries", "Cities", "Marital Status"] }))
            .then(response => {
                console.log('get specific options ->', response.payload)
            })
            .catch(err => {
                console.log(err)
            })



    }, [])

    const onSelectCountry = (value) => {



        dispatch(cityOptions(value))
            .then(response => {

                setCities(response.payload.content.cities)
                setIncomes(response.payload.content.incomes)
                setEducations(response.payload.content.educationData)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const waveKeyframes = `
    @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
  }`;
    const waveStyle = {
        display: 'inline-block',
        animation: 'wave-animation 0.8s infinite',
        transformOrigin: '70% 70%',
    };
    React.useEffect(() => {
        const styleTag = document.createElement('style');
        styleTag.type = 'text/css';
        styleTag.appendChild(document.createTextNode(waveKeyframes));
        document.head.appendChild(styleTag);
    }, []);


    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (
                <LoadingOverlay tag="div" active={isLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "#fff",
                            opacity: 0.5,
                            // position: 'fixed',
                            // top: '2%'
                        }),
                    }}
                    spinner={
                        <Loader color="#6c757d" active type="ball-pulse" />
                    }>


                    <div className="_signup_">

                        <div className="d-flex">
                            <div className="col-lg-8 col-12">

                                {/* Logo */}
                                <div className={height > 768 && width > 800 ? "container-fluid position-fixed m-3" : "container-fluid my-3 mr-3"}>
                                    <a href="/">
                                        <img src={require('../../assets/utils/images/logo.png')} alt="logo" width='135' />
                                       
                                    </a>
                                </div>

                                <div className="container">
                                    <div className={`row justify-content-center align-items-center ${height > 768 && width > 800 ? 'h-100' : ''}`}>
                                        <div className="col-12">

                                            {/* <h4 className="mb-5 ml-4">
                                                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Hello <span role="img" aria-label="Greetings"> 👋 </span>  </h3>
                                                <p style={{ fontSize: '1rem' }}>It takes only seconds to fill up the basic details.</p>
                                            </h4> */}
                                            <h4 className="mb-5 ml-4 mt-2">
                                                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Hello <span style={waveStyle}>👋</span> </h3>
                                                <p style={{ fontSize: '1rem' }}>It takes only seconds to fill up the basic details.</p>
                                                <p style={{ fontSize: '1rem' }}>Returning Member? <span><a href={`/conceptTesting_login/${activityId}`} style={{ color: '#006FA7', fontWeight: '500' }}>
                                                    Click here
                                                </a></span> </p>
                                            </h4>

                                            {/* <div className="row"> */}

                                            <Formik
                                                initialValues={userData}
                                                enableReinitialize={true}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(true)
                                                    onSubmit(values)

                                                }}


                                                const validationSchema={Yup.object().shape({
                                                   
                                                    gender: Yup.string().required("Gender is required"),
                                                    phone: Yup.string().required("Phone is required"),

                                                    birthday: Yup.date().required("Birth Date is required")
                                                    .test("DOB", "Min age should be 13", (value) => {
                                                        return moment().diff(moment(value), "years") > 13;
                                                    }),
                                                })}


                                            >
                                                {props => {
                                                    const {
                                                        values,
                                                        touched,
                                                        errors,
                                                        setFieldValue,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,

                                                    } = props;

                                                    const onAcceptTerms = (event) => {
                                                        const { name, checked } = event.target;

                                                        handleChange(event); // Use handleChange to update the form field value
                                                    };

                                                    return (
                                                        <form autoComplete="off" onSubmit={handleSubmit}>

                                                            {/* Start of form */}
                                                            <div className='row mx-2'>


                                                           
                                                                <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">


                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Phone Number</label>

                                                                        <div className="">
                                                                            <div className="">
                                                                                <PhoneInput
                                                                                    inputStyle={{ width: '100%', height: '38px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                                                    country={'pk'}

                                                                                    value={values.phone}
                                                                                    onChange={(phone, country) => {

                                                                                        setFieldValue('phone', phone)
                                                                                    }}
                                                                                    enableSearch={true}
                                                                                    inputProps={{
                                                                                        autoComplete: 'off',
                                                                                    }}

                                                                                />


                                                                                {errors.phone && touched.phone && (
                                                                                    <small className='text-danger'>
                                                                                        {errors.phone}
                                                                                    </small>
                                                                                )}
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>User Name</label>
                                                                        <Input type="text"
                                                                            className={errors.userName && touched.userName ? styles.is_invalid : 'valid'}
                                                                            name="userName"
                                                                            placeholder="Username"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.userName}
                                                                        />

                                                                        {errors.userName && touched.userName && (
                                                                            <small className='text-danger'>
                                                                                {errors.userName}
                                                                            </small>
                                                                        )}

                                                                    </div>
                                                                </div> */}

                                                                <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Gender</label>
                                                                        <Input type="select" name="gender"
                                                                            // className={errors.gender && touched.gender ? styles.is_invalid : 'valid'}
                                                                            className={'form-control valid'}
                                                                            placeholder="Gender"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.gender}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                        </Input>

                                                                        {errors.gender && touched.gender && (
                                                                            <small className='text-danger'>
                                                                                {errors.gender}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Country</label>
                                                                        <Input type="select" name="country"
                                                                            className={'form-control valid'}
                                                                            placeholder="Country"
                                                                            onChange={handleChange}
                                                                            onInput={(e) => onSelectCountry(e.target.value)}
                                                                            onBlur={handleBlur}
                                                                            value={values.country}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                systemVariables.countries && systemVariables.countries.map(country => {
                                                                                    return <option value={country.name}>{country.name}</option>
                                                                                })
                                                                            }
                                                                        </Input>

                                                                        {errors.country && touched.country && (
                                                                            <small className='text-danger'>
                                                                                {errors.country}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div> */}

                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>City</label>
                                                                        <Input type="select" name="city"
                                                                            className={'form-control valid'}
                                                                            placeholder="City"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.city}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                cities && cities.map(city => {
                                                                                    return <option value={city.name}>{city.name}</option>
                                                                                })
                                                                            }
                                                                        </Input>

                                                                        {errors.city && touched.city && (
                                                                            <small className='text-danger'>
                                                                                {errors.city}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div> */}





                                                                <div className="col-md-6 col-12 mb-3">

                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Date of Birth</label>

                                                                        <Input
                                                                            type="date"
                                                                            className={errors.birthday && touched.birthday ? styles.is_invalid : 'valid'}
                                                                            max={moment().format("YYYY-MM-DD")}
                                                                            name="birthday"
                                                                            placeholder="birthDay"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.birthday}
                                                                        >
                                                                        </Input>
                                                                        {errors.birthday && touched.birthday && (
                                                                            <small className='text-danger'>
                                                                                {errors.birthday}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Monthly House Income</label>
                                                                        <Input type="select" name="income"
                                                                            className={'form-control valid'}
                                                                            placeholder="income"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.income}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                // systemVariables.incomeRange && systemVariables.incomeRange.map(income => {
                                                                                //     return <option value={income.name}>{income.name}</option>
                                                                                // })
                                                                                incomes && incomes.map(income => {
                                                                                    return <option value={income.name}>{income.name}</option>
                                                                                })
                                                                            }
                                                                        </Input>

                                                                        {errors.income && touched.income && (
                                                                            <small className='text-danger'>
                                                                                {errors.income}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div> */}


                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Education</label>
                                                                        <Input type="select" name="education"
                                                                            className={'form-control valid'}
                                                                            placeholder="education"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.education}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                // systemVariables.educationLevel && systemVariables.educationLevel.map(education => {
                                                                                //     return <option value={education.name}>{education.name}</option>
                                                                                // })
                                                                                educations && educations.map(city => {
                                                                                    return <option value={city.name}>{city.name}</option>
                                                                                })
                                                                            }
                                                                        </Input>

                                                                        {errors.education && touched.education && (
                                                                            <small className='text-danger'>
                                                                                {errors.education}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div> */}


                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Marital Status</label>
                                                                        <Input type="select" name="maritalStatus"
                                                                            className={'form-control valid'}
                                                                            placeholder="Marital Status"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.maritalStatus}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                systemVariables.maritalStatus && systemVariables.maritalStatus.map(status => {
                                                                                    return <option value={status.name}>{status.name}</option>
                                                                                })

                                                                            }
                                                                        </Input>

                                                                        {errors.maritalStatus && touched.maritalStatus && (
                                                                            <small className='text-danger'>
                                                                                {errors.maritalStatus}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-md-6 col-12 mb-3">
                                                                    <div className="form-group">
                                                                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Age</label>
                                                                        <Input type="number"

                                                                            className={errors.age && touched.age ? styles.is_invalid : 'valid'}
                                                                            name="age"
                                                                            placeholder="Age"
                                                                            //onChange={handleChange}
                                                                            onChange={(e) => {
                                                                                // Prevent negative numbers
                                                                                if (/^[0-9]*$/.test(e.target.value)) {
                                                                                    handleChange(e);
                                                                                }
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            value={values.age}
                                                                            inputMode="numeric" // Ensures numeric keyboard on mobile
                                                                            pattern="^[0-9]*$"  // Restricts input to non-negative numbers only
                                                                        />

                                                                        {errors.age && touched.age && (
                                                                            <small className='text-danger'>
                                                                                {errors.age}
                                                                            </small>
                                                                        )}

                                                                    </div>
                                                                </div> */}


                                                               

                                                                {/* </div> */}
                                                                <div className="col-md-6 col-sm-12 text-right mt-4">
                                                                    <Button className={`${styles.bg_blue} btn-hover-shine text-light w-100`} type="submit">Continue</Button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }}

                                            </Formik>

                                            {/* </div> */}



                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-4 d-lg-block d-none px-0" style={{ position: 'fixed', top: '0px', right: '0px' }}>
                                {/* Gradient Overlay */}


                                <div className="">
                                    <div className="d-flex align-items-center h-100" style={{ position: 'sticky', top: '0px' }}>
                                        <div className="col-12">
                                            {/* <h3 className="mb-4" style={{ color: "#eee", fontWeight: '700', lineHeight: '2.65rem', letterSpacing: '2px' }}>Signup now</h3> */}
                                            {/* <h4 style={{ color: "#eee", fontWeight: '500', lineHeight: '2.65rem', letterSpacing: '2px' }}>
                                                Genuinely Connecting Consumers to Brands.
                                                Influence change in the world with your Voice!
                                            </h4> */}


                                            <div className={`${styles3.login_img_signup}`}
                                                style={{
                                                    backgroundImage: "url(" + login_bg_new + ")",
                                                }} />



                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div >

                    </div >

                </LoadingOverlay>



            )
            }
        />

    )

}

export default ConceptTesting_G_signup_additional