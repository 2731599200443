import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { UPDATE_LOGS } from './types.js';

// *************used in app.js for all 3 types of users************


// for updating user active status based on user activity
export const updateLogs = (data) => {

    let url = `${BACK_SERVER_URL}api/logs`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: UPDATE_LOGS,
        payload: request
    }
}