import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../../assets/css/rstyling.module.css';
import { userDetailsContext } from '../../../UserDetailsProvider';

const Step_2 = (props) => {

    const dispatch = useDispatch()
    const { profileDetails, setProfileDetails } = props
    const {userDetails, setUserDetails} = useContext(userDetailsContext);
    console.log('userDetails income ranges->', userDetails)
    const systemVariables = useSelector( state => state.SystemSettings.specificOptions )

    const [err, setError] = useState({
        employment: '',
        education: '',
        occupation: '',
        income: '',
        seniority:''
    })

    const removeErr = () => {
        setError({
            employment: '',
            education: '',
            occupation: '',
            income: '',
            seniority:''
        })
    }

    const checkValidation = () => {
        removeErr()
        let notCompleted = false;
        // if (broadcastMsg.title === '') {
        //     setError((prevState) => ({ ...prevState, title: 'Title is required.' }))
        // }
        if (profileDetails.employment === '') {
            setError((prevState) => ({ ...prevState, employment: 'Employment is required.' }))
            notCompleted = true;
        }
        if (profileDetails.seniority === '') {
            console.log("INNN")
            setError((prevState) => ({ ...prevState, seniority: 'Seniority is required.' }))
            notCompleted = true;
        }
        if (profileDetails.education === '') {
            setError((prevState) => ({ ...prevState, education: 'Education of Birth is required.' }))
            notCompleted = true;
        }
        if (profileDetails.occupation === '') {
            setError((prevState) => ({ ...prevState, occupation: 'Occupation is required.' }))
            notCompleted = true;
        }
        if (profileDetails.income === '') {
            setError((prevState) => ({ ...prevState, income: 'Income Range is required.' }))
            notCompleted = true;
        }
        return !notCompleted
    }
console.log(err, profileDetails)
    const next = () => {
        if (checkValidation()) {
            props.nextStep()
        }
    }


    const onSelectIncome =(value)=>{
        
        setProfileDetails({ ...profileDetails, income: value}) 

        // .then(response => {
        //     console.log('cities frontend->',response.payload.content)
        //     setCities(response.payload.content)

        // })
        // .catch(err => {
        //     console.log(err)
        // })
}
    return (

        <React.Fragment>
            <h4 className="mt-3 mb-5">
                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Set up your Profile</h3>
                <p style={{ fontSize: '1rem' }}>It takes only seconds to set up your Profile</p>
            </h4>

            <div className="row">

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Employment Details</label>
                        <select  value={profileDetails.employment}
                            className={err.employment ?"form-control "+  styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, employment: e.target.value })}>
                            <option value='' >Select</option>
                            {
                                systemVariables.employment && systemVariables.employment.map( employment => {
                                    return <option value={employment.name}>{employment.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.employment}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Seniority</label>
                        <select  value={profileDetails.seniority}
                            className={err.seniority ?"form-control "+  styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, seniority: e.target.value })}>
                            <option value='' >Select</option>
                            {
                                systemVariables.seniority && systemVariables.seniority.map( seniority => {
                                    return <option value={seniority.name}>{seniority.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.seniority}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Education</label>
                        <select  value={profileDetails.education}
                            className={err.education ?"form-control "+  styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, education: e.target.value })}>
                            <option  value='' >Select</option>
                            {
                                systemVariables.educationLevel && systemVariables.educationLevel.map( education => {
                                    return <option value={education.name}>{education.name}</option>
                                })
                            }
                        </select>
                        <small className="text-danger">
                            {err.education}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Occupation</label>
                        <input type="text" placeholder="Enter your Occupation here" value={profileDetails.occupation}
                            className={err.occupation ? "form-control " + styles.is_invalid : 'form-control valid'}
                            onChange={(e) => setProfileDetails({ ...profileDetails, occupation: e.target.value })} />
                        <small className="text-danger">
                            {err.occupation}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Monthly Income</label>
                        <select  value={profileDetails.income}
                            className={err.income ?"form-control "+  styles.is_invalid : 'form-control valid'}
                            onChange={(e) => onSelectIncome(e.target.value)}>
                            <option  value='' >Select</option>
                            {
                                // systemVariables.incomeRange && systemVariables.incomeRange.map( income => {
                                //     return <option value={income.name}>{income.name}</option>
                                // })
                                userDetails && userDetails.map(income=>{
                                    return <option value={income.name}>{income.name}</option>
                                })
                            }
                            
                        </select>
                        <small className="text-danger">
                            {err.income}
                        </small>
                    </div>
                </div>

            </div>

            <div className="clearfix">
                <div className="float-right">
                    <div className="mb-2">
                        <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={() => next()} disabled={props.isAnimationPlaying}>Next</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}

export default Step_2