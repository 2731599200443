import React, { useState } from 'react'
import avatar from '../../../../assets/images/profile_placeholder.png'
import bg4 from "../../../../assets/utils/images/dropdown-header/city2.jpg";
import dots from '../../../../assets/images/dots_icon.png'
import {
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import ArrowLeft from '../../../../assets/images/arrow_left.png'
import { useNavigate } from 'react-router';
import styles from '../../../../assets/css/rstyling.module.css'
import PageHeader from '../../../../assets/components/pageheader/PageHeader';
import ResercherIcon from '../../../../assets/images/researcher_icon_lg.png'


// ************* This file is removed from the UI *****************

const Researcher = () => {

    const history = useNavigate()
    const [isModalOpen, setModal] = useState(false)

    const [data, setData] = useState([
        {
            Name: 'Alina',
            Status: 'Active',
            JoinedStudy: '4 days',
            StudyVisits: 15,
            Lastvisit: '58m',
            LastEmailOpen: '3 Days',
            ActivitiesCompleted: 10,
            memberType: 'Researcher'
        },
        {
            Name: 'Alina',
            Status: 'Active',
            JoinedStudy: '5 min ago',
            StudyVisits: 15,
            Lastvisit: '58m',
            LastEmailOpen: '3 Days',
            ActivitiesCompleted: 10,
            memberType: 'Researcher'
        },
        {
            Name: 'Alina',
            Status: 'Active',
            JoinedStudy: '4 days',
            StudyVisits: 15,
            Lastvisit: '58m',
            LastEmailOpen: '3 Days',
            ActivitiesCompleted: 10,
            memberType: 'Researcher'
        },
        {
            Name: 'Alina',
            Status: '4 days ago',
            JoinedStudy: '4 days',
            StudyVisits: 15,
            Lastvisit: '58m',
            LastEmailOpen: '3 Days',
            ActivitiesCompleted: 10,
            memberType: 'Researcher'
        },
        {
            Name: 'Alina',
            Status: 'Active',
            JoinedStudy: '4 days',
            StudyVisits: 15,
            Lastvisit: '58m',
            LastEmailOpen: '3 Days',
            ActivitiesCompleted: 10,
            memberType: 'Researcher'
        }
    ])

    // Show Image inModal
    const showModal = () => {
        setModal(!isModalOpen)
    }


    return (

        <>
            <div className="admin_header">
                <PageHeader
                    heading="Researcher" img={ResercherIcon}
                    btnText="Add Researcher"
                    btnClick={() => showModal()}
                />
            </div>
            <div className="container-fluid">

                {/* Back Icon */}
                <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                    <img src={ArrowLeft} alt="" width="8px" />
                    <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                </span>

                <div className="row">
                    {/* Modal */}
                    <ModalContent
                        setModal={showModal}
                        isModalOpen={isModalOpen}
                    />

                    {/* Card */}
                    {
                        data.map((data, index) => {
                            return <div key={index} className="col-md-4 col-sm-12 col-12 my-3">
                                <div className="dropdown-menu-header" >
                                    <div className="dropdown-menu-header-inner bg-dark participant_card" style={{ borderRadius: ' 0.5rem 0.5rem 0 0' }}>
                                        <div className="menu-header-image"
                                            style={{
                                                backgroundImage: "url(" + bg4 + ")",
                                            }} />
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left mr-3" style={{ zIndex: '15' }}>
                                                    <div className="widget-content-left" >
                                                        <input type="checkbox" name="select" className="mr-2" />
                                                        <img width={32} className="rounded" src={avatar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="widget-content-left flex2">
                                                    <div className="widget-heading text-white">{data.Name}</div>
                                                    <div className="widget-subheading text-white" style={{ fontSize: '0.7rem' }}>
                                                        <div className={`mb-2 mr-2 badge badge-pill badge-danger`}>
                                                            {data.memberType}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-content-right d-flex custom_drop">
                                                    <div className={data.Status === `Active` ? `text-success widget-heading mt-2` : `text-white widget-heading mt-2`} style={{ fontSize: '0.7rem' }}>
                                                        {data.Status}
                                                    </div>
                                                    <UncontrolledButtonDropdown className="mb-2 mr-2 participant_btn" style={{ zIndex: '15' }} >
                                                        <DropdownToggle>
                                                            <img className="ml-2 mr-2" src={dots} height="12px" alt="" />
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ marginLeft: '-13rem' }}>
                                                            <DropdownItem onClick={() => showModal()}>Drop</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-menu border-left border-right border-bottom" style={{ borderRadius: ' 0 0 0.5rem 0.5rem' }}>
                                    <div className="no-gutters row">
                                        <div className="col-4 text-center border-bottom py-2">
                                            <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Joined Study</label><br />
                                            <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSlabelace: 'pre-wrap' }} >{data.JoinedStudy}</label>
                                        </div>
                                        <div className="col-4 text-center py-2 border-right border-bottom">
                                            <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Study Visits</label><br />
                                            <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{data.StudyVisits}</label>
                                        </div>
                                        <div className="col-4 text-center py-2 border-bottom">
                                            <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Visit</label><br />
                                            <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{data.Lastvisit}</label>
                                        </div>
                                        <div className="col-6 text-center py-2 border-right" >
                                            <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Last Email Opened</label><br />
                                            <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{data.LastEmailOpen}</label>
                                        </div>
                                        <div className="col-6 py-2 text-center">
                                            <label className="m-0" style={{ color: '#666', fontSize: "0.7rem", whiteSpace: 'pre-wrap' }} >Activities Completed</label><br />
                                            <label style={{ color: '#666', fontSize: "0.9rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} >{data.ActivitiesCompleted}</label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        })
                    }
                </div>

            </div>
        </>
    )
}

export default Researcher


export const ModalContent = (props) => {

    const toggle = () => {
        props.setModal()
    }

    return (

        <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" >
            <ModalHeader className="bg-white" toggle={toggle}>Add Participant</ModalHeader>
            <ModalBody className="text-center">
                <p> Are you sure want to add participant? </p>
                <Button className={` ${styles.bg_blue} mr-3`}>
                    Yes
                </Button>
                <Button className={` bg-light text-muted ml-3`}
                    style={{ border: '1px solid #6c757d ' }}>
                    No
                </Button>
            </ModalBody>
        </Modal>

    )
}