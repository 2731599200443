import React, { Fragment } from "react";
import Tabs from 'react-responsive-tabs';
import { useNavigate } from "react-router";
import { TransitionGroup } from 'react-transition-group';
import Projecticon from '../../../../assets/images/attachement_header_icon.png'
import PageHeader from "../../../../assets/components/pageheader/PageHeader";
import WordCloud from "../Activity/Analytics/WordCloud";
import Files from "../Activity/Analytics/Files";
import ProjectStream from "./ProjectStreams";


// ************ This screen is removed from the UI *****************

const ProjectDetails = () => {

    // const history = useNavigate()
    // const path = currentPath.slice(0, currentPath.lastIndexOf('/'))

    // const tabsContent = [
    //     {
    //         title: 'Stream',
    //         content: <ProjectStream />
    //     },
    //     {
    //         title: 'Word Cloud',
    //         content: <WordCloud/>
    //     },
    //     {
    //         title: 'Images',
    //         content: <Files />
    //     },
    //     {
    //         title: 'Videos',
    //         content: <Files />
    //     }
    // ];

    // function getTabs() {
    //     return tabsContent.map((tab, index) => ({
    //         title: tab.title,
    //         getContent: () => tab.content,
    //         key: index,
    //     }));
    // }

    return (
        // <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
        //     transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

        //     <div className="admin_header">
        //         <PageHeader heading="Project Details" img={Projecticon} />
        //     </div>

        //     <div className="tab_blue">
        //         <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false} showInkBar={false} items={getTabs()} />
        //     </div>



        // </TransitionGroup>
        <div></div>
    )
}

export default ProjectDetails


