import React, { useEffect, useState } from 'react'
import avatar from '../../../assets/images/profile_placeholder.png'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import SecureLS from 'secure-ls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';


// ********** This component is used to show messages of a user on right side of chat screen ****************

const Chatroom = (props) => {

    const { chat, getMoreChat, roomId } = props

    const ls = new SecureLS()
    const timelineRef = React.useRef();
    const chatState = useSelector(state => state.Chat)
    const [isLoading, setLoading] = useState(false)

    const [hasMore, setHasMore] = useState(true)
    let [count, setCount] = useState(10)

    // for getting older chat 
    const fetchMoreData = () => {
        if (chatState.count <= chat.length) {
            setHasMore(false)
        } else {
            setCount(count + 10)
            getMoreChat(false, chatState.currentPage + 1, 10, roomId)

        }
    };


    return (
        <LoadingOverlay tag="div" active={isLoading}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#fff",
                    opacity: 0.5,
                }),
            }}
            spinner={
                <Loader color="#6c757d" active type="ball-pulse" />
            }>
            <>
                <div className="chat-wrapper pb-5 px-3" id="scrollableDiv" style={{
                    height: 300,
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column-reverse",
                }}
                    ref={timelineRef}>

                    <InfiniteScroll
                        dataLength={count}
                        next={fetchMoreData}
                        style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                        inverse={true}
                        hasMore={chat.length > 0 ? hasMore : false}
                        loader={<p className='text-center' style={{ textAlign: "center" }}>Loading...</p>}
                        scrollableTarget="scrollableDiv"
                        scrollThreshold="200px"
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>No more messages</b>
                            </p>
                        }
                    >
                        {
                            chat.length > 0 && chat.map((chat, index) => {
                                if (chat.author !== ls.get('accessUser').user._id) {
                                    return <div className="chat-box-wrapper" key={index}>
                                        <div className="mr-1">
                                            <div className="avatar-icon-wrapper mr-1">
                                                <div className="avatar-icon">
                                                    <img width={82} src={props.activeUser.profileImage ? props.activeUser.profileImage : avatar} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="chat-box text-white" style={{ background: '#006FA7' }}>
                                                {chat.message}
                                            </div>
                                            <small className='ml-0'>
                                                {new Date(chat.created_at).toLocaleDateString()} | {new Date(chat.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </small>
                                        </div>
                                    </div>
                                } else {
                                    return <div key={index}>
                                        <div className='mr-auto'></div>
                                        <div className="chat-box-wrapper chat-box-wrapper-right float-right" >
                                            <div className="d-flex">
                                                <div>
                                                    <div className="chat-box text-white" style={{ background: '#006FA7' }}>
                                                        {chat.message}
                                                    </div>
                                                    <small>
                                                        {new Date(chat.created_at).toLocaleDateString()} | {new Date(chat.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                    </small>
                                                </div>
                                                <div className="ml-1">
                                                    <div className="avatar-icon-wrapper ml-1">
                                                        <div className="avatar-icon">
                                                            <img width={82} src={ls.get('accessUser').user.profileImage ? ls.get('accessUser').user.profileImage : avatar} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            })
                        }
                    </InfiniteScroll>


                </div>
            </>
        </LoadingOverlay>

    )
}

export default Chatroom