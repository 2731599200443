import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { AVAILABLE_ACTIVITY_EXCERPTS, CREATE_EXCERPTS, DELETE_EXCERPT, EDIT_EXCERPT, GET_EXCERPT_DETAILS } from './types.js';

// ****************  These apis are used in ADMIN & RESEARCHER END inside Activities Section of Projects  *********************


// Returns an array of excepts against activity id to show in listing
export const getActivityExcerpts = (data) => {

    let url = `${BACK_SERVER_URL}api/excerpts/activity`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: AVAILABLE_ACTIVITY_EXCERPTS,
        payload: request
    }
}

// Used to create new excerpt 
// Takes an object containing activity id and except details as param
export const createExcerpt = (data) => {

    let url = `${BACK_SERVER_URL}api/excerpts`;

    const request = axios.post(url, data)
        .then(response => response.data);

        
    return {
        type: CREATE_EXCERPTS,
        payload: request
    }
}

// Returns an object containing excerpt details of specific id we pass in param
export const getExcerptDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/excerpts/${id}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_EXCERPT_DETAILS,
        payload: request
    }
}

// updates an excerpt against the id we pass in the param
export const editExcerpt = (id, data) => {

    let url = `${BACK_SERVER_URL}api/excerpts/${id}`;

    const request = axios.put(url, data)
        .then(response => response.data);

    return {
        type: EDIT_EXCERPT,
        payload: request
    }
}

// deletes an except against the id we pass in the param
export const deleteExcerpt = (id) => {

    let url = `${BACK_SERVER_URL}api/excerpts/${id}`;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_EXCERPT,
        payload: request
    }
}
