import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getActivity, activityQuestionsCategories, createCategories, getAllCategories, scriptCategories } from '../../../../../../actions/activities_actions'
import { useDispatch } from 'react-redux'
import Select from 'react-select';
import SecureLS from 'secure-ls';
import Swal from 'sweetalert';
import { Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap';
import styles from '../../../../../../assets/css/styling.module.css'

export default function Script_Survey() {
    const params = useParams()
    const ls = new SecureLS()
    const dispatch = useDispatch()
    const [questions, setQuestions] = useState([])
    const [dropDown, setDropDown] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [surveyId, setSurveyId] = useState('')
    const [categoryName, setCategoryName] = useState('');
    const [scriptForm, setScriptForm] = useState([])
    const [allCategories, setAllCategories] = useState([]) // array of obj
    const [CategoriesNames, setCategoriesNames] = useState([])
    const [selectedOpt, setSelectedOpt] = useState([])
    const [CatQuestions, setCatQuestions] = useState([])
    const [conditionQuestionType, setConditionQuestionType] = useState('')
    const [options, setOptions] = useState([])
    const [conditionQuestion, setConditionQuestion] = useState('')
    const [conditionOption, setConditionOption] = useState('')
    console.log('params in script survey ->', params)
    useEffect(() => {
        dispatch(getActivity(params.activityid))
            .then(response => {

                console.log('activity full questionnire ->', response.payload.content.survey)
                setSurveyId(response.payload.content.survey._id)
               
                setQuestions(response.payload.content.survey.pages[0].questions)
            })
            .catch(err => {
                console.log('')
            })


    }, [])

    const addCategory = () => {

        setDropDown([...dropDown, { id: dropDown.length + 1, value: '' }]);

    }
    const addScript = () => {
        setScriptForm([...scriptForm, { id: scriptForm.length + 1, value: '' }])
    }

    const handleChange = (selectedOptions) => {
        
        setSelectedQuestions(selectedOptions);
    };


    const onSelectCategory = (catId) => {

        let questions = [{}];
        allCategories.map((category) => {
            if (category._id === catId) {
                category.questions.map(question =>
                    questions.push({ question: question.questionText, questionId: question._id, questionOptions: question.options, questionType: question.questionType })
                )
            }
        }

        )

        setCatQuestions(questions)

    }

    const onSelectCategory2 = (catId) => {

const conditionOption= selectedOpt.map(x=>x.value)
        let finalScriptObj = {
            categoryToEdit: catId,
            conditionQuestion: conditionQuestion,
            conditionOption: conditionOption,
            conditionQuestionType: conditionQuestionType
        }
        console.log('final Condition obj ->', finalScriptObj)
        dispatch(scriptCategories(params.activityid, surveyId, finalScriptObj))
            .then((response) => {
                // setAllCategories(response.payload.content)
                if(response.payload.message === 'Section Scripted'){
                Swal({
                    title: 'Section Scripted',
                   
                    icon: 'success'
                })
            }

            }
            )
    }

    const onSelectQuestion = (questionIdd) => {
        //console.log('question id selected ->', questionId)
        setConditionQuestion(questionIdd)

        CatQuestions.map((question) => {
            if (question.questionId === questionIdd) {
                setConditionQuestionType(question.questionType)
                setOptions(question.questionOptions)
            }
        }

        )

    }

    const onSelectOption = (option) => {
        setSelectedOpt(option)
       
        //setConditionOption(option)

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const categoryObject = {
            questionnaire: surveyId,
            categoryName: categoryName,
            questions: selectedQuestions
        };


        dispatch(createCategories(params.activityid, categoryObject))
        // .then(res=>
        //     console.log('respons ->', res.payload.content)
        //     )

    };
    useEffect(() => {

        dispatch(getAllCategories(params.activityid, surveyId))
            .then((response) => {
                setAllCategories(response.payload.content)

            }
            )
            .catch(err => {
                console.log('')
            })

    }, [surveyId])

    return (
        <>
            <div style={{ height: '150rem' }}>
                <Button className='my-5 mx-5' onClick={() => addCategory()}>Add a Section</Button>
                {dropDown.map((dropDown, index) => (
                    <div key={index} className='mx-5 my-5'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Section Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Section Name"
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                />
                            </div>

                            <Select
                                isMulti
                                name="Questions"
                                options={questions.map(question => ({ value: question._id, label: question.questionText && question.questionText != undefined ? question.questionText.replace(/<span>|<\/span>/g, '') : '', questionOptions: question.options, questionType: question.questionType, required: question.required, questionRows: question.rows, questionCol: question.columns, questionFile: question.questionFile && question.questionFile }))}
                                placeholder='Select Question'
                                className="basic-multi-select"
                                classNamePrefix="Select"
                                onChange={handleChange}
                            />

                            <Button type="submit" className='my-2'>Submit</Button>
                        </form>
                    </div>
                ))}

                {/* scripting */}
                <Button className='my-5 mx-5' onClick={() => addScript()}>Script a Section</Button>
                {scriptForm.map((form, index) => (
                    <div className="form-group container my-5">
                        <div>
                            <label>If a Respondent </label>
                        </div>
                        <label >In a Section:</label>

                        <Input type="select" name="category" id="category"
                            className={`form-control`}
                            onInput={(e) => onSelectCategory(e.target.value)}
                        >
                            <option value="">Select</option>

                            {
                                allCategories && allCategories.map((category, index) => {
                                    return <option key={index} value={category._id}>{category.categoryName}</option>
                                })
                            }
                        </Input>

                        <label className='mt-3'>In a Question:</label>

                        <Input type="select" name="category" id="category"
                            className={`form-control`}
                            onInput={(e) => onSelectQuestion(e.target.value)}
                        >
                            <option value="">Select</option>

                            {
                                CatQuestions && CatQuestions.map((question, index) => {
                                    return <option key={index} value={question.questionId}>{question.question && question.question != undefined ? question.question.replace(/<span>|<\/span>/g, '') : ''}</option>
                                })
                            }
                        </Input>
                        {/* show dropdown of multiple selection instead of single dropdown */}
                        <label className='mt-3'>Selects Option</label>

                        {/* <Input type="select" name="category" id="category"
                            className={`form-control`}
                            onInput={(e) => onSelectOption(e.target.value)}
                        >

                            
                            <option value="">Select</option>

                            {
                                options && options.map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>
                                })
                            }
                        </Input> */}
                        <Select

                            isMulti
                            name="interests"

                            options={options.map(opt => ({ value: opt, label: opt }))}
                            value={selectedOpt}
                            placeholder='Select options'
                            className="basic-multi-select"
                            classNamePrefix="Select"
                            onChange={onSelectOption}

                        />


                        <label className='mt-3'>Show Section</label>

                        <Input type="select" name="category" id="category"
                            className={`form-control`}
                            onInput={(e) => onSelectCategory2(e.target.value)}
                        >
                            <option value="">Select</option>

                            {
                                allCategories && allCategories.map((category, index) => {
                                    return <option key={index} value={category._id}>{category.categoryName}</option>
                                })
                            }
                        </Input>

                    </div>


                ))}
            </div>

        </>
    )
}

