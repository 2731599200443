import React from 'react';
import { useSelector } from 'react-redux';

const Step_3 = (props) => {

    const { profileDetails, setProfileDetails } = props
    const systemVariables = useSelector( state => state.SystemSettings.specificOptions )

    return (

        <React.Fragment>
            <h4 className="mt-3 mb-5">
                <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Set up your Profile</h3>
                <p style={{ fontSize: '1rem' }}>It takes only seconds to set up your Profile</p>
            </h4>

            <div className="row">

                <div className="col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Q. Relation with Cheif Wage Earner (CWE) :</label>
                        <select className="form-control" value={profileDetails.cwe}
                            onChange={ (e) => setProfileDetails({ ...profileDetails, cwe: e.target.value }) }>
                            <option selected={true} disabled={true} value='' >Select</option>
                            {
                                systemVariables.relationship && systemVariables.relationship.map( relationship => {
                                    return <option value={relationship.name}>{relationship.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Q. What is the name of the company you / your CWE works for?</label>
                        <textarea className="form-control" rows="3" style={{ resize: 'none' }} placeholder="Answer here" value={profileDetails.company}
                            onChange={ (e) => setProfileDetails({ ...profileDetails, company: e.target.value }) }/>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <div className="form-group">
                        <label style={{ fontSize: '1rem', fontWeight: '600', color: '#666' }}>Q. What is your / your CWE's job title?</label>
                        <textarea className="form-control" rows="3" style={{ resize: 'none' }} placeholder="Answer here" value={profileDetails.jobRole}
                            onChange={ (e) => setProfileDetails({ ...profileDetails, jobRole: e.target.value }) }/>
                    </div>
                </div>

            </div>

            <div className="clearfix">
                <div className="float-right">
                    <div className="mb-2">
                        <button className="btn btn-primary py-2 px-5" style={{ backgroundColor: '#006FA7' }} onClick={() => props.nextStep()} disabled={props.isAnimationPlaying}>Next</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}

export default Step_3