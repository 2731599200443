import React, { useEffect, useState } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams } from 'react-router';
import MessageIcon from '../../../assets/images/inperson_forum_icon.png'
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import styles from '../../../assets/css/rstyling.module.css';
import ArrowLeft from '../../../assets/images/arrow_left.png'
import Swal from 'sweetalert'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getBroadcastMsgDetails } from '../../../actions/broadcast_msg_actions';

const ViewBroadcastMessage = () => {

    const [broadcatMsgDetails, setBroadcastMsgDetails] = useState({})
    const [isLoading, setLoading] = useState(true)

    const params = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getBroadcastMsgDetails(params.msgid))
            .then(response => { 
                setBroadcastMsgDetails(response.payload.content)
                setLoading(false) 
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Something went wrong",
                    icon: 'error',
                }).then(() => history('/admin/broadcast-message'))


            })
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <div className="project_header">
                <PageHeader heading="Broadcast Message" img={MessageIcon} />
            </div>

            {/* Back Icon */}
            <span onClick={() => history(-1)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} alt="" width="8px" />
                <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
            </span>

            <div className="container-fluid">

                <div className={`${styles.text_bold} mb-2`}>
                    Send To
                </div>
                {/* Sender List */}
                <div className="d-flex">
                    <div className="widget-content p-0 ml-2">
                        <div className="widget-content-wrapper">

                            {/* Receiver */}
                            <div className="widget-content-left">
                                <div className={classNames({
                                    'badge-success': broadcatMsgDetails.receiver === 'all',
                                    'badge-warning': broadcatMsgDetails.receiver === 'project',
                                }, 'mb-2 mr-2 badge badge-pill widget-subheading opacity-10')}
                                    style={{ fontSize: '0.7rem' }}>
                                    {
                                        broadcatMsgDetails.receiver && broadcatMsgDetails.receiver === 'all' ?
                                            'All Respondent' : 'Projects'
                                    }
                                </div>

                            </div>
                        </div>
                    </div>


                    {
                        broadcatMsgDetails.receiver && broadcatMsgDetails.receiver === 'project' &&
                        
                        // Project list
                        broadcatMsgDetails.projectList.map(project => {
                            return <div className="widget-content p-0 ml-2">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <div className="widget-heading text-dark">
                                            {project.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>

                {/* Subject */}
                <div className={`${styles.text_bold} my-2`}>
                    Subject
                </div>
                <p>
                    {broadcatMsgDetails.subject && broadcatMsgDetails.subject}
                </p>

                {/* Template */}
                <div className="mt-2">
                    <div className={`${styles.text_bold} mb-2`}>
                        Message Template
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: broadcatMsgDetails.emailContent && broadcatMsgDetails.emailContent }}>
                    </div>
                </div>
            </div>
        </TransitionGroup>

    )
}

export default ViewBroadcastMessage
