import React, { useEffect, useState } from "react";
import ResizeDetector from "react-resize-detector";
import { useNavigate } from "react-router-dom";
import styles from '../../assets/css/rstyling.module.css'
import styles1 from '../../assets/css/styling.module.css'
import login_bg from "../../assets/images/login_bg.jpg";
import logo from '../../assets/images/logo.png'

const SetProfileOption = () => {

    const history = useNavigate()

    const imageLayout = (width) => {
        if (width <= 440) {
            return {
                width: '80px',
                height: '80px'
            }
        } else {
            return {
                width: '120px',
                height: '120px'
            }
        }
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width, height }) => (

                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-4 p-0 d-none d-lg-block">
                            <div className={`h-100 ${styles.gradient_grbl}`}>
                                <div
                                    style={{
                                        backgroundImage: "url(" + login_bg + ")",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        opacity: "0.2",
                                        zIndex: "5",
                                        backgroundSize: "cover",
                                        top: 0,
                                        left: 0
                                    }} />

                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 bg-white">

                            {/* Logo */}
                            <ul className="nav justify-content-end">
                                <li className="nav-item">
                                    <img src={logo} alt="logo" width="150px" style={{ margin: '2rem 1rem 0 0' }} />
                                </li>
                            </ul>

                            <div className="col-12 pt-5">

                                <div className="justify-content-center align-self-center">
                                    <div className="mb-5 ml-3">
                                        <h3 className="text-dark mb-2" style={{ fontWeight: '500' }}>Join Our Community</h3>
                                        <p style={{ fontSize: '1rem' }}>Become a part of the Consumer Connect community today! And become  eligible to participate in surveys to win exciting rewards!
                                            Join as a member  😊 to earn points and enjoy rewards or
                                            Join as a guest to participate in our quick surveys!</p>
                                    </div>

                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer', marginTop: '7rem' }}>
                                        {/* Guest Profile */}
                                        <div className={width <= 440 ? "text-center mx-3" : "text-center mx-5"} onClick={() => history('/guest_profile')}>
                                            <span className={`${styles1.icon_placer} ${styles1.guest_icon}`} style={imageLayout(width)}></span>

                                            {/* text */}
                                            <h5 className="mt-3" style={{ color: "#444", fontWeight: 600, fontSize: "1.1rem" }}>
                                                Continue as Guest
                                            </h5>
                                        </div>

                                        

                                        {/* Profile Completion */}
                                        <div className={width <= 440 ? "text-center mx-3" : "text-center mx-5"} onClick={() => history('/set_profile')}>
                                            <span className={`${styles1.icon_placer} ${styles1.registration_icon}`} style={imageLayout(width)}></span>

                                            {/* text */}
                                            <h5 className="mt-3" style={{ color: "#444", fontWeight: 600, fontSize: "1.1rem" }}>
                                                Become a Member
                                            </h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        />
    )

}

export default SetProfileOption