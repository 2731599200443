import axios from 'axios';

import { BACK_SERVER_URL } from '../config/server.js';
import { CAMPAIGN_AVAILABLE, CREATE_CAMPAIGN, DELETE_CAMPAIGN, EDIT_CAMPAIGN, GET_CAMPAIGN_DETAIL } from './types.js';

// ****************  These apis are used in ADMIN END in CAMPAIGN SECTION of Templates *********************


// Takes an object as param containing campaign details
// creates a new campaign in db
export const createCampaign = (campaignData) => {

    let url = `${BACK_SERVER_URL}api/campaigns/`;

    const request = axios.post(url, campaignData)
        .then(response => response.data);

    return {
        type: CREATE_CAMPAIGN,
        payload: request
    }
}

// Returns an array of all campaigns to display listing
export const getAllCampaigns = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/campaigns?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: CAMPAIGN_AVAILABLE,
        payload: request
    }
}

// Takes campaign id as param
// Returns object containing details of a specific campaign 
export const getCampaignDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/campaigns/` + id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_CAMPAIGN_DETAIL,
        payload: request
    }
}

// updates campaign against the id we pass in the param
export const editCampaign = (id, emailTemplateData) => {

    let url = `${BACK_SERVER_URL}api/campaigns/` + id;

    const request = axios.put(url, emailTemplateData)
        .then(response => response.data);

    return {
        type: EDIT_CAMPAIGN,
        payload: request
    }
}

// deletes campaign against the id we pass in the param
export const delCampaign = (id) => {

    let url = `${BACK_SERVER_URL}api/campaigns/` + id;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_CAMPAIGN,
        payload: request
    }
}
