import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';

import Dashboard from './Dashboard/Dashboard';
import Referral from './Referral/Referral';
import Projectlist from './Project/ProjectList';
import CreateProject from './Project/create/CreateProject';

import Analytics from './Project/Activity/Analytics/Analytics';
import UserList from './UserManagement/UserList';
import AddUser from './UserManagement/AddUser';
import Contact from './UserManagement/view/Contact';
import UserInfo from './UserManagement/view/UserInfo';
import Email from './Templates/Emails/Email';
import CreateTemplate from './Templates/Emails/create/CreateTemplate';
import CreateSmsTemplate from './Templates/sms/create/CreateSmsTemplate';
import Sms from './Templates/sms/Sms';
import Campaign from './Templates/Campaign/Campaign';
import CreateCampaignTemplate from './Templates/Campaign/create/CreateCampaignTemplate';
import ViewEmail from './Templates/Emails/ViewEmail';
import ViewCampaign from './Templates/Campaign/ViewCampaign';
import ViewSms from './Templates/sms/ViewSms';
import BroadcastMessages from './BroadcastMessages/BroadcastMessages';
import CreateBroadcastMessage from './BroadcastMessages/create/CreateBroadcastMessage';
import ViewBroadcastMessage from './BroadcastMessages/ViewBroadcastMessage';
import Project from './Project/Project';
import Introduction from './Project/Introduction/Introduction';
import EditIntro from './Project/Introduction/EditIntro';
import FocusGroups from './Project/Participants/FocusGroup/FocusGroups';
import Main from './Project/Participants/Main';
import FocusGroupDetail from './Project/Participants/FocusGroup/FocusGroupDetail';
import InpersonInterview from './Project/Activity/create/InpersonInterview/InpersonInterview';
import InpersonInterviewPreview from './Project/Activity/create/InpersonInterview/Preview';
import OnlineInterview from './Project/Activity/create/OnlineInterview/OnlineInterview';
import OnlineInterviewPreview from './Project/Activity/create/OnlineInterview/OnlineInterviewPreview';
import Journal from './Project/Activity/create/journal/Journal';
import JournalPreview from './Project/Activity/create/journal/JournalPreview';
import Forum from './Project/Activity/create/Forum/Forum';
import ForumPreview from './Project/Activity/create/Forum/ForumPreview';
import Participant from './Project/Activity/view/Participant/Participant';
import Create_Survey from './Project/Activity/create/SurveyForm/CreateSurvey';
import SurveyFormPreview from './Project/Activity/create/SurveyForm/SurveyFormPreview';
import CreateEvaluation from './Project/Evaluation/CreateEvaluation';
import EvaluationFormPreview from './Project/Evaluation/EvaluationFormPreview';
import ExcerptsPreview from './Project/Activity/view/Excerpts/ExcerptsPreview';
import ExcerptsEdit from './Project/Activity/view/Excerpts/ExcerptsEdit';
import Respondent from './Project/Participants/Respondants';
import ResearcherList from './Project/Participants/Researcher';
import JournalActivity from './Project/Activity/view/Participant/JournalActivity';
import SurveyActivity from './Project/Activity/view/Participant/SurveyActivity';
import InpersonForumPreview from './Project/Activity/create/InpersonForum/InpersonForumPreview';
import InpersonForum from './Project/Activity/create/InpersonForum/InpersonForum';

import SurveyReports from './Project/Activity/reports/SurveyReports';
import InterviewReports from './Project/Activity/reports/InterviewReports';
import JournalReports from './Project/Activity/reports/JournalReports';
import ForumReports from './Project/Activity/reports/ForumReports';
import SearchResults from './Project/SearchResults/SearchResults';
import Report from './Project/Report/Report';
import MyProfile from './Profile/MyProfile';
import ProjectDetails from './Project/ProjectDetails/ProjectDetails';
import GeneralForumListing from './GeneralForums/GeneralForumListing';
import GeneralForum from './GeneralForums/GeneralForum';
import GeneralForumPreview from './GeneralForums/GeneralForumPreview';
import Chat from '../Admin/Chats/Chat';
import MiniSurveys from './MiniSurveys/MiniSurveys';
import CreateMiniSurvey from './MiniSurveys/CreateMiniSurvey';
import MiniSurveyPreview from './MiniSurveys/PreviewMiniSurvey';
import MiniSurveyActivity from './MiniSurveys/view/MiniSurveyActivity';
import Scripting from './MiniSurveys/view/Scripting';

// ************************* All Commented Screens are Removed from the UI *************************
const Researcher = () => {
// this is change in master branch 
    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <AppHeader />
                    {width >= 1250 && <AppSidebar width={width} />}
                    <div className="app-main">
                        {width < 1250 && <AppSidebar width={width} />}
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <div className="app-inner-layout">

                                    <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                                        transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                                       <Routes>
                                        {/* All Researcher Routes  */}
                                        <Route path="/" exact element={<Dashboard/>} />
                                        {/* <Route path="/researcher/referral" exact component={Referral} /> */}
                                        <Route path="/chat" exact element={<Chat/>} />

                                        {/* Templates */}
                                        {/* <Route path="/researcher/templates/email" exact component={Email} />
                                        <Route path="/researcher/create-email-template" exact component={CreateTemplate} />
                                        <Route path="/researcher/edit-email-template/:templateid" exact component={CreateTemplate} />
                                        <Route path="/researcher/view-email-template/:templateid" exact component={ViewEmail} />
                                        <Route path="/researcher/create-email-template/:templateid" exact component={CreateTemplate} />

                                        <Route path="/researcher/create-sms-template" exact component={CreateSmsTemplate} />
                                        <Route path="/researcher/edit-sms-template/:templateid" exact component={CreateSmsTemplate} />
                                        <Route path="/researcher/templates/sms" exact component={Sms} />
                                        <Route path="/researcher/view-sms-template/:templateid" exact component={ViewSms} />
                                        <Route path="/researcher/create-sms-template/:templateid" exact component={CreateSmsTemplate} />

                                        <Route path="/researcher/create-campaign-template" exact component={CreateCampaignTemplate} />
                                        <Route path="/researcher/edit-campaign-template/:templateid" exact component={CreateCampaignTemplate} />
                                        <Route path="/researcher/templates/campaign" exact component={Campaign} />
                                        <Route path="/researcher/view-campaign-template/:templateid" exact component={ViewCampaign} />
                                        <Route path="/researcher/create-campaign-template/:templateid" exact component={CreateCampaignTemplate} /> */}

                                        {/* Broadcast Messages */}
                                        {/* <Route path="/researcher/broadcast-message" exact component={BroadcastMessages} />
                                        <Route path="/researcher/create-broadcast-message" exact component={CreateBroadcastMessage} />
                                        <Route path="/researcher/view-broadcast-message/:msgid" exact component={ViewBroadcastMessage} /> */}

                                        {/* Users */}
                                        {/* <Route path="/researcher/user" exact component={UserList} />
                                        <Route path="/researcher/add-user" exact component={AddUser} />
                                        <Route path="/researcher/view-user/:userid" exact component={UserInfo} /> */}

                                        {/* Projects */}
                                        <Route path="/project" exact element={<Projectlist/>} />
                                        <Route path="/add-project" exact element={<CreateProject/>} />
                                        <Route path="/add-project/:projectid" exact element={<CreateProject/>} />
                                        <Route path="/edit-project/:projectid" exact element={<CreateProject/>} />

                                        {/* Project Details */}
                                        <Route path="/project/intro/:projectid" exact element={<Project/>} />
                                        <Route path="/project/edit-intro/:projectid" exact element={<EditIntro/>} />
                                        <Route path="/project/detail/:projectid" exact element={<Project/>} />
                                        <Route path="/project/attachement/:projectid" exact element={<Project/>} />
                                        <Route path="/project/evaluation/:projectid" exact element={<Project/>} />
                                        <Route path="/project/activity/:projectid" exact element={<Project/>} />
                                        <Route path="/projelementing/:projectid" exact element={<Project/>} />
                                        {/* Project Report & Searching */}
                                        <Route path="/project/searchresults/:projectid" exact element={<SearchResults/>} />
                                        <Route path="/project/report/:projectid" exact element={<Report/>} />
                                        {/* Project Participants */}
                                        <Route path="/project/participants/:projectid" exact element={<Main/>} />
                                        <Route path="/project/groups/:projectid" exact element={<Main/>} />
                                        <Route path="/project/group/:projectid/:audienceId" exact element={<FocusGroupDetail/>} />

                                        <Route path="/respondantlist" exact element={<Respondent/>} />
                                        <Route path="/researcherlist" exact element={<ResearcherList/>} />

                                        {/* Project Screenings */}
                                        <Route path="/project/preview-evaluation/:projectid/:evaluationid" exact element={<EvaluationFormPreview/>} />
                                        <Route path="/project/create-evaluation/:projectid" exact element={<CreateEvaluation/>} />
                                        <Route path="/project/edit-evaluation/:projectid/:evaluationid" exact element={<CreateEvaluation/>} />

                                        {/* Project Activities */}
                                        <Route path="/project/forum-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/journal-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/survey-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/interview-activity/:projectid/:activityid" exact element={<Project/>} />
                                        <Route path="/project/inperson-forum-activity/:projectid/:activityid" exact element={<Project/>} />

                                        <Route path="/project/create-interview/:projectid" exact element={<InpersonInterview/>} />
                                        <Route path="/project/create-interview/:projectid/:activityid" exact element={<InpersonInterview/>} />
                                        <Route path="/project/preview-interview/:projectid/:interviewid" exact element={<InpersonInterviewPreview/>} />
                                        <Route path="/project/edit-interview/:projectid/:activityid" exact element={<InpersonInterview/>} />

                                        <Route path="/project/create-onlineinterview/:projectid" exact element={<OnlineInterview/>} />
                                        <Route path="/project/create-onlineinterview/:projectid/:activityid" exact element={<OnlineInterview/>} />
                                        <Route path="/project/preview-onlineinterview/:projectid/:onlineinterviewid" exact element={<OnlineInterviewPreview/>} />
                                        <Route path="/project/edit-onlineinterview/:projectid/:activityid" exact element={<OnlineInterview/>} />

                                        <Route path="/project/create-journal/:projectid" exact element={<Journal/>} />
                                        <Route path="/project/create-journal/:projectid/:activityid" exact element={<Journal/>} />
                                        <Route path="/project/preview-journal/:projectid/:journalid" exact element={<JournalPreview/>} />
                                        <Route path="/project/edit-journal/:projectid/:activityid" exact element={<Journal/>} />

                                        <Route path="/project/preview-forum/:projectid/:forumid" exact element={<ForumPreview/>} />
                                        <Route path="/project/create-forum/:projectid" exact element={<Forum/>} />
                                        <Route path="/project/create-forum/:projectid/:forumid" exact element={<Forum/>} />
                                        <Route path="/project/edit-forum/:projectid/:forumid" exact element={<Forum/>} />

                                        <Route path="/project/preview-inpersonforum/:projectid/:forumid" exact element={<InpersonForumPreview/>} />
                                        <Route path="/project/create-inpersonforum/:projectid" exact element={<InpersonForum/>} />
                                        <Route path="/project/create-inpersonforum/:projectid/:forumid" exact element={<InpersonForum/>} />
                                        <Route path="/project/edit-inpersonforum/:projectid/:forumid" exact element={<InpersonForum/>} />

                                        <Route path="/project/create-survey/:projectid" exact element={<Create_Survey/>} />
                                        <Route path="/project/create-survey/:projectid/:surveyid" exact element={<Create_Survey/>} />
                                        <Route path="/project/preview-survey/:projectid/:surveyid" exact element={<SurveyFormPreview/>} />
                                        <Route path="/project/edit-survey/:projectid/:surveyid" exact element={<Create_Survey/>} />



                                        <Route path="/project/journal-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />
                                        <Route path="/project/survey-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />
                                        <Route path="/project/forum-activity/excerpt/:projectid/:excerptid" exact element={<ExcerptsPreview/>} />

                                        <Route path="/project/journal-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/survey-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/forum-activity/edit-excerpt/:projectid/:excerptid" exact element={<ExcerptsEdit/>} />
                                        <Route path="/project/participant-post-activity/:activityid/:userid" exact element={<JournalActivity/>} />
                                        <Route path="/project/participant-survey-activity/:activityid/:userid" exact element={<SurveyActivity/>} />

                                        {/* Activities Report */}
                                        <Route path="/project/survey-report/:projectid/:activityid" exact element={<SurveyReports/>} />
                                        <Route path="/project/online-interview-report/:projectid/:activityid" exact element={<InterviewReports/>} />
                                        <Route path="/project/inperson-interview-report/:projectid/:activityid" exact element={<InterviewReports/>} />
                                        <Route path="/project/journal-report/:projectid/:activityid" exact element={<JournalReports/>} />
                                        <Route path="/project/forum-report/:projectid/:activityid" exact element={<ForumReports/>} />
                                        <Route path="/researcher/project/inperson-forum-report/:projectid/:activityid" exact element={<InterviewReports/>} />

                                        <Route path="/project/journal-analytics/:projectid/:activityid" exact element={<Analytics/>} />
                                        <Route path="/project/survey-analytics/:projectid/:activityid" exact element={<Analytics/>} />
                                        <Route path="/project/forum-analytics/:projectid/:activityid" exact element={<Analytics/>} />

                                        {/* General Forum */}
                                        {/* <Route path="/researcher/general-forum" exact component={GeneralForumListing} />
                                        <Route path="/researcher/create-generalforum" exact component={GeneralForum} />
                                        <Route path="/researcher/create-generalforum/:forumid" exact component={GeneralForum} />
                                        <Route path="/researcher/preview-generalforum/:forumid" exact component={GeneralForumPreview} />
                                        <Route path="/researcher/edit-generalforum/:forumid" exact component={GeneralForum} />
                                         */}

                                        <Route path="/profile" exact element={<MyProfile/>} />
                                        <Route path="/projects" exact element={<ProjectDetails/>} />


                                        <Route path="/minisurvey" exact element={<MiniSurveys/>} />
                                        <Route path="/create-minisurvey" exact element={<CreateMiniSurvey/>} />
                                        <Route path="/edit-minisurvey/:activityid" exact element={<CreateMiniSurvey/>} />
                                        <Route path="/preview-minisurvey/:activityid" exact element={<MiniSurveyPreview/>} />
                                        <Route path="/minisurvey-activity/:activityid" exact element={<MiniSurveyActivity/>} />
                                        <Route path="/minisurvey/responses/:activityid/:userid" exact element={<SurveyActivity/>} />
                                        <Route path="/minisurvey/scripting/:activityid" exact element={<Scripting/>} />
                                        
                                        <Route path="/minisurvey-analytics/:activityid" exact element={<Analytics/>} />

                                        </Routes>



                                        {/* <Route path="/researcher/verify_account" exact component={AccountVerification} /> */}

                                        {/*  */}

                                    </TransitionGroup>

                                </div>

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />
    )

}

export default Researcher;