import React, { useState } from "react";
import { TransitionGroup } from 'react-transition-group';

import styles from '../../../../assets/css/styling.module.css';

const Contact_Details = (props) => {

    const { profileDetails, setProfileDetails } = props
    const createdDate = new Date(profileDetails.created_at)
    const labelStyle = {
        color: '#000',
        fontSize: '1rem',
        fontWeight: '500',
        letterSpacing: '0.3px'
    }

    const [err, setError] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        phone: ''
    })

    const removeErr = () => {
        setError({
            firstName: '',
            lastName: '',
            userName: '',
            phone: ''
        })
    }


    const checkValidation = () => {
        removeErr()
        let notCompleted = false;
        if (profileDetails.firstName === '') {
            setError((prevState) => ({ ...prevState, firstName: 'First Name is required.' }))
            notCompleted = true
        }
        if (profileDetails.lastName === '') {
            setError((prevState) => ({ ...prevState, lastName: 'Last Name is required.' }))
            notCompleted = true
        }
        if (profileDetails.userName === '') {
            setError((prevState) => ({ ...prevState, userName: 'Username is required.' }))
            notCompleted = true
        }
        if (profileDetails.phone === '') {
            setError((prevState) => ({ ...prevState, phone: 'Phone number is required.' }))
            notCompleted = true
        }

        return !notCompleted
    }

    const onSaveContactDetails = () => {
        if (checkValidation()) {
            props.editUser()
        }
    }

    const [isAllowEdit, setAllowEdit] = useState(false);

    return (
        <TransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
            <div className="mb-3">

                {/* Edit */}
                <div className="clearfix px-3">
                    <div className="text-right">
                        <button className="btn btn-link" style={{ color: "#1c6f9f", fontSize: '1.05rem', fontWeight: 600, letterSpacing: "0.5px" }} onClick={() => setAllowEdit(true)}>Edit</button>
                    </div>
                </div>

                <div className={styles.card_border}>
                    <div className={styles.card_border_body + " px-3 pt-3 pb-1"}>

                        <div className="m-3">

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    {/* Referral Code */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Referral Code</label>
                                        <input type="text" className="form-control" style={{ color: "#999" }} disabled={true} value={profileDetails.referralCode} />
                                    </div>

                                </div>
                                <div className="col-sm-12 col-lg-6">

                                    {/* First Name */}
                                    <div className="form-group">
                                        <label style={labelStyle}>First Name</label>
                                        <input type="text" className="form-control" disabled={!isAllowEdit} value={profileDetails.firstName}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, firstName: e.target.value })} />
                                        <small className="text-danger">
                                            {err.firstName}
                                        </small>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    {/* Last Name */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Last Name</label>
                                        <input type="text" className="form-control" disabled={!isAllowEdit} value={profileDetails.lastName}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, lastName: e.target.value })} />
                                        <small className="text-danger">
                                            {err.lastName}
                                        </small>
                                    </div>

                                </div>
                                <div className="col-sm-12 col-lg-6">

                                    {/* User Name */}
                                    <div className="form-group">
                                        <label style={labelStyle}>User Name</label>
                                        <input type="text" className="form-control" disabled={!isAllowEdit} value={profileDetails.userName}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, userName: e.target.value })} />
                                        <small className="text-danger">
                                            {err.userName}
                                        </small>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">

                                    {/* Email address */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Email address</label>
                                        <input type="email" className="form-control" disabled value={profileDetails.email}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, email: e.target.value })} />
                                    </div>

                                </div>
                                <div className="col-sm-12 col-lg-6">

                                    {/* Phone */}
                                    <div className="form-group">
                                        <label style={labelStyle}>Phone</label>
                                        <input type="number" className="form-control" disabled={!isAllowEdit} value={profileDetails.phone}
                                            onChange={(e) => setProfileDetails({ ...profileDetails, phone: e.target.value })} />
                                        <small className="text-danger">
                                            {err.phone}
                                        </small>
                                    </div>

                                </div>
                                <div className="col-12">

                                    <div className="row">

                                        <div className="col-lg-6">

                                            {/* Date of Joining */}
                                            <div className="form-group">
                                                <label style={labelStyle}>Date of Joining</label>
                                                <input type="text" className="form-control" disabled={true} value={createdDate.toLocaleDateString()} />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {isAllowEdit && (
                                <div className="row mt-2">
                                    <div className="col-12 text-right">

                                        {/* Save Changes button */}
                                        {/* <button className="btn btn-hover-shine text-light py-2" style={{ backgroundColor: "#0071a2", borderRadius: "8px" }} onClick={() => onSaveContactDetails()}>Save Changes</button> */}

                                    </div>
                                </div>
                            )}


                        </div>

                    </div>
                </div>
            </div>
        </TransitionGroup>
    );

}

export default Contact_Details