import React, { useEffect, useState } from 'react';
import Slider from "rc-slider";
import YouTube from 'react-youtube'
import styles from '../../css/styling.module.css';
import styles3 from '../../css/newStyling.module.css'
import { getActivityVideo, getActivityQuestionImage } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sendActivityReplyAnswers, sendSharableSurveyReplyAnswers } from '../../../actions/activities_reply_actions';

const Question_With_Spectrum = (props) => {
    const[selectColor, setSelectColor] = useState(null)
    // On option change
    const onOptionChange = (value) => {
setSelectColor(value)


// AUTO SAVE
        // let selectedAnswer = value;
        // let allContent = props.allContent
        // let questionId = props.allContent.pages[props.pageIndex].questions[props.questionIndex]._id

        // const replyObj = {
        //     answer: selectedAnswer,
        //     formContent: allContent,
        //     questionIndex: props.questionIndex,
        //     questionId: questionId,
        //     questionType: props.allContent.pages[props.pageIndex].questions[props.questionIndex].questionType,
        //     activityId: props.allContent.activityId,
        //     activityType: props.allContent.questionnaireType,
        //     pageNo: props.pageIndex,
        //     pageName: props.allContent.pages[props.pageIndex].pageName
        // }


        props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = value;
        props.setContent({ ...props.allContent });


        // if (userId) {
        //     dispatch(sendSharableSurveyReplyAnswers(params.ActivityId, replyObj, userId))
        //         .then((response => {
                   
        //         }))
        // }
        // else {
        //     dispatch(sendActivityReplyAnswers(params.surveyid, replyObj))
        //         .then((response => {
                  
        //         }))
        // }

    }

    const handleMarks = () => {

        let result = props.options.reduce((arr, val, index) => ({
            ...arr,
            [parseInt(((index + 1) / props.options.length) * 100)]: val

        }), {})

        return result;

    }

    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    const params = useParams();
    const pageIndex = props.pageIndex
    const questionIndex = props.questionIndex
    const userId = params.UserId
    const dispatch = useDispatch();

    const [qVideo, setQVideo] = useState({})
    const [qImage, setQImage] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (params.surveyid || params.ActivityId || params.activityid) {
            dispatch(getActivityVideo(params.surveyid || params.ActivityId || params.activityid, pageIndex, questionIndex))
                .then((response) => {
                    try {
                        console.log('question video in respodant side survey->', response.payload.content.fileData)
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not avaialbe') }
                    setIsLoading(false);

                })

            dispatch(getActivityQuestionImage(params.ActivityId || params.surveyid || params.activityid))
                .then((response) => {
                    try {
                        console.log('question image in respondant side survey->', response.payload.content.fileData)
                        setQImage(response.payload.content.fileData)
                    } catch (err) { console.log('image in questionnaire not available') }
                    setIsLoading(false);
                })



        }
        else if (params.evaluationid) {
            dispatch(getActivityVideo(params.evaluationid))
                .then((response) => {
                    try {
                     
                        setQVideo(response.payload.content.fileData)
                    } catch (err) { console.log('video not available') }
                    setIsLoading(false);

                })
            dispatch(getActivityQuestionImage(params.evaluationid))
                .then((response) => {
                    try {
                        console.log('question image long text in respondant side survey->', response.payload.content.fileData)
                        setQImage(response.payload.content.fileData)
                    } catch (err) { console.log('image in questionnaire not available') }
                    setIsLoading(false);
                })
        }
    }, []);
    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }

    const changeBackgroundColor = (index, length) => {

        let backgroundColor = ''
        if (length == 3) {

            switch (index) {

                case 0: return backgroundColor = '#ff4122'; //red
                case 1: return backgroundColor = '#ffa53f'; // orange
                case 2: return backgroundColor = '#4ded30' // green
            }
        }

        if (length == 4) {

            switch (index) {

                case 0: return backgroundColor = '#ff4122'; //red
                case 1: return backgroundColor = '#ffa53f'; //orange
                case 2: return backgroundColor = '#ffea61'; // yellow
                case 3: return backgroundColor = '#4ded30'; //green
            }
        }

        if (length == 5) {

            switch (index) {

                case 0: return backgroundColor = '#ff4122'; //red
                case 1: return backgroundColor = '#ffa53f'; //orange
                case 2: return backgroundColor = '#ffea61'; //yellow
                case 3: return backgroundColor = '#a7e074'; //parrot
                case 4: return backgroundColor = '#4ded30'; //green
            }
        }

        if (length == 6) {

            switch (index) {

                case 0: return backgroundColor = '#ff4122'; //red
                case 1: return backgroundColor = '#f4675a'; //vermilon
                case 1: return backgroundColor = '#ffa53f'; //orange
                case 2: return backgroundColor = '#ffea61'; //yellow
                case 3: return backgroundColor = '#a7e074'; //parrot
                case 4: return backgroundColor = '#4ded30'; //green
            }
        }



    }


    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                    <ReactPlayer
                                                        url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                        controls
                                                        width ='100%'
                                                    />
                                                    </div>
                                                ) : (
                                                    <b>Loading Video...</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Image Display */}
                                        <div className="mb-3 mt-3">

                                            {qVideo.data &&

                                                // Image Display
                                                <div>
                                                    <div className="position-relative">
                                                        <img src={`data:${qVideo.type} ;base64, ${qVideo.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px' }} alt="img" />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                            }
                        </>


                    }


                    {/* Options */}
                    <div className="table-responsive">
                        <div>
                            <div className="mx-4" style={{ height: 'auto', marginTop: 20 }}>

                                <div className='row d-flex my-2' style={{ justifyContent: 'center', gap:'10px' }}>
                                    {props.options.map((x, index) => {
                                        const dynamicSpectrum = changeBackgroundColor(index, props.options.length)
                                        return (

                                            <div style={{ minHeight: '6rem', width:'8rem', cursor: 'pointer',backgroundColor: selectColor === x ? '#E8F6FF':'', border:selectColor ===x? '2px solid #0069AB':'' }} className={`${styles3.card_border} d-flex align-items-center justify-content-center`} onClick={() => onOptionChange(x)}>
                                                <p className={`${styles3.spectrum_option} pt-2`}>{x}</p>

                                            </div>
                                        )
                                    })

                                    }

                                   

                                </div>
                                {/* <Slider
                                    dots
                                    included={false}
                                    min={parseInt((1 / props.options.length) * 100) - 10}
                                    max={110}
                                    marks={handleMarks()}
                                    value={(parseInt(((props.options.indexOf(props.selected) + 1) / props.options.length) * 100))}
                                    step={null}
                                    onChange={e => onOptionChange(handleMarks()[e])}
                                /> */}

                            </div>

                        </div>
                    </div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_Spectrum;
