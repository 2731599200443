import axios from 'axios';
import { CREATE_EMAIL_TEMPLATE, EMAIL_TEMPLATE_AVAILABLE, GET_EMAIL_TEMPLATE_DETAILS, EDIT_EMAIL_TEMPLATE_DETAILS, DELETE_EMAIL_TEMPLATE, GET_USER_EMAIL_TEMPLATES_TITLE } from './types';

import { BACK_SERVER_URL } from '../config/server.js';

// **************  These apis are used in ADMIN END in EMAIL SECTION of Templates  *********************


// Takes an object as param containing template details
// creates a new email template in db
export const createEmailTemplate = (emailTemplateData) => {

    let url = `${BACK_SERVER_URL}api/emailTemplates/`;

    const request = axios.post(url, emailTemplateData)
        .then(response => response.data);

    return {
        type: CREATE_EMAIL_TEMPLATE,
        payload: request
    }
}

// Returns an array of all email templates
export const getAllEmailTemplates = (page, limit) => {

    let url = `${BACK_SERVER_URL}api/emailTemplates?page=${page}&limit=${limit}`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: EMAIL_TEMPLATE_AVAILABLE,
        payload: request
    }
}

// Takes template id as param
// Returns object containing details of a specific template like title, subject, description, author and template variables
export const getEmailTemplateDetails = (id) => {

    let url = `${BACK_SERVER_URL}api/emailTemplates/` + id;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_EMAIL_TEMPLATE_DETAILS,
        payload: request
    }
}

// Takes id and an object (updated/edited template) as param 
// updates the template against the id we pass as params
export const editEmailTemplateDetails = (id, emailTemplateData) => {

    let url = `${BACK_SERVER_URL}api/emailTemplates/` + id;

    const request = axios.put(url, emailTemplateData)
        .then(response => response.data);

    return {
        type: EDIT_EMAIL_TEMPLATE_DETAILS,
        payload: request
    }
}

// deletes sms template against the id we pass in the param
export const delEmailTemplate = (id) => {

    let url = `${BACK_SERVER_URL}api/emailTemplates/` + id;

    const request = axios.delete(url)
        .then(response => response.data);

    return {
        type: DELETE_EMAIL_TEMPLATE,
        payload: request
    }
}

// Returns an array of objects containing template titles and their ids 
// use to display templates dropdown in campaigns screen
export const getUserEmailTemplatesTitle = () => {

    let url = `${BACK_SERVER_URL}api/emailTemplates/userTemplates/`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: GET_USER_EMAIL_TEMPLATES_TITLE,
        payload: request
    }
}
