import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from '../../css/rstyling.module.css'

const AlertModal = (props) => {

    const toggle = () => {
        props.setModal()
    }

    return (

        <Modal isOpen={props.isModalOpen} toggle={toggle} id={`ImageModal`} className="modal-dialog-centered shadow-none" >
            <ModalHeader className="bg-white" toggle={toggle}>Delete Record</ModalHeader>
            <ModalBody className="text-center">
                <p> {props.msgText ? props.msgText : "Are you sure want to delete?"} </p>
                <Button className={` ${styles.bg_blue} mr-3`} onClick={() => props.onClick()}>
                    Yes
                </Button>
                <Button className={` bg-light text-muted ml-3`}
                    style={{ border: '1px solid #6c757d ' }} onClick={toggle}>
                    No
                </Button>
            </ModalBody>
        </Modal>

    )
}

export default AlertModal