import { EMPTY_PROJECT, GET_PROJECT, GET_PROJECT_DETAILS, LOADING_START, LOADING_STOP, PROJECT_AVAILABLE, PROJECT_INTRODUCTION } from '../actions/types';

let projectDataState = { projects:[], totalPages:0, currentPage: 1, loading:false, projectDetails: {}, projectIntro: {} };
export default function (state = projectDataState, action) {
    switch (action.type) {
        case LOADING_START:
            return {...state, loading:true};
        case LOADING_STOP:
            return {...state, loading:false};
        case PROJECT_AVAILABLE:
            if (state.projects.length === 0) {
                return {
                    ...state,
                    projects: action.payload.content.projects,
                    totalPages: action.payload.content.totalPages,
                    currentPage: parseInt(action.payload.content.currentPage),
                }
            } else {
                let concatResult = {};
                concatResult.projects =  state.projects.concat(action.payload.content.projects);
                concatResult.totalPages = action.payload.content.totalPages;
                concatResult.currentPage = parseInt(action.payload.content.currentPage);
                return {
                    ...state,
                    projects: concatResult.projects,
                    totalPages:concatResult.totalPages,
                    currentPage:concatResult.currentPage,
                }
            }
        case EMPTY_PROJECT:
            return {...state, projects: [], totalPages: 0, currentPage: 1 };
        case PROJECT_INTRODUCTION:
            return {...state, projectIntro: action.payload.content };

        case GET_PROJECT_DETAILS:
            return {...state, projectDetails: action.payload.content }
        default:
            return state;
    }
}
