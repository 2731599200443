import React, { useEffect, useState } from 'react';
import Slider from "rc-slider";
import YouTube from 'react-youtube'
import styles from '../../css/styling.module.css';
import { getActivityVideo } from '../../../actions/activities_actions';
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "rc-slider/assets/index.css";





const Question_With_Slider = (props) => {
    const [currentOption, setCurrentOption] = useState(props.selected || props.options[0]);
    const [isSliding, setIsSliding] = useState(false);

    // On option change
    const onOptionChange = (value) => {

        const selectedOptionIndex = Math.round((value / 100) * (props.options.length - 1));
        const selectedOption = props.options[selectedOptionIndex];
        setCurrentOption(selectedOption);
        props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = selectedOption;
       
        console.log('selected option ->',   props.setContent({ ...props.allContent }))

    }

    const handleMarks = () => {

        return props.options.reduce((arr, val, index) => ({
            ...arr,
            [parseInt(((index + 1) / props.options.length) * 100)]: ''
        }), {});

    }

    useEffect(() => {
        if (!props.selected) {
            setCurrentOption(props.options[0]);
            if(props.allContent !=undefined){
             props.allContent.pages[props.pageIndex].questions[props.questionIndex].selected = props.options[0];
             
              
             props.setContent({ ...props.allContent });
            }
        }
    }, [props.selected, props.options]);
    // const handleMarks = () => {
    //     return props.options.reduce((arr, val, index) => ({
    //         ...arr,
    //         [parseInt(((index + 1) / props.options.length) * 100)]: val
    //     }), {});
    // };


    const makeLinksOpenInNewTab = (htmlContent) => {
        // Create a DOM element to parse the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;

        // Find all anchor tags within the parsed HTML content
        const anchorTags = tempElement.getElementsByTagName('a');

        // Iterate through the anchor tags and set the target attribute to '_blank'
        for (const anchorTag of anchorTags) {
            anchorTag.setAttribute('target', '_blank');
            // You can also set 'rel' attribute to 'noopener noreferrer' for security best practices
            anchorTag.setAttribute('rel', 'noopener noreferrer');
        }

        // Return the modified HTML content
        return tempElement.innerHTML;
    }


    const opts = {
        width: '640',
        height: '360',
        playerVars: {
            autoplay: 0
        },
    };

    const params = useParams();
    const dispatch = useDispatch();

    const [qVideo, setQVideo] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (params.surveyid) {
            dispatch(getActivityVideo(params.surveyid))
                .then((response) => {

                    console.log('question video in respodant side survey->', response.payload.content.fileData)
                    setQVideo(response.payload.content.fileData)
                    setIsLoading(false);

                })
        }
        else if (params.evaluationid) {
            dispatch(getActivityVideo(params.evaluationid))
                .then((response) => {

                    console.log('question video in respodant side survey->', response.payload.content.fileData)
                    setQVideo(response.payload.content.fileData)
                    setIsLoading(false);

                })
        }
    }, []);

    return (
        <div className="my-3">
            <div className={styles.card_border} style={props.required && !props.isAnswered ? { borderColor: '#d92550' } : {}}>
                <div className={styles.card_border_body + " pt-4 pb-1"}>
                    <p style={{ color: '#666', fontSize: "1rem", fontWeight: '500', whiteSpace: 'pre-wrap' }} className="mb-0">

                        {/* Required check */}
                        {props.required &&
                            <span className="text-danger mr-1">*</span>
                        }

                        {/* Question text */}
                        {props.questionText &&
                            <span style={{ color: '#000', fontSize: '27px', fontWeight: '500' }} className='mb-0' dangerouslySetInnerHTML={{ __html: makeLinksOpenInNewTab(props.questionText) }} />
                        }

                        {/* Question video */}
                        {props.questionVideo && (
                            <div>

                                <YouTube videoId={props.questionVideo} opts={opts} className='py-4 text-center'></YouTube>

                            </div>
                        )}

                    </p>

                    {/* Question Video */}
                    {
                        props.questionFile &&
                        <>
                            {
                                (typeof props.questionFile === 'string' ? props.questionFile.split('.').pop() === 'mp4' : props.questionFile.name.split('.').pop() === 'mp4') ? (
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Video Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                {/* <video width={300} controls className="img-fluid- border" style={{ borderRadius: '10px', maxHeight: 500 }}>
                                                    <source src={props.questionFile} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video> */}
                                                {isLoading ? (
                                                    <b>Loading Video...</b>
                                                ) : qVideo.data ? (
                                                    <ReactPlayer
                                                        url={`data:${qVideo.type};base64,${qVideo.data}`}
                                                        controls
                                                    />
                                                ) : (
                                                    <b>Error: No video with supported format and mime type found.</b>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    /* Question Image */
                                    <div className="mx-3 mt-2 mb-4">

                                        {/* Question Image Display */}
                                        <div className="pt-3">
                                            <div className="position-relative">
                                                <img src={typeof props.questionFile === 'string' ? props.questionFile : URL.createObjectURL(props.questionFile)} className="img-fluid border" style={{ borderRadius: '10px', maxHeight: 400 }} alt="survey-img" width="200px" />
                                            </div>
                                        </div>

                                    </div>
                            }
                        </>


                    }


                    {/* Options */}
                    <div className="table-responsive">
                        <div>
                            <div className="mx-4" style={{ height: '160px', marginTop: 20, textAlign: "center" }}>
                            <div style={{ textAlign: 'center', marginTop: '20px', marginBottom:'20px' }}>
                                {console.log('initial value ->', currentOption)}
                                {console.log('initial value ->', props.options[0])}
                                    <p style={{ fontSize: '18px', fontWeight: '500' }}>{currentOption ==undefined ? props.options[0]: currentOption}</p>
                                </div>
                                <Slider
                                  
                                    //dots
                                    // included={true}
                                    min={parseInt((1 / props.options.length) * 100) - 10}
                                    //min={0}

                                    max={100}
                                    marks={handleMarks()}
                                    value={(parseInt(((props.options.indexOf(currentOption) + 1) / props.options.length) * 100))}
                                    step={parseInt(100 / (props.options.length - 1))}
                                    //step={null}
                                    onChange={e => onOptionChange(e)}
                                    
                                />
                               
                            </div>

                        </div>
                    </div>

                    {/* Required Answer error */}
                    {props.required && !props.isAnswered && (
                        <div id={`error-msg-${props.id}`}>
                            <div className="my-2">
                                <div className="alert alert-danger mb-0">
                                    Please answer this question.
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )

}

export default Question_With_Slider;

