import React from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import logo from '../../assets/images/logo.png'
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux'
import landing_bg_new from "../../assets/images/landing_page.png";
import landing_page_logo from '../../assets/images/landing_page_logo.png';
import { signup, SignupGoogle, signupFacebook, Representative_SignupGoogle } from '../../actions/auth_actions';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialFacebook } from 'reactjs-social-login';
import login_bg_new from "../../assets/images/login_bg2.png";
import { Formik } from 'formik'
import * as Yup from 'yup'
import styles from '../../assets/css/styling.module.css'
import { Button } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import { getLoggedInUser } from '../../actions/auth_actions';
import axios from 'axios';
import Swal from 'sweetalert';
import SecureLS from 'secure-ls';
import { SocialButton } from 'react-social-login-buttons';


export default function ConceptTesting_signupOptions() {



    const { ActivityId } = useParams()
    const dispatch = useDispatch()
    const history = useNavigate();
    const ls = new SecureLS()



    const handleGoogleLoginSuccess = (tokenResponse) => {
        //clearCaches()

        const accessToken = tokenResponse.access_token;




        dispatch(Representative_SignupGoogle(accessToken))



            .then(response => {
                if (response.payload.message === 'User email already exist. Please use a new one') {

                    Swal({
                        title: "Account already exists",
                        text: "User email already exists. Please use a new one.",

                        icon: 'info'
                    })

                }
                else if (response.payload.message === 'Representative SignUp with Google Successful') {
                    ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role });

                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                    dispatch(getLoggedInUser())
                        .then(response1 => {

                            ls.set('accessUser', { user: response1.payload.content });
                            if (ls.get('accessToken').role) {
                                if (ls.get('accessToken').role === 3) {

                                    const UserId = response1.payload.content._id

                                    history(`/conceptTesting_google_setup_profile/${ActivityId}/${UserId}`)
                                }
                            }
                        })
                    // Swal({
                    //     title: "Signed Up",
                    //     text: "Signup with Google was successful.",

                    //     icon: 'success'
                    // })
                }
            })


            .catch(err => {

                if (err.response.data.name === "Duplication Error") {
                    Swal({
                        title: "Info",
                        text: "User email already exists.",
                        icon: 'info'
                    });
                } else {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                }

            })


    }



    const Login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });




    return (




        <>
            <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>

                        <div className="col-sm-7 col-12 bg-white align-self-center">
                            <div className='row justify-content-center'>
                                <div className={`${styles3.login_section}`}>
                                    {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                    <div className=" container" >



                                        <div className="container text-center">
                                            <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                            <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop: '50px' }}>Join us to share your voice and shape product innovation.</p>

                                            <div>
                                                <button className={`btn-hover-shine btn btn-block py-3 mt-4`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} onClick={() => Login()}><FcGoogle size={18} className='mx-3' />
                                                    Create account with Google
                                                </button>

                                                {/* <div className='row'>
            <div className='col'><hr></hr></div>
            <div className='col-2'><div className='py-2' style={{ color: 'rgb(173, 167, 167)', fontWeight: 'bold' }}>Or</div></div>
            <div className='col'><hr></hr></div>
          </div> */}


                                                {/* <LoginSocialFacebook

                                                    appId='1666970400472102'


                                                    onResolve={(response) => {
                                                        FacebookLogin(response)
                                                    }}
                                                    onReject={(error) => {
                                                        console.log(error)
                                                    }}

                                                >
                                                    <button className={`btn-hover-shine btn btn-block py-3 mt-4`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} ><IoLogoFacebook size={19} className='mx-3' style={{ color: '#4963ab' }} />
                                                        Create account with Facebook
                                                    </button>
                                                </LoginSocialFacebook> */}

                                                <a href={`/conceptTesting_setup_profile/${ActivityId}`} className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }}>
                                                    Create account with Email
                                                </a>
                                            </div>
                                            <div className="mt-3">
                                                <label style={{ fontSize: '0.95rem', fontWeight: '400', color: '#888' }}>
                                                    Already have an account? &nbsp;
                                                    <a href={`/conceptTesting_login/${ActivityId}`} style={{ color: '#006FA7' }}>
                                                        Login
                                                    </a>
                                                </label>

                                            </div>












                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
        </>

























    )
}