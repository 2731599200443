import React, { useEffect, useState } from 'react'
import Selectable from '../../../assets/components/selectable/Selectable'
import { TransitionGroup } from 'react-transition-group';
import { Button } from 'reactstrap'
import styles from '../../../assets/css/rstyling.module.css'
import { getProject } from '../../../actions/project_actions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import { surveyGroupings } from '../../../actions/activities_actions';
import SurveyCharts from '../../../assets/components/survey-charts/SurveyCharts';
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';

const Grouping = () => {

    const [chart1, showChart1] = useState(false)
    const [chart2, showChart2] = useState(false)
    const [isLoading, setLoading] = useState(true)


    const showChart = (whichChart) => {
        let data = {}
        if (whichChart === 2) {
            data = {
                activityId: params.activityid,
                gender: projectCriteria2.gender.options.length > 0 ? projectCriteria2.gender.options[projectCriteria2.gender.selectedIndex].toLowerCase() : 'any',
                city: projectCriteria2.gender.options.length > 0 ? projectCriteria2.cities.options[projectCriteria2.cities.selectedIndex] : 'any',
                age: projectCriteria2.gender.options.length > 0 ? projectCriteria2.ageRange.options[projectCriteria2.ageRange.selectedIndex] : '15 - 90',
                income: projectCriteria2.gender.options.length > 0 ? projectCriteria2.incomeRange.options[projectCriteria2.incomeRange.selectedIndex] : '0 - 250,000',
            }
        } else {
            data = {
                activityId: params.activityid,
                gender: projectCriteria2.gender.options.length > 0 ? projectCriteria1.gender.options[projectCriteria1.gender.selectedIndex].toLowerCase() : 'any',
                city: projectCriteria2.gender.options.length > 0 ? projectCriteria1.cities.options[projectCriteria1.cities.selectedIndex] : 'any',
                age: projectCriteria2.gender.options.length > 0 ? projectCriteria1.ageRange.options[projectCriteria1.ageRange.selectedIndex] : '15 - 90',
                income: projectCriteria2.gender.options.length > 0 ? projectCriteria1.incomeRange.options[projectCriteria1.incomeRange.selectedIndex] : '0 - 250,000',
            }
        }

        dispatch(surveyGroupings(data))
            .then(response => {

                if (whichChart === 1) {
                    setSurveyResponse1([...response.payload.content.surveyGraphs])
                    showChart1(true)
                } else {
                    setSurveyResponse2([...response.payload.content.surveyGraphs])
                    showChart2(true)
                }

            }).catch(err => {
                console.log(err)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }

    const params = useParams()
    const dispatch = useDispatch()

    const [projectCriteria1, setProjectCriteria1] = useState({
        gender: {
            options: [],
            selectedIndex: 0
        },
        cities: {
            options: [],
            selectedIndex: 0
        },
        ageRange: {
            options: [],
            selectedIndex: 0
        },
        incomeRange: {
            options: [],
            selectedIndex: 0
        },
        chartsData: []
    })

    const [surveyResponse1, setSurveyResponse1] = useState([])
    const [surveyResponse2, setSurveyResponse2] = useState([])
    const [projectCriteria2, setProjectCriteria2] = useState({
        gender: {
            options: [],
            selectedIndex: 0
        },
        cities: {
            options: [],
            selectedIndex: 0
        },
        ageRange: {
            options: [],
            selectedIndex: 0
        },
        incomeRange: {
            options: [],
            selectedIndex: 0
        },
        chartsData: []
    })

    const onTabChange = (index, whichData) => {
        if (whichData === 'gender')
            setProjectCriteria1({ ...projectCriteria1, gender: { ...projectCriteria1.gender, selectedIndex: index } });
        else if (whichData === 'cities')
            setProjectCriteria1({ ...projectCriteria1, cities: { ...projectCriteria1.cities, selectedIndex: index } });
        else if (whichData === 'age range')
            setProjectCriteria1({ ...projectCriteria1, ageRange: { ...projectCriteria1.ageRange, selectedIndex: index } });
        else if (whichData === 'income range')
            setProjectCriteria1({ ...projectCriteria1, incomeRange: { ...projectCriteria1.incomeRange, selectedIndex: index } });
    }

    const onTabChange1 = (index, whichData) => {
        console.log(index, whichData)
        if (whichData === 'gender')
            setProjectCriteria2({ ...projectCriteria2, gender: { ...projectCriteria2.gender, selectedIndex: index } });
        else if (whichData === 'cities')
            setProjectCriteria2({ ...projectCriteria2, cities: { ...projectCriteria2.cities, selectedIndex: index } });
        else if (whichData === 'age range')
            setProjectCriteria2({ ...projectCriteria2, ageRange: { ...projectCriteria2.ageRange, selectedIndex: index } });
        else if (whichData === 'income range')
            setProjectCriteria2({ ...projectCriteria2, incomeRange: { ...projectCriteria2.incomeRange, selectedIndex: index } });
    }

    const getProjectDetails = () => {
        dispatch(getProject(params.projectid))
            .then(response => {
                const project = response.payload.content.criteria
                const grid = {
                    gender: {
                        options: project.genders,
                        selectedIndex: 0
                    },
                    cities: {
                        options: project.cities,
                        selectedIndex: 0
                    },
                    ageRange: {
                        options: project.ageRanges,
                        selectedIndex: 0
                    },
                    incomeRange: {
                        options: project.incomeRanges,
                        selectedIndex: 0
                    },
                    chartsData: project.gridSets
                }
                // setData(grid)
                setProjectCriteria1(grid)
                setProjectCriteria2(grid)
                setLoading(false)

            }).catch(err => {
                setLoading(false)
                Swal({
                    title: err.response ? err.response.data.name : "Error",
                    text: err.response ? err.response.data.content : "Something went wrong",
                    icon: 'error'
                })
            })
    }
    useEffect(() => {
        getProjectDetails()
    }, [])

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                            <Selectable data={projectCriteria1} onTabChange={onTabChange} />
                            <div className="align-self-center pt-3 text-right">
                                <Button className={`${styles.bg_blue} mr-2 mb-3`} onClick={() => showChart(1)}>
                                    Generate
                                </Button>
                            </div>
                            {
                                chart1 &&
                                <SurveyCharts surveyResponse={surveyResponse1} />
                            }
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 mt-3">
                            <Selectable data={projectCriteria2} onTabChange={onTabChange1} />
                            <div className="align-self-center pt-3 text-right">
                                <Button className={`${styles.bg_blue} mr-2 mb-3`} onClick={() => showChart(2)}>
                                    Generate
                                </Button>
                            </div>
                            {
                                chart2 &&
                                <SurveyCharts surveyResponse={surveyResponse2} />
                            }
                        </div>
                    </div>
                </div>
            </LoadingOverlay>


        </TransitionGroup>
    )
}

export default Grouping
