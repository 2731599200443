import React from 'react';
import styles from '../../../assets/css/styling.module.css';

const Project_View = (props) => {

    return (
        <div className={props.class + " mb-3"}>

            <div className="_activity_card_" style={{ backgroundImage: props.images ? `url(${props.images})` : `url(${'https://unsplash.it/600/800?image=1061'})` }}>
                <div className="title-content">
                    <h3>
                        {/* Title */}
                        <a href="#">{props.title}</a>
                    </h3>
                    {/* <div className="intro"> <a href="#">Inspiration</a> </div> */}
                </div>
                <div className="card-info">

                    {/* Project Details */}
                    <p dangerouslySetInnerHTML={{ __html: props.details }}></p>

                    {/* More */}
                    <a onClick={props.link}>More<span className="licon icon-arr icon-black" /></a>
                </div>
                <div className="utility-info">

                    {/* Bottom Content */}
                    <div className="clearfix mb-4 px-4">
                        <div className="float-left">

                            <div className="d-flex">
                                <div className="d-inline-flex">

                                    {/* Calender Icon */}
                                    <span className={styles.group_form_icons + " " + styles.calender_icon}></span>
                                </div>

                                <div className="d-inline-flex align-self-center mx-2">

                                    <div style={{ fontSize: '0.95rem', color: '#666' }}>

                                        {/* Deadline Date */}
                                        <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>Deadline {props.deadline}</span>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="float-right">

                            <div className="d-flex pt-1">
                                <div className="d-inline-flex align-self-center">

                                    {/* Complete Percentage */}
                                    <span style={{ fontSize: '0.9rem', fontWeight: '500', color: '#999' }}>{props.completePercentage}% completed</span>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="gradient-overlay" />
                <div className="color-overlay" />
            </div>
        </div >
    )

}

export default Project_View;