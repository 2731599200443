import React, { useState, useEffect } from 'react'
import '../../../../assets/components/pageheader/PageHeader'
import styles from '../../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import Search from '../../../../assets/images/search_icon.png'
import {
    Card, CardBody,
} from "reactstrap";

import Listing from '../../../../assets/components/listing/Listing'

import CopyIcon from '../../../../assets/images/copy_icon.png'
import Eyeicon from '../../../../assets/images/eye_icon.png'
import Deleteicon from '../../../../assets/images/delete_icon.png'
import Editicon from '../../../../assets/images/edit_icon.png'
import ResizeDetector from "react-resize-detector";
import AlertModal from '../../../../assets/components/modal/AlertModal';

const CampaignManagement = () => {

    const [delId, setDelID] = useState()
    const [column] = useState([
        { Name: 'Serial No', Type: 'number' },
        { Name: "Name of Campaigns", Type: 'string' },
        { Name: "Created Date Time", Type: 'date' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    url: "/admin/view-campaign-template",
                    type: 'button'
                },
                {
                    icon: Editicon,
                    url: "/admin/edit-campaign-template",
                    type: 'button'
                },
                {
                    icon: Deleteicon,
                    setID: setDelID,
                    type: 'delete'
                },
                {
                    icon: CopyIcon,
                    url: "/admin/create-campaign-template",
                    type: 'button'
                },

            ]
        }
    ])

    const [data] = useState([
        // { SerialNo: '04', NameofCampaigns: 'Happy New Year', CreatedDateTime: '01/01/2020 1:00 PM' },
        // { SerialNo: '04', NameofCampaigns: 'HAppy New Year', CreatedDateTime: '01/01/2020 1:00 PM' },
        // { SerialNo: '04', NameofCampaigns: 'HAppy New Year', CreatedDateTime: '01/01/2020 1:00 PM' },
        // { SerialNo: '04', NameofCampaigns: 'HAppy New Year', CreatedDateTime: '01/01/2020 1:00 PM' },
        // { SerialNo: '04', NameofCampaigns: 'HAppy New Year', CreatedDateTime: '01/01/2020 1:00 PM' },
    ])

    const [isModalOpen, setModal] = useState(false)

    const showModal = () => {
        setDelID()
        setModal(!isModalOpen)
    }

    // Show / hide modal
    useEffect(() => {
        delId ? setModal(true) : setModal(false)
    }, [delId])

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                    transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

                    {/* Delete Modal */}
                    <AlertModal isModalOpen={isModalOpen} setModal={showModal} />

                    <Card className="main-card mb-3">
                        <nav class="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#006FA7', borderRadius: '5px 5px 0 0' }}>
                            <div className="text-white menu-header-title text-capitalize">Campaign List</div>

                            <div className={width > 470 && "collapse navbar-collapse"}>
                                <ul className="navbar-nav mr-auto">
                                </ul>
                                <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2.5rem' }}>
                                    <div className="input-group input-group-sm w-75">
                                        <div className="input-group-prepend border-0">
                                            <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                        </div>
                                        <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search" placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} />
                                    </div>

                                </form>
                            </div>
                        </nav>

                        <CardBody className="p-0">
                            <Listing columnName={column} data={data} />
                        </CardBody>
                    </Card>
                </TransitionGroup>
            )}
        />
    )
}

export default CampaignManagement
