import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { createExcerpt } from '../../../../../../actions/excerpts_actions'
import styles from '../../../../../../assets/css/rstyling.module.css'
import Swal from 'sweetalert'
import { useDispatch } from 'react-redux'

const CreateExcerpt = (props) => {

    const { isModalOpen, showModal, data, setData } = props
    const dispatch = useDispatch()

    const saveExcerpt = () => {
        const details = {
            selectedString: data.selectedString,
            note: data.note,
            activity: data.activity,
            responseAuthor: data.responseAuthor._id
        }
        dispatch(createExcerpt(details))
            .then(response => {
                // setData()
                showModal()
                Swal({
                    title: "Saved",
                    text: "Excerpt Saved",
                    icon: 'success'
                })
            })
            .catch(err => {
                Swal({
                    title: err.content ? err.content : "Error",
                    text: "Please try again!",
                    icon: 'error'
                });
            })
    }

    return (
        <Modal isOpen={isModalOpen} toggle={showModal} id={`ImageModal`} className="modal-dialog-centered shadow-none" size="lg">
            <ModalBody>
                <div className="text-right close" onClick={() => showModal()} style={{ cursor: 'pointer' }}>&times;</div>
                <div className="container-fluid mt-3">

                    <div className="row">

                        <div className="col-12">
                            <p className="text-muted m-0" style={{ fontWeight: '500' }}>Excerpts</p>
                            <p>{data.selectedString}</p>
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="notes">Add Notes</label>
                            <textarea className="form-control" type="text" name="notes" id="notes" value={data.note} rows="4" cols="50"
                                onChange={(e) => setData({ ...data, note: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 col-sm-12 col-12">
                            <p className="text-muted m-0" style={{ fontWeight: '500' }}>From Response</p>
                            <div className={`d-flex ${styles.text_bold}`}>
                                <img src={data.responseAuthor && data.responseAuthor.profileImage} alt="" width="25" height="25" className="rounded-circle mr-2" />
                                <p className={" align-self-center"}>{data.responseAuthor && data.responseAuthor.userName}</p>
                            </div>
                        </div>

                        <div className="col-12 text-right">
                            <Button className={` ${styles.bg_blue} mr-3`} onClick={saveExcerpt}>
                                Save
                            </Button>
                            <Button className={` bg-light text-muted`}
                                style={{ border: '1px solid #6c757d ' }}
                                onClick={showModal}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </div>
            </ModalBody>
        </Modal>
    )
}

export default CreateExcerpt