import React, { useState } from "react";

const Pagination = (props) => {
    const { currentPage, totalPages, listingDataLimit, setListingDataLimit, startLoading } = props

    

    const onPrevious = (e) => {
        e.preventDefault()
        startLoading()
        props.getListingData(currentPage - 1, listingDataLimit)
    }

    const onNext = (e) => {
        e.preventDefault()
        startLoading()
        props.getListingData(currentPage + 1, listingDataLimit)
    }

    const onPageChange = (e) => {
        e.preventDefault()
        startLoading()

        props.getListingData(e.target.value, listingDataLimit)
    }

    const onDataLimitChange = (e) => {
       
        e.preventDefault()
        startLoading()

        setListingDataLimit(e.target.value)
        props.getListingData(currentPage, e.target.value)
    }

    return (
        <div className="ReactTable" >
            <div class="pagination-bottom">
                <div class="-pagination">
                    {/* previous */}
                    <div class="-previous">
                        <button type="button" class="-btn" onClick={(e) => onPrevious(e)} disabled={currentPage === 1 ? true : false}>Previous</button>
                    </div>
                    {/* page no input */}
                    <div class="-center"><span class="-pageInfo">Page <div class="-pageJump">
                        <input aria-label="jump to page" type="number" value={currentPage} onChange={(e) => onPageChange(e)} />
                    </div> of <span class="-totalPages">{totalPages}</span></span><span class="select-wrap -pageSizeOptions">
                            <select aria-label="rows per page" onChange={(e) => onDataLimitChange(e)}>
                                <option value="5">5 rows</option>
                                <option value="10">10 rows</option>
                                <option value="20">20 rows</option>
                                <option value="25">25 rows</option>
                                
                            </select></span></div>
                    {/* next btn */}
                    <div class="-next">
                        <button type="button" class="-btn" onClick={(e) => onNext(e)} disabled={currentPage >= totalPages ? true : false}>Next</button></div>
                </div>
            </div>
        </div>

    )
}

export default Pagination
